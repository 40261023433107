import React, { useCallback } from 'react';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { backToPreviousPage } from 'actions/profileDetails';
import { useDispatch, useSelector } from 'utils/redux';

import Loader from './Loader'

const sxDrawer = {
  left: 64,
  [`.${drawerClasses.paper}`]: {
    left: 64,
  },
};

const selector = ({views}) => ({
	open: views.profileDetails.show,
});

const ProfileDrawer = () => {
	const dispatch = useDispatch();

	const { open } = useSelector(selector);

	const onCloseDrawer = useCallback(
		event => {
			if(event?.type === 'keydown' && event?.key === 'Escape') {
				dispatch(backToPreviousPage())
			}
		},
		[dispatch]
	);

	return (
		<Drawer
			anchor="bottom"
			open={open}
			onClose={onCloseDrawer}
			hideBackdrop
			elevation={0}
			sx={sxDrawer}
		>
			<Loader />
		</Drawer>
	)
}

export default ProfileDrawer;
