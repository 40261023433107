/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { nanoid } from 'nanoid';

import { USER_ROLES } from 'constants/states';
import * as USER from 'constants/user';
import * as MESSAGING from 'constants/messaging';
import { filters as initialFilters, initSearchFilter } from 'config/init';
import { User } from 'types/user';

const intialState: User = {
	profile: {},
	role: USER_ROLES.GUEST,
	hasTeam: false,
	filters: {
		...initialFilters,
		sid: 'init',
	} as unknown as User['filters'],
	contactCategory: [],
	hasMonitorings: false,
	canUseGmail: false,
	canExport: false,
	isFromKolsquare: false,
	sendingMethod: 'kolsquare',
	hideGmailConnect: false,
	unacknowledgedFeatures: [],
	integrations: {
		loaded: false,
	},
	initialWsData: {
		banner_message: null,
		notifications: null,
	},
	locale: 'en',
	timezone: '',
	dateFormat: '',
	currency: undefined,
	features: {},
	tags: []
};

const reducers = {
	[USER.UPDATE]: (state, { payload: { key, data }}) => {
		state[key] = data
	},

	[USER.PARAMS_LOADED]: (state, { payload }) => update(state, {
		$merge: payload
	}),
	[USER.PROFILE_PARAMS_LOADED]: (state, { payload }) => update(state, {
		profile: {
			$merge: payload,
		}
	}),

	[USER.LICENCE_UPDATE]: (state, { payload: licence }) => {
		state.profile.licence = licence;
		state.canExport = licence.canExport || state.profile?.admin;
	},
	[USER.INFO_UPDATE]: (state, { payload: { firstName, lastName, phone }}) => update(state, {
		profile: {
			firstName: { $set : firstName },
			lastName: { $set : lastName },
			phone: { $set: phone },
		}
	}),
	[USER.EMAIL_UPDATE]: (state, { payload }) => update(state, {
		profile: {
			email: { $set: payload }
		}
	}),

	[USER.ACTIVE_FILTER]: (state, { payload: { value }}) => update(state, {
		filters: {
			hasActiveFilter: {
				$set: value
			},
		}
	}),

	[USER.SELECTED_FILTER]: (state, { payload: { value, field }}) => update(state, {
		filters: {
			[field]: {
				$set: value
			},
			sid: { $set: nanoid() }
		}
	}),

	[USER.RESET_ALL_NETWORK_FILTERS]: state => update(state, {
		filters: {
			socialNetworks: {
				$merge: {
					...initSearchFilter.socialNetworks
				}
			}
		}
	}),

	[USER.RESET_NETWORK_FILTERS]: (state, { payload: { network }}) => update(state, {
		filters: {
			socialNetworks: {
				[network]: {
					$merge: {
						...initSearchFilter.socialNetworks[network]
					}
				}
			}
		}
	}),

	[USER.UPDATE_FILTER_INFLUENCER]: (state, { payload: { field, value }}) => update(state, {
		filters: {
			[field]: { $set: value },
			sid: { $set: nanoid() }
		}
	}),

	[USER.UPDATE_FILTER_NETWORK]: (state, { payload: { network, field, value }}) => update(state, {
		filters: {
			socialNetworks: {
				[network]: {
					[field]: { $set: value },
				},
			},
			sid: { $set: nanoid() }
		}
	}),

	[USER.UPDATE_FILTERS]: (state, { payload }) => update(state, {
		filters: {
			$merge: {
				...payload
			}
		}
	}),

	[USER.UPDATE_FILTER]: (state, { payload: { field, value } }) => update(state, {
		filters: {
			[field]: { $set: value },
			sid: { $set: nanoid() }
		}
	}),

	[USER.UPDATE_STATE]: (state, { payload }) => update(state, {
		$merge: {
			...payload
		},
	}),

	[USER.RESET_UPDATE_FILTERS]: (state, { payload }) => update(state, {
		filters: {
			$merge: {
				...initialFilters,
				...payload
			},
		}
	}),

	[USER.RESET_FILTERS]: state => update(state, {
		filters: { $merge: {
			...initialFilters,
			sid: nanoid()
		}}
	}),

	[USER.RESET_FILTERS_WHITOUT_SEARCH]: (state, { payload }) => update(state, {
		filters: { $merge: {
			...initialFilters,
			search: payload.value,
		}}
	}),

	[USER.TAGS_SET]: (state, { payload:tags }) => update(state, {
		tags: {
			$set: tags
		}
	}),

	[USER.SENDING_METHOD]: (state, { payload: sendingMethod }) => {
		state.sendingMethod = sendingMethod
	},

	[USER.HIDE_GMAIL_CONNECT]: (state, { payload: hideGmailConnect }) => {
		state.hideGmailConnect = hideGmailConnect
	},

	[USER.SET_USER_UNACKOWNLEDGE_FEATURES]: (state, { payload: value }) => {
		state.unacknowledgedFeatures = state.unacknowledgedFeatures.filter(item => item !== value);
	},

	[USER.SAVE_INTEGRATIONS]: (state, { payload: data }) => update(state, {
		canUseGmail: { $set: !!data?.gmail_account?.id },
		integrations: {
			$merge: {
				...data,
				loaded: true,
			}
		}
	}),

	[USER.UPDATE_INTEGRATIONS]: (state, { payload: data }) => {
		state.canUseGmail = !!data?.gmail_account?.id;
		state.integrations = {
			...state.integrations,
			...data,
		}
	},

	[MESSAGING.SET_GMAIL_ADDRESS]: (state, { payload: gmailAddress }) => {
		state.canUseGmail = !!gmailAddress
	},

	[USER.CLEAR_INITIAL_BANNER_MESSAGE]: (state) => {
		state.initialWsData.banner_message = intialState.initialWsData.banner_message;
	},
};

export default createReducer(intialState, reducers);
