import { get, patch, post } from './index';

export const getBrandInfos = () => get({
  endpoint: `influence/brand_monitorings`,
  catchErrors: [404]
});

export const saveMention = (params) => post({
  endpoint: `influence/brand_monitorings`,
  params,
});

export const updateMention = ({ params, id }) => patch({
  endpoint: `influence/brand_monitorings/${id}`,
  params,
});

export const getTableStats = ({ params, id })  => get({
  endpoint: `influence/brand_monitorings/${id}/table_stats`,
  params,
});

export const getGrowthStats = ({ params, id })  => get({
  endpoint: `influence/brand_monitorings/${id}/followers_graph`,
  params,
  catchErrors: [404]
});

export const getMedias = ({ params, id }) => get({
  endpoint: `influence/brand_monitorings/${id}/posts`,
  params,
});