import mapper from 'mappers/myInfluencers';

import { get, post, put, remove } from './index';

export const getProfilesList = params => get({
	endpoint: `influence/profile_lists`,
	params,
});

export const getAllProfilesIds = query => get({
	endpoint: `influence/profile_lists/profile_ids`,
	params: mapper.getProfilesList.toApi(query),
});

export const getSettingsColumns = () => get({
  endpoint: `influence/v2/mykol_dashboards`,
  debug: true,
});

export const updateSettingsColumns = data => put({
  endpoint: `influence/v2/mykol_dashboards`,
  params: {
    mykol_dashboard: {
      columns: JSON.stringify(data)
    }
  },
  debug: true,
});

export const getAccountCreationRequests = ({ page, per_page, status }) =>
  get({
    endpoint: `influence/account_creation_requests`,
    params: { page, per_page, status: status === 'all' ? null : status },
  });


/**
 *
 * LIST
 */

export const getCustomList = () => get({
  endpoint: `influence/custom_lists`,
});

export const createCustomList = (name, project_ids) => post({
  endpoint: `influence/custom_lists`,
  params: { name, project_ids },
  catchErrors: [422],
});

export const updateCustomList = ({ name, listId, project_ids }) => put({
  endpoint: `influence/custom_lists/${listId}`,
  params: { id: listId, name, project_ids },
  catchErrors: [422],
});

export const deleteCustomList = listId => remove({
  endpoint: `influence/custom_lists/${listId}`,
  debug: true,
});

/**
 *
 * AUDIENCE OVERLAP
 */

export const createOrUpdateAudienceOverlap = ({ listId, params }) => post({
  endpoint: `influence/custom_lists/${listId}/overlap_analyses`,
  params,
  debug: {},
  catchErrors: [404]
});

export const getAudienceOverlapStatus = ({ listId, params }) => post({
  endpoint: `influence/custom_lists/${listId}/overlap_analyses`,
  params,
  catchErrors: [404, 422]
});

export const getAudienceOverlapStatistics = listId => get({
  endpoint: `influence/custom_lists/${listId}/overlap_analyses`,
  catchErrors: [404]
});

export const exporAudienceOverlaResults = listId => post({
  endpoint: `influence/custom_lists/${listId}/overlap_analyses/export`,
  dubg: true,
  catchErrors : [404, 422]
});

/**
 *
 * FIELDS
 */

export const getCustomFields = () => get({
  endpoint: `influence/custom_fields`,
});

export const createCustomField = ({ name, type }) => post({
  endpoint: `influence/custom_fields`,
  params: { name, field_type: type },
  catchErrors: [422],
});

export const updateCustomField = ({ name, fieldId }) => put({
  endpoint: `influence/custom_fields/${fieldId}`,
  params: { id: fieldId, name },
  catchErrors: [422],
});

export const deleteCustomField = fieldId => remove({
  endpoint: `influence/custom_fields/${fieldId}`,
  debug: true,
});


/**
 *
 * IMPORT CSV
 */

export const importCsv = ({ listName, data, validateOnly, fileName }) => post({
  endpoint: `influence/profiles/imports`,
  params: {
    import: {
      validate_only: validateOnly,
      file_name: fileName,
      list_name: listName,
      data
    }
  },
  debug: true,
  catchErrors: [422],
});

