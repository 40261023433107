export const COUNTRIES_KEYS = [
  'BD',
  'BE',
  'BF',
  'BG',
  'BA',
  'BB',
  'WF',
  'BL',
  'BM',
  'BN',
  'BO',
  'BH',
  'BI',
  'BJ',
  'BT',
  'JM',
  'BV',
  'BW',
  'WS',
  'BQ',
  'BR',
  'BS',
  'JE',
  'BY',
  'BZ',
  'RU',
  'RW',
  'RS',
  'TL',
  'RE',
  'TM',
  'TJ',
  'RO',
  'TK',
  'GW',
  'GU',
  'GT',
  'GS',
  'GR',
  'GQ',
  'GP',
  'JP',
  'GY',
  'GG',
  'GF',
  'GE',
  'GD',
  'GB',
  'GA',
  'SV',
  'GN',
  'GM',
  'GL',
  'GI',
  'GH',
  'OM',
  'TN',
  'JO',
  'HR',
  'HT',
  'HU',
  'HK',
  'HN',
  'HM',
  'VE',
  'PR',
  'PS',
  'PW',
  'PT',
  'SJ',
  'PY',
  'IQ',
  'PA',
  'PF',
  'PG',
  'PE',
  'PK',
  'PH',
  'PN',
  'PL',
  'PM',
  'ZM',
  'EH',
  'EE',
  'EG',
  'ZA',
  'EC',
  'IT',
  'VN',
  'SB',
  'ET',
  'SO',
  'ZW',
  'SA',
  'ES',
  'ER',
  'ME',
  'MD',
  'MG',
  'MF',
  'MA',
  'MC',
  'UZ',
  'MM',
  'ML',
  'MO',
  'MN',
  'MH',
  'MK',
  'MU',
  'MT',
  'MW',
  'MV',
  'MQ',
  'MP',
  'MS',
  'MR',
  'IM',
  'UG',
  'TZ',
  'MY',
  'MX',
  'IL',
  'FR',
  'IO',
  'SH',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'NI',
  'NL',
  'NO',
  'NA',
  'VU',
  'NC',
  'NE',
  'NF',
  'NG',
  'NZ',
  'NP',
  'NR',
  'NU',
  'CK',
  'XK',
  'CI',
  'CH',
  'CO',
  'CN',
  'CM',
  'CL',
  'CC',
  'CA',
  'CG',
  'CF',
  'CD',
  'CZ',
  'CY',
  'CX',
  'CR',
  'CW',
  'CV',
  'CU',
  'SZ',
  'SY',
  'SX',
  'KG',
  'KE',
  'SS',
  'SR',
  'KI',
  'KH',
  'KN',
  'KM',
  'ST',
  'SK',
  'KR',
  'SI',
  'KP',
  'KW',
  'SN',
  'SM',
  'SL',
  'SC',
  'KZ',
  'KY',
  'SG',
  'SE',
  'SD',
  'DO',
  'DM',
  'DJ',
  'DK',
  'VG',
  'DE',
  'YE',
  'DZ',
  'US',
  'UY',
  'YT',
  'UM',
  'LB',
  'LC',
  'LA',
  'TV',
  'TW',
  'TT',
  'TR',
  'LK',
  'LI',
  'LV',
  'TO',
  'LT',
  'LU',
  'LR',
  'LS',
  'TH',
  'TF',
  'TG',
  'TD',
  'TC',
  'LY',
  'VA',
  'VC',
  'AE',
  'AD',
  'AG',
  'AF',
  'AI',
  'VI',
  'IS',
  'IR',
  'AM',
  'AL',
  'AO',
  'AQ',
  'AS',
  'AR',
  'AU',
  'AT',
  'AW',
  'IN',
  'AX',
  'AZ',
  'IE',
  'ID',
  'UA',
  'QA',
  'MZ',
];

export const INTERESTS_KEYS = [
  'television_and_film',
  'music',
  'shopping_and_retail',
  'coffee_tea_and_beverages',
  'camera_and_photography',
  'clothes_shoes_handbags_and_accessories',
  'beer_wine_and_spirits',
  'sport_and_fitness',
  'electronics_and_computers',
  'gaming',
  'activewear',
  'art_and_design',
  'travel_tourism_and_aviation',
  'business_and_careers',
  'healthcare_and_medicine',
  'jewellery_and_watches',
  'restaurants_food_and_grocery',
  'toys_children_and_baby',
  'wedding',
  'tobacco_and_smoking',
  'pets',
  'healthy_lifestyle',
  'home_decor_furniture_and_garden',
  'friends_family_and_relationships',
  'cars_and_motorbikes',
  'luxury_goods',
  'beauty_and_cosmetics',
];

export const KOL_THEME_KEYS = [
  // 'adult',
  'animals',
  'achitecture_design',
  'beauty',
  'wellness_health',
  'business',
  'culture',
  'education_knowledge',
  'entertainment',
  'family',
  'food_drinks',
  'gaming',
  'humor',
  'lifestyle',
  'creative_hoobies',
  'luxury',
  'mode_accessories',
  'music',
  'nature_environment',
  'news',
  // 'politic',
  'science',
  'social',
  'sport_fitness',
  'tech',
  'vehicules',
  'traval',
  'photography',
  // 'reality_show',
];

export const LANGUAGES_KEYS = [
  'fra',
  'eng',
  'deu',
  'spa',
  'por',
  'ita',
  'nld',
  'ell',
  'jpn',
  'zho',
  'kor',
];

export const AUDIENCE_LANGUAGES_KEYS = [
  'en',
  'es',
  'pt',
  'ru',
  'ar',
  'fr',
  'it',
  'de',
  'id',
  'fa',
  'tr',
  'ja',
  'pl',
  'th',
  'zh',
  'ko',
  'hi',
  'ms',
  'arz',
  'nl',
  'uk',
  'ne',
  'az',
  'pa',
  'he',
  'sv',
  'gu',
  'ckb',
  'vi',
  'kk',
  'cs',
  'ro',
  'mr',
  'ky',
  'hu',
  'el',
  'ur',
  'fi',
  'no',
  'ca',
  'da',
  'sr',
  'sw',
  'bg',
  'hr',
  'ml',
  'bn',
  'sq',
  'tl',
  'sk',
  'ps',
  'sh',
  'sl',
  'km',
  'ceb',
  'mk',
  'ta',
  'ce',
  'hy',
  'kn',
  'lt',
  'gl',
  'af',
  'et',
  'si',
  'azb',
  'lv',
  'pnb',
  'lo',
  'bs',
  'uz',
  'ka',
  'mzn',
  'am',
  'is',
  'gd',
  'tt',
  'as',
  'ku',
  'sah',
  'my',
  'sd',
  'mn',
  'war',
  'te',
  'tg',
  'ie',
  'jv',
  'so',
  'be',
  'ia',
  'als',
  'fy',
  'nds',
  'gn',
  'jbo',
  'mhr',
  'ug',
  'ba',
  'nap',
  'su',
  'or',
  'mg',
  'min',
  'ilo',
  'bo',
  'io',
  'tk',
  'ht',
  'ga',
  'kw',
  'pam',
  'bh',
  'lez',
  'ast',
  'pms',
  'yue',
  'yi',
  'xh',
  'hsb',
  'sco',
  'lrc',
  'zu',
  'bar',
  'new',
  'cv',
  'xmf',
  'mai',
  'rw',
  'cbk',
  'sa',
  'gv',
  'krc',
  'dv',
  'eml',
  'wuu',
  'bpy',
  'rm',
  'bcl',
  'lmo',
  'sc',
  'scn',
  'mrj',
  'os',
  'kv',
  'li',
  'xal',
  'yo',
  'myv',
  'vep',
  'tyv',
  'diq',
  'gom',
  'bxr',
  'vec',
  'mwl',
  'nah',
  'vls',
  'dsb',
  'pfl',
  'dty',
  'av',
  'co',
  'frr',
  'rue',
  'other',
];

export const AGE_KEYS = [
	'13to17',
	'18to24',
	'25to34',
	'35to44',
	'45to64',
	'65more',
];

export const AGE_KEYS_SNAPCHAT = [
	'13to17',
	'18to24',
	'25to34',
	'35more',
];
