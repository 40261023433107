export const removeUndefinedFields = input =>
  Object.entries(input).reduce((output, [key, value]) => {
    if (value === undefined) return output;
    return {
      ...output,
      [key]: value,
    };
  }, {});

//  Only pick some fields from a given object
export const pickFields = (input, keys) =>
  keys.reduce(
    (output, key) => ({
      ...output,
      [key]: input[key],
    }),
    {},
  );
