export const LOAD_MESSAGES = '[Messaging] --> load list messages';
export const LOAD_MESSAGE = '[Messaging] --> load message';
export const LOAD_CONVERSATION_MESSAGE = '[Messaging] --> load conversation messages';
export const UPDATE_INBOX_WEBSOCKET = '[Messaging] -> update list message from websocket';
export const SORT_INBOX_LIST = '[Messaging] -> sort list inbox';
export const CONVERSATION_MESSAGE_UPDATE = '[Messaging] -> update conversation message from websocket';
export const UP_NEW_MESSAGE = '[Messaging] --> Up new message in list';

export const CREATE_MESSAGE = '[Messaging] --> create message';
export const HANDLE_MESSAGE = '[Messaging] --> handle message';
export const RELOAD_MESSAGE = '[Messaging] --> force reload messages';
export const CLEAR_MESSAGE = '[Messaging] --> clear message';
export const RESET_MESSAGE = '[Messaging] --> reset message';

export const LOAD_DRAFTS = '[Messaging] --> load drafts';
export const LOAD_DRAFT = '[Messaging] --> load draft';
export const POST_DRAFT = '[Messaging] --> post draft';
export const DELETE_DRAFT = '[Messaging] --> delete draft';
export const UPDATE_DRAFT = '[Messaging] --> update draft';

export const LOAD_PROFILES = '[Messaging] --> load profiles';

export const SEARCH_PROFILES = '[Messaging] --> search profiles';
export const LOAD_CAMPAIGN = '[Messaging] --> load & search campaign';

export const HANDLE_SELECTION = '[Messaging] --> handle selection profiles';

export const SET_RECIPIENTS = '[Messaging] --> set recipients';

export const DISPLAY_PROFILE = '[Messaging] --> Display profile details';

export const HANDLE_VIEW = '[Messaging] --> display panel';
export const TOGGLE_KOL_TO_CAMPAIGN = '[Messaging] --> Toggle display kol to campaign';

export const SET_CAMPAIGN = '[Messaging] --> store campaign id';
export const RESET_CAMPAIGN = '[Messaging] --> reset selected campaign';

export const TEMPLATES_LOADED = '[Messaging] --> Templates loaded';
export const TEMPLATE_DELETED = '[Messaging] --> Template deleted';

export const GET_FROM = '[Messaging] --> get from page';

export const READ_STATUS = '[Messaging] --> change read status';

export const SET_GMAIL_ADDRESS = '[Messaging] --> set gmail address';

export const SEARCH_QUERY = '[Messaging] --> search query';
export const SET_STATUS_FILTER = '[Messaging] --> filter by status';

export const SET_ALL_UNREAD_MESSAGES = '[Messaging] --> set all unread message count';

export const SET_CURRENT_CAMPAIGN_ID = 'Messaging --> set current campaign id';

export const SET_NEW_SUBJECT = '[Messaging] --> set new subject';

export const SAVE_PREVIEW = '[Messaging] --> save preview';
export const CLEAR_PREVIEW = '[Messaging] --> clear preview';

