import { get, post, remove } from 'api/index';

export const loadProfileCore = kolId => get({
  endpoint: `influence/v2/profiles/${kolId}`,
  catchErrors: [404],
  debug: true
});

export const getBrandCollaborations = ({ profileId, snaId, period, page, per_page }) => get({
  endpoint: `influence/v2/profiles/${profileId}/my_brands_mentions`,
  params: { sna_id: snaId, period, page, per_page },
  debug: true,
});

export const getTopMentions = ({ kolId, filters }) => get({
  endpoint: `influence/profiles/${kolId}/${filters?.mentioned ? 'my_mentions':'who_mentioned_me'}`,
  params: filters,
  catchErrors: [404]
});

export const loadTabPerformance = kolId => get({
  endpoint: `influence/v2/profiles/${kolId}/performance`,
  catchErrors: [404],
  debug: true
});

export const loadTabPerformanceSna = ({ kolId, snaId }) => get({
  endpoint: `influence/v2/profiles/${kolId}/performance`,
  params: {
    social_network_account_id: snaId
  },
  catchErrors: [404],
  debug: true
});

export const loadProfileTopHashtags = kolId => get({
  endpoint: `influence/profiles/${kolId}/top_hashtags`,
  catchErrors: [404],
});

export const getFollowersGrowth = ({
  profile_id,
  social_network_account_id,
  start_at,
  end_at,
  interval,
}) => get({
  endpoint: `influence/v2/profiles/${profile_id}/performance_graphs`,
  params: {
    start_at,
    end_at,
    interval,
    social_network_account_id
  },
});

export const loadPerformanceGraph = ({ kolId, snaId, startAt }) => get({
  endpoint: `influence/profiles/${kolId}/accounts/${snaId}`,
  params: { start_at: startAt },
  catchErrors: [404],
});

export const getTags = kolId => get({
  endpoint: `influence/profiles/${kolId}/taggings`,
  debug: [{ id: 1, value: 'Michel' }],
  catchErrors: [404]
});

export const updateTags = ({ profile_ids, tag_ids }) => post({
  endpoint: `influence/profiles/taggings/batch`,
  params: { profile_ids, tag_ids },
  debug: true,
});

export const removeTags = ({ profile_ids, tag_ids }) => remove({
  endpoint: `influence/profiles/taggings/batch_destroy`,
  params: { profile_ids, tag_ids },
  debug: true
});

export const getCollaborationDetails = kolId => get({
  endpoint: `influence/profiles/${kolId}/detail`,
  debug: {
    pseudo: 'Jean Jean',
    first_name: 'Jean',
    last_name: 'Jean',
    affilae_id: '',
    address: 'WMD Ltd, Baghdad, Iraq',
    zip_code: 'UXB POP',
    city: 'Baghdad',
    country: 'Iraq',
    phone_number: '0606060606',
    agent_name: 'John Smith',
    agent_email: `john.smith@manager.com`,
    agent_phone_number: '0606060606',
    invested_budget: '140000',
    invested_budget_currency: 'EUR',
  },
  catchErrors: [404]
});

export const getProfileLists = profileId => get({
  endpoint: `influence/profiles/${profileId}/lists`,
  debug: [5000, 753],
  catchErrors: [404]
});

export const getProfileCustomFields = kolId => get({
  endpoint: `influence/profiles/${kolId}/custom_fields`,
  catchErrors: [404],
});

export const removeProfileCustomFields = ({ fieldIds, kolId }) => remove({
  endpoint: `influence/profiles/${kolId}/custom_fields/batch`,
  params: {
    custom_field_ids: fieldIds,
  },
  catchErrors: [422],
  debug: true,
});
