import { createAction } from 'redux-actions';
import { AppThunk } from 'config/store';
import { setControlledLoader } from 'actions/ui';
import { MEDIAS_PER_PAGE } from 'config/followersIncrease';
import * as mappers from 'mappers/followersIncrease';
import * as api from 'api/followersIncrease';
import * as FOLLOWERS_INCREASE from 'constants/followersIncrease';

const loadBrandInfos = createAction(FOLLOWERS_INCREASE.BRAND_INFOS_LOADED);
const loadStats = createAction(FOLLOWERS_INCREASE.STATS_LOADED);
const loadGrowthStats = createAction(FOLLOWERS_INCREASE.GROWTH_STATS_LOADED);
export const updateSettings = createAction(FOLLOWERS_INCREASE.SETTINGS_CHANGED);
const updateContents = createAction(FOLLOWERS_INCREASE.CONTENTS_LOADED);

export const getBrandInfos = (): AppThunk<void> => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: true }));
  const response = await api.getBrandInfos();
  const { startDateLimit, ...brandInfos} = mappers.brandsInfos.fromApi(response);
  dispatch(updateSettings({ startDateLimit }));
  dispatch(loadBrandInfos(brandInfos));
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: false }));
};

export const saveMention = (mention: string): AppThunk<void> => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: true }));
  const params = mappers.brandsInfos.toApi(mention);
  const response = await api.saveMention(params);
  const brandInfos = mappers.brandsInfos.fromApi(response);
  dispatch(loadBrandInfos(brandInfos));
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: false }));
};

export const updateMention = ({mention, id} : {mention: string, id: number}): AppThunk<void> => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: true }));
  const params = mappers.brandsInfos.toApi(mention)
  const response = await api.updateMention({params, id});
  const brandInfos = mappers.brandsInfos.fromApi(response);
  dispatch(loadBrandInfos(brandInfos));
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: false }));
};

export const getGrowthStats = (): AppThunk<void> => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'followersGrowthLoader', show: true }));
  const { id } = getState().followersIncrease.brandMonitoring;
  const { dateRanges: { startDate, endDate }, timeRange } = getState().followersIncrease.settings;
  const params = mappers.getGrowthStats.toApi({
    startDate,
    endDate,
    timeRange,
  });
  const response = await api.getGrowthStats({id, params});
  if (response?.error) {
    dispatch(loadGrowthStats(({
      error: response?.payload?.error
    })));
  } else {
    const stats = mappers.getGrowthStats.fromApi(response);
    dispatch(loadGrowthStats(stats));
  }
  dispatch(setControlledLoader({ id: 'followersGrowthLoader', show: false }));
};

export const getStats = (): AppThunk<void> => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: true }));
  const { id } = getState().followersIncrease.brandMonitoring;
  if (!id) return;
  const {
    followersIncrease: {
      settings: { dateRanges },
    },
  } = getState();

  const params = mappers.getStats.toApi(dateRanges);
  const response = await api.getTableStats({ id, params });
  const stats = mappers.getStats.fromApi(response);
  dispatch(loadStats(stats));
  dispatch(setControlledLoader({ id: 'followersIncreaseLoader', show: false }));
};

export const updateSettingsFilters = (newFilters) => (dispatch, getState) =>{
  const { settings } = getState().followersIncrease;
  dispatch(updateSettings({
    ...settings,
    filters: {
      ...settings.filters,
      ...newFilters
    }
  }));
}

export const loadContents = (): AppThunk<void> => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'updateFIContents', show: true }));
  const {
    brandMonitoring: {
      id
    },
    settings: {
      filters: {
        selectedPeriod,
        sources,
        contentTypes,
        contentSort,
        currentPage,
      }
    }
  } = getState().followersIncrease;
  if (selectedPeriod) {
    const params = mappers.getContents.toApi({
      sources,
      contentTypes,
      contentSort,
      currentPage,
      perPage: MEDIAS_PER_PAGE,
      startAt: selectedPeriod?.startDate,
      endAt: selectedPeriod?.endDate,
    })
    const res = await api.getMedias({ id, params });
    const dataMapped = mappers.getContents.fromApi(res);
    dispatch(updateContents(dataMapped));
  }
  dispatch(setControlledLoader({ id: 'updateFIContents', show: false }));
};
