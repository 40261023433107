import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'config/store';

import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import { DEFAULT_WORKFLOW_NAME } from 'config/campaignFlow';

import * as api from './campaignWorkflow.api';
import { Status, Workflow, WorkflowEdition } from './campaignWorkflow.types';

export const getCampaignWorkflows = createAsyncThunk<
  {
    workflows: Array<Workflow> | null;
    current_workflow_id: number | null;
    team_statuses: Array<Status>;
  },
  { project_id: number },
  { state: RootState }
>('campaign/workflows/getCampaignWorkflows', async ({ project_id }) => {
  const response = await api.getCampaignWorkflows({ project_id });
  if (!response?.error) {
    return Promise.resolve({
      workflows: response.workflows,
      current_workflow_id: response.current_workflow_id || null,
      team_statuses: response.team_statuses,
    });
  } else {
    return Promise.reject(response.error);
  }
});

export const addNewWorkflow = createAsyncThunk<
  Workflow,
  WorkflowEdition,
  { state: RootState }
>('campaign/workflows/addNewWorkflow', async (workflow, { getState }) => {
  const projectId = getState().views.campaignDetails.id;
  if (!projectId) return Promise.reject(new Error('Project id not defined'));
  // Replace coded DEFAULT_WORKFLOW_NAME with localized default workflow name
  const intl = getIntl();
  const response = await api.addNewWorkflow(
    {
      ...workflow,
      name:
        workflow?.name === DEFAULT_WORKFLOW_NAME
          ? intl.formatMessage({
              id: 'campaignWorkflow.default_template',
            })
          : workflow?.name,
    },
    projectId,
  );
  if (!response?.error) {
    toast(
      intl.formatMessage(
        { id: 'toasts.newWorkflowCreated' },
        {
          name:
            workflow?.name === DEFAULT_WORKFLOW_NAME
              ? intl.formatMessage({
                  id: 'campaignWorkflow.default_template',
                })
              : workflow?.name,
        },
      ),
      { type: 'success' },
    );

    return Promise.resolve(response);
  } else {
    return Promise.reject(response.error);
  }
});

export const updateWorkflow = createAsyncThunk<
  { workflow: Workflow },
  WorkflowEdition,
  { state: RootState }
>('campaign/workflows/updateWorkflow', async (workflow, { getState }) => {
  const projectId = getState().views.campaignDetails.id;
  if (!projectId) return Promise.reject(new Error('Project id not defined'));
  const intl = getIntl();
  const response = await api.updateWorkflow(
    {
      ...workflow,
      name:
        workflow?.name === DEFAULT_WORKFLOW_NAME
          ? intl.formatMessage({
              id: 'campaignWorkflow.default_template',
            })
          : workflow?.name,
    },
    projectId,
  );
  if (!response?.error) {
    toast(
      intl.formatMessage(
        { id: 'toasts.workflowUpdated' },
        {
          name:
            workflow?.name === DEFAULT_WORKFLOW_NAME
              ? intl.formatMessage({
                  id: 'campaignWorkflow.default_template',
                })
              : workflow?.name,
        },
      ),
      { type: 'success' },
    );
    return Promise.resolve({ workflow: response });
  } else {
    return Promise.reject(response.error);
  }
});

export const removeWorkflow = createAsyncThunk<Pick<Workflow, 'id'>, Workflow>(
  'campaign/workflows/removeWorkflow',
  async (workflow) => {
    const response = await api.removeWorkflow(workflow?.id);
    if (!response?.error) {
      const intl = getIntl();
      toast(
        intl.formatMessage(
          { id: 'toasts.workflowRemoved' },
          { name: workflow?.name },
        ),
        { type: 'success' },
      );

      return Promise.resolve({ id: workflow?.id });
    } else {
      return Promise.reject(response.error);
    }
  },
);

/**
 * Update the workflow assigned to a campaign
 */
export const updateCampaignWorkflow = createAsyncThunk<
  boolean,
  { id: number; workflowId: number },
  { state: RootState }
>(
  'campaign/workflows/updateCampaignWorkflow',
  async ({ id, workflowId }, { dispatch, getState }) => {
    const { updateCampaign: updateCampaignApi } = await import('api/campaigns');
    await updateCampaignApi({
      campaignId: id,
      current_project_workflow_id: workflowId,
    });
    const { update: updateCampaignAction } = await import('actions/campaigns');
    dispatch(
      updateCampaignAction({
        id,
        core: {
          updatedAt: new Date().toISOString(),
        },
        current_project_workflow_id: workflowId,
      }),
    );

    const {
      campaigns,
      campaignsWorkflow: { workflows },
    } = getState();
    const campaign = campaigns[id];
    const intl = getIntl();
    toast(
      intl.formatMessage(
        { id: 'toasts.campaignWorkflowChanged' },
        {
          campaignName: campaign?.core?.name?.trim(),
          newWorkflowName:
            workflows?.[workflowId]?.name &&
            workflows?.[workflowId]?.name === DEFAULT_WORKFLOW_NAME
              ? intl.formatMessage({ id: 'campaignWorkflow.default_template' })
              : workflows?.[workflowId]?.name,
        },
      ),
      { type: 'success' },
    );
    return true;
  },
);

/**
 * Import workflow
 */

export const importWorkflowFromCampaign = createAsyncThunk<
  number,
  number,
  { state: RootState }
>(
  'campaign/workflows/importWorkflowFromCampaign',
  async (campaignId, { getState, dispatch }) => {
    // Get workflow id applied to campaign
    const campaign = getState().campaigns[campaignId];
    if (!campaign) return Promise.reject(new Error('Campaign not found'));

    const workflowId = campaign.current_project_workflow_id;
    if (!workflowId)
      return Promise.reject(new Error('Workflow not set on campaign'));

    // Find workflow in store
    const workflow = getState().campaignsWorkflow.workflows[workflowId];
    if (!workflow) return Promise.reject(new Error('Workflow not found'));

    // Duplicate the workflow
    const newWorkflow: WorkflowEdition = {
      id: null,
      name: workflow.name,
      status_ids: workflow.status_ids,
    };

    // Create it
    const payloadResponse = await dispatch(addNewWorkflow(newWorkflow));

    const createdWorkflow = payloadResponse.payload as Workflow;

    // Select it by default in ChooseWorkflow
    dispatch(updateCampaignWorkflowSelection(createdWorkflow.id));

    return createdWorkflow.id;
  },
);

/**
 *
 * STATUS
 */

export const addStatus = createAsyncThunk<Status, string>(
  'campaign/status/addStatus',
  async (name) => {
    const _name = name.trim();
    const res = await api.addStatus({ name: _name });
    if (!res || res.error)
      return Promise.reject(
        new Error(res?.error || `Could not delete status "${_name}"`),
      );
    const intl = getIntl();
    toast(
      intl.formatMessage({ id: 'campaigns.states.createdSuccess' }, { name }),
      {
        title: intl.formatMessage({ id: 'global.success' }),
        type: 'success',
      },
    );

    return {
      id: res.id || 0,
      key: null,
      name: _name,
      is_default: false,
    };
  },
);

export const deleteStatus = createAsyncThunk<number, Status>(
  'campaign/status/deleteStatus',
  async (status) => {
    const res = await api.deleteStatus(status.id);

    if (!res) return Promise.reject();

    const intl = getIntl();
    toast(
      intl.formatMessage(
        { id: 'campaigns.states.deletedSuccess' },
        { name: status.name },
      ),
      { type: 'success' },
    );

    return status.id;
  },
);

/**
 * Update workflow selection in ChooseWorkflow
 */
export const updateCampaignWorkflowSelection = createAction<number | null>(
  'campaign/workflows/updateCampaignWorkflowSelection',
);
