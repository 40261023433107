  /* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import * as PROFILE from 'constants/profile';


const social_network_account = {
  sna_id: null,
  uid: "",
  concealed: false,
  blocked: false,
  username: "",
  network: "",
  url: "",
  followers: 0,
  community_progression_rate: 0,
  engagement_rate_progression: 0.,
  posts_per_week: null,
  posts_emv: 0,
  posts_min_estimated_cost: 0,
  posts_max_estimated_cost: 0,
  posts_impressions: 0,
  posts_engagements_3m: 0,
  posts_engagement_rate_3m: 0,
  posts_avg_engagements_3m: 0,
  posts_reach: null,
  posts_likes_3m: null,
  posts_avg_comments_3m: null,
  posts_comments_3m: null,
  reels_per_week: 0,
  stories_3m: null,
  stories_emv: 0,
  stories_min_estimated_cost: 0,
  stories_max_estimated_cost: 0,
  stories_impressions: 0,
  stories_reach: 0,
  videos_avg_views_3m: null,
  videos_avg_shares_3m: null
}

const profile = {
  sna_id: null,
  profile_id: null,
  display_name: "",
  activities: [],
  picture_url: "",
  themes: [],
  arpp_certified: false,
  werberat_certified: false,
  influencerregels_certified: false,
  audience_credibility_from_followers: 0,
  main_audience_gender: "",
  main_audience_country: "",
  main_audience_age_range: "",
  main_audience_interest: "",
  is_favorite: false,
  profile: {
    city: "",
    country_code: "",
    langs: [],
    email: "",
    website: ""
  },
  snas: {},
  compliance_score: {
    enabled: false,
    country: "",
    sponsored_post_rate_3m: 0,
    avg_brand_mentions_per_post_3m: 0
  },
  brand_collaborations: {
    page: 1,
    per_page: 10,
    total: 0,
    max_content_per_period: 1,
    brands: [],
    date_labels: []
  },
  top_hashtags: [],
  posts: {
    page: 0,
    total: 0,
    contents: [],
  },
  tags: [],
  fields: [],
  list_ids: [],
  collaboration: {},
  notes: [],
}

const initialState = profile

const reducers = {
  [PROFILE.LOAD_CORE_PROFILE]: (state, { payload }) => {
    return state = {
      ...state,
      ...payload
    }
  },
  [PROFILE.UPDATE]: (state, { payload: { key, data, value } }) => {
    if (value) state[key] = value;
    if (!value) {
      state[key] = {
        ...state[key],
        ...data,
      }
    }
  },
};

export default createReducer(initialState, reducers);