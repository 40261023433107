import React, { useMemo, useEffect, useState, useCallback } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl-phraseapp';

import { Loader, Button, Icon } from 'kolkit';

import * as authActions from 'actions/auth';
import { useDispatch } from 'utils/redux';
import { tokenTypes } from 'constants/auth';

import AuthHeader from '../AuthHeader';
import ErrorMessage from '../ErrorMessage';

import authStyles from '../Auth.module.scss';
import styles from './AccountConfirmation.module.scss';

const flowStatuses = {
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
};

const AccountConfirmation = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const [state, setState] = useState({
    flowStatus: flowStatuses.IN_PROGRESS,
    errorCode: ''
  });

  const token = useMemo(
    () => {
      const urlQueries = querystring.parse(location.search.substring(1)); // without the ?
      return urlQueries.token;
    },
    [location]
  );

  const launchVerification = useCallback(
    async () => {
      let confirmationError = '';
      const { error: tokenVerificationError } = await dispatch(authActions.verifyToken({
        tokenType: tokenTypes.CONFIRMATION,
        token
      }));
      if (!tokenVerificationError) {
        const { error } = await dispatch(authActions.confirmAccount({
          token
        }));
        confirmationError = error;
      }
      setState(prevState => ({
        ...prevState,
        errorCode: tokenVerificationError || confirmationError,
        flowStatus: flowStatuses.DONE,
      }));
    },
    [dispatch, token]
  );

  useEffect(
    () => {
      if (state.flowStatus !== flowStatuses.DONE) {
        launchVerification();
      }
    },
    [launchVerification, state.flowStatus]
  );

  if (state.flowStatus === flowStatuses.IN_PROGRESS) return (
    <div className={authStyles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.accountConfirmation.title' })} />
      <p>{intl.formatMessage({ id: 'auth.accountConfirmation.description' })}</p>
      <Loader />
    </div>
  );

  if (state.errorCode) return (
    <div className={authStyles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.accountConfirmation.title' })} />
      <ErrorMessage context="accountConfirmation" errorCode={state.errorCode} />
    </div>
  );

  return (
    <div className={authStyles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.accountConfirmation.title' })} />
      <div className={authStyles.wrapper}>
        <div className={authStyles.fields}>
          <div className={styles.successMessage}>
            <Icon label="check" />
            {intl.formatMessage({ id: 'auth.accountConfirmation.confirmed' })}
          </div>
          <Button fullWidth size="fat" onClick={authActions.goToAppHomePage}>
            {intl.formatMessage({ id: 'auth.accountConfirmation.openApp' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountConfirmation;
