import { conf } from 'config/env';

const getIpGeolocationData = async () => {
  try {
    const response = await fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${conf.ipgeolocationIoApiKey}&fields=geo`,
    );
    const { country_code2 } = (await response.json()) as {
      country_code2: string;
    };
    return country_code2;
  } catch (e) {
    console.error('getIpGeolocationData :> ', e);
    return null;
  }
};

export default getIpGeolocationData;
