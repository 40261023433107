/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import _uniq from 'lodash.uniq';

import * as MYINFLUENCERS from 'constants/myInfluencers';
import * as PROFILES from 'constants/profiles';

export const initialState = {
  total: 0,
  rows: [],
  aggregate: [],
  panel: {
    open: false,
    profileId: null,
  },
  hasFetch: false,
  searchQuery: '',
  filters: {
    activatedFilter: false,
    onlyShowSelected: false,
    page: 0,
    perPage: 30,
    hasMore: true,
    sortBy: 'name',
    sortDirection: 'asc',
    contactIds: [],
    advancedFilters: [],
    tagOperator: 'and',
  },
  settings: {
    columns: null,
  },
  account_creation_requests: {
    page: 0,
    per_page: 20,
    has_more: false,
    status: 'all', // 'accepted' | 'in_progress' | 'rejected',
    total: 0,
    rows: [],
  },
  lists: [],
  fields: [],
  imports: {
    config: {},
    fields: {},
    header: {},
    ignored: [],
  },
  audienceOverlap: {
    status: null,
    myBrandAnalysis: null,
    kolsAnalysis: null
  }
};

const reducers = {
  [MYINFLUENCERS.LOAD_PROFILES_LIST]: (state, { payload: { total, rows, aggregate }}) => {
    const { page, perPage } = state.filters;
    state.total = total;
    if (aggregate) state.aggregate = aggregate;
    state.hasFetch = true;
    state.rows = rows;
    state.filters = {
      ...state.filters,
      hasMore: page + 1 < Math.ceil(total / perPage)
    }
  },

  [MYINFLUENCERS.UPDATE_PROFILES_LIST]: (state, { payload: { total, rows }}) => {
    const { page, perPage } = state.filters;
    state.total = total;
    state.rows.push(...new Set(rows));
    state.filters = {
      ...state.filters,
      hasMore: page + 1 < Math.ceil(total / perPage)
    }
  },

  [MYINFLUENCERS.UPDATE_FILTERS]: (state, { payload: parameters }) => {
    state.filters = {
      ...state.filters,
      ...parameters
    }
  },

  [MYINFLUENCERS.UPDATE_SETTINGS]: (state, { payload: settings }) => {
    state.settings = {
      ...state.settings,
      ...settings
    }
  },

  [MYINFLUENCERS.HANDLE_TAG_OPERATOR]: (state, { payload: operator }) => {
    state.filters.tagOperator = operator;
  },

  [MYINFLUENCERS.HANDLE_RESET_ACTIVATED_FILTER]: state => {
    state.filters.activatedFilter = false
    state.filters.advancedFilters = [];
  },

  [MYINFLUENCERS.SEARCH_QUERY]: (state, { payload: query }) => { state.searchQuery = query },

  [MYINFLUENCERS.UPDATE_ACCOUNT_REQUESTS_PARAMS]: (
    state,
    { payload: { page, status, per_page } },
  ) => {
    state.account_creation_requests.page = page || 0;
    if (status) {
      state.account_creation_requests.status = [
        'accepted',
        'in_progress',
        'rejected',
      ].includes(status)
        ? status
        : 'all';
    }
    if (per_page) state.account_creation_requests.per_page = per_page;
  },

  [MYINFLUENCERS.UPDATE_ACCOUNT_REQUESTS_LIST]: (
    state,
    { payload: { total, rows } },
  ) => {
    const { page, per_page, rows: oldRows } = state.account_creation_requests;

    if (Array.isArray(rows)) {
      state.account_creation_requests.rows = page === 0 ? rows : [...oldRows, ...rows];
    }
    state.account_creation_requests.total = total;
    state.account_creation_requests.has_more = page + 1 < Math.ceil(total / per_page);
  },

  [MYINFLUENCERS.APPEND_TO_ACCOUNT_REQUESTS_LIST]: (
    state,
    { payload: { requests } },
  ) => {
    const { rows: oldRows } = state.account_creation_requests;

    const oldRowsIds = oldRows.map((row) => Number(row.id));

    const newRows = requests.reduce((rows, request) => {
      // Find if request existing
      const oldRequestIndex = oldRowsIds.findIndex(
        (oldRowId) => oldRowId === Number(request.id),
      );
      if (oldRequestIndex === -1) {
        // If not, add it
        return [request, ...rows];
      } else {
        // If it is in, replace it
        rows[oldRequestIndex] = request;
        return rows;
      }
    }, oldRows);

    state.account_creation_requests.rows = newRows;
    state.account_creation_requests.total += requests.length;
  },

  [MYINFLUENCERS.UPDATE]: (state, { payload: { key, data } }) => {
    state[key] = { ...state[key], ...data }
  },

  [MYINFLUENCERS.UPDATE_KEY]: (state, { payload: { key, data } }) => {
    state[key] = data
  },

  [MYINFLUENCERS.UPDATE_ROWS]: (state, { payload: { profileId, ...data } }) => {
    const getIndex = state.rows.findIndex(
      ({ id }) => Number(id) === Number(profileId),
    );
    state.rows[getIndex] = { ...state.rows[getIndex], ...data }
  },

  [MYINFLUENCERS.UPDATE_CUSTOM_LIST]: (state, { payload: { data, listId, profileIds, action } }) => {
    if (!action) state.lists = data;
    if (action === 'create') state.lists = [...state.lists, data];

    if (listId && action !== 'create') {
      const getIndex = state?.lists?.findIndex(({ id }) => Number(id) === Number(listId));

      if (action === 'edit') state.lists[getIndex] = { ...state.lists[getIndex], ...data };
      if (action === 'delete') state.lists = state.lists.filter(item => item.id !== listId)
      if (action === 'add') {
        state.lists[getIndex].kols_ids = _uniq([
          ...state.lists[getIndex].kols_ids,
          ...profileIds
        ]);
        state.lists[getIndex].kols_count += profileIds.length;
      }
      if (action === 'remove') {
        state.lists[getIndex].kols_ids = state.lists[getIndex].kols_ids.filter(id => !profileIds.includes(id));
        state.lists[getIndex].kols_count -= profileIds.length;
      }
    }
  },

  [MYINFLUENCERS.UPDATE_CUSTOM_FIELDS]: (state, { payload: { data, fieldId, action } }) => {
    if (!action) state.fields = data;
    if (action === 'create') state.fields = [...state.fields, data];

    if (fieldId && action !== 'create') {
      const getIndex = state.fields.findIndex(({ custom_field_id }) => Number(custom_field_id) === Number(fieldId));
      if (action === 'edit') state.fields[getIndex] = { ...state.fields[getIndex], ...data };
      if (action === 'delete') state.fields = state.fields.filter(item => item.custom_field_id !== fieldId)
    }
  },

  [MYINFLUENCERS.SET_A_OVERLAP_STATUS]: (state, { payload }) => {
    state.audienceOverlap.status = payload
  },
  [MYINFLUENCERS.UPDATE_A_OVERLAP_STATUS]: (state, { payload }) => {
    state.audienceOverlap.status = {
      ...state.audienceOverlap.status,
      ...payload
    }
  },
  [MYINFLUENCERS.SET_A_OVERLAP_STATISTICS]: (state, { payload }) => {
    state.audienceOverlap.myBrandAnalysis = payload.myBrandAnalysis;
    state.audienceOverlap.kolsAnalysis = payload.kolsAnalysis;
  },
  [MYINFLUENCERS.UPDATE_A_OVERLAP_BRAND]: (state, { payload }) => {
    state.audienceOverlap.myBrandAnalysis = {
      ...state.audienceOverlap.myBrandAnalysis,
      ...payload
    };
  },
	[PROFILES.REMOVE_PROFILES_FROM_STORE]: (state, { payload: profileIds }) => {
		const newMyKOLsRows = state.rows.filter(
      (row) => !profileIds.includes(Number(row.id)),
    );
    const nbRemoved = state.rows.length - newMyKOLsRows.length;

    // TODO: handle aggregates
    state.total -= nbRemoved;
    state.rows = newMyKOLsRows;
  },
};

export default createReducer(initialState, reducers);
