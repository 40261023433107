import { useEffect } from 'react';
import { useDispatch, useSelector } from 'utils/redux';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  showProfileDetails,
  hideProfileDetails,
  updateKey,
  updateContentDate,
  updateContentSearch,
  updateTab,
  updateContentSelectedSnas,
} from 'actions/profileDetails';
import { parseStringToDateRange } from 'utils/dateRangeUtil';
import { BACKEND_DATE_FORMAT } from 'config/app';

const ProfileDetailsDispatcher = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { campaignId, pId, conversationId, monitoringId, startDate, endDate } = useParams();

  const { currentTab } = useSelector(({ views }) => ({
    currentTab: views.profileDetails.currentTab
  }))

  useEffect(() => {
    dispatch(showProfileDetails({
      id: pId?.trim(),
      campaignId: campaignId?.trim(),
      conversationId: conversationId?.trim(),
      currentTab,
      monitoringFromUrl: {
        monitoringId,
        startDate,
        endDate,
      }
    }));

    return () => {
      dispatch(hideProfileDetails());
      dispatch(
        updateKey({
          key: 'performanceFilters',
          snaId: null,
        }),
      );
    }
    // eslint-disable-next-line
  }, [campaignId, pId, conversationId]);


  useEffect(() => {
    if (searchParams.size) {
      const tab = searchParams.get('tab');
      const contentSearch = searchParams.get('content-search');
      const period = searchParams.get('period');
      const network = searchParams.get('network');

      if (tab) {
        dispatch(updateTab(tab))
      }
      if (contentSearch) {
        dispatch(updateContentSearch(contentSearch));
      }
      if (period) {
        if (period.includes('to')) {
          const { startAt: startDate, endAt: endDate } = parseStringToDateRange(
            period,
            BACKEND_DATE_FORMAT,
          );
          dispatch(updateContentDate({ startDate, endDate }));
        } else {
          dispatch(updateContentDate({ slice: period }));
        }
      }
      if (network) {
        dispatch(updateContentSelectedSnas([network]));
      }
    }
  }, [dispatch, searchParams, searchParams.size]);

  return null;
}

ProfileDetailsDispatcher.displayName = 'ProfileDetailsDispatcher';

export default ProfileDetailsDispatcher;
