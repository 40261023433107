export const UPDATE = "view::update";
export const UPDATE_KEY = "view::update::key";

export const HAS_SELECTION = '[view] -> hasSelection';
export const UPDATE_PROFILE_STATS = '[view] -> update profile stats';
export const UPDATE_PROFILE_DETAILS_ID = '[view] -> update kol id';

export const LOAD_LOOKALIKE = '[view] -> load look a like';
export const UPDATE_LOOKALIKE = '[view] -> update look a like';
export const UPDATE_LOOKALIKE_CONFIG = '[view] -> update look a like config';

export const HANDLE_SELECTION = "[view] -> update kol's selection";
export const RESET_CAMPAIGN_DETAILS = "[view] -> reset campaign details";
export const RESET_CAMPAIGN_DETAILS_PROFILES_PARAMS = "[view] -> reset campaign details profiles params";
