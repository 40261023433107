import React, { useEffect, useMemo } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { unstable_HistoryRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { initializePhraseAppEditor } from 'react-intl-phraseapp';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loadable from 'react-loadable';

import Loader from 'kolkit/Loader';
import useTranslations, { projectIds } from 'utils/hooks/useTranslations';
import useTrackingLogin from 'utils/hooks/useTrackingLogin';
import { useSelector } from 'utils/redux';
import { conf } from 'config/env';

import { ActionCableProvider } from 'config/actioncable';
import IntlGlobalSingleton from 'utils/HOCs/IntlGlobalSingleton';

import history from 'config/history';
import routes from 'config/routes';
import theme from 'config/theme';

import AuthComponent from 'components/Auth'

const MainContainer = Loadable({
  loader: () => import('components/MainContainer'),
  loading: () => <Loader full />,
});

const PublicPaneMonitor = Loadable({
  loader: () => import('components/campaigns/details/PublicPaneMonitor'),
  loading: Loader,
});

const PublicCastingValidation = Loadable({
  loader: () => import('components/campaigns/details/PublicCastingValidation/PublicCastingValidation'),
  loading: Loader,
});

const PublicContentValidation = Loadable({
  loader:() => import('components/campaigns/details/PublicContentValidation/PublicContentValidation'),
  loading: Loader
});

const RegisterHubspot = Loadable({
  loader: () => import('components/Auth/components/Register/RegisterHubspot'),
  loading: Loader,
});

const App = () => {
  const { messages } = useTranslations();

  useTrackingLogin();

  const { user, timeZone, locale } = useSelector(({ env, user }) => ({
    timeZone: env.timezone,
    locale: env.locale.slice(0, 2),
    user: user?.profile,
  }));

  const localeIso2 = useMemo(
    () => locale.startsWith('fr') ? locale : 'en',
    [locale]
  );

  useEffect(
    () => {
      if (user?.canUpdatePhrase) initializePhraseAppEditor({
        projectId: projectIds.influence,
        accountId: conf.phraseAccountId,
        phraseEnabled: true,
        autoLowercase: false,
        fullReparse: true, // uses Virtual DOM. This allows proper reparsing when DOM changes are detected
      });
    },
  [user?.canUpdatePhrase]
  );

  return (
    <IntlProvider
      messages={messages}
      locale={localeIso2}
      defaultLocale="en"
      timeZone={timeZone}
      onError={(error) => messages ? console.warn(error) : null}
    >
      <IntlGlobalSingleton />
      <GoogleOAuthProvider clientId={conf.gOAuthClientId}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ActionCableProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Router history={history}>
                  <Routes>
                    <Route
                      path={`/${routes.login}`}
                      element={<AuthComponent />}
                    />
                    <Route
                      path={`/${routes.register}`}
                      element={<RegisterHubspot />}
                    />
                    <Route
                      path={`/${routes.publicReport}/:token`}
                      element={<PublicPaneMonitor />}
                    />
                    <Route
                      path={`/${routes.publicCasting}/:token`}
                      element={<PublicCastingValidation />}
                    />
                    <Route
                      path={`/${routes.publicContentValidation}/:token`}
                      element={<PublicContentValidation />}
                    />
                    <Route path="*" element={<MainContainer />} />
                  </Routes>
                </Router>
              </LocalizationProvider>
            </ActionCableProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </GoogleOAuthProvider>
    </IntlProvider>
  );
};

export default App;
