import zIndex from 'config/zIndex';

// https://kolsquare.zendesk.com/agent/admin/widget
export const KOLSQUARE_ZENDESK_KEY = "ac806f5b-18c8-460a-8faf-cbd085692eee";

export const FOOTER_HEIGHT = 60;

/*
* All settings are accessible on this url:
* https://developer.zendesk.com/embeddables/docs/widget/core
*/
export const settings = {
  color: {
    theme: "var(--color-dominant-on-dominant)"
  },
  chat: {
    connectOnPageLoad: false,
    offlineForm: {
      greeting: {
        en: "We aren't online right now, please leave a message",
        fr: "Nous ne sommes pas en ligne pour le moment, s'il vous plaît laissez un message"
      }
    }
  },
  navigation: {
    popoutButton: {
      enabled: false
    }
  },
  offset: {
    vertical: '0px',
  },
  launcher: {
    chatLabel: {
      '*': 'Chat now'
    }
  },
  zIndex: zIndex.zenDesk,
};

export const actions = {
  CHAT_OPENED: 'Chat Opened',
  CHAT_MINIMISED: 'Web Widget Minimised',
}
