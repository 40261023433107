/* eslint-disable no-param-reassign,default-case */
import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import * as ENGINE from 'constants/engine';
import * as PROFILES from 'constants/profiles';
import { filters as initialFilters, sortBy } from 'config/init';

const initialState = {
	filters: {
		...initialFilters,
		sid: 'init',
	},
	sortBy,
	type: 'post',
	selectedResults: [],
	results: {
		hasSearch: false,
		resultsPerPage: 0,
		page: 0,
		rows: []
	},
	savedSearch: [],
};

const reducers = {
	[ENGINE.SEARCH_LOADING]: state => update(state, {
		results: {
			loading: { $set: true }
		}
	} ),

	[ENGINE.SEARCH_COMPLETE]: (state, { payload: results }) => {
		state.results = {
			...results,
			rows: results.page === 0
				? results.rows
				: [...state.results.rows, ...results.rows],
			hasSearch: true,
			loading: false
		};
		state.selectedResults = [];
	},

	[ENGINE.SELECTED_UPDATE]: (state, {payload: results}) => update(state, {
		selectedResults: { $set: results }
	}),

	[ENGINE.FILTER_CHANGE]: (state, { payload:{ field, value }}) => update(state, {
		filters: {
			[field]: { $set: value },
		},
	}),

	[ENGINE.FILTERS_UPDATE]: (state, { payload: filters }) => update(state, {
		filters: {
			$set: {
				...initialFilters,
				...filters
			}
		},
	}),

	[ENGINE.SORT_CHANGE]: (state, {payload:value}) => update(state, {
		sortBy: { $set: value },
	}),

	[ENGINE.INPUT_CHANGE]: (state, {payload:{input, value}}) => update(state, {
		filters: {
			[input]: { $set: value },
		},
	}),

	[ENGINE.CHANGE_PAGE]: (state, {payload:page}) => update(state, {
		results: {
			page: { $set: page || 0 },
		}
	}),

	[ENGINE.CHANGE_TAB]: (state, {payload:type}) => update(state, {
		type: { $set: type }
	}),

	[ENGINE.HASH_CHANGE]: (state, {payload:{filters, sortBy, page, type}}) => update(state, {
		filters: { $merge: filters || state.filters },
		sortBy: { $set: sortBy || state.sortBy },
		type: { $set: type || 'post' },
		results: {
			page: { $set: page || state.page },
		}
	}),

	[ENGINE.FILTERS_RESET]: state => update(state, {
		filters: { $set: initialState.filters },
		sortBy: { $set: sortBy },
		results: { $set: initialState.results },
		type: { $set: 'post' },
	}),

	[ENGINE.SAVED_SEARCHES]: (state, { payload: data }) => {
		if (data) state.savedSearch = data;
	},

  [PROFILES.REMOVE_PROFILES_FROM_STORE]: (state, { payload: profileIds }) => {
    const newSearchRows = state.results.rows.filter(
      (row) => !profileIds.includes(Number(row.id)),
    );
    const nbRemoved = state.results.rows.length - newSearchRows.length;

    return update(state, {
      selectedResults: {
        $set: state.selectedResults.filter(
          (id) => !profileIds.includes(Number(id)),
        ),
      },
      results: {
        total: { $set: state.results.total - nbRemoved },
        rows: { $set: newSearchRows },
      },
    });
  },
};


export default createReducer(initialState, reducers);
