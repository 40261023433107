export const PARAMS_LOADING = "user::params::loading";
export const PARAMS_LOADED = "user::params::loaded";
export const PROFILE_PARAMS_LOADED = "user::profile::params::loaded";

export const UPDATE = '[user] Update Key';

export const INFO_UPDATE = 'user::info::update';
export const EMAIL_UPDATE = 'user::email::update';

export const LICENCE_UPDATE = '[user] -> licence update';

export const ACTIVE_FILTER = '[user] Filter -> active';
export const UPDATE_FILTERS = 'user::filters::update'; // Merge full object as new value or reset

export const UPDATE_FILTER = 'user::filter::update';

export const UPDATE_FILTER_NETWORK = 'user::network filter::update';
export const UPDATE_FILTER_INFLUENCER= 'user::influencer filter::update';

export const RESET_UPDATE_FILTERS = 'user::filters::reset::update';

export const RESET_FILTERS = '[user] Filters -> reset';

export const RESET_FILTERS_WHITOUT_SEARCH = '[user] Search -> reset';
export const UPDATE_STATE = '[user] Search -> update';

export const RESET_NETWORK_FILTERS = '[user] Network Filters -> reset';
export const RESET_ALL_NETWORK_FILTERS = '[user] All Network Filters -> reset';

export const SELECTED_FILTER = 'use::filter::select filter';

export const TAGS_SET = "user::tags::set";

export const SENDING_METHOD = '[user] --> set default send method';
export const HIDE_GMAIL_CONNECT = '[user] --> set display gmail connect';

export const SET_USER_UNACKOWNLEDGE_FEATURES = '[user] --> display new features information';

export const SAVE_INTEGRATIONS = '[user] --> save integrations state';

export const CLEAR_INITIAL_BANNER_MESSAGE = '[user] --> clear initialWsData.banner_message';
export const UPDATE_INTEGRATIONS = '[user] --> update integrations state';
