export const CLIENT_TOKEN_SET = "env::clientToken::set";
export const LOCALE_CHANGED = "env::locale::change";
export const SET_INTERNET = "env::internet::set";
export const NEW_VERSION_AVAILABLE = "env::new-version::available";
export const NEW_VERSION_REQUIRED = "env::new-version::required";
export const MAINTENANCE_ACTIVATED = "env::maintenance::mode";
export const FORBIDDEN_ACTION = "env::forbidden::action";

export const SET_VIEWPORT = "[env] -> set viewport";
export const UPDATE_KEY = "[env] -> update key";
