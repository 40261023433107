import { get, post, put, remove } from './index';

import {
  ListeningCreateDashboard,
  ListeningUpdateDashboard,
  ListeningDeleteDashboard,
  AudienceLocation,
} from './listening.d';


export const getListeningsV2 = (params) => get ({
  endpoint: `influence/v2/monitorings`,
  params,
  debug: {},
  catchErrors: [404],
});

export const getKpiOverview = ({ id, ...params }) => get ({
  endpoint: `influence/v2/monitoring/${id}/overview`,
  params,
});

export const loadKpiOverviewByCountry = ({ id, ...params }) => get ({
  endpoint: `influence/v2/monitoring/${id}/overview_countries`,
  params,
  catchErrors: [404],
});

export const getKpiEvolution = ({ id, ...params }) => get ({
  endpoint: `influence/v2/monitoring/${id}/time`,
  params,
})

export const getKolDistribution = ({ id, ...params }) => get ({
  endpoint: `influence/v2/monitoring/${id}/communities_size`,
  params,
})

export const getTopKols = ({ id, ...params }) => get({
  endpoint: `influence/v2/monitoring/${id}/top_kols`,
  params,
})

export const getContents = ({ id, ...params }) =>
  get({
    endpoint: `influence/monitorings/${id}/posts`,
    params,
  });

export const getListeningPreference = ({ monitoringId }) =>
  get({
    endpoint: `influence/monitorings/${monitoringId}/preference`,
    debug: {},
  });

export const saveListeningPreferences = ({ monitoringId, ...params }) =>
  put({
    endpoint: `influence/monitorings/${monitoringId}/preference`,
    params,
    debug: {}
  });

export const createDashboard: ListeningCreateDashboard = ({
  title,
  audienceLocations,
  myMention,
  mentionsToTrack,
}) =>
  post({
    endpoint: `influence/v2/monitorings`,
    params: Object.assign(
      {},
      {
        title,
        owner_mention: myMention,
        wanted_mentions: mentionsToTrack,
      },
      audienceLocations && {
        audience_filters: audienceLocations.map(location => ({
          country: location.country,
          percentage: location.min,
        }))
      },
    ),
    debug: {},
    catchErrors: [],
  });

export const updateDashboard: ListeningUpdateDashboard = ({
  id,
  title,
  audienceLocations,
  myMention,
  mentionsToTrack,
}) =>
  put({
    endpoint: `influence/v2/monitorings/${id}`,
    params: {
      title,
      owner_mention: myMention || null,
      wanted_mentions: mentionsToTrack || {},
      audience_filters: audienceLocations.map(location => ({
        country: location.country || null,
        percentage: location.min || null,
      }))
    },
    debug: {},
    catchErrors: [404],
  });

export const updateAudienceLocations = (
  { id, audienceLocations }: {id: number, audienceLocations: AudienceLocation[]}
) =>
  put({
    endpoint: `influence/v2/monitorings/${id}`,
    params: {
      audience_filters: audienceLocations.map(location => ({
        country: location.country || null,
        percentage: location.min || null,
      })),
    },
    debug: {},
    catchErrors: [404],
  });

export const deleteDashboard: ListeningDeleteDashboard = ({ id }) =>
  remove({
    endpoint: `influence/monitorings/${id}`,
    debug: {},
    catchErrors: [404],
  });

export const requestDemo = () =>
  post({
    endpoint: `influence/monitorings/request_demo`,
    debug: {}
  });