import { createAction } from 'redux-actions';

import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import toast from 'utils/toast';
import { planhatTraker } from 'utils/tags';
import { AppThunk } from 'config/store';
import * as CAMPAIGNS_FOLDERS from 'constants/campaignsFolders';
import * as api from 'api/campaignsFolders';
import { setControlledLoader } from 'actions/ui';
import {
  update as updateCampaign,
} from 'actions/campaigns';

const setCampaignsFolders = createAction(CAMPAIGNS_FOLDERS.SET_FOLDERS);
const addFolderAction = createAction(CAMPAIGNS_FOLDERS.ADD_FOLDER);
export const updateFolderAction = createAction(CAMPAIGNS_FOLDERS.UPDATE_FOLDER);
const removeFolderAction = createAction(CAMPAIGNS_FOLDERS.DELETE_FOLDER);

export const loadCampaignsFolders: () => AppThunk = () => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'loadCampaignsFolders', show: true }));
  const response = await api.getFolders();
  if (!response?.error) {
    const folders = response.reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.id]: curr,
      }),
      {},
    );
    dispatch(setCampaignsFolders(folders));
  } else {
    dispatch(setCampaignsFolders({}));
  }
  dispatch(setControlledLoader({ id: 'loadCampaignsFolders', show: false }));
};

export const addFolder: (name: string) => AppThunk<Promise<number>> =
  (name) => async (dispatch, getState) => {
    dispatch(setControlledLoader({ id: 'manageFolders', show: true }));

    const response = await api.addFolder(name);

    const { id, firstName, lastName, teamId } = getState().user.profile;
    const folderAdded = {
      id: response?.id,
      team_id: Number(teamId),
      name,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      owner: {
        id: Number(id),
        user_name: `${firstName} ${lastName}`,
        team_id: Number(teamId),
      },
      project_ids: [],
    };
    dispatch(addFolderAction(folderAdded));

    // @ts-ignore
    await planhatTraker({
      action: 'create_campaign_folder',
    })

    const intl = getIntl();
    toast(
      intl.formatMessage(
        { id: 'campaigns.folders.add.successToast' },
        { name },
      ),
      {
        title: intl.formatMessage({ id: 'global.success' }),
        type: 'success',
      },
    );
    dispatch(setControlledLoader({ id: 'manageFolders', show: false }));
    return response?.id;
  };

export const updateFolder: (params: {
  id: number;
  newName: string;
  oldName: string;
}) => AppThunk<Promise<void>> =
  ({ id, newName, oldName }) =>
  async (dispatch, getState) => {
    dispatch(setControlledLoader({ id: 'manageFolders', show: true }));
    const folders = getState()?.campaignsFolders;
    if (folders?.[id]) {
      const newFolderData = {
        ...folders?.[id],
        name: newName,
      };
      const response = await api.updateFolder(newFolderData);

      if (!response?.error) {
        dispatch(updateFolderAction(newFolderData));
        const intl = getIntl();
        toast(
          intl.formatMessage(
            { id: 'campaigns.folders.update.successToast' },
            { oldName, newName },
          ),
          {
            title: intl.formatMessage({ id: 'global.success' }),
            type: 'success',
          },
        );
      }
    }
    dispatch(setControlledLoader({ id: 'manageFolders', show: false }));
  };

export const removeFolder: (params: {
  id: number;
  name: string;
}) => AppThunk<Promise<void>> =
  ({ id, name }) =>
  async (dispatch) => {
    dispatch(setControlledLoader({ id: 'manageFolders', show: true }));
    const response = await api.deleteFolder(id);
    if (!response?.error) {
      dispatch(removeFolderAction(id));
      const intl = getIntl();
      toast(
        intl.formatMessage(
          { id: 'campaigns.folders.remove.successToast' },
          { name },
        ),
        {
          title: intl.formatMessage({ id: 'global.success' }),
          type: 'success',
        },
      );
    }

    // @ts-ignore
    await planhatTraker({
      action: 'delete_campaign_folder',
    })

    dispatch(setControlledLoader({ id: 'manageFolders', show: false }));
  };

export const moveCampaignTo: (params: {
  campaignId: number;
  oldFolderId: number | null;
  newFolderId: number | null;
}) => AppThunk<Promise<void>> =
  ({ campaignId, oldFolderId, newFolderId }) =>
  async (dispatch, getState) => {
    dispatch(setControlledLoader({ id: 'manageFolders', show: true }));
    const intl = getIntl();
    let newSelectedFolderName = '' as string | null;
    const { campaignsFolders: folders, campaigns } = getState();

    if (newFolderId && oldFolderId === null) {
      // add campaign folder
      const newFolderData = folders[newFolderId];
      const newFolderDataUpdated = {
        ...newFolderData,
        project_ids: [...newFolderData.project_ids, campaignId],
      };
      await api.updateFolder(newFolderDataUpdated);
      dispatch(updateFolderAction(newFolderDataUpdated));
      dispatch(
        updateCampaign({
          id: campaignId,
          core: {
            folderId: newFolderData?.id,
          },
        }),
      );
      newSelectedFolderName = newFolderData?.name;
    }

    if (newFolderId == null && oldFolderId) {
      // delete campaign folder
      const oldFolderData = folders[oldFolderId];
      const oldFolderDataUpdated = {
        ...oldFolderData,
        project_ids: oldFolderData.project_ids?.filter(
          (id) => id !== campaignId,
        ),
      };
      await api.updateFolder(oldFolderDataUpdated);
      dispatch(updateFolderAction(oldFolderDataUpdated));
      dispatch(
        updateCampaign({
          id: campaignId,
          core: {
            folderId: null,
          },
        }),
      );
      newSelectedFolderName = null;
    }

    if (newFolderId && oldFolderId) {
      // move to folder
      const newFolderData = folders[newFolderId];
      const newFolderDataUpdated = {
        ...newFolderData,
        project_ids: [...newFolderData.project_ids, campaignId],
      };

      const oldFolderData = folders[oldFolderId];
      const oldFolderDataUpdated = {
        ...oldFolderData,
        project_ids: oldFolderData.project_ids?.filter(
          (id) => id !== campaignId,
        ),
      };
      await Promise.all([
        api.updateFolder(newFolderDataUpdated),
        api.updateFolder(oldFolderDataUpdated),
      ]);
      dispatch(updateFolderAction(newFolderDataUpdated));
      dispatch(updateFolderAction(oldFolderDataUpdated));
      dispatch(
        updateCampaign({
          id: campaignId,
          core: {
            folderId: newFolderData?.id,
          },
        }),
      );
      newSelectedFolderName = newFolderData?.name;
    }

    if (newSelectedFolderName === null) {
      toast(
        intl.formatMessage(
          { id: 'campaigns.folders.moveToRoot.successToast' },
          { campaignName: campaigns?.[campaignId]?.core?.name },
        ),
        { title: intl.formatMessage({ id: 'global.success' }), type: 'success' },
      );
    } else {
      toast(
        intl.formatMessage(
          { id: 'campaigns.folders.move.successToast' },
          { folderName: newSelectedFolderName },
        ),
        { title: intl.formatMessage({ id: 'global.success' }), type: 'success' },
      );

    }
    dispatch(setControlledLoader({ id: 'manageFolders', show: false }));
  };

export const moveCampaignsTo: (params: {
  campaignIds: number[];
  newFolderId: number | null;
}) => AppThunk<Promise<void>> =
  ({ campaignIds, newFolderId }) =>
  async (dispatch, getState) => {
    if (!campaignIds?.length) return;
    dispatch(setControlledLoader({ id: 'manageFolders', show: true }));

    const intl = getIntl();

    // Get state
    const { campaignsFolders: folders, campaigns } = getState();

    // Store a map of old folder ids and related campaigns
    const oldFoldersMap: Record<number, number[]> = {};
    campaignIds.forEach((id) => {
      const campaign = campaigns?.[id];
      if (campaign?.core?.folderId) {
        oldFoldersMap[campaign.core.folderId] = [
          ...(oldFoldersMap[campaign.core.folderId] || []),
          id,
        ];
      }
    });

    // Remove campaigns from old folders
    await Promise.all(
      Object.keys(oldFoldersMap).map((oldFolderId) => {
        const oldFolderData = folders[oldFolderId];
        const oldFolderDataUpdated = {
          ...oldFolderData,
          project_ids: oldFolderData.project_ids?.filter(
            (id) => !oldFoldersMap[oldFolderId].includes(id),
          ),
        };
        return api
          .updateFolder(oldFolderDataUpdated)
          .then(() =>
            Promise.resolve(dispatch(updateFolderAction(oldFolderDataUpdated))),
          );
      }),
    );

    // Move campaigns to new folder
    if (newFolderId) {
      const newFolderData = folders[newFolderId];
      const newFolderDataUpdated = {
        ...newFolderData,
        project_ids: [...newFolderData.project_ids, ...campaignIds],
      };
      await api.updateFolder(newFolderDataUpdated);
      dispatch(updateFolderAction(newFolderDataUpdated));
    }

    // Update campaigns wether they have been moved to another folder or root
    campaignIds.forEach((id) => {
      dispatch(
        updateCampaign({
          id,
          core: {
            folderId: newFolderId || null,
          },
        }),
      );
    });

    const newSelectedFolderName = newFolderId
      ? folders[newFolderId]?.name
      : null;

    // Toast notification
    if (newSelectedFolderName) {
      toast(
        intl.formatMessage(
          { id: 'campaigns.folders.move.successToast' },
          { folderName: newSelectedFolderName, count: campaignIds.length },
        ),
        {
          title: intl.formatMessage({ id: 'global.success' }),
          type: 'success',
        },
      );
    } else {
      toast(
        intl.formatMessage(
          { id: 'campaigns.folders.moveToRoot.successToast' },
          { count: campaignIds.length },
        ),
        {
          title: intl.formatMessage({ id: 'global.success' }),
          type: 'success',
        },
      );
    }

    dispatch(setControlledLoader({ id: 'manageFolders', show: false }));
  };
