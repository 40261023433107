import queryString from 'jquery-param';

import { getDispatcher, getStore } from 'config/store';
import { conf, DEV_DEBUG as DEBUG } from 'config/env';
import { setHasInternet, newApplicationVersionAvailable, newApplicationVersionRequired, setMaintenanceMode, actionForbidden } from 'actions/env';
import { setApiError } from 'actions/ui';
import { logout } from 'actions/user';
import logError from 'utils/logError';

const frontVersion = '4.9.6';

let fetchCount = 0;

const callApi = async datas => {
  const {
    endpoint,
    params,
    method,
    catchErrors,
    withCredentials,
    default204,
    default201,
    catchNoAnswer,
    debug,
    acceptType,
    noRetry,
    timeout = 120000,
  } = datas;
  const dispatch = getDispatcher();
  const { userToken, locale } = getStore().env;

  let api = `${conf.api}/${endpoint}`;
  let wait = null;

  const ob = Object.assign({
      mode: 'cors',
      method,
      headers: Object.assign(
        {
          'Accept': acceptType || 'application/vnd.bandc.v1+json',
          'Accept-Language': locale,
          "Cache-Control": "no-store",
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
        frontVersion && frontVersion !== '' ? { 'X-Front-Version': frontVersion } : null
      ),
    },
    withCredentials === true ? { credentials: 'include' } : null,
  );

  if ((method === 'GET') && params && Object.keys(params)?.length > 0) {
    api = `${api}?${queryString(params)}`;
  }
  if (method !== 'GET') {
    ob.body = JSON.stringify(params);
  }

  if (debug && DEBUG && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')) {
    if (process.env.NODE_ENV === 'development') console.info(`[${method}] ${api}`, ob);

    return new Promise(resolve => {
      if (wait) clearTimeout(wait);
      wait = setTimeout(
        () => resolve(debug),
        debug.timer || 350
      )
    })
  }

  // Making API Call
  let response;
  try {
    response = await fetch(api, ob);
  } catch (e) {
    fetchCount += 1;

    if (catchNoAnswer) return ({
      error: true,
      status: 'no-answer',
    });

    if (noRetry && fetchCount < 2) return ({
      error: true,
      status: 'timeout'
    })

    if (fetchCount < 4) {
      return new Promise((resolve) => {
        if (wait) clearTimeout(wait);
        wait = setTimeout(() => {
          clearTimeout(wait);
          resolve(callApi(datas));
        }, timeout)
      })
    }

    // Not Connected to Internet
    dispatch(setHasInternet(false));
    return response;
  }

  /**
   * pass "preventEffect = true" if you do not want this function
   * to perform an automatic redirection in case of identification error
   * eg: 401 error
   *
   */
  if (datas.preventEffect && !response.ok) {
    try {
      return await response.json();
    } catch (error) {
      return { error };
    }
  }

  dispatch(setHasInternet(true));

  // Checking response status
  if (response.status < 200 || response.status >= 300) {
    if (catchErrors && catchErrors.indexOf(response.status) > -1) {
      let payload;
      try {
        payload = await response.json();
      } catch (error) {
        payload = {};
      }
      return ({
        error: true,
        status: response?.status,
        payload,
      });
    }
    await errorCatcher({
      status: response.status,
      datas,
      api,
      error: new ApiError(api, response?.status,)
    });
    return false;
  }

  // Checking theorical version
  const serverVersion = response.headers.get('X-Server-Version');

  if (serverVersion && frontVersion && serverVersion !== '' && frontVersion !== '' && serverVersion !== frontVersion) {
    // Current API version and targetted mismatch, and we haven't failed, so it means a minor update is available
    dispatch(newApplicationVersionAvailable());
  }

  // Handle 204
  if (response.status === 204) return default204 || true;

  // Handle 201
  if (response.status === 201 && default201) return default201;

  // Parsing response
  try {
    const contentType = response.headers.get('Content-Type');
    if (contentType?.includes('application/json')) {
      return await response.text().then((text) => {
        if (!text) return {};
        if (text.replace) {
          return JSON.parse(text.replace(/\bNaN\b/g, null));
        } else {
          // Text is not parsable, send an error to know why
          errorCatcher({
            error: new Error('Response not parsable'),
            api,
            datas: { ...datas, response: text },
            status: response?.status
          });
        }
      });
    } else {
      return response;
    }
  } catch (error) {
    await errorCatcher({ error, api, datas, status: response?.status });
    return error;
  }
};

export const get = datas => callApi({...datas, method: 'GET'});
export const post = datas => callApi({...datas, method: 'POST'});
export const put = datas => callApi({...datas, method: 'PUT'});
export const patch = datas => callApi({...datas, method: 'PATCH'});
/* Hell yeah, I cannot use "delete".... */
export const remove = datas => callApi({...datas, method: 'DELETE'});

const errorCatcher = async ({ error, status, api, datas }) => {
  const dispatch = getDispatcher();

  // particular action depending on status Code
  switch (status) {
    case 401:
      await dispatch(logout());
      return true;
    case 403:
      // Not Allowed to perform this action
      dispatch(actionForbidden());
      return true;
    case 412:
      // Blocking updated needed
      dispatch(newApplicationVersionRequired());
      return true;
    case 503:
      // Blocking Maintenance Mode
      dispatch(setMaintenanceMode());
      return true;
    default: {
      dispatch(setApiError(status));
      const { id, firstName, lastName, companyName } = getStore().user?.profile;
      logError(error, {
        id,
        name: `${firstName} ${lastName}`,
        companyName,
        api_status: status,
        api,
        datas,
      });
    }
  }
};

class ApiError extends Error {
  constructor(message = "", status) {
    super(message);
    this.name = `${status} Error`;
  }
}
