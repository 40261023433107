export const checkIfDuplicateExists: (array: any[]) => boolean = (array = []) =>
  new Set(array).size !== array.length;

export function findDuplicates<T>(array: T[]) : T[] {
  const count = {};
  const result: T[] = [];

  array.forEach(item => {
    const hash = JSON.stringify(item);
    if (!count[hash]) {
      count[hash] = 0;
    }
    count[hash] += 1;
    if (count[hash] > 1) {
      result.push(item);
    }
  });

  return result;
}
