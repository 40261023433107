import React, { memo, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { Li, Radios, Tooltip } from 'kolkit';

import { changeLocale } from 'actions/env';
import { useDispatch, useSelector } from 'utils/redux';
import { availableLocales } from 'locales';
import * as FLAGS from 'components/icons/flags';

import styles from './LocalesSwitcher.module.scss';


const LocalesSwitcher = ({ asFlags, onChangeLocale }) => {
  const dispatch = useDispatch();

  const locale = useSelector(({ env }) => env.locale);

  const localeDataset = useMemo(
    () => availableLocales.map(item => ({
      label: item.label,
      value: item.id,
    })),
    []
  );

  const handleSelectLanguage = useCallback(
    ({ target: { value } }) => {
      dispatch(changeLocale(value));
      if (onChangeLocale) onChangeLocale(value)
    },
    [onChangeLocale, dispatch]
  );

  if (!locale || availableLocales?.length <= 1) return null;

  const index = availableLocales.findIndex(l => l.id === locale);

  if (index === -1) {
    console.error(`Oupss! something went wrong... Locale ${locale} not found...`);
    return null;
  }

  return asFlags ? (
    <div className="locales-switcher">
      <ul>
        {availableLocales.map(l => (
          <Item key={l.id} data={l} />
        ))}
      </ul>
    </div>
  ) : (
    <Radios
      className={styles.radios}
      options={localeDataset}
      name="locale"
      checked={locale}
      onChange={handleSelectLanguage}
      direction="vertical"
      theme="KolLab"
    />
  );
}

const Item = memo(({ data }) => {
  const location = useLocation();
  const flagLink = useMemo(
    () => {
      const parsedQuery = querystring.parse(location.search.substring(1));
      parsedQuery.lang = data.iso2;
      return {
        ...location,
        search: `?${querystring.stringify(parsedQuery)}`
      }
    },
    [data, location]
  );

  return (
    <Li className="switcher-item">
      <Link to={flagLink}>
        <Tooltip content={data.label}>
          {/* eslint-disable-next-line import/namespace */}
          {FLAGS[`FLAG_${data.iso3.toUpperCase()}`]}
        </Tooltip>
      </Link>
    </Li>
  )
});

export default LocalesSwitcher;