export const MESSAGE_TYPES = {
    message: 'message',
    notification: 'notification',
}

export const MESSAGE_SUBTYPES = {
    stateUpdated: 'proposition_state_update',
    statsSharingApproval: 'stats_sharing_approval',
    statsSharingRequest: 'stats_sharing_request',
    statsSharingHalted: 'stats_sharing_halted',
    statsSharingResumed: 'stats_sharing_resumed',
    answerBrief: 'answer_brief',
}

export const PROFILE_TYPES = {
    contact: 'contact',
    profile: 'profile'
};