import React, { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { nanoid } from 'nanoid';

import Icon from '../Icon';
import usePrevious from '../hooks/usePrevious';

import styles from './Li.module.scss';

let timer;

const Li = forwardRef(({
  dataId,
  icon,
  iconTheme,
  iconFill,
  children,
  className,
  onClick,
  react,
  notification,
  active,
  disabled,
  fullWidth,
  bigNotif,
  withoutNotifNumber,
  size = 'medium',
  ...rest
}, ref) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const prevNotification = usePrevious(parseInt(notification, 10));

  useEffect(() => {
    if (startAnimation) {
      timer = setTimeout(
        () => setStartAnimation(false),
        300
      )
    }
    return () => clearTimeout(timer);
  }, [startAnimation]);

  useEffect(() => {
    if (prevNotification < notification) setStartAnimation(true);
  }, [prevNotification, notification]);

  const cnLi = cn(styles.li, className, size ? { [styles[size]]: true } : null, {
    [styles.button]: onClick,
    [styles.active]: active,
    [styles.disabled]: disabled,
    [styles.fullWidth]: fullWidth,
  });

  const cnNotif = cn(styles.contentNotification, {
    [styles.bigNotif]: bigNotif,
    [styles.withoutNotifNumber]: withoutNotifNumber,
    [styles.animated]: startAnimation
  });

  const handleClick = useCallback(
    () => !disabled ? onClick() : null,
    [disabled, onClick]
  );

  const renderNotification = useMemo(
    () => {
      const getNotification = parseInt(notification, 10);
      if (notification && getNotification !== 0) {
       return (
         <span className={styles.notification}>
          <span className={cnNotif}>
            {getNotification < 100 ? notification : '+99'}
          </span>
        </span>
       )
      }
      return null;
    },
    [notification, cnNotif]
  );

  const renderReact = useMemo(
    () => react && React.cloneElement(
      react, {
        ...rest,
        key: nanoid(),
        className: styles.react
      }
    ),
    [react, rest]
  );

  const renderIcon = useMemo(
    () => icon ? (
      <Icon
        key={icon}
        label={icon}
        theme={iconTheme}
        fill={iconFill}
        width={14}
        className={styles.icon}
      />
    ) : null,
    [icon, iconTheme, iconFill]
  );

  return (
    <li
      ref={ref}
      className={cnLi}
      onClick={handleClick}
      aria-selected={active}
      role="option"
      data-id={dataId}
      {...rest}
    >
      {renderIcon}
      {renderReact}
      {children}
      {renderNotification}
    </li>
  )
});

Li.displayName = 'Li';

Li.propTypes = {
  dataId: PropTypes.string,
  icon: PropTypes.string,
  iconTheme: PropTypes.string,
  iconFill: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  notification: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.node,
    PropTypes.element
  ]),
  react: PropTypes.element,
  bigNotif: PropTypes.bool,
  withoutNotifNumber: PropTypes.bool,
  size: PropTypes.string,
};

Li.defaultProps = {
  dataId: null,
  icon: null,
  iconFill: 'var(--color-dominant-on-dominant)',
  iconTheme: 'thin',
  children: null,
  className: null,
  onClick: () => {},
  notification: null,
  active: false,
  disabled: false,
  fullWidth: false,
  react: null,
  bigNotif: false,
  withoutNotifNumber: false,
  size: 'medium',
}

export default memo(Li);
