import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { useParams } from 'react-router-dom';

import { FullWidthBanner, HeightTransition } from 'kolkit';
import { CHANNELS } from 'config/actioncable';
import { useDispatch, useSelector } from 'utils/redux';
import useActioncableSubscription from 'utils/hooks/useActioncableSubscription';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import useViews from 'utils/hooks/useViews';
import { loadListenings } from 'actions/listeningV2';
import { clearInitialBannerMessage } from 'actions/user';
import { getBrandInfos } from 'actions/followersIncrease';
import { isFollowersIncreasePage } from 'validators/views';
import { BannerSubscriptionData } from 'types/user';

const AnnouncementBanner = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const urlParams = useParams();
  const { isListeningPage } = useViews();
  const [showBanner, setShowBanner] = useState(false);
  const locale = useSelector(({ env }) => env.locale);
  const [closedBannerId, setClosedBannerId] = useLocalStorage(
    'banner_last_closed_id',
    '',
  );

  const { banner_message } = useSelector(
    ({ user: { initialWsData } }) => initialWsData,
  );

  const [announcement, setAnnouncement] = useState<{
    id: number;
    type: 'warning' | 'announcement';
    message: string;
    cta?: string;
    link?: string;
    updatedAt?: string;
    disabledAt?: string;
  }>();

  const handleReceivedBannerMessage = useCallback(
    (data: BannerSubscriptionData) => {
      const lang = locale?.slice(0, 2) || 'en';
      const dataLink = data[`link_${lang}`];
      const link =
        !dataLink || dataLink.startsWith('http')
          ? dataLink
          : `https://${dataLink}`;

      const message = data.action_type === 'brand_monitoring_ready' // Followers increase ready
        ? data.rejection_reason
          ? intl.formatMessage(
            { id: 'followersIncrease.rejectionReason.banner'},
            { error: intl.formatMessage({ id: `followersIncrease.rejectionReason.${data.rejection_reason}`}) })
          : intl.formatMessage({ id: 'followersIncrease.isReady'})
        : data[`content_${lang}`]

      setShowBanner(
        !data.disabled_at && (!data.id || data.id !== closedBannerId),
      );
      setAnnouncement({
        id: data.id,
        type: data.theme,
        message,
        cta: data[`call_to_action_${lang}`],
        link,
        updatedAt: data.updated_at,
        disabledAt: data.disabled_at,
      });
      // ACTIONS
      if (data.action_type === 'monitoring_banner_message') {
        if (isListeningPage) {
          void dispatch(loadListenings(urlParams?.monitoringId));
        }
      }
      if (data.action_type === 'brand_monitoring_ready') {
        if (isFollowersIncreasePage()) {
          dispatch(getBrandInfos());
        }
      }
    },
    [dispatch, locale, closedBannerId, intl, isListeningPage, urlParams],
  );

  const handleClose = useCallback(() => {
    setShowBanner(false);
    setClosedBannerId(announcement?.id);
  }, [announcement, setClosedBannerId]);

  useEffect(() => {
    if (banner_message) {
      handleReceivedBannerMessage(banner_message);
      dispatch(clearInitialBannerMessage());
    }
  }, [banner_message, dispatch, handleReceivedBannerMessage]);

  useActioncableSubscription({
    // Used to show announcement banner in the listening and followersIncrease when the board is ready
    channel: CHANNELS.BANNER_MESSAGES,
    onDataReceived: handleReceivedBannerMessage,
    // getInitialState: true,
  });

  useActioncableSubscription({
    channel: CHANNELS.USER,
    onDataReceived: handleReceivedBannerMessage,
  });

  return (
    <HeightTransition on={showBanner}>
      {(showBanner && announcement && (
        <FullWidthBanner
          type={announcement.type}
          message={announcement?.message}
          cta={announcement?.cta}
          link={announcement?.link}
          onClose={handleClose}
        />
      )) ||
        null}
    </HeightTransition>
  );
};

export default React.memo(AnnouncementBanner);
