
const basicColumns = [
  {
    title: 'manageKols.table.kols',
    width: '350px',
    sortBy: 'name',
    sortable: true,
    dataLink: 'core',
    dataApi: '',
    type: 'core',
    alwaysVisible: true,
    disableOrder: true,
    aggregate: {
      default: 'count',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.firstName',
    configLabel: 'manageKols.configuration.firstName',
    width: '180px',
    dataLink: 'firstName',
    dataApi: 'first_name',
    visible: true,
    type: 'editable',
    aggregate:  {
      default: 'count',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.lastName',
    configLabel: 'manageKols.configuration.lastName',
    width: '180px',
    dataLink: 'lastName',
    dataApi: 'last_name',
    visible: true,
    type: 'editable',
    aggregate: {
      default: 'count',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.pseudo',
    configLabel: 'manageKols.configuration.pseudo',
    width: '180px',
    dataLink: 'pseudo',
    dataApi: 'pseudo',
    visible: true,
    type: 'editable',
    aggregate: {
      default: 'count',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.investedBudget',
    subTitle: 'manageKols.table.totalCurrency',
    configLabel: 'manageKols.configuration.investedBudget',
    width: '180px',
    dataLink: 'investedBudget',
    dataApi: 'invested_budget',
    visible: true,
    type: 'money',
    aggregate:  {
      default: 'sum',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.labels',
    configLabel: 'manageKols.configuration.labels',
    width: '300px',
    dataLink: 'labels',
    dataApi: 'tags',
    visible: true,
    type: 'label',
    aggregate:  {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.credibilityScore',
    configLabel: 'manageKols.configuration.credibilityScore',
    tooltip: 'manageKols.table.credibilityScore.tooltip',
    width: '198px',
    sortable: true,
    sortBy: 'community_credibility',
    dataLink: 'communityCredibility',
    dataApi: 'community_credibility',
    visible: true,
    type: 'audienceCredibility',
    aggregate: {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.snas',
    configLabel: 'manageKols.configuration.snas',
    width: '184px',
    dataLink: 'snas',
    dataApi: 'snas',
    visible: true,
    type: 'sna',
    aggregate: {
      default: null,
      editable: false,
    },
  },
]

const instagramColumns = [
  {
    title: 'manageKols.table.community',
    configLabel: 'manageKols.configuration.community',
    width: '186px',
    sortBy: 'instagram_community_count',
    sortable: true,
    dataLink: 'instagramCommunityCount',
    dataApi: 'instagram_community_count',
    network: 'instagram',
    visible: true,
    type: 'community',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.emv',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.emv.tooltip',
    configLabel: 'manageKols.configuration.emvPerPost',
    width: '186px',
    sortBy: 'instagram_emv_per_post',
    sortable: true,
    dataLink: 'instagramEmvPerPost',
    dataApi: 'instagram_emv_per_post',
    network: 'instagram',
    visible: true,
    type: 'money',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.emv',
    subTitle: 'manageKols.table.perReel',
    tooltip: 'manageKols.table.emv.tooltip',
    configLabel: 'manageKols.configuration.emvPerReel',
    width: '186px',
    sortBy: 'instagram_emv_per_reel',
    sortable: true,
    dataLink: 'instagramEmvPerReel',
    dataApi: 'instagram_emv_per_reel',
    network: 'instagram',
    visible: true,
    type: 'money',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estImpressions',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.estImpressions.tooltip',
    configLabel: 'manageKols.configuration.estimatedImprPerPost',
    width: '204px',
    sortBy: 'instagram_impressions_per_post',
    sortable: true,
    dataLink: 'instagramEstimatedImpressionPerPost',
    dataApi: 'instagram_impressions_per_post',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estImpressions',
    subTitle: 'manageKols.table.perStory',
    tooltip: 'manageKols.table.estImpressions.tooltip',
    configLabel: 'manageKols.configuration.estimatedImprPerStory',
    width: '204px',
    sortBy: 'instagram_impressions_per_story',
    sortable: true,
    dataLink: 'instagramEstimatedImpressionPerStory',
    dataApi: 'instagram_impressions_per_story',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estImpressions',
    subTitle: 'manageKols.table.perReel',
    tooltip: 'manageKols.table.estImpressions.tooltip',
    configLabel: 'manageKols.configuration.estimatedImprPerReel',
    width: '204px',
    sortBy: 'instagram_impressions_per_reel',
    sortable: true,
    dataLink: 'instagramEstimatedImpressionPerReel',
    dataApi: 'instagram_impressions_per_reel',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estReach',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.estReach.tooltip',
    configLabel: 'manageKols.configuration.estimatedReachPerPost',
    width: '204px',
    sortBy: 'instagram_reach_per_post',
    sortable: true,
    dataLink: 'instagramEstimatedReachPerPost',
    dataApi: 'instagram_reach_per_post',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estReach',
    subTitle: 'manageKols.table.perReel',
    tooltip: 'manageKols.table.estReach.tooltip',
    configLabel: 'manageKols.configuration.estimatedReachPerReel',
    width: '204px',
    sortBy: 'instagram_reach_per_reel',
    sortable: true,
    dataLink: 'instagramEstimatedReachPerReel',
    dataApi: 'instagram_reach_per_reel',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estReach',
    subTitle: 'manageKols.table.perStory',
    tooltip: 'manageKols.table.estReach.tooltip',
    configLabel: 'manageKols.configuration.estimatedReachPerStory',
    width: '204px',
    sortBy: 'instagram_reach_per_story',
    sortable: true,
    dataLink: 'instagramEstimatedReachPerStory',
    dataApi: 'instagram_reach_per_story',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementPostTooltip',
    configLabel: 'manageKols.configuration.engagementPerPost',
    width: '186px',
    sortBy: 'instagram_engagement_per_post',
    sortable: true,
    dataLink: 'instagramEngagementPerPost',
    dataApi: 'instagram_engagement_per_post',
    network: 'instagram',
    visible: true,
    type: 'number',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagementRate',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementRatePostTooltip',
    configLabel: 'manageKols.configuration.engagementRatePerPost',
    width: '220px',
    sortBy: 'instagram_engagement_rate_per_post',
    sortable: true,
    dataLink: 'instagramEngagementRatePerPost',
    dataApi: 'instagram_engagement_rate_per_post',
    network: 'instagram',
    visible: true,
    type: 'percent',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  // {
  //   title: 'instagramEngagementPerReel',
  //   tooltip: 'engagementReelTooltip',
  //   width: '186px',
  //   sortBy: 'instagram_avg_engagement_per_reel',
  //   sortable: true,
  //   dataLink: 'instagramEngagementPerReel',
  //   dataApi: 'instagram_avg_engagement_per_reel',
  //   network: 'instagram',
  //   visible: true,
  //   type: 'number',
  //   aggregate: {
  //     default: 'avg',
  //     editable: true,
  //   },
  // },
  // {
  //   title: 'instagramEngagementRatePerReel',
  //   tooltip: 'engagementRateReelTooltip',
  //   width: '220px',
  //   sortBy: 'instagram_avg_engagement_rate_per_reel',
  //   sortable: true,
  //   dataLink: 'instagramEngagementRatePerReel',
  //   dataApi: 'instagram_avg_engagement_rate_per_reel',
  //   network: 'instagram',
  //   visible: true,
  //   type: 'percent',
  //   aggregate:  {
  //     default: 'avg',
  //     editable: false,
  //   },
  // },
  {
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.estimatedCostPostTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerPost',
    width: '208px',
    sortBy: 'instagram_emv_range_per_post',
    sortable: true,
    dataLink: 'instagramEstimatedCostPerPost',
    dataApi: 'instagram_emv_range_per_post',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perStory',
    tooltip: 'manageKols.table.estimatedCostStoryTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerStory',
    width: '208px',
    sortBy: 'instagram_emv_range_per_story',
    sortable: true,
    dataLink: 'instagramEstimatedCostPerStory',
    dataApi: 'instagram_emv_range_per_story',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },{
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perReel',
    tooltip: 'manageKols.table.estimatedCostReelTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerReel',
    width: '186px',
    sortBy: 'instagram_emv_range_per_reel',
    sortable: true,
    dataLink: 'instagramEstimatedCostPerReel',
    dataApi: 'instagram_emv_range_per_reel',
    network: 'instagram',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.community',
    subTitle: 'manageKols.table.evolution',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.communityEvolution',
    width: '210px',
    sortBy: 'instagram_community_progression_rate',
    sortable: true,
    dataLink: 'instagramCommunityProgressionRate',
    dataApi: 'instagram_community_progression_rate',
    network: 'instagram',
    visible: true,
    type: 'percentEvolution',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.evolutionPerPost',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.engagementRateEvoPerPost',
    width: '220px',
    sortBy: 'instagram_engagement_rate_progression',
    sortable: true,
    dataLink: 'instagramEngagementRateProgression',
    dataApi: 'instagram_engagement_rate_progression',
    network: 'instagram',
    visible: true,
    type: 'percentEvolution',
    aggregate: {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topGender',
    subTitle: 'manageKols.table.followers',
    configLabel: 'manageKols.configuration.topGenderFollowers',
    width: '180px',
    sortable: false,
    dataLink: 'instagramTopGenderFollowers',
    dataApi: 'instagram_top_gender_followers',
    network: 'instagram',
    visible: true,
    type: 'topGender',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topAge',
    subTitle: 'manageKols.table.followers',
    configLabel: 'manageKols.configuration.topAgeFollowers',
    width: '180px',
    sortable: false,
    dataLink: 'instagramTopAgeSliceFollowers',
    dataApi: 'instagram_top_age_slice_followers',
    network: 'instagram',
    visible: true,
    type: 'topAgeSlice',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topLocation',
    subTitle: 'manageKols.table.followers',
    configLabel: 'manageKols.configuration.topLocationFollowers',
    width: '180px',
    sortable: false,
    dataLink: 'instagramTopLocationFollowers',
    dataApi: 'instagram_top_location_followers',
    network: 'instagram',
    visible: true,
    type: 'topLocation',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topGender',
    subTitle: 'manageKols.table.likers',
    configLabel: 'manageKols.configuration.topGenderLikers',
    width: '180px',
    sortable: false,
    dataLink: 'instagramTopGenderLikers',
    dataApi: 'instagram_top_gender_likers',
    network: 'instagram',
    visible: true,
    type: 'topGender',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topAge',
    subTitle: 'manageKols.table.likers',
    configLabel: 'manageKols.configuration.topAgeLikers',
    width: '180px',
    sortable: false,
    dataLink: 'instagramTopAgeSliceLikers',
    dataApi: 'instagram_top_age_slice_likers',
    network: 'instagram',
    visible: true,
    type: 'topAgeSlice',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topLocation',
    subTitle: 'manageKols.table.likers',
    configLabel: 'manageKols.configuration.topLocationLikers',
    width: '180px',
    sortable: false,
    dataLink: 'instagramTopLocationLikers',
    dataApi: 'instagram_top_location_likers',
    network: 'instagram',
    visible: true,
    type: 'topLocation',
    aggregate: {
      default: null,
      editable: false,
    },
  },
]

const tiktokColumns = [
  {
    title: 'manageKols.table.community',
    configLabel: 'manageKols.configuration.community',
    width: '186px',
    sortBy: 'tiktok_community_count',
    sortable: true,
    dataLink: 'tiktokCommunityCount',
    dataApi: 'tiktok_community_count',
    network: 'tiktok',
    visible: true,
    type: 'community',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.emv',
    subTitle: 'manageKols.table.perPostCurrency',
    tooltip: 'manageKols.table.emv.tooltip',
    configLabel: 'manageKols.configuration.emvPerPost',
    width: '186px',
    sortBy: 'tiktok_emv_per_post',
    sortable: true,
    dataLink: 'tiktokEmvPerPost',
    dataApi: 'tiktok_emv_per_post',
    network: 'tiktok',
    visible: true,
    type: 'money',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estImpressions',
    tooltip: 'manageKols.table.estImpressions.tooltip',
    configLabel: 'manageKols.configuration.estimatedImprPerPost',
    width: '204px',
    sortBy: 'tiktok_impressions_per_post',
    sortable: true,
    dataLink: 'tiktokEstimatedImpressionPerPost',
    dataApi: 'tiktok_impressions_per_post',
    network: 'tiktok',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementPostTooltip',
    configLabel: 'manageKols.configuration.engagementPerPost',
    width: '186px',
    sortBy: 'tiktok_engagement_per_post',
    sortable: true,
    dataLink: 'tiktokEngagementPerPost',
    dataApi: 'tiktok_engagement_per_post',
    network: 'tiktok',
    visible: true,
    type: 'number',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagementRate',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementRatePostTooltip',
    configLabel: 'manageKols.configuration.engagementRatePerPost',
    width: '220px',
    sortBy: 'tiktok_engagement_rate_per_post',
    sortable: true,
    dataLink: 'tiktokEngagementRatePerPost',
    dataApi: 'tiktok_engagement_rate_per_post',
    network: 'tiktok',
    visible: true,
    type: 'percent',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.estimatedCostPostTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerPost',
    width: '208px',
    sortBy: 'tiktok_emv_per_post',
    sortable: true,
    dataLink: 'tiktokEstimatedCostPerPost',
    dataApi: 'tiktok_emv_range_per_post',
    network: 'tiktok',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.community',
    subTitle: 'manageKols.table.evolution',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.communityEvolution',
    width: '210px',
    sortBy: 'tiktok_community_progression_rate',
    sortable: true,
    dataLink: 'tiktokCommunityProgressionRate',
    dataApi: 'tiktok_community_progression_rate',
    network: 'tiktok',
    visible: true,
    type: 'percentEvolution',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.evolutionPerPost',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.engagementRateEvoPerPost',
    width: '220px',
    sortBy: 'tiktok_engagement_rate_progression',
    sortable: true,
    dataLink: 'tiktokEngagementRateProgression',
    dataApi: 'tiktok_engagement_rate_progression',
    network: 'tiktok',
    visible: true,
    type: 'percentEvolution',
    aggregate: {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topGender',
    subTitle: 'manageKols.table.followers',
    configLabel: 'manageKols.configuration.topGenderFollowers',
    width: '180px',
    sortable: false,
    dataLink: 'tiktokTopGenderFollowers',
    dataApi: 'tiktok_top_gender_followers',
    network: 'tiktok',
    visible: true,
    type: 'topGender',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topAge',
    subTitle: 'manageKols.table.followers',
    configLabel: 'manageKols.configuration.topAgeFollowers',
    width: '180px',
    sortable: false,
    dataLink: 'tiktokTopAgeSliceFollowers',
    dataApi: 'tiktok_top_age_slice_followers',
    network: 'tiktok',
    visible: true,
    type: 'topAgeSlice',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topLocation',
    subTitle: 'manageKols.table.followers',
    configLabel: 'manageKols.configuration.topLocationFollowers',
    width: '180px',
    sortable: false,
    dataLink: 'tiktokTopLocationFollowers',
    dataApi: 'tiktok_top_location_followers',
    network: 'tiktok',
    visible: true,
    type: 'topLocation',
    aggregate: {
      default: null,
      editable: false,
    },
  },
]

const youtubeColumns = [
  {
    title: 'manageKols.table.community',
    configLabel: 'manageKols.configuration.community',
    width: '186px',
    sortBy: 'youtube_community_count',
    sortable: true,
    dataLink: 'youtubeCommunityCount',
    dataApi: 'youtube_community_count',
    network: 'youtube',
    visible: true,
    type: 'community',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.emv',
    subTitle: 'manageKols.table.perVideoCurrency',
    tooltip: 'manageKols.table.emv.tooltip',
    configLabel: 'manageKols.configuration.emvPerVideo',
    width: '186px',
    sortBy: 'youtube_emv_per_post',
    sortable: true,
    dataLink: 'youtubeEmvPerPost',
    dataApi: 'youtube_emv_per_post',
    network: 'youtube',
    visible: true,
    type: 'money',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perVideo',
    tooltip: 'manageKols.table.estimatedCostPostTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerVideo',
    width: '208px',
    sortBy: 'youtube_emv_range_per_post',
    sortable: true,
    dataLink: 'youtubeEstimatedCostPerPost',
    dataApi: 'youtube_emv_range_per_post',
    network: 'youtube',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perVideo',
    tooltip: 'manageKols.table.engagementPostTooltip',
    configLabel: 'manageKols.configuration.engagementPerVideo',
    width: '186px',
    sortBy: 'youtube_engagement_per_post',
    sortable: true,
    dataLink: 'youtubeEngagementPerPost',
    dataApi: 'youtube_engagement_per_post',
    network: 'youtube',
    visible: true,
    type: 'number',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagementRate',
    subTitle: 'manageKols.table.perVideo',
    tooltip: 'manageKols.table.engagementRatePostTooltip',
    configLabel: 'manageKols.configuration.engagementRatePerVideo',
    width: '220px',
    sortBy: 'youtube_engagement_rate_per_post',
    sortable: true,
    dataLink: 'youtubeEngagementRatePerPost',
    dataApi: 'youtube_engagement_rate_per_post',
    network: 'youtube',
    visible: true,
    type: 'percent',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topGender',
    subTitle: 'manageKols.table.subscribers',
    configLabel: 'manageKols.configuration.topGenderSubscribers',
    width: '180px',
    sortable: false,
    dataLink: 'youtubeTopGenderFollowers',
    dataApi: 'youtube_top_gender_followers',
    network: 'youtube',
    visible: true,
    type: 'topGender',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topAge',
    subTitle: 'manageKols.table.subscribers',
    configLabel: 'manageKols.configuration.topAgeSubscribers',
    width: '180px',
    sortable: false,
    dataLink: 'youtubeTopAgeSliceFollowers',
    dataApi: 'youtube_top_age_slice_followers',
    network: 'youtube',
    visible: true,
    type: 'topAgeSlice',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topLocation',
    subTitle: 'manageKols.table.subscribers',
    configLabel: 'manageKols.configuration.topLocationSubscribers',
    width: '180px',
    sortable: false,
    dataLink: 'youtubeTopLocationFollowers',
    dataApi: 'youtube_top_location_followers',
    network: 'youtube',
    visible: true,
    type: 'topLocation',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topGender',
    subTitle: 'manageKols.table.commenters',
    configLabel: 'manageKols.configuration.topGenderCommenters',
    width: '180px',
    sortable: false,
    dataLink: 'youtubeTopGenderCommenters',
    dataApi: 'youtube_top_gender_commenters',
    network: 'youtube',
    visible: true,
    type: 'topGender',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topAge',
    subTitle: 'manageKols.table.commenters',
    configLabel: 'manageKols.configuration.topAgeCommenters',
    width: '180px',
    sortable: false,
    dataLink: 'youtubeTopAgeSliceCommenters',
    dataApi: 'youtube_top_age_slice_commenters',
    network: 'youtube',
    visible: true,
    type: 'topAgeSlice',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topLocation',
    subTitle: 'manageKols.table.commenters',
    configLabel: 'manageKols.configuration.topLocationCommenters',
    width: '180px',
    sortable: false,
    dataLink: 'youtubeTopLocationCommenters',
    dataApi: 'youtube_top_location_commenters',
    network: 'youtube',
    visible: true,
    type: 'topLocation',
    aggregate: {
      default: null,
      editable: false,
    },
  },
]

const facebookColumns = [
  {
    title: 'manageKols.table.community',
    configLabel: 'manageKols.configuration.community',
    width: '186px',
    sortBy: 'facebook_community_count',
    sortable: true,
    dataLink: 'facebookCommunityCount',
    dataApi: 'facebook_community_count',
    network: 'facebook',
    visible: true,
    type: 'community',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.emv',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.emv.tooltip',
    configLabel: 'manageKols.configuration.emvPerPost',
    width: '186px',
    sortBy: 'facebook_emv_per_post',
    sortable: true,
    dataLink: 'facebookEmvPerPost',
    dataApi: 'facebook_emv_per_post',
    network: 'facebook',
    type: 'money',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estImpressions',
    tooltip: 'manageKols.table.estImpressions.tooltip',
    configLabel: 'manageKols.configuration.estimatedImprPerPost',
    width: '204px',
    sortBy: 'facebook_impressions_per_post',
    sortable: true,
    dataLink: 'facebookEstimatedImpressionPerPost',
    dataApi: 'facebook_impressions_per_post',
    network: 'facebook',
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementPostTooltip',
    configLabel: 'manageKols.configuration.engagementPerPost',
    width: '186px',
    sortBy: 'facebook_engagement_per_post',
    sortable: true,
    dataLink: 'facebookEngagementPerPost',
    dataApi: 'facebook_engagement_per_post',
    network: 'facebook',
    visible: true,
    type: 'number',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagementRate',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementRatePostTooltip',
    configLabel: 'manageKols.configuration.engagementRatePerPost',
    width: '220px',
    sortBy: 'facebook_engagement_rate_per_post',
    sortable: true,
    dataLink: 'facebookEngagementRatePerPost',
    dataApi: 'facebook_engagement_rate_per_post',
    network: 'facebook',
    visible: true,
    type: 'percent',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.estimatedCostPostTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerPost',
    width: '208px',
    sortBy: 'facebook_emv_range_per_post',
    sortable: true,
    dataLink: 'facebookEstimatedCostPerPost',
    dataApi: 'facebook_emv_range_per_post',
    network: 'facebook',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },

  {
    title: 'manageKols.table.community',
    subTitle: 'manageKols.table.evolution',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.communityEvolution',
    width: '210px',
    sortBy: 'facebook_community_progression_rate',
    sortable: true,
    dataLink: 'facebookCommunityProgressionRate',
    dataApi: 'facebook_community_progression_rate',
    network: 'facebook',
    visible: true,
    type: 'percentEvolution',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.evolutionPerPost',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.engagementRateEvoPerPost',
    width: '220px',
    sortBy: 'facebook_engagement_rate_progression',
    sortable: true,
    dataLink: 'facebookEngagementRateProgression',
    dataApi: 'facebook_engagement_rate_progression',
    network: 'facebook',
    visible: true,
    type: 'percentEvolution',
    aggregate: {
      default: 'avg',
      editable: false,
    },
  },
]

const twitterColumns = [
  {
    title: 'manageKols.table.community',
    configLabel: 'manageKols.configuration.community',
    width: '186px',
    sortBy: 'twitter_community_count',
    sortable: true,
    dataLink: 'twitterCommunityCount',
    dataApi: 'twitter_community_count',
    network: 'twitter',
    visible: true,
    type: 'community',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.emv',
    subTitle: 'manageKols.table.perPostCurrency',
    tooltip: 'manageKols.table.emv.tooltip',
    configLabel: 'manageKols.configuration.emvPerPost',
    width: '186px',
    sortBy: 'twitter_emv_per_post',
    sortable: true,
    dataLink: 'twitterEmvPerPost',
    dataApi: 'twitter_emv_per_post',
    network: 'twitter',
    visible: true,
    type: 'money',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.estImpressions',
    tooltip: 'manageKols.table.estImpressions.tooltip',
    configLabel: 'manageKols.configuration.estimatedImprPerPost',
    width: '204px',
    sortBy: 'twitter_impressions_per_post',
    sortable: true,
    dataLink: 'twitterEstimatedImpressionPerPost',
    dataApi: 'twitter_impressions_per_post',
    network: 'twitter',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementPostTooltip',
    configLabel: 'manageKols.configuration.engagementPerPost',
    width: '186px',
    sortBy: 'twitter_engagement_per_post',
    sortable: true,
    dataLink: 'twitterEngagementPerPost',
    dataApi: 'twitter_engagement_per_post',
    network: 'twitter',
    visible: true,
    type: 'number',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagementRate',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.engagementRatePostTooltip',
    configLabel: 'manageKols.configuration.engagementRatePerPost',
    width: '220px',
    sortBy: 'twitter_engagement_rate_per_post',
    sortable: true,
    dataLink: 'twitterEngagementRatePerPost',
    dataApi: 'twitter_engagement_rate_per_post',
    network: 'twitter',
    visible: true,
    type: 'percent',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.estimatedCost',
    subTitle: 'manageKols.table.perPost',
    tooltip: 'manageKols.table.estimatedCostPostTooltip',
    configLabel: 'manageKols.configuration.estimatedCostPerPost',
    width: '208px',
    sortBy: 'twitter_emv_range_per_post',
    sortable: true,
    dataLink: 'twitterEstimatedCostPerPost',
    dataApi: 'twitter_emv_range_per_post',
    network: 'twitter',
    visible: true,
    type: 'estimatedCost',
    aggregate: {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.community',
    subTitle: 'manageKols.table.evolution',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.communityEvolution',
    width: '210px',
    sortBy: 'twitter_community_progression_rate',
    sortable: true,
    dataLink: 'twitterCommunityProgressionRate',
    dataApi: 'twitter_community_progression_rate',
    network: 'twitter',
    visible: true,
    type: 'percentEvolution',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.evolutionPerPost',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.engagementRateEvoPerPost',
    width: '220px',
    sortBy: 'twitter_engagement_rate_progression',
    sortable: true,
    dataLink: 'twitterEngagementRateProgression',
    dataApi: 'twitter_engagement_rate_progression',
    network: 'twitter',
    visible: true,
    type: 'percentEvolution',
    aggregate: {
      default: 'avg',
      editable: false,
    },
  },
]

const snapchatColumns = [
  {
    title: 'manageKols.table.community',
    configLabel: 'manageKols.configuration.community',
    width: '186px',
    sortBy: 'snapchat_community_count',
    sortable: true,
    dataLink: 'snapchatCommunityCount',
    dataApi: 'snapchat_community_count',
    network: 'snapchat',
    visible: true,
    type: 'community',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.community',
    subTitle: 'manageKols.table.evolution',
    tooltip: 'manageKols.table.overLast3months',
    configLabel: 'manageKols.configuration.communityEvolution',
    width: '186px',
    sortBy: 'snapchat_community_progression_rate',
    sortable: true,
    dataLink: 'snapchatCommunityProgressionRate',
    dataApi: 'snapchat_community_progression_rate',
    network: 'snapchat',
    visible: true,
    type: 'percentEvolution',
    aggregate:  {
      default: 'avg',
      editable: false,
    },
  },
  {
    title: 'manageKols.table.views',
    subTitle: 'manageKols.table.perSpotlight',
    configLabel: 'manageKols.configuration.viewsPerSpotlight',
    width: '186px',
    sortBy: 'snapchat_views_per_spotlight',
    sortable: true,
    dataLink: 'snapchatViewsperSpotlight',
    dataApi: 'snapchat_views_per_spotlight',
    network: 'snapchat',
    visible: true,
    type: 'number',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.reach',
    subTitle: 'manageKols.table.perSpotlight',
    configLabel: 'manageKols.configuration.reachPerSpotlight',
    width: '186px',
    sortBy: 'snapchat_reach_per_spotlight',
    sortable: false, // Disable: limit perf on the DB side
    dataLink: 'snapchatReachPerSpotlight',
    dataApi: 'snapchat_reach_per_spotlight',
    network: 'snapchat',
    visible: true,
    type: 'number',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perSpotlight',
    configLabel: 'manageKols.configuration.engagementPerSpotlight',
    width: '186px',
    sortBy: 'snapchat_engagement_per_spotlight',
    sortable: true,
    dataLink: 'snapchatEngagementPerSpotlight',
    dataApi: 'snapchat_engagement_per_spotlight',
    network: 'snapchat',
    visible: true,
    type: 'number',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.views',
    subTitle: 'manageKols.table.perSavedStories',
    configLabel: 'manageKols.configuration.viewsPerSavedStories',
    width: '186px',
    sortBy: 'snapchat_views_per_saved_story',
    sortable: true,
    dataLink: 'snapchatViewsPerSavedStory',
    dataApi: 'snapchat_views_per_saved_story',
    network: 'snapchat',
    visible: true,
    type: 'number',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.reach',
    subTitle: 'manageKols.table.perSavedStories',
    configLabel: 'manageKols.configuration.reachPerSavedStories',
    width: '186px',
    sortBy: 'snapchat_reach_per_saved_story',
    sortable: false, // Disable: limit perf on the DB side
    dataLink: 'snapchatReachPerSavedStory',
    dataApi: 'snapchat_reach_per_saved_story',
    network: 'snapchat',
    visible: true,
    type: 'number',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.engagement',
    subTitle: 'manageKols.table.perSavedStories',
    configLabel: 'manageKols.configuration.engagementPerSavedStories',
    width: '186px',
    sortBy: 'snapchat_engagement_per_saved_story',
    sortable: true,
    dataLink: 'snapchatEngagementPerSavedStory',
    dataApi: 'snapchat_engagement_per_saved_story',
    network: 'snapchat',
    visible: true,
    type: 'number',
    aggregate:  {
      default: 'avg',
      editable: true,
    },
  },
  {
    title: 'manageKols.table.topGender',
    subTitle: 'manageKols.table.views',
    configLabel: 'manageKols.configuration.topGenderViews',
    width: '180px',
    sortable: false,
    dataLink: 'snapchatTopGenderViews',
    dataApi: 'snapchat_top_gender_views',
    network: 'snapchat',
    visible: true,
    type: 'topGender',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topAge',
    subTitle: 'manageKols.table.views',
    configLabel: 'manageKols.configuration.topAgeViews',
    width: '180px',
    sortable: false,
    dataLink: 'snapchatTopAgeSliceViews',
    dataApi: 'snapchat_top_age_slice_views',
    network: 'snapchat',
    visible: true,
    type: 'topAgeSlice',
    aggregate: {
      default: null,
      editable: false,
    },
  },
  {
    title: 'manageKols.table.topLocation',
    subTitle: 'manageKols.table.views',
    configLabel: 'manageKols.configuration.topLocationViews',
    width: '180px',
    sortable: false,
    dataLink: 'snapchatTopLocationViews',
    dataApi: 'snapchat_top_location_views',
    network: 'snapchat',
    visible: true,
    type: 'topLocation',
    aggregate: {
      default: null,
      editable: false,
    },
  },
]

export const orderData = [
  {
    group: 'profile',
    icon: 'user',
    iconTheme: 'solid',
    label: 'manageKols.configuration.kolProfile',
    rows: basicColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'instagram',
    icon: 'instagram-color',
    iconTheme: 'brand',
    label: 'Instagram',
    rows: instagramColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'tiktok',
    icon: 'tiktok-color',
    iconTheme: 'brand',
    label: 'Tiktok',
    rows: tiktokColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'youtube',
    icon: 'youtube-color',
    iconTheme: 'brand',
    label: 'Youtube',
    rows: youtubeColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'snapchat',
    icon: 'snapchat-color',
    iconTheme: 'brand',
    label: 'Snapchat',
    rows: snapchatColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'facebook',
    icon: 'facebook-color',
    iconTheme: 'brand',
    label: 'Facebook',
    rows: facebookColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'twitter',
    icon: 'twitter-color',
    iconTheme: 'brand',
    label: 'Twitter',
    rows: twitterColumns.map((row, index) => ({...row, order: index + 1 })),
  },
  {
    group: 'customField',
    icon: 'input-text',
    iconTheme: 'solid',
    label: 'manageKols.configuration.customFields',
    rows: [],
  },
]

export const dataHeader = orderData.reduce((prev, curr, index) => [
  ...prev,
  ...curr.rows.map(row => ({
    ...row,
    groupIndex: 100 * index,
    order: row.order,
  }))
], []);

export const accountRequestsHeader = [
  {
    name: 'date',
    sortable: true,
  },
  {
    name: 'urls',
    sortable: true,
  },
  {
    name: 'account',
    sortable: true,
  },
];

export const ACCOUNT_CREATION_REQUESTS_GRID_TEMPLATE_COLUMNS = '2fr 7fr 3fr';
