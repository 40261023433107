import React, { memo, useCallback, useMemo } from 'react';
import { toast as toastify } from 'react-toastify';
import { useIntl } from 'react-intl-phraseapp';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import toast from 'utils/toast';

import ErrorBoundary from 'components/modules/ErrorBoundary';
// import logError from 'utils/logError';

export const isNotNull = (v) => v && v.trim() !== '' && v.trim() !== '​\n';

/**
 * INFO: for <a /> links: if you want to open links in new tab, surround the text with "=="
 * ex: [==read the faq==](https://www.fag.link/article)
 *
 */

const Markdown = ({ children, className = '' }) => {
  const intl = useIntl();
  const content = useMemo(
    () => (Array.isArray(children) || isNotNull(children) ? children : ''),
    [children],
  );

  const parseContent = (toParse) => {
    let markdownParsed = toParse;
    const matchesUnderline = toParse.match(/\++.+?\++/g);
    if (matchesUnderline?.length) {
      matchesUnderline.forEach((mark) => {
        markdownParsed = markdownParsed.replaceAll(
          mark,
          `~~${mark.replaceAll('++', '').trim()}~~`,
        );
      });
    }
    return markdownParsed;
  };

  const handleMarkdownError = useCallback(
    (error) => {
      console.error('Markdown error :>>', error);
      // Let's keep track of this for now
      // logError(error, { handled: true });
      // It's probably an error due to unsupported browser, so show the toast
      if (!toastify.isActive('unsupportedBrowser')) {
        toast(intl.formatMessage({ id: 'unsupportedBrowser.description' }), {
          type: 'error',
          duration: false,
          toastId: 'unsupportedBrowser',
          title: intl.formatMessage({ id: 'unsupportedBrowser.title' }),
        });
      }
    },
    [intl],
  );

  const markdownParsed = Array.isArray(content)
    ? content.map(parseContent).join('\n')
    : parseContent(content);

  return (
    <span className={className}>
      <ErrorBoundary fallback={markdownParsed} onError={handleMarkdownError}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            del: (props) => <ins>{props.children}</ins>,
            a: (props) => {
              const text = props.children?.[0];
              return text &&
                typeof text === 'string' &&
                text.startsWith('==') &&
                text.endsWith('==') ? (
                <a href={props.href} target="_blank" rel="noreferrer">
                  {React.Children.toArray(
                    props.children.map((child) => child.replaceAll('==', '')),
                  )}
                </a>
              ) : (
                <a href={props.href}>{props.children}</a>
              );
            },
          }}
        >
          {markdownParsed}
        </ReactMarkdown>
      </ErrorBoundary>
    </span>
  );
};

export default memo(Markdown);
