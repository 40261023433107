// Strips an object of undefined/null/empty strings/empty objects/empy array fields recursively
const stripObject = object => {
    if (!object || typeof object !== "object") return object;
    const newObject = Array.isArray(object) ? [...object] : { ...object };
    Object.keys(newObject).forEach(key => {
        if (newObject[key]) {
            newObject[key] = stripObject(newObject[key]);
        }
        if (!newObject[key] || (typeof newObject[key] === 'object' && Object.keys(newObject[key]).length === 0)) {
            delete newObject[key];
        }
    });
    return newObject;
}

export default stripObject;
