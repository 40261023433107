import { configFilters } from 'config/filters';
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'config/app';
import { getPeriodByPreset } from 'utils/dateRangeUtil';

import * as profilesMapper from './profiles';

const engineSort = {
	relevance: `relevance`,
	communitySize: `community_size`,
	commitment: `commitment`,
	commitmentPercent: `commitment_percent`,
	notoriety: `notoriety`,
	mostRecent: `most_recent`,
};

const engineTypes = {
	post: 'posts',
	people: 'names',
	description: 'biographies',
	url: 'quicksearch',
};

const initCommunity = value => Object.assign(
	{},
	value && value.min !== '' ? { min: value.min } : null,
	value && value.max !== '' ? { max: value.max } : null,
);

const initSelectPercent = value => value?.map(val => {
	return ({
		percent: val.percent,
		value: val.value
	})
});

const countries = value => value?.map(val => {
	const params = val.city && val.city !== ''
		? { uid: val.id }
		: { country_code: val.countryCode };

	return ({
		percent: val.percent,
		...params
	})
});

const localizations = value => value.map(value => {
	return ({
		g_country_code: value.g_country_code,
		g_city: value.g_city,
	})
});

const doSearch = {
	toApi: query => {
		const { filters, page, sortBy, type } = query;
		const { activeFilter } = filters;

		// Let's rock the Mapper bitch!
		const initFilterFromStore = Object.entries(filters)
			.reduce((store, [key, value]) => {
				switch (key) {
					case 'enlisted':
						return Object.assign(store,
							value ? { enlisted: true } : null,
						);

					case 'seedingKol':
						return Object.assign(store,
							value
								? { [configFilters[key].mapper]: value }
								: null,
						);

					case 'status':
						return Object.assign(store,
							value ? { status: ['qualified'] } : null,
						);

					case 'credibility':
						return Object.assign(store,
							value ? { credibility: value } : null,
						);

					case 'excludeFavorite':
						return Object.assign(store,
							value
								? { [configFilters[key].mapper]: value }
								: null,
						);

					case 'arpp_certified':
						return Object.assign(store,
							value
								? { [configFilters[key].mapper]: value }
								: null,
						);

					case 'excludeTag':
						return Object.assign(store,
							value.length
								? { [configFilters[key].mapper]: value }
								: null,
						);

					case 'localisations':
						return Object.assign(store,
							value.length
								? {[configFilters[key].mapper]: localizations(value)}
								: null,
						);

					case 'influencerThemes':
						return Object.assign(store,
							value.length
								? {[configFilters[key].mapper]: value.map(value => value?.toLowerCase()) }
								: null,
						);

					case 'activities':
					case 'languages':
					case 'ages':
					case 'genders':
						return Object.assign(store,
							value.length
								? { [configFilters[key].mapper]: value }
								: null,
						);

					case 'socialNetworks': return {
						...store,
						social_networks: Object.entries(value)
							.filter(([key,]) => activeFilter.includes(key))
							.reduce((network, [key, value]) => {
								return {
									...network,
									[key]: Object.entries(value)
									.reduce((network, [key, value]) => {
										return Object.assign(network,
											key === 'accountCountry'
												? {[configFilters[key].mapper]: value}
												: null,

											typeof configFilters[key].default === 'object' &&
											'min' in configFilters[key]?.default &&
											'max' in configFilters[key]?.default &&
											JSON.stringify(initCommunity(value)) !== '{}'
												? {[configFilters[key].mapper]: initCommunity(value)}
												: null,

											Array.isArray(value) && value.length > 0 && key !== 'communityCountries' && key !== 'accountCountry'
												? {[configFilters[key].mapper]: initSelectPercent(value, key)}
												: null,

											key === 'communityCountries' && value.length > 0
												? {[configFilters[key].mapper]: countries(value)}
												: null,

											key === 'active' && {[configFilters[key].mapper]: value}
										);
									}, {})
								}
							}, {})
					};

					case 'period': {
						return {
							...store,
							...getPeriodByPreset(value, BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT),
						};
					}

					case 'state':
						return Object.assign(
							store,
							value.indexOf("communityAccessOnly") > -1 ? { has_community_access: true} : null,
							value.indexOf("contactableOnly") > -1 ? { contactable: true} : null,
							value.indexOf("showBrands") > -1 ? { include_brands: true} : null,
							value.indexOf("unqualifiedOnly") > -1 ? { status: ['unqualified']} : null,
						);

					case 'search':
						return Object.assign(store,
							value.length > 0 ? {[key]: [...value]} : null,
						);

					case 'activeFilter':
					case 'hasActiveFilter':
					case 'sid':
					case 'initFilter':
						return store;
					default:
						return store;
				}
			}, {});

		return ({
			search_within: !type ? 'posts' : engineTypes[type],
			filters: initFilterFromStore,
			page,
			sort_by: engineSort[sortBy] || 'community_size',
		})
	},

	fromApi: ({ total, rows, per_page }) => {
		return {
			profiles: rows ? rows.map(profilesMapper.mapProfile.fromApi) : [],
			results: {
				total: total || 0,
				resultsPerPage: per_page || 0,
				rows: rows ? rows.map(({ id, snas }) => ({
					matchSnas: snas ? snas.filter(sna => sna.match).map(sna => String(sna.id)) : [],
					id: String(id),
				})) : [],
			}
		}
	},
};

export const mapSnas = snas => snas ? snas.map(mapSna) : [];

export const mapSna = sna => ({
	id: String(sna.id),
	uid: String(sna.uid),
	biography: sna.biography,
	network: sna.label,
	isPrivate: sna.is_private,
	username: sna.username,
	stats: profilesMapper.mapSnaStats.fromApi(sna),
});

export default {
	doSearch
}
