import React, { useEffect, useCallback, useMemo, useState } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl-phraseapp';

import { Input, Label, Button3, Loader } from 'kolkit';

import * as authActions from 'actions/auth';
import { useDispatch } from 'utils/redux';
import { tokenTypes } from 'constants/auth';

import AuthHeader from '../AuthHeader';
import ErrorMessage from '../ErrorMessage';

import styles from '../Auth.module.scss';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();

  const [state, setState] = useState({
    newPassword: '',
    newPasswordConfirmation: '',
    loading: false,
    checkingToken: true,
    successfullyReset: false,
    invalidToken: false,
    errorCode: ''
  });

  const token = useMemo(
    () => {
      const urlQueries = querystring.parse(location.search.substring(1)); // without the ?
      return urlQueries.token;
    },
    [location]
  );

  useEffect(
    () => {
      (async () => {
        const { error } = await dispatch(authActions.verifyToken({
          tokenType: tokenTypes.RESET_PASSWORD,
          token
        }));
        setState(prevState => ({
          ...prevState,
          errorCode: error,
          checkingToken: false,
          invalidToken: !!error,
        }));
      })();
    },
    [dispatch, token]
  );

  const handleChange = useCallback(({ name, value }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
      errorCode: '',
    }));
  }, []);

  const handlePasswordReset = useCallback(
    async e => {
      e.preventDefault();
      setState(prevState => ({
        ...prevState,
        loading: true,
        successfullyReset: false,
        errorCode: ''
      }));
      const { error } = await dispatch(authActions.resetPassword({
        password: state.newPassword,
        passwordConfirmation: state.newPasswordConfirmation,
        token
      }));
      setState(prevState => ({
        ...prevState,
        loading: false,
        errorCode: error || '',
        successfullyReset: !error
      }));
    },
    [dispatch, state.newPassword, state.newPasswordConfirmation, token]
  );

  const submitButtonContent = useMemo(
    () => {
      if (state.successfullyReset) return intl.formatMessage({ id: 'auth.passwordChange.submitted' });
      if (state.loading) return intl.formatMessage({ id: 'auth.passwordChange.loading' });
      return intl.formatMessage({ id: 'global.cta.submit' });
    },
    [state.successfullyReset, state.loading, intl]
  );

  const fieldDisabled = state.loading || state.successfullyReset;

  const submitDisabled = state.successfullyReset
    || state.loading
    || !state.newPassword
    || !state.newPasswordConfirmation;

  if (state.checkingToken) return (
    <div className={styles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.passwordReset.title' })} />
      <Loader />
    </div>
  );

  if (state.invalidToken) return (
    <div className={styles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.passwordReset.title' })} />
      <ErrorMessage errorCode={state.errorCode} />
    </div>
  );

  return (
    <div className={styles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.passwordReset.title' })} />
      <form onSubmit={handlePasswordReset}>
        <div className={styles.fields}>
          <Label label={intl.formatMessage({ id: 'auth.passwordChange.newPassword' })} id="auth-password-reset-new-password-label">
            <Input
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'auth.passwordChange.newPassword' })}
              size="big"
              type="password"
              name="newPassword"
              value={state.email}
              disabled={fieldDisabled}
              error={!!state.errorCode}
              fullWidth
            />
          </Label>
          <Label label={intl.formatMessage({ id: 'auth.passwordChange.newPasswordConfirmation' })} id="auth-password-reset-confirm-password-label">
            <Input
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'auth.passwordChange.newPasswordConfirmation' })}
              size="big"
              type="password"
              name="newPasswordConfirmation"
              value={state.email}
              disabled={fieldDisabled}
              error={!!state.errorCode}
              fullWidth
            />
          </Label>
          {state.errorCode && <ErrorMessage errorCode={state.errorCode} />}
          <Button3
            fullWidth
            disabled={submitDisabled}
            size="medium"
            type="submit"
            icon={state.successfullyReset ? 'check' : '' }
            label={submitButtonContent}
          />
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
