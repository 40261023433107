import React, { memo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { Loader } from 'kolkit';
import useLoading from 'utils/hooks/useLoading';
import Footer from 'components/ui/Footer';

import styles from './AuthPageWrapper.module.scss';

const AuthPageWrapper = ({ children, contentFooter = null }) => {
  const { loading } = useLoading('phraseLoader');

  return (
    <div className="loaded">
      {loading && <Loader full background="rgba(255, 255, 255, .8)" />}
      <div className="structure_scroll full-width">
        <div className="scroll_content">
          <CssBaseline />
          <div className={styles.page}>
            <div className={styles.container}>
              <div className={styles.background} />
              <div className={styles.content}>
                <div className={styles.paddedContent}>
                  {children}
                </div>
                {contentFooter}
              </div>
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(AuthPageWrapper);
