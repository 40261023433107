import React, { useCallback, useMemo, useState, useRef } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl-phraseapp';

import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

import { Input2, TruncateText } from 'kolkit';

import { getLocations } from 'api/utils';
import buildCountryOptionLabel from 'utils/buildCountryOptionLabel';

import styles from './InputLocation.module.scss';

const PINNED_COUNTRIES = ['FR', 'US', 'GB', 'BE', 'ES', 'IT'];

export type LocationItem = Awaited<ReturnType<typeof getLocations>>[number];
export type LocationItemOptionalId = Omit<LocationItem, 'id'> & { id?: string };

type Props = {
  label?: string;
  placeholder: string;
  isCountryOnly?: boolean;
  fullWidth?: boolean;
  autoFocus?: boolean;
  className?: string;

  onChange: ({
    item,
    selected,
  }: {
    item: LocationItem;
    selected: LocationItemOptionalId[];
  }) => void;
  selected: LocationItemOptionalId[];
  notFoundText?: string;
  minChars?: number;
  required?: boolean;
  disabled?: boolean;
  renderValue?: (selected: LocationItemOptionalId[]) => string;
  size?: React.ComponentProps<typeof Input2>['size'];
  theme?: React.ComponentProps<typeof Input2>['theme'];
  dataId?: string;
};

const InputLocation: React.FC<Props> = ({
  label,
  placeholder,
  fullWidth,
  autoFocus,
  className,
  isCountryOnly,
  onChange,
  selected,
  notFoundText = '',
  minChars = 3,
  required,
  disabled,
  renderValue,
  size = 'small',
  theme = undefined,
  dataId,
}) => {
  const intl = useIntl();

  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const [openOptions, setOpenOptions] = useState(false);
  const [searchResults, setSearchResults] = useState<LocationItem[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiCallTimeout = useRef<NodeJS.Timeout>();
  const triggerRef = useRef<HTMLInputElement>();

  const handleChangeSearch: (props: { value: string }) => void = useCallback(
    ({ value }) => {
      if (!value || value.length === 0) {
        setOpenOptions(true);
        setSearch('');
        setSearchResults([]);
      }

      setSearch(value);

      if (apiCallTimeout.current) {
        clearTimeout(apiCallTimeout.current);
      }

      if (value.length >= minChars) {
        apiCallTimeout.current = setTimeout(() => {
          setLoading(true);
          getLocations({ search: value, isCountryOnly })
            .then(
              (results) => {
                const searchResults = [...results].map((item) => ({
                  ...item,
                  selected: false,
                }));
                setSearchResults(searchResults);
                setError('');
              },
              (error) => {
                setSearchResults([]);
                setError(error);
              },
            )
            .finally(() => {
              setOpenOptions(true);
              setLoading(false);
            });
        }, 350);
      }
    },
    [isCountryOnly, minChars],
  );

  const handleClick = useCallback(
    (location: LocationItem) => {
      const keyToCheck = isCountryOnly ? 'countryCode' : 'id';

      const newSelected = selected.find((selectedItem) => selectedItem[keyToCheck] === location[keyToCheck])
        ? selected.filter((item) => item[keyToCheck] !== location[keyToCheck])
        : [...selected, location];

      onChange({
        item: location,
        selected: newSelected,
      });
      setOpenOptions(false);
      setSearch('');
    },
    [isCountryOnly, onChange, selected],
  );

  const inPortal = useMemo(() => {
    // has done no search yet
    if (isCountryOnly && search?.trim()?.length < minChars) {
      return (
        <ul className={styles.optionsList}>
          <li className={styles.hint}>
            {intl.formatMessage({
              id: 'engineSearch.filters.location.hint',
            })}
          </li>
          <Divider flexItem />
          {PINNED_COUNTRIES.map((countryCode) => (
            <li
              key={countryCode}
              role="option"
              aria-selected={false}
              className={styles.option}
              onClick={
                disabled
                  ? undefined
                  : () =>
                      handleClick({
                        id: countryCode,
                        countryCode,
                      })
              }
            >
              <TruncateText>
                {buildCountryOptionLabel(
                  null,
                  intl.formatDisplayName(countryCode, {
                    type: 'region',
                  }),
                )}
              </TruncateText>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <ul className={styles.optionsList}>
        {loading && <LinearProgress />}
        {loading || searchResults.length > 0 ? (
          React.Children.toArray(
            searchResults.map((item, idx) => {
              const keyToCheck = isCountryOnly ? 'coutryCode' : 'id';
              const isSelected = !!selected.find(
                (selectedItem) => selectedItem[keyToCheck] === item[keyToCheck],
              );
              return (
                <li
                  key={idx}
                  role="option"
                  aria-selected={isSelected || false}
                  className={cn(styles.option, {
                    [styles.optionSelected]: isSelected,
                  })}
                  onClick={disabled ? undefined : () => handleClick(item)}
                >
                  <TruncateText>
                    {buildCountryOptionLabel(
                      item.city,
                      intl.formatDisplayName(item.countryCode, {
                        type: 'region',
                      }),
                    )}
                  </TruncateText>
                </li>
              );
            }),
          )
        ) : (
          <span className={cn(styles.option, { [styles.optionError]: error })}>
            {intl.formatMessage({
              id: error
                ? 'global.inputLocation.error'
                : notFoundText || 'global.inputSearch.noResult',
            })}
          </span>
        )}
      </ul>
    );
  }, [
    isCountryOnly,
    search,
    minChars,
    loading,
    searchResults,
    error,
    intl,
    notFoundText,
    disabled,
    handleClick,
    selected,
  ]);

  const displayedValues = useMemo(
    () => (renderValue ? renderValue(selected) : ''),
    [renderValue, selected],
  );

  return (
    <div className={cn(styles.wrapper, { [styles.fullWidth]: fullWidth })}>
      <Input2
        label={label}
        placeholder={placeholder}
        value={isFocused ? search : displayedValues}
        errorRequiredMessage={intl.formatMessage({
          id: 'global.messages.error.required',
        })}
        disabled={disabled}
        onChange={disabled ? null : handleChangeSearch}
        required={required}
        fullWidth
        autoFocus={autoFocus}
        className={className}
        ref={triggerRef}
        size={size}
        theme={theme}
        // Delay opening the popper to allow focus on input
        onFocus={() => {
          if (isCountryOnly) setTimeout(() => setOpenOptions(true), 250);
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        noIcon
        dataId={dataId}
      />
      <Popper
        open={openOptions}
        anchorEl={triggerRef.current}
        placement="bottom-start"
        className={styles.popper}
        style={{ minWidth: triggerRef.current?.offsetWidth }}
      >
        <ClickAwayListener
          mouseEvent="onMouseUp"
          onClickAway={() => setOpenOptions(false)}
        >
          {inPortal}
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default InputLocation;
