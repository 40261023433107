import { actions } from './contentValidation.reducers';

// Types
export type {
  ContentRequest,
  ContentStatus,
  SliceState,
  AggregatedContentRequest,
  ContentRequestEdition,
  ContentProposalVersion,
  ContentProposalEdition,
  PublicContentValidationSession,
} from './contentValidation.types';

// Actions
export const {
  updateKey,
  addMediaPlan,
  removeMediaPlan,
  editMediaPlan,
  resetMediasPlan,
  updateContentProposal,
  setCurrentPublicUser,
  updateUploadInProgress,
} = actions;

export {
  // Thunk
  getContentRequests,
  updateContentRequests,
  updateContentInstructions,
  getContentProposals,
  uploadContentVersion,
  updateContentRequestStatus,
  getTokenUrlInfos,
  getPublicContentValidation,
  getContentValidationShareLink,
  loginContentValidationGuest,
  deleteProposal,
} from './contentValidation.actions';

export { CHUNK_UPLOAD_API } from './contentValidation.api';

export { default } from './contentValidation.reducers';
