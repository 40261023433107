import React, { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { newApplicationVersionRequired } from 'actions/env';
import logError from 'utils/logError';

import ApiErrorModale from './ui/ApiErrorModale';

type Props = PropsWithChildren<{
  actions: any,
  user: {
    id: string;
    firstName: string;
    lastName: string;
    companyName: string;
  };
  isApiError: boolean;
}>;

type State = {
  isJsError: boolean;
  isChunkLoadError: boolean;
};

class GlobalErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { isJsError: false, isChunkLoadError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error('componentDidCatch', error, errorInfo);
		if (typeof error === 'object' && error?.name === 'ChunkLoadError') {
      const { newApplicationVersionRequired } = this.props.actions;
      newApplicationVersionRequired();
      this.setState({ isChunkLoadError: true });
		} else {
      const { id, firstName, lastName, companyName } = this.props.user;
      void logError(error, {
        errorInfo,
        id,
        name: `${firstName}  ${lastName}`,
        companyName,
      });
      this.setState({ isJsError: true });
    }
  }

  render() {
    if (this.state.isChunkLoadError) return null;
    if (this.state.isJsError || this.props.isApiError)
      return <ApiErrorModale isRuntimeError />;
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  isApiError: !!state.ui.modales?.apiError?.show,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ newApplicationVersionRequired }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalErrorBoundary);
