import moment from 'moment';
import _get from 'lodash.get';

import { BACKEND_DATE_FORMAT } from 'config/app';
import { mapInteractionDetails } from 'mappers/posts';
import { SOURCES } from 'config/followersIncrease';

export const brandsInfos = {
  fromApi: ({ brand_monitoring, start_date_limit }) => ({
    id: brand_monitoring?.id || null,
    isAccountReady: Boolean(brand_monitoring?.is_account_ready),
    rejectionReason: brand_monitoring?.rejection_reason || false,
    mention: brand_monitoring?.mention || '',
    brandId: brand_monitoring?.public_profile?.id || '',
    brandName: brand_monitoring?.public_profile?.name || '',
    picture: brand_monitoring?.public_profile?.picture || '',
    startDateLimit: start_date_limit || null,
  }),
  toApi: mention => ({
    mention: String(mention)
  })
};

const mapStatsBycategory = (stat) => ({
  emv: _get(stat, 'emv', null),
  kols: _get(stat, 'kols_count', null),
  stories: _get(stat, 'stories_count', null),
  posts: _get(stat, 'posts_count', null),
  reels: _get(stat, 'reels_count', null),
  engagement: _get(stat, 'total_engagements', null),
  engagementRate: _get(stat, 'engagement_rate', null),
  distribution: _get(stat, 'kols_distribution', null),
});

export const getStats = {
  toApi: ({ startDate, endDate}) => ({
    start_at: startDate ? moment(startDate).format(BACKEND_DATE_FORMAT): null,
    end_at: endDate ? moment(endDate).format(BACKEND_DATE_FORMAT): null,
  }),
  fromApi: ({ brand_monitorings_stats }) => ({
    owned: mapStatsBycategory(brand_monitorings_stats?.owned),
    campaign: mapStatsBycategory(brand_monitorings_stats?.campaign),
    organic: mapStatsBycategory(brand_monitorings_stats?.organic),
    all: mapStatsBycategory(brand_monitorings_stats?.all),
  })
};

export const getGrowthStats = {
  toApi: ({ startDate, endDate, timeRange }) => ({
    start_at: startDate
      ? moment(startDate).format(BACKEND_DATE_FORMAT)
      : null,
    end_at: endDate ? moment(endDate).format(BACKEND_DATE_FORMAT) : null,
    interval: timeRange,
  }),
  fromApi: ({
    error,
    data,
    evolution,
    evolution_rate,
    time_ranges,
    total_followers,
    medias,
  }) => ({
    error: error || null,
    followers: total_followers || null,
    medias: medias || 0,
    evolution:
      evolution < 0 ? Math.floor(evolution) : Math.ceil(evolution),
    evolutionRate: evolution_rate || null,
    data: (data || []).map((item, index) => ({
      date: {
        start: time_ranges?.[index],
        end:
          index !== data?.length - 1
            ? moment(time_ranges?.[index + 1], BACKEND_DATE_FORMAT)
                .subtract(1, 'days')
                .format(BACKEND_DATE_FORMAT)
            : moment().format(BACKEND_DATE_FORMAT),
      },
      organic: item?.organic,
      owned: item?.owned,
      campaign: item?.campaign,
      evolution:
        item?.evolution < 0
          ? Math.floor(item?.evolution)
          : Math.ceil(item?.evolution),
      evolutionRate: item?.evolution_rate,
      avgFollowers: item?.average_followers,
    })),
  }),
};

export const getContents = {
  fromApi: ({ total, posts }) => {
    const decodeContent = (highlightedContent, content) => {
      try {
        // INFO: stringify first in order to escape "\n"
        return JSON.parse(JSON.stringify(`${highlightedContent}`));
      } catch (e) {
        return content;
      }
    };

    return {
      total: total || 0,
      rows: (posts || []).map(post => ({
        id: String(post.id),
        avatar: post.avatar_url,
        campaigns: post.campaigns || [],
        displayName: post.display_name,
        profileId: String(post.profile_id),
        snaId: String(post.sna_id),
        network: post.social_network,
        username: post.username,
        publishedAt: post.published_at,
        updatedAt: post.updated_at,
        contentUrl: post.content_url,
        picture: post.picture_url,
        video: post.video_url,
        content: decodeContent(post.content, ''),
        stickerLinkUrl: post?.sticker_link_url,
        avgCommitments: post.avg_engagement,
        interactionDetails: mapInteractionDetails.fromApi(post.engagement_details),
        manualInteractionDetails:
          post.manual_engagement_details !== null
            ? mapInteractionDetails.fromApi(post.manual_engagement_details)
            : {},
        manualInteractionDetailsForced: !!post.manual_engagement_details?.is_manual,
        type: post.type || 'picture',
        isStory: !!post.story,
        isIgtv: !!post.is_igtv,
        isVideoExpired: !!post.is_video_expired,
        isReel: !!post.is_reel,
        isSnapchatStory: !!post?.is_saved_story,
        isSnapchatSpotlight: !!post?.is_spotlight,
        manuallyAdded: !!post.manually_added,
      })),
    };
  },
  toApi: ({
    sources,
    contentTypes,
    contentSort,
    currentPage,
    perPage,
    startAt,
    endAt,
  }) => ({
    sources: sources.length === 0 ? SOURCES : sources,
    publication_type: contentTypes,
    sort_by: contentSort,
    page: currentPage,
    per_page: perPage,
    start_at: moment(startAt).format(BACKEND_DATE_FORMAT),
    end_at: endAt ? moment(endAt).format(BACKEND_DATE_FORMAT) : moment(startAt).format(BACKEND_DATE_FORMAT),
  }),
};
