import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

const errorsManager = ({ status, intlId, source }) => {
  const intl = getIntl();

  if (status === 500) {
    return toast(intl.formatMessage({
      id: intlId,
      defaultMessage: `Houston, we have a problem on ${source}`
    }));
  }
}

export default errorsManager;