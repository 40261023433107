import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import moment from 'moment-timezone';

import * as LISTENING from 'constants/listeningV2';
import * as USER from 'constants/user';
import { PERIOD } from 'config/listening';
import { PUBLICATION_TYPES_LIST } from 'config/posts';

export interface Brand {
  author_id: number;
  mention: string;
  name: string;
  picture_url: string;
  is_self: boolean;
  is_active: boolean;
  community_count: number;
  unavailable_mentions: boolean;
}

export type Monitoring = {
  status: 'ready' | 'on_load';
  monitoring_id: number;
  title: string;
  read_only: boolean;
  owner_id: number;
  owner_name: string;
  owner_team: string;
  audience_filters: {
    country: string;
    percentage: string;
  }[];
  brands: {
    instagram: Brand[];
    tiktok: Brand[];
  };
  owner_mention: {
    instagram: string;
    tiktok: string;
  };
  expired_on: string;
  color: string;
};

export interface ListeningEnv {
  settings: {
    currentMonitoring: Monitoring | null;
    brandsColors: {
      instagram: { [mention: string]: string; };
      tiktok: { [mention: string]: string; };
    };
    network: string;
    dateRanges: {
      startDate: string | null;
      endDate: string | null;
    },
    minDate: string | null;
    maxDate: string | null;
    hasError: boolean;
    excluded_mentions: {
      instagram: string[];
      tiktok: string[];
    },
    exclude_self_kol: boolean;
    remaining_monitorings: boolean;
  },
  monitorings: Monitoring[];
  kpiOverview: unknown;
  shareOfVoices: {
    kpi: "emv" | "contents" | "engagement";
    data: unknown;
  };
  shareOfVoiceByCountry: {
    kpi: "emv" | "contents" | "engagement";
    data: unknown;
  };
  kpiEvolution: {
    kpi?: string;
    rangeType?: (typeof PERIOD)[number];
    timeRanges: (typeof PERIOD)[number][];
    data: unknown;
  },
  kolDistribution: {
    data: {
      [mention: string]: {
        nano: number;
        micro: number;
        macro: number;
        mega: number;
      }
    };
  };
  topKols: {
    rows: unknown,
    communitySize: "nano" | "micro" | "macro" | "mega" | "all";
  };
  contents: {
    rows: unknown;
    total: number;
    page: number;
    publication_type: string[];
    sort_by: string;
  };
}

const initialState: ListeningEnv = {
  settings: {
    currentMonitoring: null,
    brandsColors: {
      instagram: {},
      tiktok: {}
    },
    network: 'instagram',
    dateRanges: {
      startDate: null,
      endDate: null
    },
    minDate: null,
    maxDate: null,
    hasError: false,
    excluded_mentions: {
      instagram: [],
      tiktok: [],
    },
    exclude_self_kol: false,
    remaining_monitorings: false,
  },
  monitorings: [],
  kpiOverview: [],
  shareOfVoices: {
    kpi: 'emv',
    data: [],
  },
  shareOfVoiceByCountry: {
    kpi: 'emv',
    data: [],
  },
  kpiEvolution: {
    kpi: 'emv',
    rangeType: 'day',
    timeRanges: [],
    data: {},
  },
  kolDistribution: {
    data: {}
  },
  topKols: {
    rows: [],
    communitySize: 'all',
  },
  contents: {
    rows: [],
    total: 0,
    page: 0,
    publication_type: PUBLICATION_TYPES_LIST,
    sort_by: 'most_recent'
  },
};

const reducers: {
  [key: string]: CaseReducer<ListeningEnv, PayloadAction<any>>;
} = {
  [USER.PARAMS_LOADED]: (state: ListeningEnv, { payload: { timezone } }) => update(state, {
    settings: {
      $merge: {
        minDate: timezone ? moment().tz(timezone).subtract(25, 'months').format() : null,
        maxDate: timezone ? moment().tz(timezone).format() : null,
      },
    },
  }),
  [LISTENING.UPDATE_SETTINGS]: (state, { payload }: PayloadAction<ListeningEnv['settings']>) => update(state, {
    settings: { $merge: payload },
  }),
  [LISTENING.RESET_SETTINGS]: (state) => update(state, {
    settings: {
      $set: {
        ...initialState?.settings,
        minDate: state?.settings?.minDate,
        maxDate: state?.settings?.maxDate,
      }
    },
  }),
  [LISTENING.LISTENINGS_LOADED]: (state: ListeningEnv, { payload: { monitorings, remaining_monitorings } }) => update(state, {
    monitorings: { $set: monitorings },
    settings: {
      $merge: { remaining_monitorings: remaining_monitorings || false }
    },
  }),
  [LISTENING.UPDATE_LISTENING]: (state: ListeningEnv, { payload: { index, data } }) => update(state, {
    monitorings: {
      [index]: { $merge: data}
    },
  }),
  [LISTENING.OVERVIEW_LOADED]: (state: ListeningEnv, { payload: overview }) => update(state, {
    kpiOverview: { $set: overview },
  }),
  [LISTENING.SET_KPI_EVOLUTION]: (state: ListeningEnv, { payload: data }) => update(state, {
    kpiEvolution: {
      data: { $set: data }
    }
  }),
  [LISTENING.SET_KOL_DISTRIBUTION]: (state: ListeningEnv, { payload: data }) => update(state, {
    kolDistribution: {
      data: { $set: data }
    }
  }),
  [LISTENING.SET_TOP_KOLS]: (state: ListeningEnv, { payload: topKols }) => update(state, {
    topKols: {
      rows: { $set: topKols }
    }
  }),
  [LISTENING.SET_SHARE_OF_VOICES]: (state: ListeningEnv, { payload: data }) => update(state, {
    shareOfVoices: {
      data: {
        $set: data
      }
    }
  }),
  [LISTENING.SET_SHARE_OF_VOICES_BY_COUNTRY]: (state: ListeningEnv, { payload: data }) => update(state, {
    shareOfVoiceByCountry: {
      data: {
        $set: data
      }
    }
  }),
  [LISTENING.SET_CONTENTS]: (state: ListeningEnv, { payload: { contents, total } }) => update(state, {
    contents: {
      rows: { $set: contents },
      total: { $set: total },
    }
  }),
  [LISTENING.UPDATE_KPI_FILTERS]: (state: ListeningEnv, { payload: { target, filters } }) => update(state, {
    [target]: {$merge: filters }
  }),
};

export default createReducer(initialState, reducers);
