/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';

import * as CAMPAIGNS_FOLDERS from 'constants/campaignsFolders';


export interface Folder {
  id: number;
  name: string;
  team_id: number;
  owner: {
    id: number;
    user_name: string;
    team_id: number;
    is_left_the_team?: boolean;
  };
  created_at: string;
  updated_at: string;
  project_ids: number[];
}

export interface StateFolder {
  [key: string]: Folder
}

const reducers: { [key: string]: CaseReducer<StateFolder, PayloadAction<any>> } = {
  [CAMPAIGNS_FOLDERS.SET_FOLDERS]: (state, { payload: folders } : PayloadAction<StateFolder>) => {
    return state = folders;
  },
  [CAMPAIGNS_FOLDERS.ADD_FOLDER]: (state, { payload: folder } : PayloadAction<Folder>) => {
    return state = {
      ...state,
      [folder.id]: folder
    };
  },
  [CAMPAIGNS_FOLDERS.UPDATE_FOLDER]: (state, { payload: folder } : PayloadAction<Folder>) => {
    return state = {
      ...state,
      [folder.id]: folder
    };
  },
  [CAMPAIGNS_FOLDERS.DELETE_FOLDER]: (state, { payload: fId }) => {
    const { [fId]: id, ...rest } = state;
    return rest;
  }
};

export default createReducer({} as StateFolder, reducers);
