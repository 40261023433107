import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl-phraseapp';
import { Modal2, Text } from 'kolkit';

import { Markdown } from 'components/ui';
import { useSelector } from 'utils/redux';

import styles from 'css/scss/ui/forbidden-action-alert.module.scss'

const ForbiddenActionAlert = () => {
	const intl = useIntl()
	const navigate = useNavigate();

	const show = useSelector(
		({ env }) => env.forbiddenActionAlert
	);

	const reloadPage = useCallback(
		() => {
			navigate('/');
			navigate(0);
		},
		[navigate]
	);

	const action = useMemo(
		() => ({
			primary: {
				title: intl.formatMessage({ id: "global.cta.reloadApp" }),
				onClick: reloadPage,
			}
		}),
		[intl, reloadPage]
	);

	return (
		<Modal2
			on={show}
			title={intl.formatMessage({ id: "forbiddenAction.title" })}
			action={action}
			isAlert
		>
			<Text className={styles.content}>
				<Markdown>
					{intl.formatMessage({ id: "forbiddenAction.body" })}
				</Markdown>
			</Text>
		</Modal2>
	);
};

export default ForbiddenActionAlert;
