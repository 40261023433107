import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import styles from './FullWidthBanner.module.scss';

const config = {
  info: {
    icon: 'info-circle',
    closable: true
  },
  warning: {
    icon: 'engine-warning'
  },
  announcement: {
    icon: 'sparkles',
    closable: true
  }
};

const FullWidthBanner = ({ type, message, cta, link, className, onClose, dataId }) => {
  const cnRoot = cn(styles.root, styles[type], className);

  return (
    <div className={cnRoot}>
      <Icon
        label={config[type]?.icon}
        size="huge"
        className={styles.icon}
        theme="regular"
      />
      <span className={styles.message}>
        {message}
        &nbsp;
        {cta && link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {cta}
          </a>
        )}
      </span>
      {config[type]?.closable && (
        <Icon
          isButton
          label="times"
          size="huge"
          className={styles.icon}
          theme="regular"
          onClick={onClose}
          dataId={dataId}
        />
      )}
    </div>
  );
};

FullWidthBanner.propTypes = {
  dataId: PropTypes.string,
  type: PropTypes.oneOf(['warning', 'announcement', 'info']),
  message: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func
};

FullWidthBanner.defaultProps = {
  dataId: null,
  type: 'warning',
  message: '',
  cta: null,
  link: null,
  onClose: () => {},
  className: null,
};

export default memo(FullWidthBanner);
