import { useCallback, useEffect, memo } from 'react';
import { conf, env } from 'config/env';
import { useSelector } from 'utils/redux';

const SatisMeter = () => {
  const userInfos = useSelector(
    ({ user: { profile }, env: { locale } }) => ({
      userId: profile?.id,
      email: profile?.email,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      companyName: profile?.companyName,
      isAdmin: profile?.admin,
      isPaidUser: profile?.licence?.isActive,
      shouldDisplayNps: profile?.licence?.shouldDisplayNps,
      licenceType: profile?.licence?.isModerate ? 'moderate' : '',
      createdAt: profile?.licence?.startDate,
      locale,
    })
  );

  const init = useCallback(
    () => {
      const {
        userId,
        email,
        firstName,
        lastName,
        companyName,
        licenceType,
        locale,
        isPaidUser,
        isAdminUser,
        shouldDisplayNps,
        createdAt,
      } = userInfos;

      if (!userId || !shouldDisplayNps) return null;

      ((...args) => {
        window.satismeter =
          window.satismeter ||
          (() => {
            (window.satismeter.q = window.satismeter.q || []).push(args);
          });
        window.satismeter.l = 1 * new Date();
        const script = document.createElement('script');
        const parent = document.getElementsByTagName('script')[0].parentNode;
        script.async = 1;
        script.src = 'https://app.satismeter.com/satismeter.js';
        parent.appendChild(script);
      })();

      return setTimeout(() => {
        window.satismeter({
          writeKey: conf.satisMeterWriteKey,
          userId, // required
          traits: {
            // optionals
            firstName,
            lastName,
            companyName,
            licenceType,
            email,
            locale,
            env,
            isPaidUser,
            isAdminUser,
            userInfos,
            createdAt
          },
        });
      }, 1000);
    },
    [userInfos]
  );

  useEffect(() => {
    const timeout = init();
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [init]);

  return null;
};

export default memo(SatisMeter);
