export const SEARCH_LOADING = '[engine] Search -> loading';
export const SEARCH_COMPLETE = '[engine] Search -> complete';

export const FILTER_CHANGE = '[engine] Filter -> change';
export const FILTERS_UPDATE = '[engine] Filters -> update';

export const SORT_CHANGE = '[engine] Sort -> change';
export const INPUT_CHANGE = '[engine] Input -> change';
export const HASH_CHANGE = '[engine] Hash -> change';
export const FILTERS_RESET = '[engine] Filters -> reset';
export const CHANGE_PAGE = '[engine] Pages -> change';
export const CHANGE_TAB = '[engine] Tab -> change';

export const SAVED_SEARCHES = '[engine] Saved Search';

export const SELECTED_UPDATE = "engine::selected::update";
