import { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select2, Text } from 'kolkit';

const CountrySelectComponent = ({
  onChange,
  value,
  options,
  iconComponent: IconComponent,
  ...props
}) => {
  const intl = useIntl();
  const intlOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: intl.formatDisplayName(option.value, { type: 'region' }),
      })),
    [intl, options],
  );
  const renderItem = useCallback(
    (item) => {
      return (
        <li key={item.value} className="flex aic gap8 jcsb">
          {item.label || item.value}
          <IconComponent country={item.value} />
        </li>
      );
    },
    [IconComponent],
  );

  return (
    <div className="flex fdc gap8" style={{ minWidth: 200 }}>
      <Text resetMargin>
        <FormattedMessage
          id="auth.doubleAuthentication.modal.setup.country"
          defaultMessage="Country"
        />
      </Text>
      <Select2
        {...props}
        search
        searchKey="label"
        options={intlOptions}
        onSelectItem={(selectedCountry) => {
          onChange(selectedCountry.value);
        }}
        selected={value}
        renderItem={renderItem}
        fullWidth
      />
    </div>
  );
};

export default CountrySelectComponent;
