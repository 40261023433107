import { getStore } from 'config/store';

const currencies = [
  {
    label: 'Euro',
    value: 'EUR',
    symbol: '€',
  },
  {
    label: 'British Pound Sterling',
    value: 'GBP',
    symbol: '£',
    symbolAlwaysAtStart: true,
  },
  {
    label: 'United States Dollar',
    value: 'USD',
    symbol: '$',
    symbolAlwaysAtStart: true,
  },
];

export const initCurrencySymbol = () => {
  if (!getStore()) return null;
  const index = currencies?.find(
    ({ value }) => getStore()?.env?.currency.value === value,
  );
  return index?.symbol;
};

export type Currency = 'EUR' | 'USD' | 'GBP';

export const DEFAULT_CURRENCY = 'EUR' as const;
export const BASE_CURRENCY_BACKEND = 'USD' as const;
export const BASE_CURRENCY_SHOPIFY = 'EUR' as const;

export default currencies;
