export const availableLocales = [
  {
    id: 'fr-FR',
    label: 'French',
    iso3: 'FRA',
    iso2: 'fr',
  },
  {
    id: 'en-GB',
    label: 'English',
    iso3: 'GBR',
    iso2: 'en',
  },
  {
    id: 'de-DE',
    label: 'German',
    iso3: 'GER',
    iso2: 'de',
  },
  {
    id: 'es-ES',
    label: 'Spanish',
    iso3: 'SPA',
    iso2: 'es',
  },
  {
    id: 'it-IT',
    label: 'Italian',
    iso3: 'ITA',
    iso2: 'it',
  }
]

export const getLocale = locale => locale.replace('_', '-');

export const getLocaleByIso2Code = (iso2LangCode) => {
  const matchingLang = availableLocales.find(l => l.iso2 === iso2LangCode);
  return matchingLang;
}

export const getLocaleById = (langId) => {
  const matchingLang = availableLocales.find(l => l.id === langId);
  return matchingLang;
}
