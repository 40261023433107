import { TrackingLinksResponse } from 'types/trackingLinks';

import { get, post, patch } from './index';
import { ApiPromise } from './types.d';

export type NewTrackingLinks = {
  id: number;
  profile_id: number;
  profile_name: string;
  profile_mention: string | null,
  profile_image_url: string | null,
  short_link: string
}

export const checkNewTrackingLinks: (params: {
  campaign_id: number,
  long_link: string;
  profiles_ids: number[];
}) => ApiPromise<{
  tracking_links: NewTrackingLinks[];
}> = ({ campaign_id, long_link, profiles_ids }) => post({
  endpoint: `influence/projects/${campaign_id}/tracking_links/batch`,
  params: {
    long_link,
    profiles_ids,
  },
  catchErrors: [422],
  debug: true,
});

export const saveNewTrackingLinks: (params: {
  ids: number[];
}) => ApiPromise<any> = ({ ids }) => patch({
  endpoint: 'influence/tracking_links/validate',
  params: {
    ids,
  },
  catchErrors: [404],
  debug: true,
});

export const getProfilesHasTrackingLinks: (campaign_id: string) => ApiPromise<{ kols_ids: number[]}> = (campaign_id) => {
  return get({
    endpoint: `influence/projects/${campaign_id}/tracking_links/profiles`,
    debug: { kols_ids: [] },
    catchErrors: [],
  });
};

export const getTrackingLinksForCampaign: (params: {
  campaign_id: number;
  params: {
    sort: string;
    page: number;
    per_page: number;
    search: string;
    scopes: string;
    starts_at?: string;
    ends_at?: string;
  };
}) => ApiPromise<TrackingLinksResponse> = ({ campaign_id, params }) => {
  return get({
    endpoint: `influence/v2/projects/${campaign_id}/tracking_links`,
    params,
    catchErrors: [],
  });
};

export const updateSentStatus: (params: {
  tracking_link_id: string;
  sent: boolean;
}) => ApiPromise<any> = ({ tracking_link_id, sent }) =>
  patch({
    endpoint: `influence/tracking_links/${tracking_link_id}`,
    params: {
      sent,
    },
    debug: true,
  });

export const disableTrackingLinks: (params: {
  tracking_link_ids: number[];
}) => ApiPromise<any[]> = async ({ tracking_link_ids }) =>
  Promise.all(
    tracking_link_ids.map((tracking_link_id) =>
      patch({
        endpoint: `influence/tracking_links/${tracking_link_id}`,
        params: {
          status: 'expired',
        },
        debug: true,
        catchErrors: [422],
      }),
    ),
  );
