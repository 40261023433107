import React, { memo } from 'react';
import Loadable from 'react-loadable';
import Loader from 'kolkit/Loader';

const LoadedComponent = Loadable({
  loader: () => import('./Details'),
  loading: () => <Loader full />,
});

const LoaderProfile = () => {
  return <LoadedComponent />
}

export default memo(LoaderProfile)