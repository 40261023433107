import { memo, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { screenview } from 'utils/tags';
import { useDispatch, } from 'utils/redux';
import { doSearchFromQuery } from 'actions/engine';
import { resetSearch } from 'actions/user';

const PageNavigatorChecker = memo(() => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const [searchParams] = useSearchParams();
	const search = searchParams.get("q");

	// Check change pages > pageview
	useEffect(() => {
		screenview(pathname);
	}, [pathname]);

	// Check for changes in Search Query
	useEffect(() => {
		// Search is empty : reset
		if (!search) {
			dispatch(resetSearch());
		}
		else {
			// We have a search
			dispatch(doSearchFromQuery(true))
		}
	}, [dispatch, search]);

	return null;
});

export default PageNavigatorChecker;
