import React, { ComponentProps, forwardRef, useCallback } from 'react';
import { Input2, Text } from 'kolkit';
import { FormattedMessage } from 'react-intl';

const InputComponent = forwardRef<typeof Input2, ComponentProps<typeof Input2>>(
  (props, ref) => {

    const handleReset = useCallback(
      () => props.onChange(''),
      [props]
    );

    return (
      <div className="flex fdc gap8 fg1">
        <Text resetMargin>
          <FormattedMessage
            id="auth.doubleAuthentication.modal.setup.number"
            defaultMessage="Enter phone number"
          />
        </Text>
        <Input2
          {...props}
          ref={ref}
          fullWidth
          error={props.error}
          errorMessage={props.errorMessage}
          onClickIcon={handleReset}
        />
      </div>
    );
  },
);

InputComponent.displayName = 'InputComponent';

export default InputComponent;
