import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import * as SHOPIFY from 'constants/shopify';
import * as USER from 'constants/user';

const initialState = {
  loaded: false,
  isConnected: false,
  store_id: '',
  store_name: '',
  campaigns: {},
};

const reducers = {
  [USER.SAVE_INTEGRATIONS]: (state, { payload: { shopify_store } }) =>
    update(state, {
      loaded: { $set: true},
      store_id: { $set: shopify_store?.id || '' },
      isConnected: { $set: !!shopify_store?.id },
      store_name: { $set: shopify_store?.name || ''},
    }),
  [SHOPIFY.SET_CONNECTED]: (state, { payload: { store_id, store_name } }) => {
    return update(state, {
      $set: {
        ...initialState,
        loaded: { $set: true},
        isConnected: true,
        store_id,
        store_name,
      },
    });
  },
  [SHOPIFY.SET_DISCONNECTED]: state => {
    return update(state, {
      $set: initialState,
    });
  },
  [SHOPIFY.SET_CAMPAIGN_DATA]: (state, { payload: { campaignId, data } }) => {
    return update(state, {
      campaigns: {
        [campaignId]: {
          $set: data,
        },
      },
    });
  },
};

export default createReducer(initialState, reducers);
