import { createAction } from 'redux-actions';
import * as affilaeApi from 'api/affilae';
import * as affilaeMapper from 'mappers/affilae';
import * as AFFILAE from 'constants/affilae';
import { setControlledLoader } from 'actions/ui';

const setAffilaeCampaigns = createAction(AFFILAE.SET_CAMPAINGS);
const setAffilaeAffiliates = createAction(AFFILAE.SET_AFFILIATES);
const setAffilaeDatasLoaded = createAction(AFFILAE.AFFILAE_DATAS_LOADED);

export const setHasAffilaeConnection = createAction(AFFILAE.SET_HAS_CONNECTION)

export const loadAffilaeCampaignsAndAffiliates = () => async (dispatch, getState) => {
  const { affilae } = getState();
  if (!affilae.hasConnection || (affilae.hasConnection && affilae.loaded)) return false;
  dispatch(setControlledLoader({ id: 'getAffilaeCampaignsAndAffiliates', show: true }));
  const response = await affilaeApi.getAffilaeCampaignsAndAffiliates();
  const { campaigns, affiliates } = affilaeMapper.mapAffilaeCampaignsAndAffiliates.fromApi(response);
  dispatch(setAffilaeCampaigns(campaigns));
  dispatch(setAffilaeAffiliates(affiliates));
  dispatch(setAffilaeDatasLoaded());
  dispatch(setControlledLoader({ id: 'getAffilaeCampaignsAndAffiliates', show: false }));
};
