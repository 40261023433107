import {
  CASTING_VALIDATION_KEYS,
  CONTENT_VALIDATION_KEYS,
} from 'constants/states';

export const excludedStatuses = ['refused', 'declined'];

export const DEFAULT_WORKFLOW_NAME = 'default_workflow';

export const GROUPED_STATUSES = [
  CASTING_VALIDATION_KEYS,
  CONTENT_VALIDATION_KEYS,
  ['participating', 'declined'],
];
