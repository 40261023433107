import _has from 'lodash.has';
import Cookies from 'universal-cookie';

import { noEffectAppInit } from 'api/user';
import { conf } from 'config/env';
import { setClientToken } from 'actions/env';
import { getDispatcher, getStore } from 'config/store';

const isCookieTokenAlreadySet = () => {
  const store = getStore();
  return !!store?.env?.userToken;
}

const loadTokenIntoStore = async () => {
  const dispatch = getDispatcher();
  const cookies = new Cookies();
  const cookieToken = cookies.get(conf.cookieToken);
  await dispatch(setClientToken(cookieToken));
}

/**
 * Check if the browser has valid token,
 * by calling app_init endpoint
 * 
 */
const useAuthChecker = async () => {
  try {
    if (!isCookieTokenAlreadySet()) {
      await loadTokenIntoStore();
    }
    const appInitResponse = await noEffectAppInit();
    return _has(appInitResponse, 'current_user.id');
  } catch (e) {
    return false;
  }
}

export default () => useAuthChecker;