/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useRef, useEffect, useMemo, memo } from 'react';
import { nanoid } from 'nanoid';

// Init Datepicker
import flatpickr from 'flatpickr';
import 'flatpickr/dist/l10n/fr';
import 'css/scss/ui/flatpickr.scss';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';

import { isSameDay, startOfDay, endOfDay } from 'date-fns';
import cn from 'classnames';
import { Input2, Label2 } from 'kolkit';

import { useSelector } from 'utils/redux';

import styles from './FlatDatePicker.module.scss';

type ReactFlatpickr = React.Component<DateTimePickerProps> & {
  flatpickr: flatpickr.Instance;
};

export type DateType =
  | string
  | number
  | Date
  | string
  | number
  | Date[]
  | undefined;

type Props = {
  id?: string;
  type?: 'single' | 'range' | 'range-condensed';
  value?: DateType;
  onChange?: (dates: Date[] | undefined) => void;
  label?: string | string[];
  required?: boolean;
  size?: 'small' | 'medium' | 'large';
  dateFormat?: string;
  placeholder?: string;
  fullWidth?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disableInput?: boolean;
};

const FlatDatePicker: React.FC<Props> = ({
  id = nanoid(),
  type = 'single',
  value,
  onChange,
  label,
  required,
  size,
  dateFormat = 'd/m/Y',
  placeholder,
  fullWidth,
  minDate,
  maxDate,
  disableInput = false,
}) => {
  const flatpickrRef = useRef<ReactFlatpickr>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const rangeSecondInputRef = useRef<HTMLInputElement>();
  const locale = useSelector(({ env }) => env.locale.slice(0, 2));

  const previouslySelectedRange = useRef<Date[]>([]);

  /**
   * Position the calendar modal, taking into account changes in style
   */
  const onPreCalendarPosition = useCallback((_dates, _dateString, fp) => {
    setTimeout(() => {
      const offset = 8;
      const padding = 24;

      let offsetTop = 0;

      if (!fp.calendarContainer) return null;
      if (fp.calendarContainer.className.includes('arrowBottom')) {
        offsetTop = -1 * (offset + 2 * padding);
      } else {
        offsetTop = offset;
      }

      // eslint-disable-next-line no-param-reassign
      fp.calendarContainer.style.top = `${
        parseInt(fp.calendarContainer.style.top, 10) + offsetTop
      }px`;
    }, 0);
  }, []);

  const handleChange = useCallback<
    NonNullable<DateTimePickerProps['onChange']>
  >(
    (dates, dateString, fp) => {
      if (
        (type === 'range' || type === 'range-condensed') &&
        dates.length === 1
      )
        return null;
      if (type === 'single' && dates.length > 1) return null;
      fp.setDate(dates, false, dateFormat);
      if (onChange) {
        onChange(dates.length ? dates : undefined);
      }
    },
    [onChange, type, dateFormat],
  );

  /**
   * Update the calendar right after the input is manually changed
   */
  const handleInput = useCallback(
    (e) => {
      if (flatpickrRef.current) {
        const datePicker = flatpickrRef.current.flatpickr;
        const inputValue = datePicker._input.value;
        if (type === 'single') {
          const parsedDate = datePicker.parseDate(inputValue, dateFormat);
          if (
            parsedDate &&
            inputValue === datePicker.formatDate(parsedDate, dateFormat)
          )
            handleChange([parsedDate], '', datePicker);
        } else if (type === 'range-condensed') {
          const parsedDates = inputValue
            .split(datePicker.l10n.rangeSeparator)
            .map(
              (date) => datePicker.parseDate(date, dateFormat) || new Date(),
            );
          if (parsedDates.every((date) => !!date)) {
            handleChange(parsedDates, '', datePicker);
          }
        } else {
          // Get parsed dates
          const secondInput = rangeSecondInputRef.current;
          const secondInputValue = (secondInput && secondInput.value) || '';
          const firstParsedDate = datePicker.parseDate(inputValue, dateFormat);
          const secondParsedDate = datePicker.parseDate(
            secondInputValue,
            dateFormat,
          );

          // Test validity
          const isValidFirstDate =
            firstParsedDate &&
            inputValue === datePicker.formatDate(firstParsedDate, dateFormat);
          const isValidSecondDate =
            secondParsedDate &&
            secondInputValue ===
              datePicker.formatDate(secondParsedDate, dateFormat);

          // Set dates if valid
          if (datePicker._input?.contains(e.target) && isValidFirstDate) {
            const dates = [firstParsedDate, datePicker.selectedDates[1]];
            handleChange(dates, '', datePicker);
            previouslySelectedRange.current = dates;
          }
          if (
            secondInput?.contains(e.target) &&
            isValidSecondDate &&
            isValidFirstDate
          ) {
            const dates = [datePicker.selectedDates[0], secondParsedDate];
            handleChange(dates, '', datePicker);
            previouslySelectedRange.current = dates;
          }
        }
      }
    },
    [dateFormat, type, handleChange],
  );

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const options = useMemo<flatpickr.Options.Options>(
    () => ({
      showMonths: type === 'single' ? 1 : 2,
      mode: type === 'single' ? ('single' as const) : ('range' as const),
      locale: locale as flatpickr.Options.LocaleKey,
      animate: true,
      allowInput: !disableInput,
      disableMobile: true,
      altInput: true,
      altFormat: dateFormat,
      minDate: minDate ? startOfDay(minDate) : undefined,
      maxDate: maxDate ? endOfDay(maxDate) : undefined,
      onPreCalendarPosition,
      onDayCreate: (dates, dateString, fp, date) => {
        const { dateObj } = date;
        if (
          Array.isArray(value) &&
          value?.some(
            (selectedDate) =>
              selectedDate && isSameDay(new Date(selectedDate), dateObj),
          )
        ) {
          date.classList.add('previousDate');
        } else if (
          value &&
          !Array.isArray(value) &&
          isSameDay(value instanceof Date ? value : new Date(value), dateObj)
        ) {
          date.classList.add('previousDate');
        }
      },
      onOpen: () => {
        if (flatpickrRef.current && flatpickrRef.current.flatpickr) {
          const instance = flatpickrRef.current.flatpickr;
          instance.calendarContainer.addEventListener('click', stopPropagation);
        }
      },
      onClose: () => {
        if (flatpickrRef.current && flatpickrRef.current.flatpickr) {
          const instance = flatpickrRef.current.flatpickr;
          instance.calendarContainer.removeEventListener('click', stopPropagation);
        }
      },
      positionElement: inputContainerRef.current || undefined,
      prevArrow:
        '<svg viewBox="0 0 448 512"><path d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z"></path></svg>',
      nextArrow:
        '<svg viewBox="0 0 448 512"><path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>',
    }),
    [
      flatpickrRef,
      stopPropagation,
      value,
      type,
      dateFormat,
      locale,
      disableInput,
      maxDate,
      minDate,
      onPreCalendarPosition,
    ],
  );

  const cnInput = cn(styles.input, styles[type]);

  const renderFirstInput = useCallback(
    (props, ref) => {
      const displayLabel =
        (typeof label === 'string' && label) ||
        (Array.isArray(label) && label[0]);
      return (
        <div>
          {displayLabel && (
            <Label2
              label={displayLabel}
              required={required}
              className={styles.label}
            />
          )}
          <Input2
            id={props.id}
            containerRef={inputContainerRef}
            ref={ref}
            placeholder={placeholder}
            size={size}
            icon="calendar"
            fullWidth={fullWidth}
            className={cnInput}
          />
        </div>
      );
    },
    [label, size, fullWidth, required, placeholder, cnInput],
  );

  /**
   * Register and clear event listeners on input
   */
  useEffect(() => {
    const clearEffects: (() => void)[] = [];
    if (type === 'range' && flatpickrRef.current) {
      const secondRangeInput = rangeSecondInputRef.current;
      if (secondRangeInput) {
        flatpickrRef.current.flatpickr.destroy();
        flatpickrRef.current.flatpickr = flatpickr(
          flatpickrRef.current.flatpickr.element,
          {
            ...options,
            defaultDate: value,
            onChange: handleChange,
            // Fix disapearing date after "onClose"
            onOpen: (dates) => {
              previouslySelectedRange.current = dates;
            },
            onClose: (dates, dateString, fp) => {
              if (dates.length === 1) {
                if (
                  secondRangeInput.value &&
                  flatpickrRef.current?.flatpickr._input.value
                ) {
                  handleChange(previouslySelectedRange.current, '', fp);
                  // eslint-disable-next-line no-param-reassign
                  fp._input.value = fp.formatDate(
                    previouslySelectedRange.current[0],
                    dateFormat,
                  );
                } else {
                  fp.clear();
                  previouslySelectedRange.current = [];
                }
              }
              if (dates.length === 0) {
                fp.clear();
                secondRangeInput.value = '';
                previouslySelectedRange.current = [];
              }
            },
            plugins: [rangePlugin({ input: rangeSecondInputRef.current })],
          },
        );

        secondRangeInput.addEventListener('input', handleInput, true);
        clearEffects.push(() =>
          secondRangeInput?.removeEventListener('input', handleInput),
        );
      }
    }
    const datePicker = flatpickrRef.current?.flatpickr;
    if (datePicker) {
      datePicker._input?.addEventListener('input', handleInput, true);
      clearEffects.push(() =>
        datePicker._input?.removeEventListener('input', handleInput),
      );
    }

    return () => {
      clearEffects.forEach((fn) => fn());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cnContainer = cn(styles.container, styles[type], {
    [styles.fullWidth]: fullWidth,
  });

  return (
    <div className={cnContainer}>
      <Flatpickr
        id={id}
        ref={flatpickrRef}
        value={type === 'range' ? undefined : value}
        onChange={handleChange}
        render={renderFirstInput}
        options={options}
      />
      {type === 'range' && (
        <div>
          {label && Array.isArray(label) && label[1] && (
            <Label2 label={label[1]} className={styles.label} />
          )}
          <Input2
            inputRef={rangeSecondInputRef}
            placeholder={placeholder}
            size={size}
            noIcon
            fullWidth={fullWidth}
            className={cnInput}
          />
        </div>
      )}
    </div>
  );
};

export default memo(FlatDatePicker);
