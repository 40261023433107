import { MenuProps as MuiMenuProps } from '@mui/material';
import cn from 'classnames';

import styles from './Select.module.scss';

const getMenuProps: (
  size?: 'small' | 'medium' | 'large',
  autoHeight?: boolean,
  searchable?: boolean,
) => Partial<MuiMenuProps> = (size, autoHeight, searchable = false) => ({
  PaperProps: {
    className: cn(styles.menu, {
      [styles[size || '']]: !!size,
      [styles.autoHeight]: autoHeight,
      [styles.searchable]: searchable,
    }),
  },
  MenuListProps: {
    className: styles.menuList,
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  variant: 'menu',
  autoFocus: false,
});

export { getMenuProps };
