import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { Modal2, Text } from 'kolkit';

import { Markdown } from 'components/ui';
import { useSelector } from 'utils/redux';

import styles from 'css/scss/ui/maintenance-mode-modale.module.scss';


const MaintenanceModeModale = memo(() => {
	const intl = useIntl()
	const show = useSelector(
		({ env }) => env.maintenanceModeActivated
	);
	const reloadPage = useCallback(
		() => {
			window.location.reload(true)
		},
		[]
	);


	const action = useMemo(
		() => ({
			primary: {
				title: intl.formatMessage({ id: "global.cta.reloadApp" }),
				onClick: reloadPage,
			}
		}),
		[intl, reloadPage]
	);

	return (
		<Modal2
			on={show}
			title={intl.formatMessage({ id: "maintenanceMode.title" })}
			action={action}
			isAlert
		>
			<div className={styles.content}>
				<Text>
					<Markdown>
						{intl.formatMessage({ id: "maintenanceMode.body" })}
					</Markdown>
				</Text>
			</div>
		</Modal2>
	);
});

export default MaintenanceModeModale;
