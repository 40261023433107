export default {
  MIN_PRICE: 0,
  NAME: 'Influence',
  ID: 'com.brandandcelebrities.influence',
};

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const FRONTEND_DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAYED_DATE_FORMAT = 'DD MMMM YYYY';

export const FNS_BACKEND_DATE_FORMAT = 'yyyy-MM-dd';
export const FNS_FRONTEND_DATE_FORMAT = 'dd-MM-yyyy';
export const FNS_DISPLAYED_DATE_FORMAT = 'dd MMMM yyyy';

export const notifications = {
  info: {
    icon: 'info-circle',
    iconTheme: 'solid',
    iconSize: 'huge',
    color: 'var(--color-alert-information)',
  },
  success: {
    icon: 'check-circle',
    iconTheme: 'solid',
    iconSize: 'huge',
    color: 'var(--color-alert-success)',
  },
  warning: {
    icon: 'exclamation-circle',
    iconTheme: 'solid',
    iconSize: 'huge',
    color: 'var(--color-alert-warning)',
  },
  error: {
    icon: 'times-circle',
    iconTheme: 'solid',
    iconSize: 'huge',
    color: 'var(--color-alert-error)',
  },
  new: {
    icon: 'sparkles',
    iconTheme: 'solid',
    iconSize: 'huge',
    color: 'var(--color-primary-500)',
  },
  warning_light: {
    icon: 'exclamation-circle',
    iconTheme: 'solid',
    iconSize: 'medium',
    color: 'var(--color-alert-error)',
  },
};
