import querystring from 'jquery-param';
import { tokenTypes } from 'constants/auth';
import { removeAtFromMention } from 'utils';

export const translateStatusCode = (statusCode) => {
  switch (statusCode) {
    case 601: return 'expiredToken';
    case 602: return 'unconfirmed';
    case 603: return 'forbidden';
    case 604: return 'isAlreadyConfirmed';
    case 605: return 'emailNotFound';
    case 607: return 'wrongPassword';
    case 608: return 'notActive';
    case 609: return 'alreadyUsed';
    case 610: return 'nonActivated';
    case 611: return 'passwordTooShort';
    case 701: return 'connectionError';
    case 801: return 'tokenNotFound';
    case 803: return 'alreadyUsed';
    case 'incorrect_code': return 'incorrect_code';
    default: return 'unknown';
  }
}

export const verifyToken = {
  toApi: ({ tokenType, token }) => querystring({
    token_type: tokenType,
    token,
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const confirmAccount = {
  toApi: ({ token }) => querystring({
    [tokenTypes.CONFIRMATION]: token,
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const activateAccount = {
  toApi: ({ password, passwordConfirmation, token }) => querystring({
    password,
    password_confirmation: passwordConfirmation,
    [tokenTypes.ACTIVATION]: token,
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const resetPassword = {
  toApi: ({ password, passwordConfirmation, token }) => querystring({
    password,
    password_confirmation: passwordConfirmation,
    [tokenTypes.RESET_PASSWORD]: token,
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const login = {
  toApi: ({ email, password, otp_user_id, otp_attempt, fingerprint }) =>
    querystring({
      fingerprint,
      user:
        otp_user_id && otp_attempt
          ? {
              otp_user_id,
              otp_attempt,
            }
          : {
              email: String(email)?.trim(),
              password: String(password)?.trim(),
            },
    }),
  fromApi: (response) => {
    if (response?.error_code)
      return {
        error: translateStatusCode(response?.error_code),
      };
    return {
      userId: response?.user_id,
      hasCommunityAccess: response?.has_community_access,
      otp_user_id: response?.otp_user_id,
      otp_method: response?.otp_method,
      phone: response?.phone,
      must_enter_phone: response?.must_enter_phone || false,
      hasCookie: response?.hasCookie,
    };
  },
};

export const requestPasswordReset = {
  toApi: ({ email }) => querystring({
    email: String(email)?.trim(),
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const register = {
  toApi: (data) => {
    const isBrand = data.youAre === 'brand';
    return {
      company_name: data.company,
      country: data.country,
      email: data.professionnalEmail,
      first_name: data.firstname,
      job_title: data.jobtitle,
      last_name: data.lastname,
      mention: isBrand ? removeAtFromMention(data.mentionInstagram) : "",
      password: data.password,
      phone: data.phone?.trim(),
      locale: data.locale,
      // influencers_next_12_months: isBrand ? "" : register.influencersToActivate,
      // origin_url: "",
      // referrer: "",
    }
  },
}

export const submitRegisterForm = {
  fromApi: ({ lead_id }) => ({ leadId: lead_id }),
  toApi: register => {
    const isBrand = register.you_are === 'Brand';
    return Object.assign(
      {
        email: register.email,
        password: register.password,
        locale: register.locale,
        first_name: register.firstname,
        last_name: register.lastname,
        referrer: document.referrer,
        company_name: register.company,
        phone: register.phone?.trim(),
        country: register.country,
        job_title: register.jobtitle,
        mention: isBrand ? removeAtFromMention(register.mention_id) : "",
        influencers_next_12_months: isBrand ? "" : register.influencersToActivate,
      },
      register.sources?.utms && 'utm_source' in register.sources.utms ? { source: register.sources.utms.utm_source } : null,
      register.sources?.originUrl ? { origin_url: register.sources.originUrl } : null,
      register.enlisted && { enlisted: true }
    );
  }
};
