export const ADD = "campaign::add";
export const UPDATE = "campaign::update";
export const REMOVE = "campaign::remove";
export const BATCH = "campaigns::batch";
export const BATCH_SET = "campaigns::batch::set";
export const ADD_PROFILES = "campaign::profiles::add";
export const UPDATE_PROFILE = "campaign::profile::update";
export const UPDATE_PROFILES = "campaign::profiles::batch";
export const REMOVE_PROFILES = "campaign::profiles::remove";

export const PUSH_PROFILES = "[Campaign] -> Push profiles to campaign";
export const RESET_PROFILES = "[Campaign] -> reset profiles in campaign";

export const GET_POSTS = "[Campaign] -> get campaign's posts";
export const RESET_POSTS = "[Campaign] -> reset campaign's posts";
export const UPDATE_TRACKING_LINKS_PARAMS = "[Campaign] -> update tracking links parameters";

export const INCREASE_COMMENT_COUNT = "[Campaign] -> increase comments count for KOL in a campaign";
