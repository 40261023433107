import moment from 'moment';

export const TAB_STATISTICS = {
  COLUMNS: [
    {
      name: 'emv',
      apiKey: 'emv',
      currency: true,
      label: 'global.statistics.emv.label',
      tooltip: 'global.statistics.emv.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-ovw-EMV",
    },
    {
      name: 'kolsCount',
      apiKey: 'kols_count',
      label: 'global.statistics.kols.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.kols.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-ovw-KOL",
    },
    {
      name: 'storiesCount',
      apiKey: 'stories_count',
      label: 'global.statistics.stories.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.stories.tooltip',
      networks: ['instagram'],
      dataId: "LIS-ovw-stories",
    },
    {
      name: 'postsCount',
      apiKey: 'posts_count',
      highlight: true,
      label: 'global.statistics.posts.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.posts.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-ovw-posts",
    },
    {
      name: 'reelsCount',
      apiKey: 'reels_count',
      highlight: true,
      label: 'global.statistics.reels.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.reels.tooltip',
      networks: ['instagram'],
      dataId: "LIS-ovw-reels",
    },
    {
      name: 'engagementVolume',
      apiKey: 'total_engagement',
      highlight: true,
      label: 'global.statistics.engagement.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.engagement.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-ovw-EV",
    },
    {
      name: 'engagementRate',
      apiKey: 'engagement_rate',
      highlight: true,
      className: 'Listening-engagement-rate-responsive',
      percentage: true,
      label: 'global.statistics.engagement.label',
      subLabel: 'global.statistics.rate',
      tooltip: 'global.statistics.engagementRate.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-ovw-ER",
    },
  ],
  GRID_TEMPLATE_COLUMNS: [
    '2.5fr',
    '1fr',
    '1fr',
    '1fr',
    '1fr',
    '1fr',
    '1fr',
    '1fr',
  ].reduce((template, span) => template.concat(` minmax(0,${span})`), ``),
};

export const TAB_KOLS = {
  COLUMNS: [
    {
      name: 'emv',
      apiKey: 'emv',
      currency: true,
      label: 'global.statistics.emv.label',
      tooltip: 'global.statistics.emv.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-KOLs-EMV",
    },
    {
      name: 'storiesCount',
      apiKey: 'stories',
      label: 'global.statistics.stories.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.stories.tooltip',
      networks: ['instagram'],
      dataId: "LIS-KOLs-stories",
    },
    {
      name: 'postsCount',
      apiKey: 'posts',
      highlight: true,
      label: 'global.statistics.posts.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.posts.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-KOLs-posts",
    },
    {
      name: 'reelsCount',
      apiKey: 'reels',
      highlight: true,
      label: 'global.statistics.reels.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.reels.tooltip',
      networks: ['instagram'],
      dataId: "LIS-KOLs-reels",
    },
    {
      name: 'engagementVolume',
      apiKey: 'engagements',
      highlight: true,
      label: 'global.statistics.engagement.label',
      subLabel: 'global.statistics.volume',
      tooltip: 'global.statistics.engagement.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-KOLs-EV",
    },
    {
      name: 'engagementRate',
      apiKey: 'engagement_rate',
      highlight: true,
      className: 'Listening-engagement-rate-responsive',
      percentage: true,
      label: 'global.statistics.engagement.label',
      subLabel: 'global.statistics.rate',
      tooltip: 'global.statistics.engagementRate.tooltip',
      networks: ['instagram', 'tiktok'],
      dataId: "LIS-KOLs-ER",
    },
  ],
  GRID_TEMPLATE_COLUMNS: ['2.5fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr'].reduce(
    (template, span) => template.concat(` minmax(0,${span})`),
    ``,
  ),
};

export const COLOR_LIST = [
  '#F22254',
  '#F28DAE',
  '#FC8A21',
  '#FFD166',
  '#ffeb47',
  '#B5E023',
  '#008553',
  '#02C39A',
  '#27EECA',
  '#311657',
  '#5216D2',
  '#8F00FF',
  '#9B8DF2',
  '#c0a2fd',
  '#0092E3',
  '#136DE2',
  '#68caff',
  '#325fa1',
  '#9FD5E7',
  '#284a52',
];

export const COUNTRY_COLORS = COLOR_LIST.slice().reverse();

export const SELF_COLOR = '#86ffbd';

export const DEFAULT_DATE_RANGES = {
  startDate: moment().subtract(1, 'month'),
  endDate: moment(),
};

export const FILTERS_COMMUNITY_SIZE = [
  'nano',
  'micro',
  'macro',
  'mega',
  'all',
] as const;

export const FILTERS_CONTENT_SORT = [
  'most_recent',
  'most_emv',
  'most_engaging',
  'most_engaging_rate',
  'most_liked',
  'most_commented',
] as const;

export const KPIS_EVOLUTION = [
  'emv',
  'posts_count',
  'stories_count',
  'reels_count',
  'engagement_rate'
] as const;

export const POSTS_PER_PAGE = 12;

export const PERIOD = ['day', 'week', 'month', 'quarter'];

export const CHARTS_LINE = ['kpiEvolution', 'communityEvolution'] as const;

export const SHARE_OF_VOICE = [
  { key: 'emv', dataId: 'EMV' },
  { key: 'contents', dataId: 'content' },
  { key: 'engagement', dataId: 'EV' },
  ];

export const KOLS_TYPE = ['nano', 'micro', 'macro', 'mega', 'all'];