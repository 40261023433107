import { AnyAction, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

import reducers from '../reducers/index';

declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const store = configureStore({
  reducer: reducers,
});

if (module.hot?.accept) {
  // Enable Webpack hot module replacement for reducers
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  module.hot.accept('../reducers', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    store.replaceReducer(require('../reducers/index'));
  });
}

export const getStore = (): RootState => store.getState();
export const getDispatcher = (): AppDispatch => store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export default store;
