import React, { useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-phraseapp';
import cn from 'classnames';
import { Text } from 'kolkit';
import Select from 'kolkit/Select';
import Icon from 'kolkit/Icon';

import buildCountryOptionLabel from 'utils/buildCountryOptionLabel';
import InputLocation, { LocationItem } from 'components/ui/InputLocation';

import styles from './SelectMultipleWithPercentage.module.scss';

type Items = {
  id?: string;
  country: string;
  city?: string;
  min?: number;
}[];

type Props = {
  defaultPercentage?: number;
  percentages?: number[];
  selected?: Items;
  onChange?: (selection: { selected: Items }) => void;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  max?: number;
  autoFocus?: boolean;
  noPercentage?: boolean;
  countryOnly?: boolean;
  dataId?: string;
};

const defaults = {
  defaultPercentage: 30,
  percentages: Array(20)
    .fill(null)
    .map((_value, index) => (index === 0 ? 1 : index * 5)),
  selected: [],
  onChange: ({ selected }) => {
    console.log('selected: ', selected);
  },
  inputSearchPlaceholder: '',
  searchable: false,
  disabled: false,
  placeholder: '',
  size: 'medium' as const,
  className: '',
  max: 1,
};

const SelectMultipleLocationsWithPercentage: React.FC<Props> = ({
  percentages = defaults.percentages,
  defaultPercentage = defaults.defaultPercentage,
  onChange,
  selected = defaults.selected as Items,
  placeholder = defaults.placeholder,
  disabled = defaults.disabled,
  size = defaults.size,
  className = defaults.className,
  max = defaults.max,
  autoFocus = false,
  noPercentage = false,
  countryOnly = false,
  dataId,
}) => {
  const intl = useIntl();

  const percentageOptions = useMemo(
    () =>
      percentages.map((percent, index) => ({
        value: percent.toString(),
        label: `${index === percentages.length - 1 ? '' : '>'} ${percent}%`,
      })),
    [percentages],
  );

  const handleUpdateLocation: (params: {
    index: number;
    item: Omit<LocationItem, 'countryCode'> & {
      countryCode?: string;
      country?: string;
    };
  }) => void = useCallback(
    ({ index: indexToUpdate, item }) => {
      if (!onChange) return null;

      const newSelected = selected.map((location, index) => {
        if (indexToUpdate === index) return {
          ...location,
          id: item.id,
          country: item.country || item.countryCode || '',
          city: item.city,
        }
        return location;
      })

      onChange({ selected: newSelected });
    },
    [onChange, selected],
  );

  const handleAddItem = useCallback(
    ({ item }) => {
      if (!onChange) return null;

      const newItem = Object.assign({},
        {
          id: item.id,
          country: item.country || item.countryCode || '',
          city: item.city,
        },
        noPercentage ? null : { min: defaultPercentage },
      );
      const keyToCheck = countryOnly ? 'country' : 'id';
      if (selected.some(location => location[keyToCheck] === newItem[keyToCheck])) return null;

      onChange({
        selected: [...selected, newItem]
      });
    },
    [onChange, selected, countryOnly, noPercentage, defaultPercentage],
  );

  const handleRemoveItem = useCallback(
    (indexToRemove) => {
      if (!onChange) return null;
      onChange({
        selected: selected.filter((_, index) => index !== indexToRemove)
      });
    },
    [onChange, selected],
  );

  const handleChangePercentage = useCallback(
    ({ value, index: indexToUpdate }) => {
      if (!onChange) return null;
      onChange({
        selected: selected.map((location, index) => ({
          ...location,
          min: index === indexToUpdate ? value : location.min
        }))
      })
    },
    [selected, onChange],
  );

  const renderValue = useCallback(
    (seleted) => {
      if (!selected.length) return '';
      const item = seleted[0];
      return buildCountryOptionLabel(
        item.city,
        intl.formatDisplayName(item.country, {
          type: 'region',
        }),
      );
    },
    [intl, selected.length],
  );

  const cnContainer = cn(styles.container, className);

  return (
    <div className={cnContainer}>
      {selected.map((item, index) => (
        <div key={`${item.country}-${item.city}`} className={styles.row}>
          {index !== 0 && (
            <Text fontSize={14} fontWeight={500} resetMargin>
              <FormattedMessage id="engineSearch.input.or.label" />
            </Text>
          )}
          <InputLocation
            onChange={({ item }) => handleUpdateLocation({ index, item })}
            placeholder={placeholder}
            selected={[{ ...item, countryCode: item.country }]}
            minChars={3}
            disabled={disabled}
            size={size}
            className={styles.mainInput}
            theme="tone"
            fullWidth
            isCountryOnly={countryOnly}
            renderValue={renderValue}
            dataId={dataId}
          />
          {!noPercentage && (
            <Select
              selected={item.min?.toString()}
              active={!!item.min}
              options={percentageOptions}
              onChange={({ value }) => handleChangePercentage({ index, value })}
              disabled={disabled}
              size={size}
              className={styles.percentageSelect}
              theme="tone"
            />
          )}
          <Icon
            isButton
            size="medium"
            label="trash-alt"
            className={styles.icon}
            onClick={() => handleRemoveItem(index)}
          />
        </div>
      ))}
      {max && selected.length >= max ? null : (
        <div className={styles.row}>
          {Object.entries(selected).length > 0 && (
            <Text fontSize={14} fontWeight={500} resetMargin>
              <FormattedMessage id="engineSearch.input.or.label" />
            </Text>
          )}
          <InputLocation
            onChange={handleAddItem}
            placeholder={placeholder}
            selected={[]}
            minChars={3}
            disabled={disabled}
            size={size}
            className={styles.mainInput}
            theme="tone"
            fullWidth
            autoFocus={autoFocus}
            isCountryOnly={countryOnly}
            dataId={dataId}
          />
          {!noPercentage && (
            <Select
              selected=""
              options={percentageOptions}
              disabled
              size={size}
              className={styles.percentageSelect}
              theme="tone"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelectMultipleLocationsWithPercentage;
