import React, { memo, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-phraseapp';
import { Modal2, Text } from 'kolkit';

import { Markdown } from 'components/ui';
import { useSelector } from 'utils/redux';

import styles from 'css/scss/ui/NewVersionRequired.module.scss';

const NewVersionRequiredModale = () => {
  const intl = useIntl();

  const show = useSelector(
    ({ env }) => env.newVersionRequired || env.newVersionAvailable,
  );

  const actions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: "global.cta.reloadApp" }),
        onClick: () => window.location.reload(true),
      },
    }),
    [intl],
  );

  return (
    <Modal2
      title={intl.formatMessage({ id: 'global.system.updateRequired.title' })}
      on={show}
      action={actions}
    >
      <div className={styles.newVersionRequired}>
        <Text resetMargin className={styles.newVersionRequiredParagraph}>
          <Markdown>
            {intl.formatMessage({id: "global.system.updateRequired.message" })}
          </Markdown>
        </Text>
        <span className={styles.newVersionRequiredSubbody}>
          <FormattedMessage id="global.system.updateRequired.subMessage" />
        </span>
      </div>
    </Modal2>
  );
};

export default memo(NewVersionRequiredModale);
