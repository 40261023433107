import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../Tooltip';

import styles from '../Truncate/Truncate.module.scss';

const TruncateText = ({ children, className }) => {
  const [tooltip, setTooltip] = useState(false);
  const ref = useRef(null);
  const childRef = useRef(null);

  const cnSpan = cn(styles.span, className);

  useEffect(() => {
    const currentParent = ref.current;
    const currentChild = childRef.current;
    if (currentParent && currentChild) {
      setTooltip(currentChild?.scrollWidth > currentParent?.offsetWidth );
    }
  }, [ref, childRef]);

  const child = useMemo(
    () => (
      <span className={cnSpan} ref={ref}>
        <span
          ref={childRef}
          className={styles.child}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </span>
    ),
    [children, childRef, cnSpan]
  )

  return tooltip ? (
    <Tooltip content={<div className={styles.whiteText}>{children}</div>}>
      {child}
    </Tooltip>
  ) : (
    child
  );
};

TruncateText.displayName = 'TruncateText';

TruncateText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

TruncateText.defaultProps = {
  children: null,
  className: undefined
};

export default memo(TruncateText);
