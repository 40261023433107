import { Folder } from 'reducers/campaignsFolders';
import { ApiPromise } from './types.d';
import { get, post, put, remove } from './index';

export const getFolders: () => ApiPromise<Folder[]> = () =>
  get({
    endpoint: 'influence/v2/folders',
    debug: [],
    catchErrors: [404],
  });

export const addFolder: (name: string) => ApiPromise<{ id: number }> = (name) =>
  post({
    endpoint: 'influence/v2/folders',
    params: {
      name,
      project_ids: [],
    },
    catchErrors: [422, 404],
  });

export const updateFolder: (params: Folder) => ApiPromise<{ id: number }> = ({ id, name, project_ids }) =>
  put({
    endpoint: `influence/v2/folders/${id}`,
    params: {
      name,
      project_ids,
    },
    catchErrors: [422, 404],
    debug: { id },
  });

export const deleteFolder: (id: number) => ApiPromise<{ id: number }> = (id) =>
  remove({
    endpoint: `influence/v2/folders/${id}`,
    catchErrors: [422, 404],
    debug: { id },
  });
