import { createElement, forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Text.module.scss';

const Text = forwardRef(({
  tag,
  className,
  children,
  fontSize,
  fontWeight,
  margin,
  size,
  whiteSpace,
  resetMargin,
  fontTitle,
  fontSubtitle,
  fontVariable,
  lineHeight,
  uppercase,
  style,
  ...rest
}, ref) => {
  const cnText = cn(styles.text, className, styles[tag], {
    [styles[size]]: !tag.startsWith('h'),
    [styles.reset]: resetMargin,
    [styles.fontTitle]: fontTitle,
    [styles.fontSubtitle]: fontSubtitle,
    [styles.fontVariable]: fontVariable,
  });

  const styleText = useMemo(
    () => fontSize && ({
      fontSize,
      lineHeight: !lineHeight ? `${1.5 * fontSize}px` : lineHeight
    }),
    [fontSize, lineHeight]
  );

  const styleWeight = useMemo(
    () => fontWeight && ({ fontWeight }),
    [fontWeight]
  );

  const styleMargin = useMemo(
    () => margin && ({
      margin
    }),
    [margin]
  );

  const styleWhiteSpace = useMemo(
    () => whiteSpace && ({
      whiteSpace
    }),
    [whiteSpace]
  );

  const styleUppercase = useMemo(
    () => uppercase && ({
      textTransform: 'uppercase'
    }),
    [uppercase]
  );

  return createElement(
    tag, {
      ...rest,
      className: cnText,
      ref,
      style: {
        ...styleText,
        ...styleMargin,
        ...styleWhiteSpace,
        ...styleWeight,
        ...styleUppercase,
        ...style
      },
    },
    children
  )
});

Text.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.number,
  whiteSpace: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small', 'big']),
  children: PropTypes.node,
  fontTitle: PropTypes.bool,
  resetMargin: PropTypes.bool,
  uppercase: PropTypes.bool
}

Text.defaultProps = {
  tag: 'p',
  size: 'default',
  fontSize: null,
  lineHeight: null,
  whiteSpace: null,
  margin: null,
  fontWeight: null,
  uppercase: null,
  className: null,
  children: null,
  fontTitle: false,
  resetMargin: false
}

export default memo(Text);
