import React, { lazy, Suspense } from 'react';
import { Loader } from 'kolkit';

const LoadedComponent = lazy(() => import('../ui/FreePlaceholder'));

export default () => (
  <Suspense fallback={<Loader full style={{ top: 60 }} />}>
    <LoadedComponent />
  </Suspense>
);
