export const MODAL_SHOW = "ui::modal::show";
export const MODAL_HIDE = "ui::modal::hide";
export const MODAL_UPDATE = "ui::modal::update";

export const DEVICE_CHANGED = "ui::device::changed"

export const PROFILE_OPEN = "ui::profile::open";
export const PROFILE_CLOSE = "ui::profile::close";

export const FILTERS_OPEN = "ui::filters::open";
export const FILTERS_CLOSE = "ui::filters::close";

export const LOADING = '[UI] --> set loading';
export const LOADER = '[UI] --> set loader';
export const CONTROLLED_LOADER = '[UI] --> set controlled loader';

export const CLEAR_TIMER = '[UI] --> clear setTimeout action';

