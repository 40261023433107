import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import ButtonBase from '@mui/material/ButtonBase';

import { Icon } from 'kolkit';
import { Typography } from 'components/ui';

import styles from './DoubleAuthentication.module.scss';

const ITEMS = [
  {
    name: 'phone',
    label: 'auth.doubleAuthentication.modal.setup.bySms',
    icon: 'message',
  },
  {
    name: 'authenticator',
    label: 'auth.doubleAuthentication.modal.setup.byApp',
    icon: 'mobile',
  },
];

interface Props {
  onChange: (method: string) => void;
}

const Methods: React.FC<Props> = ({ onChange }) => {
  return (
    <div className="flex gap16 fdc">
      {ITEMS.map(({ name, label, icon }) => (
        <ButtonBase
          key={`2fa-${name}-method`}
          component="button"
          onClick={() => onChange(name)}
          className={styles.buttonBase}
        >
          <div className="flex aic gap8">
            <Icon label={icon} theme="solid" />
            <Typography variant="label/large">
              <FormattedMessage id={label} />
            </Typography>
          </div>
          <Icon label="chevron-right" />
        </ButtonBase>
      ))}
    </div>
  );
};

export default Methods;
