import { ErrorInfo } from 'react';
import { createAction } from 'redux-actions';

import { AppThunk } from 'config/store';
import * as ENV from 'constants/env';
import { setControlledLoader } from 'actions/ui';
import { updateLocale } from 'api/user';
import _logError from 'utils/logError';

export const setClientToken = createAction(ENV.CLIENT_TOKEN_SET);
export const changeLocaleRequest = createAction(ENV.LOCALE_CHANGED);
export const setHasInternet = createAction(ENV.SET_INTERNET);
export const newApplicationVersionAvailable = createAction(
  ENV.NEW_VERSION_AVAILABLE,
);
export const newApplicationVersionRequired = createAction(
  ENV.NEW_VERSION_REQUIRED,
);
export const setMaintenanceMode = createAction(ENV.MAINTENANCE_ACTIVATED);
export const actionForbidden = createAction(ENV.FORBIDDEN_ACTION);
export const setViewport = createAction(ENV.SET_VIEWPORT);

export const changeLocale: (locale: string) => AppThunk = locale => async (
  dispatch,
  getState,
) => {
  dispatch(changeLocaleRequest(locale));

  const profile = getState()?.user?.profile;

  // If logged In, update prefered language in Database
  if (profile?.id) {
    dispatch(setControlledLoader({ id: 'updateAreaSettings', show: true }));
    await updateLocale(locale);
    dispatch(setControlledLoader({ id: 'updateAreaSettings', show: false }));
  }
};

export const logError: (error: Error, errorInfo: ErrorInfo) => AppThunk =
  (error, errorInfo) => async (dispatch, getState) => {
    const { id, firstName, lastName, companyName } =
      getState()?.user?.profile || {};

    if (id && companyName) {
      return _logError(error, {
        errorInfo: errorInfo.toString(),
        id,
        name: `${firstName}  ${lastName}`,
        companyName,
      });
    }
  };
