const mappedInteractions = {
	'likes': 'likes',
	'dislikes': 'dislikes',
	'comments': 'comments',
	'shares': 'shares',
	'retweets': 'retweets',
	'favorites': 'saved',
	'saved': 'saved',
	'views': 'views',
	'videos_views': 'videosViews',
	'plays': 'plays',
	'swipe_ups': 'swipeUp',
	'clicks': 'clicks',
	'replies': 'replies',
	'impressions': 'impressions',
	'reach': 'reach',
	'social_network': 'social_network',
	'published_at': 'published_at',
	'author_id': 'author_id',
	'content_type': 'content_type',
	'is_story': 'is_story',
	'sna_id': 'sna_id',
	'screenshots': 'screenshots'
};

export const mapPosts = posts => (posts || []).map(mapPost);

export const mapInteractionDetails = {
  fromApi: details => {
    if (!details) return {};

    return Object.assign(
      { isManual: details.is_manual },
      Object.entries(mappedInteractions).reduce(
        (p, [key, mappedKey]) =>
          Object.assign(
            p,
            key in details &&
              details[key] !== null && {
                [mappedKey]: parseInt(details[key], 10),
              },
          ),
        {},
      ),
    );
  },
  toApi: (details, isForced) => ({
    ...Object.entries(mappedInteractions).reduce(
      (p, [mappedKey, key]) =>
        Object.assign(
          p,
          key in details && {
            [mappedKey]:
              details[key] === '-' ? null : parseInt(details[key], 10),
          },
        ),
      {},
    ),
    is_manual: isForced,
  }),
};

export const mapPost = post => {
	return {
		id: String(post.id),
		avatar: post.avatar_url,
		profileId: post.profile_id,
		profileDisplayName: post.display_name,
		mention: post.username,
		snaId: String(post.sna_id),
		publishedAt: post.published_at,
		updatedAt: post.updated_at,
		contentUrl: post.content_url,
		picture: post.picture_url,
		video: post.video_url,
		content: post.content,
		stickerLinkUrl: post?.sticker_link_url,
		transcripts: post.transcripts,
		avgCommitments: post.avg_engagement,
		interactionDetails: mapInteractionDetails.fromApi(post.engagement_details),
		manualInteractionDetails: post.manual_engagement_details !== null
			? mapInteractionDetails.fromApi(post.manual_engagement_details)
			: {},
		manualInteractionDetailsForced: !!post.manual_engagement_details?.is_manual,
		type: post.type || 'picture',
		isStory: !!post.is_story || !!post.story,
		isIgtv: !!post.is_igtv,
		isVideoExpired: !!post.is_video_expired,
		isReel: !!post.is_reel,
		isSnapchatStory: !!post?.is_saved_story,
		isSnapchatSpotlight: !!post?.is_spotlight,
		manuallyAdded: !!post.manually_added,
		isExcluded: post?.is_excluded,
		network: post?.social_network,
	}
};

export const entitySocialPostsSort = {
	mostRecent: `most_recent`,
	mostEngaging: `most_engaging`,
	mostEngagingRate: `most_engaging_rate`,
	mostRelevant: `most_relevant`,
	mostLiked: `most_liked`,
};