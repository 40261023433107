import React, { memo, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import Icon from './Icon';
import usePrevious from '../hooks/usePrevious';

import styles from './Icon.module.scss'

let timer;

const IconNavbar = props => {
  // eslint-disable-next-line
  const { selected, notification, withoutNotifNumber, bigNotif } = props;

  const [startAnimation, setStartAnimation] = useState(false);
  const [active, setActive] = useState(false);

  const prevNotification = usePrevious(Number(notification));

  useEffect(() => {
    if (startAnimation) {
      timer = setTimeout(
        () => setStartAnimation(false),
        300
      )
    }
    return () => clearTimeout(timer);
  }, [startAnimation]);

  useEffect(() => {
    if (prevNotification < notification) setStartAnimation(true);
  }, [prevNotification, notification]);

  useEffect(() => {
    if (selected) setActive(true);
    if (!selected) setActive(false);
  }, [selected])

  const cnIcon = cn(styles.navIcon, {
    [styles.off]: active
  });

  const cnHoveredIcon = cn(styles.hoveredIcon, {
    [styles.active]: active
  });

  const renderNotification = useMemo(
    () => {
      const cnNotif = cn(styles.contentNotification, {
        [styles.bigNotif]: bigNotif,
        [styles.withoutNotifNumber]: withoutNotifNumber,
        [styles.animated]: startAnimation
      });

      const getNotification = Number(notification);

      if (notification && getNotification !== 0) {
        return (
          <span className={styles.notification}>
            <span className={cnNotif}>
              {getNotification < 100 ? notification : '+99'}
            </span>
          </span>
        )
      }
      return null;
    },
    [notification, bigNotif, withoutNotifNumber, startAnimation]
  );

  return (
    <div className={styles.iconNavbar}>
      <Icon
        {...props}
        className={cnIcon}
      />
      <Icon
        {...props}
        className={cnHoveredIcon}
        theme="navbarGradient"
      />
      {renderNotification}
    </div>
  )
};

export default memo(IconNavbar);
