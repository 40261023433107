import { createAction } from 'redux-actions';
import queryString from 'jquery-param';
import _isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';

import history from 'config/history';
import { conf } from 'config/env';
import { configFilters } from 'config/filters';
import { sortBy as initSortyBy, filters as initialFilters } from 'config/init';
import { screenview } from 'utils/tags';
import stripObject from 'utils/stripObject';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';


import { resetAndUpdateFilters as resetAndUpdateUserFilters } from './user';
import * as api from '../api/engine';
import * as profilesActions from './profiles';
import * as ENGINE from '../constants/engine';
import mapper from '../mappers/engine';
import { setControlledLoader } from './ui';

const searchInProgress = createAction(ENGINE.SEARCH_LOADING);
const searchComplete = createAction(ENGINE.SEARCH_COMPLETE);
const updateAllFilters = createAction(ENGINE.FILTERS_UPDATE);
const updateTabInStore = createAction(ENGINE.CHANGE_TAB);
const updateSelectedResults = createAction(ENGINE.SELECTED_UPDATE);

export const updateFilter = createAction(ENGINE.FILTER_CHANGE);
export const resetFilters = createAction(ENGINE.FILTERS_RESET);

export const updateInput = createAction(ENGINE.INPUT_CHANGE);
export const updateSortByInStore = createAction(ENGINE.SORT_CHANGE);
export const updatePageInStore = createAction(ENGINE.CHANGE_PAGE);

export const getSavedSearchesAction = createAction(ENGINE.SAVED_SEARCHES);

export const updateTab = tab => async (dispatch, getState) => {
	dispatch(updateTabInStore(tab));

	// trigger search only if we have searched keywords
	const { search } = getState().engine.filters;
	if (search?.length > 0) dispatch(setUrlSearchQuery());
};

export const setUrlSearchQuery = () => (_, getState) => {
	const { filters, sortBy, type, results } = getState().engine;
	const query = { filters, sortBy, page: results.page, type };

	const urlQuery = encodeSearchUrl(query);

	const path = {
		pathname: window.location.pathname,
		search: `q=${urlQuery}`,
	};

	if (filters.sid === "init") {
		// First search
		history.replace(path);
	}
	else {
		history.push(path);
	}
};

export const updateSelected = id => (dispatch, getState) => {
	const { engine: { selectedResults } } = getState();

	const selected = selectedResults.includes(id)
		? selectedResults.filter(s => s !== id)
		: [...selectedResults, id];

	dispatch(updateSelectedResults(selected))
};

export const setAllSelected = ids => dispatch => {
	const selected = ids || [];
	dispatch(updateSelectedResults(selected))
};

export const updateSortBy = sort => dispatch => {
	dispatch(updateSortByInStore(sort));
	dispatch(updatePageInStore(0));
	dispatch(setUrlSearchQuery());
};

export const updatePage = page => async (dispatch) => {
	dispatch(updatePageInStore(page));
	dispatch(setUrlSearchQuery());
	screenview(`/page-${(page + 1)}`);
};

export const prepareSearch = () => (dispatch, getState) => {
	// --Preparing search
	// -- Getting filters & sort
	const { user, engine } = getState();
	const { filters } = user;
	const { sortBy } = engine;
	// -- Copying users filters to engine filters
	dispatch(updateAllFilters(filters));

	// -- A filter has changed, so moving to page 0
	dispatch(updatePageInStore(0));

	// -- Change sort depending on filters values
	if (filters.search?.length > 0 && sortBy !== "relevance") {
		dispatch(updateSortByInStore("relevance"));
	}

	// particular case if we don't have search anymore
	if (filters.search?.length === 0) {
		// If sort is relevance or mostRecent, we have to reset it
		if (sortBy === "relevance" || sortBy === "mostRecent") {
			dispatch(updateSortByInStore("communitySize"));
		}
	}

	// -- launch search
	dispatch(setUrlSearchQuery());
};

let oldSearchParams = "";

export const doSearchFromQuery = param => async (dispatch, getState) => {
	const hash = window.location.search;

	// No need to perform a new search if params are the same
	if (oldSearchParams === hash && !param) return null;

	oldSearchParams = hash;
	const search = decodeSearchUrl(hash);
	const oldSearch = decodeSearchUrl(oldSearchParams);

	// No search and no previous search, it should be first render with no initial Search, so we don't trigger search
	if (!oldSearch && !search) return null;

	const {
		engine: {
			sortBy,
			type,
		},
		user: {
			filters: userFilters,
		}
	} = getState();

	const isFirstSearch = userFilters.sid === 'init';

	// Check if store engine & user has same key
	if (search.filters && window.location.pathname === '/') {
		// Update store engine filters from url
		dispatch(updateAllFilters(search.filters));
	}

	// if (search.filters && userFilters.sid !== engineFilters.sid) {
	if (
		search.filters
		&& window.location.pathname === '/'
		&& userFilters.sid !== search.filters.sid
	) {
		// Update store user filters
		dispatch(resetAndUpdateUserFilters(search.filters));
	}

	if (search.sortBy && sortBy !== search.sortBy) {
		// Update store engine sortBy
		dispatch(updateSortByInStore(search.sortBy));
	}

	// Reset sortBy if needed
	if (!search.sortBy && sortBy !== initSortyBy) {
		dispatch(updateSortByInStore(initSortyBy));
	}

	if (search.type && type !== search.type) {
		// Update store engine type
		dispatch(updateTabInStore(search.type));
	}

	// Reset tab if needed
	if (!search.type && type !== 'post') {
		dispatch(updateTabInStore("post"));
	}

	// All stores are updated regarding query string, we can trigger search
	if (isFirstSearch) {
		dispatch(updatePageInStore(0));
		await dispatch(doSearch());
	}

	if (!isFirstSearch) {
		dispatch(updatePageInStore(search.page));
		await dispatch(doSearch());
	}

	return true;
};

let searchIsLoading = false;
let hasNextSearch = false;

export const doSearch = () => async (dispatch, getState) => {
	const { sortBy, filters, type, results: engineResults } = getState().engine;
	const { features: { manageCampaigns } } = getState().user;

	const query = {
		filters,
		sortBy,
		type,
		page: engineResults.page,
	};

	if (searchIsLoading) {
		hasNextSearch = true;
		return null;
	}

	searchIsLoading = true;
	// Launch search
	dispatch(searchInProgress());

	const	res = await api.doSearch(query);

	const { profiles, results } = mapper.doSearch.fromApi(res);

	dispatch(profilesActions.batch(profiles));

	dispatch(searchComplete({
		...results,
		page: query.page || 0,
	}));

	if (manageCampaigns) {
		await Promise.all(
			profiles?.map(async ({ id }) => {
				await dispatch(profilesActions.loadProfileCampaigns(id));
				return true;
			})
		)
	}

	searchIsLoading = false;

	if (hasNextSearch) {
		hasNextSearch = false;
		dispatch(doSearch());
	}
};

const filter = object => Object.entries(object)
	.filter(([key, value]) =>
		!!configFilters[key]
		&& JSON.stringify(value) !== JSON.stringify(configFilters[key].default))
	.reduce((store, [key, value]) => ({
		...store,
		[key]: value,
	}), {});

// Strip default filters fields if present in filter object
const stripFiltersFromDefaults = filter => {
	const newFilter = stripObject(filter);
	const { filters: currentFilter } = newFilter;
	const defaultFilters = stripObject(initialFilters);

	if (_isEqual(currentFilter?.activeFilter?.sort(), defaultFilters?.activeFilter?.sort())) {
		delete currentFilter.activeFilter;
	}
	Object.keys(currentFilter.socialNetworks).forEach(key => {
		if (_isEqual(currentFilter.socialNetworks[key], defaultFilters.socialNetworks[key])) {
			delete currentFilter.socialNetworks[key];
		}
	}
	);
	if (isEmpty(currentFilter.socialNetworks)) delete currentFilter.socialNetworks;

	return newFilter;
}

// Restore default filters fields if not present in filter object
const restoreFiltersFromDefault = filter => {
	const newFilter = { ...filter };
	const { filters: currentFilter } = newFilter;

	if (currentFilter && !currentFilter.activeFilter) {
		currentFilter.activeFilter = initialFilters.activeFilter;
	}
	currentFilter.socialNetworks = !currentFilter?.socialNetworks
		? initialFilters.socialNetworks
		: Object.entries(currentFilter?.socialNetworks)
			.reduce( (socialNetworks, [network, networkValue]) => {
				const newValue = Object.entries(networkValue).reduce( (acc, [key, value]) => ({
					...acc,
					[key]:
						typeof configFilters[key].default === 'object' &&
						'min' in configFilters[key]?.default &&
						'max' in configFilters[key]?.default
							? { min: '', max: '', ...value }
							: value,
				}), {});

				return {
					...socialNetworks,
					[network]: {
						...socialNetworks[network],
						...newValue
					}
				}
			}, initialFilters.socialNetworks);

	return newFilter;
}

export const encodeSearchUrl = query => {
	const { filters, sortBy, page, type } = query;
	const { activeFilter, search, sid, socialNetworks } = filters;

	const getInfluencerFilter = filters && filter(filters);

	const parse = Object.assign(
		{
			filters: {
				activeFilter: [...activeFilter],
				...getInfluencerFilter,
				socialNetworks: { ...socialNetworks },
				search: [...search],
				sid,
			},
		},
		sortBy === initSortyBy ? null : { sortBy },
		page === 0 ? null : { page },
		type !== "post" ? { type } : null
	);

	return encodeURIComponent(JSON.stringify(stripFiltersFromDefaults(parse)));
};

const safeDecodeURIComponent = (url) => {
	try {
	  return decodeURIComponent(url);
	} catch (e) {
	  console.error('URIError: The URL is malformed', e);
	  return null;
	}
}

export const decodeSearchUrl = (url, fallback) => {
	// exclude compose from messaging to avoid issues
	if (url && url.trim() !== '' && !url.includes('compose')) {
		const urlDecoded = safeDecodeURIComponent(url);
		if (urlDecoded === null || !urlDecoded.includes('q=')) return fallback || null;

		try {
			const jsonData = JSON.parse(urlDecoded.replace(/\??q=/, ''));
			return restoreFiltersFromDefault(jsonData);
		} catch (error) { // Invalid JSON syntax OR An error occurred while parsing JSON
			return fallback || null;
		}
	}
	return fallback || null;
};

export const exportSearch = () => async (_, getState) => {
	const { engine: { filters, sortBy, type, results }, env: { userToken } } = getState();
	const query = { filters, sortBy, page: results.page, type, resultsPerPage: results.resultsPerPage };

	const params = mapper.doSearch.toApi(query);

	window.open(`${conf.api}/influence/search/export?${queryString({
		...params,
		token: userToken
	})}`);

	return true;
};

export const saveSearch = ({ name, query }) => async () => {
	await api.saveSearch({ name, query });
	const intl = getIntl();
	toast(
        intl.formatMessage({ id: "savedSearches.toast.saved"}),
        {
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
        },
	);
	return true;
}

export const updateSearch = ({ id, name, query }) => async () => {
	await api.updateSearch(id, { name, query });
	const intl = getIntl();
	toast(
        intl.formatMessage({ id: "savedSearches.toast.updated"}),
        {
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
        },
	);
	return true;
}

export const deleteSearch = (id) => async (dispatch) => {
	dispatch(setControlledLoader({ id: 'globalLoader', show: true }));

	await api.deleteSearch(id);

	dispatch(setControlledLoader({ id: 'globalLoader', show: false }));

	const intl = getIntl();
	toast(
		intl.formatMessage({ id: "savedSearches.toast.deleted"}),
			{
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
		},
	);
	return true;
}

export const getSavedSearches = () => async dispatch => {
	const data = await api.getSavedSearches();
	return data && dispatch(getSavedSearchesAction(data));
}
