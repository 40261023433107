import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';

import { Icon, Input2, Text, TruncateText } from 'kolkit';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import { getMenuProps } from './utils';

import styles from './Select.module.scss';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  dataId?: string;
  style?: React.CSSProperties;
  className?: string;
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

type Props<T extends Option = Option> = Overwrite<
  SelectProps,
  {
    labelId?: string;
    options: T[];
    selected?: T['value'] | null;
    onChange?: (selection: {
      value: T['value'];
      item?: T;
      previousValue?: T['value'] | null;
    }) => void;
    inputSearchPlaceholder?: string;
    searchable?: boolean;
    disabled?: boolean;
    placeholder?: string;
    label?: string; // same as placeholder but not greyed
    size?: 'small' | 'medium' | 'large';
    className?: string;
    renderItem?: (item: T) => JSX.Element;
    theme?: 'default' | 'primary' | 'secondary' | 'tertiary' | 'tone';
    active?: boolean;
    activePlaceholder?: boolean;
    autoFocus?: boolean;
    dataId?: string;
    autoHeight?: boolean;
  }
>;

const defaults = {
  labelId: undefined,
  options: [],
  selected: '',
  onChange: ({ value }) => {
    console.log('value: ', value);
  },
  inputSearchPlaceholder: '',
  searchable: false,
  disabled: false,
  placeholder: '',
  label: '',
  size: 'medium' as const,
  className: '',
  renderItem: undefined,
  theme: 'default' as const,
  autoFocus: false,
};

let blurTimer;

function Select<T extends Option = Option>({
  labelId = defaults.labelId,
  options = defaults.options,
  selected = defaults.selected,
  onChange = defaults.onChange,
  inputSearchPlaceholder = defaults.inputSearchPlaceholder,
  searchable = defaults.searchable,
  placeholder = defaults.placeholder,
  label = defaults.label,
  disabled = defaults.disabled,
  size = defaults.size,
  className = defaults.className,
  renderItem = defaults.renderItem,
  theme = defaults.theme,
  autoFocus = defaults.autoFocus,
  active,
  activePlaceholder,
  dataId,
  autoHeight,
  ...rest
}: Props<T>) {
  const intl = useIntl();
  const [searchText, setSearchText] = useState<string>('');

  const displayedOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(searchText),
      ),
    [options, searchText],
  );

  const IconComponent = useCallback((props) => {
    return (
      <Icon {...props} label="caret-circle-down" theme="solid" fill="#001B3E" />
    );
  }, []);

  useEffect(() => {
    return () => clearTimeout(blurTimer);
  }, []);

  const handleClose = useCallback(() => {
    blurTimer = setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 0);
  }, []);

  const handleChange = useCallback(
    (e) => {
      e.stopPropagation();
      const { value } = e.target;
      if (!onChange) return null;
      onChange({
        value,
        item: options.find((option) => option.value === value),
        previousValue: selected,
      });
    },
    [onChange, options, selected],
  );

  const handleChangeSearch = useCallback(({ value }) => {
    setSearchText(value || '');
  }, []);

  const renderValue = useCallback(
    (selected) => {
      if (!selected) {
        return placeholder ? (
          <div
            className={cn(styles.placeholder, {
              [styles.activePlaceholder]: !!activePlaceholder,
            })}
          >
            <TruncateText>{placeholder}</TruncateText>
          </div>
        ) : (
          <div
            className={cn(styles.content, {
              [styles.activePlaceholder]: !!activePlaceholder,
            })}
          >
            {label}
          </div>
        );
      }
      const option = options.find((option) => option.value === selected);
      return (
        <div className={styles.content}>
          <TruncateText>{option?.label || selected}</TruncateText>
        </div>
      );
    },
    [options, placeholder, activePlaceholder, label],
  );

  const cnWrapper = cn(styles.wrapper, className);
  const cnSelect = cn(styles.select, {
    [styles[size]]: size,
    [styles[theme]]: theme,
    [styles.active]: !!active,
  });

  return (
    <FormControl variant="outlined" className={cnWrapper}>
      <MuiSelect
        {...rest}
        labelId={labelId}
        disabled={disabled}
        value={selected ?? ''}
        displayEmpty
        onChange={handleChange}
        onClose={handleClose}
        MenuProps={getMenuProps(size, autoHeight, searchable)}
        className={cnSelect}
        classes={{
          icon: styles.icon,
        }}
        IconComponent={IconComponent}
        renderValue={renderValue}
        autoFocus={autoFocus}
        data-id={dataId}
        onClick={(e) => e.stopPropagation()}
      >
        {/*
          TextField is put into ListSubheader so that it doesn't act as a selectable item in the menu,
          we can click the TextField without triggering any selection.
        */}
        {searchable && (
          <ListSubheader className={styles.subheader}>
            <Input2
              autoFocus
              fullWidth
              theme="secondary"
              size={size === 'large' ? 'medium' : 'small'}
              placeholder={
                inputSearchPlaceholder ||
                intl.formatMessage({ id: 'global.search.title' })
              }
              onChange={handleChangeSearch}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            className={styles.item}
            data-id={item.dataId}
            style={item.style}
            selected={selected === item.value}
          >
            {renderItem ? (
              renderItem(item)
            ) : (
              <Text tag="span" resetMargin className={item.className}>
                {item.label}
              </Text>
            )}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
