export const formatMentionForUI = mention => {
  const trimmed = mention?.trim();
  if (!trimmed?.length) return '';
  return trimmed.startsWith('@') ? trimmed : `@${trimmed}`;
};

export const trimMentionForAPI = mention => {
  const trimmedMention = mention?.trim();
  return trimmedMention?.startsWith(`@`)
    ? trimmedMention.slice(1)
    : trimmedMention;
};

export const trimMentionsForAPI = mentions => {
  return  mentions.reduce((prev, mention) => {
    const trimmedMention = trimMentionForAPI(mention);
    if (trimmedMention?.length) {
      return [...prev, trimmedMention];
    }
    return prev;
  }, [] as string[])
};

export const isMention = mention => {
  const trimmed = mention?.trim();
  if (!trimmed) return false;
  return /^@?[.a-zA-Z0-9_-]+$/.test(mention);
};