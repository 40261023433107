import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl-phraseapp';

import { Input, Label, Button3 } from 'kolkit';

import * as authActions from 'actions/auth';
import { useDispatch } from 'utils/redux';

import AuthHeader from '../AuthHeader';
import ErrorMessage from '../ErrorMessage';

import styles from '../Auth.module.scss';

const RequestPasswordReset = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [state, setState] = useState({
    email: '',
    loading: false,
    resetRequestSent: false,
    errorCode: ''
  });

  const handleEmailChange = useCallback(
    ({ value }) => {
      setState(prevState => ({
        ...prevState,
        resetRequestSent: false,
        email: value,
        errorCode: ''
      }));
    },
    []
  );

  const handlePasswordReset = useCallback(
    async e => {
      e.preventDefault();
      setState(prevState => ({
        ...prevState,
        loading: true,
        resetRequestSent: false,
        errorCode: ''
      }));
      const { error } = await dispatch(authActions.requestPasswordReset({
        email: state.email,
      }));
      setState(prevState => ({
        ...prevState,
        loading: false,
        errorCode: error || '',
        resetRequestSent: !error
      }));
    },
    [dispatch, state.email]
  );

  const submitButtonContent = useMemo(
    () => {
      if (state.resetRequestSent) return intl.formatMessage({ id: 'auth.requestPasswordReset.submitted'});
      if (state.loading) return intl.formatMessage({ id: 'auth.requestPasswordReset.loading'});
      return intl.formatMessage({ id: 'global.cta.submit'});
    },
    [state.resetRequestSent, state.loading, intl]
  )

  return (
    <div className={styles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.requestPasswordReset.requestPasswordReset'})} />
      <p>{intl.formatMessage({ id: 'auth.requestPasswordReset.instruction'})}</p>
      <form onSubmit={handlePasswordReset}>
        <div className={styles.fields}>
          <Label label={intl.formatMessage({ id: 'auth.login.email'})} id="auth-reques-password-reset-email-label">
            <Input
              onChange={handleEmailChange}
              placeholder={intl.formatMessage({ id: 'auth.login.email'})}
              size="big"
              type="email"
              value={state.email}
              disabled={state.loading}
              error={!!state.errorCode}
              fullWidth
            />
          </Label>
          {state.errorCode && <ErrorMessage errorCode={state.errorCode} />}
          <Button3
            fullWidth
            disabled={state.loading || !state.email}
            size="medium"
            type="submit"
            icon={state.resetRequestSent ? 'check' : '' }
            label={submitButtonContent}
          />
        </div>
      </form>

      <div className={styles.askIfWantsToLogin}>
        <Link to={{ search: '' }}>
          <FormattedMessage id="auth.login.button" />
        </Link>
      </div>
    </div>
  );
}

export default RequestPasswordReset;
