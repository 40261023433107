import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import * as AFFILAE from 'constants/affilae';

const initialState = {
  campaigns: {},
  affiliates: {},
  hasConnection: null,
  loaded: false
};

const reducers = {
  [AFFILAE.SET_CAMPAINGS]: (state, { payload }) => {
    return update(state, {
      campaigns: {
        $set: payload
      }
    });
  },
  [AFFILAE.SET_AFFILIATES]: (state, { payload }) => {
    return update(state, {
      affiliates: {
        $set: payload
      }
    });
  },
  [AFFILAE.AFFILAE_DATAS_LOADED]: (state) => {
    return update(state, {
      loaded: {
        $set: true
      }
    });
  },
  [AFFILAE.SET_HAS_CONNECTION]: (state, { payload }) => {
    return update(state, {
      hasConnection: {
        $set: payload
      }
    });
  },
};


export default createReducer(initialState, reducers);
