import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'kolkit';

import styles from './AuthHeader.module.scss'

const AuthHeader = ({ title, fontSize }) => {
  return (
    <div className={styles.wrapper}>
      <Text fontTitle tag="h1" fontSize={fontSize} className={styles.title}>
        {title}
      </Text>
    </div>
  );
};

AuthHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

AuthHeader.defaultProps = {
}

export default memo(AuthHeader);
