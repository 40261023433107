import React, { useEffect, useMemo } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'utils/redux';
import { changeLocale } from 'actions/env';
import { pageNames } from 'constants/auth';
import { getLocaleByIso2Code } from 'locales';

import AuthPageWrapper from './components/AuthPageWrapper';
import Login from './components/Login';
import RequestPasswordReset from './components/RequestPasswordReset';
import PasswordReset from './components/PasswordReset';
import AccountActivation from './components/AccountActivation';
import AccountConfirmation from './components/AccountConfirmation';

const pageNamesList = Object.values(pageNames);

const Auth = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    type: authActionType,
    lang
  } = useMemo(
    () => querystring.parse(location.search.substring(1)), // without the ?
    [location]
  );

  useEffect(() => {
    const locale = getLocaleByIso2Code(lang);
    if (locale) {
      dispatch(changeLocale(locale.id));
    }
    // eslint-disable-next-line
  }, [lang]);

  return (
    <AuthPageWrapper>
      {!pageNamesList.includes(authActionType) && <Login />}
      {authActionType === pageNames.REQUEST_PASSWORD_RESET && <RequestPasswordReset />}
      {authActionType === pageNames.RESET_PASSWORD && <PasswordReset />}
      {authActionType === pageNames.ACTIVATION && <AccountActivation />}
      {authActionType === pageNames.CONFIRMATION && <AccountConfirmation />}
    </AuthPageWrapper>
  );
}

export default Auth;
