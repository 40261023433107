import React, { useCallback, useEffect, useState } from 'react';
import { conf } from 'config/env';
import { Button2 } from 'kolkit';
import { useIntl } from 'react-intl';
import { getAzureAuthToken } from 'api/user';

import styles from './AzureSsoButton.module.scss';

type Props = {
  type: 'login' | 'register';
  token?: string;
  className?: string;
};

const AzureSsoButton: React.FC<Props> = ({
  type,
  token = undefined,
  className = undefined,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [authenticityToken, setAuthenticityToken] = useState('');

  const getAuthToken = useCallback(async () => {
    setLoading(true);
    const response = await getAzureAuthToken();
    if (response.form_authenticity_token) {
      setAuthenticityToken(response.form_authenticity_token);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void getAuthToken();
  }, [getAuthToken]);

  return (
    <div className={className}>
      <form
        action={`${conf.api}/users/auth/azure_activedirectory_v2`}
        method="post"
      >
        <input
          name="authenticity_token"
          value={authenticityToken}
          type="hidden"
        />
        {token && <input name="activation_token" value={token} type="hidden" />}

        <Button2
          icon="microsoft"
          iconTheme="brand"
          theme="secondary"
          fullWidth
          disabled={loading}
          size="large"
          type="submit"
          label={intl.formatMessage({ id: `auth.login.button.azure.${type}` })}
          className={styles.button}
        />
      </form>
    </div>
  );
};

export default AzureSsoButton;
