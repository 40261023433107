export const UPDATE = '[myInfluencers] --> Update';
export const UPDATE_KEY = '[myInfluencers] --> Update key';
export const UPDATE_ROWS = '[myInfluencers] --> Update rows';
export const UPDATE_FILTERS = '[myInfluencers] --> Update filters';
export const UPDATE_SETTINGS = '[myInfluencers] --> Update settings';

export const LOAD_PROFILES_LIST = '[myInfluencers] --> load list my kols';
export const UPDATE_PROFILES_LIST = '[myInfluencers] --> update list my kols';

export const HANDLE_SELECTION = '[myInfluencers] --> select kols';
export const HANDLE_TAG_OPERATOR = '[myInfluencers] --> filter select tag operator';
export const HANDLE_RESET_ACTIVATED_FILTER = '[myInfluencers] --> activated filter reset';

export const SEARCH_QUERY = '[myInfluencers] --> search query';
export const SET_PROFILE_LIST_PREFERENCES = '[myInfluencers] --> set user profile list preferences';

export const UPDATE_ACCOUNT_REQUESTS_PARAMS = '[myInfluencers] --> update params account requests';
export const UPDATE_ACCOUNT_REQUESTS_LIST = '[myInfluencers] --> update list account requests';
export const APPEND_TO_ACCOUNT_REQUESTS_LIST = '[myInfluencers] --> append to list account requests';

export const UPDATE_CUSTOM_LIST = '[myInfluencers] --> update custom list';
export const UPDATE_CUSTOM_FIELDS = '[myInfluencers] --> update custom fields';

export const SET_A_OVERLAP_STATUS = '[myInfluencers] --> set audienceOverlap status';
export const UPDATE_A_OVERLAP_STATUS = '[myInfluencers] --> update audienceOverlap status';
export const SET_A_OVERLAP_STATISTICS = '[myInfluencers] --> set audienceOverlap statistics';
export const UPDATE_A_OVERLAP_BRAND = '[myInfluencers] --> update audienceOverlapy brand';
