import moment from 'moment';
import { FRONTEND_DATE_FORMAT } from 'config/app';
import { isToday, parse, differenceInMonths } from 'date-fns';

export const formatDateRangeToString = ({ startDate, endDate }) =>
  `${moment(startDate).format(FRONTEND_DATE_FORMAT)}to${moment(endDate).format(
    FRONTEND_DATE_FORMAT,
  )}`;

export const parseStringToDateRange = (dateRangeString, format) => {
  const period = dateRangeString?.split('to');
  return period?.length === 2
    ? {
        startAt: moment(period[0], format),
        endAt: moment(period[1], format),
      }
    : null;
};

export const getPeriodByPreset = (value, outputFormat, inputFormat) => {
  switch (value) {
    case 'lastMonth':
      return {
        start_at: moment()
          .startOf('day')
          .subtract(1, 'months')
          .format(outputFormat),
        end_at: moment().format(outputFormat),
      };
    case 'last3Months':
      return {
        start_at: moment()
          .startOf('day')
          .subtract(3, 'months')
          .format(outputFormat),
        end_at: moment().format(outputFormat),
      };
    case 'last6Months':
      return {
        start_at: moment()
          .startOf('day')
          .subtract(6, 'months')
          .format(outputFormat),
        end_at: moment().format(outputFormat),
      };
    case 'lastYear':
      return {
        start_at: moment()
          .startOf('day')
          .subtract(1, 'years')
          .format(outputFormat),
        end_at: moment().format(outputFormat),
      };
    case 'all':
      return { start_at: undefined, end_at: undefined };
    default: {
      const { startAt, endAt } = parseStringToDateRange(value, inputFormat);
      return {
        start_at: moment(startAt).format(outputFormat),
        end_at: moment(endAt).format(outputFormat),
      };
    }
  }
};

export const getPresetByDateRange = (start_at, end_at, inputFormat) => {
  const startAt = parse(start_at, inputFormat, new Date());
  const endAt = parse(end_at, inputFormat, new Date());

  if (isToday(endAt)) {
    const diffInMonths = differenceInMonths(startAt, endAt);

    switch (Math.abs(diffInMonths)) {
      case 1:
        return 'lastMonth';
      case 3:
        return 'last3Months';
      case 6:
        return 'last6Months';
      case 12:
        return 'lastYear';
      default:
        return 'all';
    }
  } else return formatDateRangeToString({ startDate: startAt, endDate: endAt });
};
