import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl-phraseapp';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';

import styles from './ErrorMessage.module.scss'


const ErrorMessage = ({ errorCode }) => {
  return (
    <div className={styles.errorMessage}>
      <p className={styles.error}>
        <FormattedMessage
          id={`auth.errorMessages.${errorCode}`}
          minPassword={MINIMUM_PASSWORD_LENGTH}
        />
      </p>
    </div>
  );
};

ErrorMessage.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

export default memo(ErrorMessage);
