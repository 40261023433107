export const UPDATE = "profile::update";
export const BATCH = "profiles::batch";

export const UPDATE_SNA = "profile::sna::update";
export const UPDATE_SNAS = "profile::snas::batch";

export const LOAD_CAMPAIGNS = "profile::campaings::load";
export const LOAD_LISTS = '[Profile] -> load lists';
export const ADD_CAMPAIGN = "profile::campaings::add";
export const UPDATE_LIST = '[Profile] -> Update list';
export const DELETE_CAMPAIGN = "profile::campaings::detele";

export const UPDATE_EMAILS = '[Profile] -> Update contact';

export const AUDIENCE_TYPES = ['followers', 'likers'];
export const AUDIENCE_TYPES_YOUTUBE = ['followers', 'commenters'];
export const AUDIENCE_TYPES_SNAPCHAT = ['viewers'];
export const CREDIBILITY_SCORE_KEYS = ['noScore', '0-49', '50-59', '60-69', '70-84', '85-100'];
export const CAMPAGNE_HISTORY_PER_PAGE = 10;

export const REMOVE_PROFILES_FROM_STORE = '[Profile] -> Remove profiles from store';
