import NF from 'simple-number-formatter';
import _deburr from "lodash.deburr";
import history from 'config/history'
import { getStore } from 'config/store';

// FORMATTERS
export const celebrityNameFormatter = i => {
	if (!i) return null;

	return i?.pseudo && i?.pseudo.trim() !== ""
		? i.pseudo
		: i.fullName
			? i.fullName
			: i.full_name;
};

export const extractKolName = (profile) => {
  return (
    celebrityNameFormatter(profile?.details) ||
    celebrityNameFormatter(profile?.core)
  );
};

export const extractKolMention = (profile) => {
  if (profile?.username) return profile.username;

	const getInstaUsername = Object.values(profile?.snas || {})
		?.filter((sna) => sna?.network === 'instagram')
		?.sort((a, b) => b?.stats?.communityCount - a?.stats?.communityCount)

  if (getInstaUsername?.length === 0) return '';

	return getInstaUsername?.[0]?.username
};

export const swap = (arrayToSwap, moveIndex, toIndex) => {
	// if (!arrayToSwap[moveIndex]) return arrayToSwap
  const swapped = arrayToSwap.filter((_, i) => i !== moveIndex);
  swapped.splice(toIndex, 0, arrayToSwap[moveIndex]);
  return swapped;
};

export const clientNameFormatter = c => `${c.firstName} ${c.lastName} ${c.companyName ? `(${c.companyName})` : ''}`;

export const round = (n, p) => {
	const factor = (10 ** p);
    const tempNumber = n * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
};

export const ceil  = (n, p) => {
	const factor = (10 ** p);
    const tempNumber = n * factor;
    const roundedTempNumber = Math.ceil(tempNumber);
    return roundedTempNumber / factor;
};

export const floor  = (n, p) => {
	const factor = (10 ** p);
    const tempNumber = n * factor;
    const roundedTempNumber = Math.floor(tempNumber);
    return roundedTempNumber / factor;
};

export const isNumberStat = n => n !== null && n > 0;

export const numberPrice = n => Math.round(ceil(n, 1));

export const numberFormatter = (
	n,
	{ asAnInteger } = { asAnInteger: false },
  ) => {if (n === null || Number.isNaN(n)) return '-';

	const { env } = getStore();
	const numbersLexique = {
		"en-GB": {
			m: `M`,
			k: `K`,
			b: `Bn`,
		},
		"fr-FR": {
			m: ` M`,
			k: ` K`,
			b: ` Md`,
		},
		"de-DE": {
			m: `M`,
			k: `K`,
			b: `Bn`,
		},
		"es-ES": {
			m: `M`,
			k: `K`,
			b: `Bn`,
		},
		"it-IT": {
			m: `M`,
			k: `K`,
			b: `Bn`,
		},
	}
	const getUnit = numbersLexique[env.locale];

	const absValue = Math.abs(n);

	// 100B+
	if (absValue >= 99950000000) {
		return `${NF(n/1000000000, '0')}${getUnit.b}`;
	}

	// 10B+
	if (absValue >= 9995000000) {
		return `${NF(n/1000000000, '0.0')}${getUnit.b}`;
	}

	// 1B+
	if (absValue >= 999500000) {
		return `${NF(n/1000000000, '0.00')}${getUnit.b}`;
	}

	// 100M+
	if (absValue >= 99950000) {
		return `${NF(n/1000000, '0')}${getUnit.m}`;
	}

	// 10M+
	if (absValue >= 9995000) {
		return `${NF(n/1000000, '0.0')}${getUnit.m}`;
	}

	// 1M+
	if (absValue >= 999500) {
		return `${NF(n/1000000, '0.00')}${getUnit.m}`;
	}

	// 100K+
	if (absValue >= 99950) {
		return `${NF(n/1000, '0')}${getUnit.k}`;
	}

	// 10K+
	if (absValue >= 9995) {
		return `${NF(n/1000, '0.0')}${getUnit.k}`;
	}

	// 1K+
	if (absValue >= 1000) {
		return `${NF(n/1000, '0.00')}${getUnit.k}`;
	}

	if (absValue >= 100) {
		return `${NF(n, '0')} `;
	}

	if (absValue >= 10) {
		return `${NF(n, asAnInteger? '0' : '0.0')} `;
	}

	// else
	if (Math.round(n) === n) return `${n} `;
	return `${NF(n, '0.00')} `;
};

export const percentFormatter = n => {
	if (n == null || Number.isNaN(n)) return '-';

	// >= 1000
		if (n >= 1000) return numberFormatter(n);

	// 100
	if (n >= 100) return NF(n, '0');

	// 10+
	if (n >= 10) return NF(n, '0.0');

	return NF(n, '0.00');
};

export const numberSpacer = (n, locale) => n.toLocaleString(locale, { style: "decimal", minimumFractionDigits:0 });

// SORTING
export const textRangeCompare = (a, b) => {
	if (a[0] === "+") return 1;
	if (b[0] === "+") return -1;
	if (a[0] === "-") return -1;
	if (b[0] === "-") return 1;

	// Split ranges
	const as = parseInt(a.split('-')[0].trim(), 10);
	const bs = parseInt(b.split('-')[0].trim(), 10);
	return as > bs;
};

export const numberAsStringCompare = (a, b) => {
	return parseFloat(a) > parseFloat(b);
};

// nl2br
export const nl2br = str => String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');

// RANDOMIZATION
export const random = max => Math.floor(Math.random()*max);
export const randomRange = (min, max) => Math.floor(Math.random()*(max-min))+min;
export const randomBool = () => random(2) === 1;
export const randomFromArray = arr => arr[random(arr.length)];

export const randomDate = () => {
  const from = new Date(2020, 0, 1);
  const to = new Date();
  return new Date(
    from.getTime() + Math.random() * (to.getTime() - from.getTime()),
  );
};

export const getColorByNetwork = network => {
	switch (network) {
		case "facebook":
			return '#3B5998';
		case "youtube":
			return '#E62117';
		case "twitter":
			return "#1DA1F2";
		case "instagram":
			return '#D200C5';
		case "tiktok":
			return "#101010";
		case "snapchat":
			return "#000000";
		default:
			return '#0c2329';
	}
};

export const formatUrl = (url, queryParams = '') =>
  `${url}${history.location.search}${queryParams ? `&${queryParams}` : ''}`;

export const getRandomColor = () => {
	const RANDOM_COLORS = [
		{ backgroundColor: '#f4efff', pictureColor: '#dcccff' },
		{ backgroundColor: '#ffe3ec', pictureColor: '#ff9cbb' },
		{ backgroundColor: '#ccf5ff', pictureColor: '#66e0ff' },
		{ backgroundColor: '#d6f9f2', pictureColor: '#83eed9' },
	];
	return randomFromArray(RANDOM_COLORS);
};

export const cleanDeburrWord = word => word && _deburr(word.toLowerCase()).trim()

export const checkDuplicated = (arr, word, key='value') => {
	const deburred = cleanDeburrWord(word)
	return arr.find(t => cleanDeburrWord(t?.[key]) === deburred)
};

// check if string is URL
export const isUrl = string => {
	if (!string) return false

	return string.startsWith('http://') || string.startsWith('https://')
};

export const getVariationRate = (campaignAverageCommitment, averageCommitment) => {
	if (!campaignAverageCommitment || campaignAverageCommitment === 0 || !averageCommitment || averageCommitment === 0) return null
	return 100 * (campaignAverageCommitment - averageCommitment) / averageCommitment;
};

export const formatNumberToPercentage = n => {
	const percent = percentFormatter(n);
	if (percent === '-') return `-`;
	return `${percent}%`
};

export const displayVariationRate = variationRate => {
	if (Number.isNaN(variationRate) || variationRate === '-') return '-';
	if (variationRate === 0) return `${formatNumberToPercentage(variationRate)}`;

	const absValue = Math.abs(variationRate);
	return `${variationRate < 0 ? '-' : '+'}${formatNumberToPercentage(absValue)}`;
};

export const addThousandsSeparator = value => (
	value && value
		.toString()
		.replace(/\D/g, '')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
);

export const removeAtFromMention = (mention = "") => mention.trim().charAt(0) === '@' ? mention.trim().slice(1) : mention;

export const getObjectFromArray = (data, key, target) => {
	const findKey = data?.findIndex(item => item?.[key] === target);
	return data?.[findKey];
};

export const rateFormatter = rate => {
	if (rate >= 100) return Math.floor(rate);
	if (rate >= 10) return Math.floor(rate * 10) / 10;
	if (rate < 10 && rate > -10) return Math.floor(rate * 100) / 100;
	if (rate <= -10 && rate > -100) return Math.floor(rate * 10) / 10;
	if (rate <= -100) return Math.floor(rate);
}

export const generateRangeArray = ({ from=0, to=1, step=1 }) => {
	const length = Math.ceil((to - from) / step);
	return Array.from({length}, (_, i) => from + i * step);
}