
import { useCallback, useEffect, useState } from 'react';
import useDebounce from './useDebounce';

const formattedValue = (value) =>
  (value || '')
    ?.toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const useSearch = ({ data = [], key = '', debounceDelay = 200 }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [result, setResult] = useState(() => data);
  const debouncedValue = useDebounce(formattedValue(searchQuery), debounceDelay);

  const handleChange = useCallback(
    ({ value }) => setSearchQuery(value),
    [setSearchQuery]
  );

  const handleReset = useCallback(
    () => setSearchQuery(''),
    [setSearchQuery]
  );

  useEffect(() => {
    if (!debouncedValue) {
      setResult(data);
    } else {
      setResult(data?.filter(value => {
        const target = !key ? value : value[key];
        return formattedValue(target).includes(debouncedValue)
      }));
    }
  }, [debouncedValue, data, setResult, key]);

  return {
    searchQuery,
    handleChange,
    result,
    reset: handleReset
  }
};

export default useSearch;
