export const mapAccountRequest = (account) => {
  return {
    id: account.id,
    created_at: new Date().toISOString(),
    completed_at: new Date().toISOString(),
    url: account.url,
    status: account.status,
    rejection_reason: account.rejection_reason,
    profile_id: account.profile?.id || null,
    profile_name:
      account.profile?.first_name && account.profile?.last_name
        ? `${account.profile.first_name} ${account.profile.last_name}`
        : null,
    profile_picture: account.profile?.picture_url || null,
    monitoring_id: account.monitoring_id || null,
  };
};
