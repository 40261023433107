export const SHOPIFY_COLUMNS_MONITOR = [
  'sales_promo_code',
  'sales_count',
  'sales_turnover',
  'sales_average_basket',
  'sales_roas',
  'sales_roi',
];
export const SHOPIFY_COLUMNS_TOOLTIP = {
  'sales_promo_code': false,
  'sales_count': false,
  'sales_turnover': false,
  'sales_average_basket': true,
  'sales_roas': true,
  'sales_roi': true,
};
