import React, { Component, PropsWithChildren } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { logError } from 'actions/env';

type Props = PropsWithChildren<{
  fallback?: React.ReactNode;
  onError?: (error: Error) => void;
  actions: {
    logError: typeof logError;
  };
}>;

type State = {
  hasError: boolean;
  error: Error | null;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error-reporting service
    const {
      actions: { logError },
      onError,
    } = this.props;
    logError(error, info);
    if (onError) onError(error);
  }

  render() {
    const { fallback } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return fallback || null;
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ logError }, dispatch),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
