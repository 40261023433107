import React, { PropsWithChildren, createElement, forwardRef } from 'react';
import cn from 'classnames';

import styles from './Typography.module.scss';

// INFO: Just add a variant here
const VARIANTS = [
  "label/default",
  "label/large",
  'title/page-title',
  'title/subtitle',
  'title/modal-title',
  'title/sidepanel-title',
  'title/section-title-S',
  'title/section-title-M',
  'title/tile-title',
  'title/tile-subtitle',
  'text/body small',
  'text/body small bold',
  'text/body medium',
  'text/body medium bold',
  'text/body large',
  'text/body large bold',
  'kol/identity',
  'kol/small-identity',
  'kol/username',
  'kol/small-username',
] as const;

// INFO: This will show an error if the style for a variant is missing
const VARIANT_STYLES: Record<(typeof VARIANTS)[number], string> = {
  'label/default': styles.labelDefault,
  'label/large': styles.labelLarge,
  'title/page-title': styles.titlePageTitle,
  'title/subtitle': styles.titleSubtitle,
  'title/modal-title': styles.titleModalTitle,
  'title/sidepanel-title': styles.titleSidePanelTitle,
  'title/section-title-S': styles.titleSectionTitleS,
  'title/section-title-M': styles.titleSectionTitleM,
  'title/tile-title': styles.titleTileTitle,
  'title/tile-subtitle': styles.titleTileSubtitle,
  'text/body small': styles.textBodySmall,
  'text/body small bold': styles.textBodySmallBold,
  'text/body medium': styles.textBodyMedium,
  'text/body medium bold': styles.textBodyMediumBold,
  'text/body large': styles.textBodyLarge,
  'text/body large bold': styles.textBodyLargeBold,
  'kol/identity': styles.kolIdentity,
  'kol/small-identity': styles.kolSmallIdentity,
  'kol/username': styles.kolUsername,
  'kol/small-username': styles.kolSmallUsername,
};

type Props = PropsWithChildren<{
  tag?: string;
  label?: string;
  variant?: (typeof VARIANTS)[number];
  className?: string;
} & React.HTMLAttributes<HTMLElement>>;

const Typography: React.FC<Props> = forwardRef(
  ({ tag = 'span', label, variant, children, className, ...rest }, ref) => {
    const cnSpan = cn(
      styles.text,
      className,
      variant && VARIANT_STYLES[variant],
    );

    return createElement(
      tag,
      {
        ...rest,
        className: cnSpan,
        ref,
      },
      children || label,
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
