import React, { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';

const WarningNoInternet = memo(() => {
  return (
    <div className="warning-no-internet">
      <CircularProgress size={20} style={{ color: 'white' }} />
      <span>
        <FormattedMessage id="errorModale.noInternet" />
      </span>
    </div>
  );
});

export default WarningNoInternet;
