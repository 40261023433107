import { useTheme } from '@mui/material';
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import useModal from 'utils/hooks/useModal';

const renderModal = (modalID: string, ModalComponent: any) => {
  return Object.assign(
    props => {
      const {
        transitions: {
          duration: { leavingScreen },
        },
      } = useTheme();

      const { show, off } = useModal(modalID);
      const [shoudBeMounted, setShoudBeMounted] = useState(false);

      useLayoutEffect(() => {
        if (show) {
          setShoudBeMounted(true);
        } else if (shoudBeMounted) {
          setTimeout(() => setShoudBeMounted(false), leavingScreen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [show]);

      // Force to close modal when the location changes - eg: when the user clicks on the browser back button
      const location = useLocation();
      useEffect(() => {
        if (show) {
          off();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [location]
    );

      return shoudBeMounted ? <ModalComponent {...props} /> : null;
    },
    { displayName: ModalComponent.displayName || 'renderModalHOC' },
  );
};

export default renderModal;
