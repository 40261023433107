import { createAction } from 'redux-actions';

import * as api from 'api/myInfluencers';
import * as apiProfiles from 'api/profiles'
import mapper from 'mappers/myInfluencers';

import { batch, loadLists, updateFieldsToProfile, updateProfilesToLists } from 'actions/profiles';
import { setControlledLoader } from 'actions/ui';
import * as MYINFLUENCERS from 'constants/myInfluencers';
import logError from 'utils/logError';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import { dataHeader } from 'components/myInfluencers/config/tableHeader';

export const update = createAction(MYINFLUENCERS.UPDATE);
export const updateKey = createAction(MYINFLUENCERS.UPDATE_KEY);
export const updateFilters = createAction(MYINFLUENCERS.UPDATE_FILTERS);
export const updateSettings = createAction(MYINFLUENCERS.UPDATE_SETTINGS);
export const updateRows = createAction(MYINFLUENCERS.UPDATE_ROWS);

const getProfilesListAction = createAction(MYINFLUENCERS.LOAD_PROFILES_LIST);
const updateProfilesListAction = createAction(MYINFLUENCERS.UPDATE_PROFILES_LIST);

export const handleTagOperator = createAction(MYINFLUENCERS.HANDLE_TAG_OPERATOR);
export const resetActivatedFilter = createAction(MYINFLUENCERS.HANDLE_RESET_ACTIVATED_FILTER);

export const setSearchQuery = createAction(MYINFLUENCERS.SEARCH_QUERY);
export const setUserPreferences = createAction(MYINFLUENCERS.SET_PROFILE_LIST_PREFERENCES);

const updateAccountRequestParamsAction = createAction(MYINFLUENCERS.UPDATE_ACCOUNT_REQUESTS_PARAMS);
const updateAccountRequestAction = createAction(MYINFLUENCERS.UPDATE_ACCOUNT_REQUESTS_LIST);
export const appendToAccountRequestAction = createAction(MYINFLUENCERS.APPEND_TO_ACCOUNT_REQUESTS_LIST);

export const updateCustomListAction = createAction(MYINFLUENCERS.UPDATE_CUSTOM_LIST);
const updateCustomFieldAction = createAction(MYINFLUENCERS.UPDATE_CUSTOM_FIELDS);

const setAudienceOverapStatus = createAction(MYINFLUENCERS.SET_A_OVERLAP_STATUS);
const setAudienceOverapStatistcs = createAction(MYINFLUENCERS.SET_A_OVERLAP_STATISTICS);
export const updateAudienceOverapBrand = createAction(MYINFLUENCERS.UPDATE_A_OVERLAP_BRAND);


export const getAllProfilesIds = ({ param = {} }) => async (dispatch, getState) => {
	const { myInfluencers: { filters } } = getState();

	dispatch(setControlledLoader({ id: 'getProfilesList', show: true }));

	const getParam = { ...filters, ...param };
	const response = await api.getAllProfilesIds(getParam);

	dispatch(setControlledLoader({ id: 'getProfilesList', show: false }));
	return response;
}

export const setUserAggregateDefault = ({ columnId, value }) => (dispatch, getState) => {
	const { columns } = getState()?.myInfluencers?.settings;
	const dataUpdated = {
		...columns,
		[columnId]: {
			...columns[columnId],
			aggregateDefault: value
		}
	};
	dispatch(updateSettingsColumns(Object.values(dataUpdated)));
}

export const getSettingsColumns = () => async (dispatch) => {
	dispatch(setControlledLoader({ id: 'getSettingsColumns', show: true }));
	const response = await api.getSettingsColumns();
	const defaultColumns = dataHeader
		.filter(data => !data.disableOrder)
		.map(({ dataApi, order, visible, aggregate}) => ({
			name: dataApi,
			order,
			visible,
			aggregateDefault: aggregate.default,
		}));
	const columns = typeof response?.columns === 'string'
		? JSON.parse(response?.columns)
			?.filter(Boolean)
			?.map(item => ({
				name: item.name,
				visible: item.is_visible,
				aggregateDefault: item.aggregate_default,
				order: item.order,
			}))
		: defaultColumns;

	dispatch(updateSettings({
		columns: columns.reduce((prev, col) => ({
			...prev,
			[col.name]: col
		}), {})
	}));
	dispatch(setControlledLoader({ id: 'getSettingsColumns', show: false }));
	return columns;
}

export const updateSettingsColumns = (data=[]) => async (dispatch) => {
	dispatch(setControlledLoader({ id: 'updateSettingsColumns', show: true }));
	dispatch(updateSettings({
		columns: data.reduce((prev, curr) => ({
			...prev,
			[curr.name]: curr
		}), {})
	}));

	const dataToApi = data.map(item => ({
		name: item.name,
		aggregate_default: item.aggregateDefault,
		order: item.order,
		is_visible: item.visible,
	}), []);
	await api.updateSettingsColumns(dataToApi);
	dispatch(setControlledLoader({ id: 'updateSettingsColumns', show: false }));
	return true;
}

export const updateAccountRequestParams = (params) => dispatch => {
	dispatch(updateAccountRequestParamsAction(params))
}

export const getAccountCreationRequests = () => async (dispatch, getState) => {
	dispatch(setControlledLoader({ id: 'getAccountCreationRequests', show: true }));
	const {
		myInfluencers: {
			account_creation_requests: { page, per_page, status },
		},
	} = getState();
	try {
		const response = await api.getAccountCreationRequests({ page, per_page, status });
		dispatch(updateAccountRequestAction(response));
	} catch (e) {
		logError(e, { page: 'IRM', action: 'getAccountCreationRequests' })
	} finally {
		dispatch(setControlledLoader({ id: 'getAccountCreationRequests', show: false }));
	}
};

/**
 *
 * LIST
 */

export const getProfilesList = () => async (dispatch, getState) => {
	const {
		myInfluencers: { filters, fields }
	} = getState();

	dispatch(setControlledLoader({ id: 'getProfilesList', show: true }));

	const filtersToApi = mapper.getProfilesList.toApi(filters);

	const response = await api.getProfilesList({
		...filtersToApi,
		active_kols_list: filters.active_kols_list
	});
	const mappedResponse = mapper.getProfilesList.fromApi({ ...response, fields });

	if (filters?.page === 0) {
		dispatch(getProfilesListAction(mappedResponse));
	} else {
		dispatch(updateProfilesListAction(mappedResponse));
	}

	const legacyMappedResponse = mapper.loadLegacyProfiles.fromApi({
		rows: response?.rows
	});
	dispatch(batch(legacyMappedResponse));

	dispatch(setControlledLoader({ id: 'getProfilesList', show: false }));
}

export const getCustomList = () => async dispatch => {
	dispatch(setControlledLoader({ id: 'getCustomLists', show: true }));

	const list = await api.getCustomList();

	await dispatch(updateCustomListAction({ data: list || [] }));

	await Promise.all(
		list?.map((item) =>
			dispatch(loadLists({ listIds: [item?.id], kolIds: item?.kols_ids })),
		),
	);

	dispatch(setControlledLoader({ id: 'getCustomLists', show: false }));
}

export const updateCustomList = ({ name, action, listId, intl, toaster, profileIds, project_ids }) => async (dispatch) => {
	dispatch(setControlledLoader({ id: 'updateCustomList', show: true }));

	let data = null;

	if (action === 'create') {
		data = await api.createCustomList(name, project_ids);
	}

	if (action === 'edit') {
		data = await api.updateCustomList({ listId, name, project_ids });
	}

	if (action === 'delete') {
		data = await api.deleteCustomList(listId);
	}

	if (data?.status === 422) {
		const intl = getIntl();
		toast(
			intl.formatMessage({ id: "lists.duplicatedError"}),
			{ type: 'error' }
		);

		dispatch(setControlledLoader({ id: 'updateCustomList', show: false }));

		return null;
	}

	if (data) {
		dispatch(updateCustomListAction({
			data,
			listId,
			action
		}));

		if (profileIds) {
			dispatch(updateProfilesToLists({
				profileIds: [profileIds].flat(),
				listIds: [data?.id],
				action: 'add',
			}));
		}

		if (intl) {
			toast(intl, {
				title: 'Success',
				type: 'success',
			});
		}

		if (toaster) {
			toast(toaster, {
				title: 'Success',
				type: 'success',
			});
		}
	}

	dispatch(setControlledLoader({ id: 'updateCustomList', show: false }));

	return true;
}

export const refreshProfilesList = () => dispatch => {
	// To test if needed or not
	dispatch(getCustomFields());
	dispatch(updateFilters({
		page: 0,
	}));
	dispatch(getProfilesList());
}


/**
 *
 * FIELDS
 */

export const getCustomFields = () => async dispatch => {
	dispatch(setControlledLoader({ id: 'getCustomFields', show: true }));
	const data = await api.getCustomFields();
	dispatch(updateCustomFieldAction({ data }));
	dispatch(setControlledLoader({ id: 'getCustomFields', show: false }));
}

export const updateCustomFields = ({ name, action, fieldId, type, profileId }) => async (dispatch) => {
	dispatch(setControlledLoader({ id: 'updateCustomFields', show: true }));

	let data = null;

	if (action === 'create') {
		data = await api.createCustomField({ name, type });
	}

	if (action === 'edit') {
		data = await api.updateCustomField({ fieldId, name });
	}

	if (action === 'delete') {
		data = await api.deleteCustomField(fieldId);
	}

	const intl = getIntl();
	if (data?.status === 422) {
		toast(
			intl.formatMessage({ id: "customFields.create.error"}),
			{ type: 'error' }
		);
		dispatch(setControlledLoader({ id: 'updateCustomFields', show: false }));
		return null;
	}

	if (data) {
		dispatch(updateCustomFieldAction({ data, fieldId, action }));
		const phraseSuccessMessageId = action === "create"
			? "customFields.create.success"
			: action === "edit"
				? "customFields.modified.success"
				: action === "delete"
					? "customFields.remove.success"
					: null;
		if (phraseSuccessMessageId) {
			toast(
				intl.formatMessage(
					{ id: phraseSuccessMessageId },
					{ custom_field_name: name }
				),
				{ type: 'success' }
			);
		}
	}

	if (profileId) {
		dispatch(updateFieldsToProfile({
			profileId,
			fields: { [data?.custom_field_id]: data?.name },
		}))
	}

	dispatch(setControlledLoader({ id: 'updateCustomFields', show: false }));

	return true;
}

export const toggleShowOnlySelected = profileIds => (dispatch, getState) => {
	const { filters: { onlyShowSelected }} = getState().myInfluencers;
	const newIsShowOnly = !onlyShowSelected;
	dispatch(updateFilters({ onlyShowSelected: newIsShowOnly }));
	dispatch(getAggregate(profileIds));
}

/**
 *
 * AGGREGATE
 */

export const getAggregate = profileIds => async (dispatch, getState) => {
	dispatch(setControlledLoader({ id: 'getAggegateProfiles', show: true }));

	const {
		fields,
		selectedProfiles,
		filters: {
			activatedFilter,
			onlyShowSelected,
			contactIds,
			tagsIds,
			listId,
			tagOperator,
		},
	} = getState().myInfluencers;

	const getProfileIds = (!profileIds || profileIds?.length === 0) ? selectedProfiles : profileIds;

	const params = Object.assign({},
		onlyShowSelected && { profileIds: getProfileIds },
		!onlyShowSelected && { listId },
		!onlyShowSelected && !listId && { contactIds },
		activatedFilter && { tagOperator },
		activatedFilter && tagsIds && { tagsIds },
	);

	const aggregate = await apiProfiles.getAggregateData(params);

	dispatch(update({
		key: 'aggregate',
		// eslint-disable-next-line import/no-named-as-default-member
		data: mapper.aggregateData.fromApi({
			fields,
			aggregate
		})
	}));

	dispatch(setControlledLoader({ id: 'getAggegateProfiles', show: false }));
}

export const getAudienceOverlapStatus = () => async (dispatch, getState) => {
	const { listId } = getState()?.myInfluencers.filters;
	dispatch(setControlledLoader({ id: 'getAudienceOverlap', show: true }));

	const params = mapper.audienceOverlapStatus.toApi();
	const response = await api.getAudienceOverlapStatus({ listId, params });
	const status = mapper.audienceOverlapStatus.fromApi(response?.status === 422 ? response?.payload : response);
	dispatch(setAudienceOverapStatus(status));

	dispatch(setControlledLoader({ id: 'getAudienceOverlap', show: false }));
}

export const getAudienceOverlapStatistics = () => async (dispatch, getState) => {
	const { listId } = getState()?.myInfluencers.filters;
	dispatch(setControlledLoader({ id: 'getAudienceOverlap', show: true }));

	const response = await api.getAudienceOverlapStatistics(listId);
	const statistics = mapper.audienceOverlapStatistics.fromApi(response);
	dispatch(setAudienceOverapStatistcs(statistics));

	dispatch(setControlledLoader({ id: 'getAudienceOverlap', show: false }));
}

export const createOrUpdateAudienceOverlap = () => async (dispatch, getState) => {
	const { filters: { listId }, audienceOverlap: { myBrandAnalysis }} = getState()?.myInfluencers;
	dispatch(setControlledLoader({ id: 'createOrUpdateAudienceOverlap', show: true }));

	const params = mapper.audienceOverlapStatistics.toApi({
		mention: myBrandAnalysis?.mention,
	})
	await api.createOrUpdateAudienceOverlap({ listId, params });

	setTimeout(async () => {
		await dispatch(getAudienceOverlapStatus());
		await dispatch(getAudienceOverlapStatistics());
		dispatch(setControlledLoader({ id: 'createOrUpdateAudienceOverlap', show: false }));
	}, 8000);
}

export const exporAudienceOverlaResults = (listId) => async(dispatch) => {
	dispatch(setControlledLoader({ id: 'exportAudienceOverlap', show: true }));
	await api.exporAudienceOverlaResults(listId);
	dispatch(setControlledLoader({ id: 'exportAudienceOverlap', show: false }));
}