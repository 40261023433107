import { FRONTEND_DATE_FORMAT } from 'config/app';
import routes from 'config/routes';
import history from 'config/history';

const generateListeningUrl = (monitoringId, dateRanges) => {
  const generatedUrl = `/${routes.listening}/${monitoringId}/${dateRanges?.startDate?.format(
    FRONTEND_DATE_FORMAT,
  )}/${dateRanges?.endDate?.format(FRONTEND_DATE_FORMAT)}`;

  const currentParams = new URLSearchParams(history.location.search);
  const contact_id = currentParams?.get('contact_id');
  return contact_id
    ? `${generatedUrl}?contact_id=${contact_id}`
    : generatedUrl
}

export default generateListeningUrl;
