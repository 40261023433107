import { get, post, remove, put } from './index';

import mapper from '../mappers/engine';

export const doSearch = query => get({
	endpoint: `influence/search`,
	params: mapper.doSearch.toApi(query),
});

export const saveSearch = data => post({
	endpoint: 'influence/searches',
	params: data,
});

export const updateSearch = (id, data) => put({
	endpoint: `influence/searches/${id}`,
	params: data,
});

export const deleteSearch = id => remove({
	endpoint: `influence/searches/${id}`,
});

export const getSavedSearches = () => get({
	endpoint: 'influence/searches',
	param: {},
	debug: [{
		id: 3,
		name: 'food',
		query: 'q=%7B%22filters%22%3A%7B%22search%22%3A%5B%22food%22%5D%2C%22sid%22%3A%227wKq3mzu2%22%7D%2C%22sortBy%22%3A%22relevance%22%7D'
	}]
});
