import React, { memo, useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl-phraseapp';

import { Li } from 'kolkit';

import { useSelector } from 'utils/redux';
import useZendeskLink from 'utils/hooks/useZendeskLink';
import * as viewValidator from 'validators/views';

import { LocalesSwitcher } from 'components/modules';
import styles from './Footer.module.scss';

const Footer = () => {
  const intl = useIntl();
  const [hideFooter, setHideFooter] = useState(false)

  const faqLink = useZendeskLink()
  const location = useLocation();

  const { userCanViewFaq, isLoggedIn } = useSelector( ({ user }) => ({
    isLoggedIn: !!user.profile?.id,
    userCanViewFaq: user.profile?.licence?.isActive,
  }));

  const config = useMemo(
    () => {
      const elements = [
        {
          href: intl.formatMessage({ id: 'navbar.about.link' }),
          label: intl.formatMessage({ id: 'navbar.about.label' }),
        },
        {
          href: intl.formatMessage({ id: 'navbar.legalMention.link' }),
          label: intl.formatMessage({ id: 'navbar.legalMention.label' }),
        },
        {
          href: intl.formatMessage({ id: 'navbar.blog.link' }),
          label: intl.formatMessage({ id: 'navbar.blog.label' }),
        },
        {
          href: intl.formatMessage({ id: 'navbar.privacyPolicy.link' }),
          label: intl.formatMessage({ id: 'navbar.privacyPolicy.label' }),
        },
        {
          href: intl.formatMessage({ id: 'navbar.cgu.link' }),
          label: intl.formatMessage({ id: 'navbar.cgu.label' }),
        }
      ];

      if (userCanViewFaq) {
        return [
          {
            href: faqLink,
            label: intl.formatMessage({ id: 'navbar.faq.label' })
          },
          ...elements
        ]
      }

      return elements;
    },
    [intl, userCanViewFaq, faqLink]
  );

  useEffect(() => {
    setHideFooter(
      viewValidator.isMessagingPage() ||
        viewValidator.isCampaignMessagingPage() ||
        viewValidator.isMyInfluencersPage(),
    );
  }, [location.pathname])

  return !hideFooter && (
    <footer className={styles.footer}>
      <div className={styles.logo}>
        <img
          src="/logo/logo-horizontal-navy.svg"
          alt="Kolsquare"
        />
      </div>
      <ul className='flex'>
        {config.map(item => (
          <Li className={styles.item} key={item.href}>
            <a href={item.href} target="_blank" rel="noopener noreferrer">
              {item.label}
            </a>
          </Li>
        ))}
      </ul>
      {/** Display only if user is not connected */}
      {!isLoggedIn && (
        <div className={styles.localesSwitcher}>
          <LocalesSwitcher asFlags />
        </div>
      )}
    </footer>
  );
}

export default memo(Footer);
