import React, { memo } from 'react';
import ReactZendesk, { ZendeskAPI } from 'react-zendesk';

import logError from 'utils/logError';

import { KOLSQUARE_ZENDESK_KEY } from './config/zendeskConfig';


export const safeZendeskAPICall = async (widget, method, ...args) => {
  try {
    ZendeskAPI(widget, method, ...args);
  } catch (error) {
    console.error(`Error calling Zendesk API method ${method}:`, error);
    logError(error, {
      api: `Zendesk - widget: ${widget} - method: ${method}`
    });
  }
};

const ZendeskScript = () => {
  return (
    <ReactZendesk
      defer
      zendeskKey={KOLSQUARE_ZENDESK_KEY}
    />
  );
}

export default memo(ZendeskScript);
