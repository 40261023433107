import React, { Children, useCallback, useEffect, useMemo } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Icon, IconNavbar } from 'kolkit';

import { updateFilters } from 'actions/campaigns';
import { promptCreateNewCampaign } from 'actions/ui';
import { setUnreadMessages } from 'actions/messaging'

import routes from 'config/routes';
import { CHANNELS } from 'config/actioncable';

import * as featureNames from 'constants/feature-names';

import { clearAllFilters as clearRegularSearchFilters } from 'slices/search/searchEngine.slice';
import { clearAllFilters as clearContentSearchFilters } from 'slices/search/contentSearch.slice';
import useActioncableSubscription from 'utils/hooks/useActioncableSubscription';
import { useDispatch, useSelector } from 'utils/redux';
import useViews from 'utils/hooks/useViews';
import { isFeatureActive } from 'utils/feature-toggle';
import { getUserRightsOnTeam } from 'utils/rights';
import useModal from 'utils/hooks/useModal';

import UserBadge from './UserBadge';
import HelperBadge from './HelperBadge';

import styles from './Navbar.module.scss';

const Navbar = () => {
  const intl = useIntl()
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    isMyInfluencersPage,
    isCampaignPage,
    isMessagingPage,
    isListeningDetailsPage,
    isDashboardPage,
  } = useViews();

  const {
    isAdmin,
    userRights,
    allUnreadMessagesCount,
    notifications,
  } = useSelector(({ user, messaging }) => ({
    isAdmin: user.profile.admin,
    userRights: getUserRightsOnTeam(user.role),
    allUnreadMessagesCount: messaging?.inbox?.allUnreadMessagesCount,
    notifications: user.initialWsData.notifications,
  }));

  const { show: isCreateCampaignModalOpen } = useModal('campaignModalForm');
  const { on: openSearchCampaign } = useModal('searchExistingList');

  useEffect(() => {
    if (notifications) dispatch(setUnreadMessages(notifications));
    // eslint-disable-next-line
  }, [notifications]);

  useActioncableSubscription({
    channel: CHANNELS.NOTIFICATION,
    onDataReceived: data => data && dispatch(setUnreadMessages(data)),
  });

  const resetRegularSearch = useCallback(() => {
    dispatch(clearRegularSearchFilters());
  }, [dispatch]);

  const resetContentSearch = useCallback(() => {
    dispatch(clearContentSearchFilters());
  }, [dispatch]);

  const resetCampaignsFilter = useCallback(
    () => {
      dispatch(
        updateFilters({
          byStates: ['draft', 'ready', 'started', 'finished'],
        }),
      );
    },
    [dispatch]
  );

  const handleCreateCampaign = useCallback(
    () => dispatch(promptCreateNewCampaign({ from: 'header' })),
    [dispatch]
  );

  const onClickButtonList = useCallback(
    () => {
      if (isAdmin) openSearchCampaign();
      else handleCreateCampaign();
    },
    [isAdmin, openSearchCampaign, handleCreateCampaign]
  );

  const menus = useMemo(() => {
    return [
      {
        id: 'search',
        icon: 'telescope',
        to: '/',
        disabled: !isFeatureActive(featureNames.SEARCH_ENGINE),
        label: intl.formatMessage({ id: 'navbar.search' }),
        exact: true,
        isActive: pathname === '/',
        onClick: resetRegularSearch,
      },
      {
        id: 'contentSearch',
        icon: 'content-search',
        to: `/${routes.contentSearch}`,
        disabled: !isFeatureActive(featureNames.SEARCH_ENGINE),
        label: 'Content search',
        exact: false,
        isActive:
          window.location.pathname.indexOf(`/${routes.contentSearch}`) === 0,
        hide: !isFeatureActive(featureNames.SEARCH_ENGINE, {
          onlyForCompanyZZZ: true,
        }),
        onClick: resetContentSearch,
      },
      {
        id: 'myInfluencers',
        icon: 'users',
        to: `/${routes.myKOLs}`,
        disabled: !isFeatureActive({
          any: [featureNames.FAVORITES, featureNames.LABELS],
        }),
        label: intl.formatMessage({ id: 'navbar.myKols' }),
        exact: false,
        hide: isAdmin,
        isActive: isMyInfluencersPage,
      },
      {
        id: 'campaigns',
        icon: 'folders',
        to: `/${routes.campaigns}`,
        disabled: !isFeatureActive(featureNames.MANAGE_CAMPAIGN),
        label: intl.formatMessage({ id: 'navbar.campaigns' }),
        exact: false,
        hide: isAdmin,
        onClick: resetCampaignsFilter,
        isActive: isCampaignPage,
      },
      {
        id: 'messaging',
        icon: 'comments',
        to: `/${routes.messaging}`,
        disabled: !isFeatureActive(featureNames.MANAGE_CAMPAIGN),
        label: intl.formatMessage({ id: 'navbar.messaging' }),
        exact: false,
        hide: isAdmin,
        badge: allUnreadMessagesCount,
        isActive: isMessagingPage,
      },
      {
        id: 'watch',
        icon: 'chart-pie',
        to: `/${routes.dashboard}`,
        label: intl.formatMessage({ id: 'navbar.reports' }),
        exact: false,
        isActive: isListeningDetailsPage || isDashboardPage,
        hide: isAdmin,
      },
    ];
  }, [
    intl,
    pathname,
    resetRegularSearch,
    resetContentSearch,
    isAdmin,
    isMyInfluencersPage,
    resetCampaignsFilter,
    isCampaignPage,
    allUnreadMessagesCount,
    isMessagingPage,
    isListeningDetailsPage,
    isDashboardPage,
  ]);

  return (
    <div className={styles.navbar}>
      <Link to="/" className={styles.logo} onClick={resetRegularSearch}>
        <img src="/logo/logo-favicon-navy.svg" alt="Kolsquare" />
      </Link>
      <ul className={styles.menu}>
        {Children.toArray(
          menus.map(item => {
            const {
              id,
              icon,
              badge,
              to,
              disabled,
              label,
              exact,
              hide,
              onClick,
              isActive,
            } = item;

            if (hide) return null;

            const cnItem = ({ isActive }) =>
              cn(styles.item, {
                [styles.disabled]: disabled,
                [styles.active]: isActive,
              });

            return (
              <NavLink
                end={exact}
                to={to}
                className={cnItem}
                onClick={onClick}
              >
                <IconNavbar
                  label={icon}
                  id={id}
                  size="huge"
                  selected={isActive}
                  isButton
                  notification={badge}
                  disabled={disabled}
                  className={styles.itemContent}
                  tooltip={label}
                  tooltipPosition="right"
                />
              </NavLink>
            );
          }),
        )}
      </ul>
      <div className={styles.createCampaign}>
        {userRights.createCampaign && (
          <Icon
            label="folder-plus"
            size="huge"
            onClick={onClickButtonList}
            disabled={!isFeatureActive(featureNames.MANAGE_CAMPAIGN)}
            className={styles.itemContent}
            tooltip={intl.formatMessage({ id: isAdmin ? 'navbar.selectCampaign':'navbar.addCampaign'})}
            tooltipPosition="right"
            isButton
            active={isCreateCampaignModalOpen}
          />
        )}
      </div>
      <div className={styles.bottom}>
        <HelperBadge />
        <UserBadge />
      </div>
    </div>
  )
};

export default Navbar;
