import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'utils/redux';

// Static json files generated by apiPhrase on each build
import phraseActivitiesFR from 'locales/activities/fr-FR.json';
import phraseActivitiesEN from 'locales/activities/en-US.json';
import phraseActivitiesDE from 'locales/activities/de-DE.json';
import phraseActivitiesES from 'locales/activities/es-ES.json';
import phraseActivitiesIT from 'locales/activities/it-IT.json';

const locales = {
  'fr': phraseActivitiesFR,
  'en': phraseActivitiesEN,
  'de': phraseActivitiesDE,
  'es': phraseActivitiesES,
  'it': phraseActivitiesIT,
};

const useActivitiesIntl = () => {
  const intl = useIntl();
  const locale = useSelector(({ env: { locale } }) => locale.slice(0, 2));

  const translations = defineMessages(Object.entries(locales[locale]).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        id: key,
        defaultMessage: value,
      },
    }
  }, {}));

  const formatMessage = useCallback(
    ({ id, ...values }) => {
      return translations[id]
        ? intl.formatMessage(translations[id], values)
        : id;
    },
    [intl, translations],
  );

  const renderActivities = useCallback(
    ({ activities, gender, max }) => {
      if (activities?.length === 0) return '';
      return activities
        ?.slice(0, max || 3)
        ?.map(activityId => formatMessage({ id: activityId, gender }))
        ?.join(', ')
    },
    [formatMessage]
  );

  return {
    activitiesKeys: Object.keys(locales[locale]),
    formatMessage,
    renderActivities,
  };
};

export const TREE = [
  {
    label: 'sports',
    children: [
      {
        label: 'athletics',
        children: [
          { label: 'track_race', children: null },
          { label: 'medium_distance_running', children: null },
          { label: 'sprint_race', children: null },
          { label: 'long_jump', children: null },
          { label: 'triple_jump', children: null },
          { label: 'pole_vaulting', children: null },
          { label: 'race_walking', children: null },
          { label: 'cross_country_running', children: null },
          { label: 'low_hurdle_race', children: null },
          { label: 'high_hurdle_race', children: null },
          { label: 'other_athletics', children: null },
        ],
      },
      {
        label: 'automobile',
        children: [
          { label: 'rally', children: null },
          { label: 'formula_1', children: null },
          { label: 'formula_3000', children: null },
          { label: 'endurance', children: null },
        ],
      },
      {
        label: 'rowing',
        children: [
          { label: 'skiff', children: null },
          { label: 'coxless_pairs', children: null },
        ],
      },
      { label: 'badminton', children: null },
      { label: 'basketball', children: null },
      {
        label: 'boxing',
        children: [
          { label: 'english_boxing', children: null },
          { label: 'thai_boxing', children: null },
          { label: 'kickboxer', children: null },
        ],
      },
      {
        label: 'canoeying',
        children: [
          { label: 'line_racing', children: null },
          { label: 'slalom', children: null },
        ],
      },
      {
        label: 'orienteering_race',
        children: [{ label: 'running', children: null }],
      },
      {
        label: 'skiing',
        children: [
          { label: 'freestyle_ski', children: null },
          { label: 'alpine_ski', children: null },
          { label: 'cross_country_skiing', children: null },
          { label: 'biathlon', children: null },
          { label: 'nordic_combined', children: null },
          { label: 'snowboard', children: null },
          { label: 'ski_acrobatics', children: null },
        ],
      },
      {
        label: 'cycling',
        children: [
          { label: 'mountain_biking', children: null },
          { label: 'road_cycling', children: null },
          { label: 'track_cycling', children: null },
          { label: 'bmx', children: null },
        ],
      },
      {
        label: 'horse_riding',
        children: [
          { label: 'show_jumping', children: null },
          { label: 'polo', children: null },
          { label: 'jockey', children: null },
        ],
      },
      {
        label: 'fencing',
        children: [
          { label: 'sword', children: null },
          { label: 'foil', children: null },
          { label: 'saber', children: null },
        ],
      },
      {
        label: 'underwater_sports_and_studies',
        children: [{ label: 'snorkelling', children: null }],
      },
      { label: 'football', children: null },
      { label: 'american_football', children: null },
      { label: 'golf', children: null },
      {
        label: 'gymnastics',
        children: [
          { label: 'artistic_gymnastics', children: null },
          { label: 'rythmic_gymnastics', children: null },
        ],
      },
      { label: 'weight_lifting', children: null },
      { label: 'handball', children: null },
      {
        label: 'disabilities_sports',
        children: [
          { label: 'disabilities_athletics', children: null },
          { label: 'disabilities_basketball', children: null },
          { label: 'disabilities_cycling', children: null },
          { label: 'disabilities_swimming', children: null },
          { label: 'disabilities_alpine_ski', children: null },
          { label: 'disabilities_nordic_ski', children: null },
          { label: 'disabilities_tennis', children: null },
          { label: 'disabilities_pingpong', children: null },
          { label: 'disabilities_kayaking', children: null },
          { label: 'disabilities_triathlon', children: null },
        ],
      },
      { label: 'hockey', children: null },
      { label: 'judo', children: null },
      {
        label: 'fighting',
        children: [
          { label: 'greco_roman_wrestling', children: null },
          { label: 'free_wrestling', children: null },
        ],
      },
      {
        label: 'mountains_and_climbing',
        children: [
          { label: 'climbing', children: null },
          { label: 'ski_mountaineering', children: null },
          { label: 'multisport_raid', children: null },
          { label: 'trail', children: null },
        ],
      },
      {
        label: 'motocycling',
        children: [
          { label: 'moto_cross', children: null },
          { label: 'freestyle_motobiking', children: null },
        ],
      },
      {
        label: 'swimming',
        children: [
          { label: 'pool_swimming', children: null },
          { label: 'freewater_swimming', children: null },
          { label: 'water_polo', children: null },
          { label: 'synchromized_swimming', children: null },
          { label: 'diving', children: null },
        ],
      },
      { label: 'modern_pentathlon', children: null },
      { label: 'roller_skating', children: null },
      {
        label: 'rescue_and_first_aid',
        children: [
          { label: 'cost_guard', children: null },
          { label: 'surf_boat', children: null },
        ],
      },
      { label: 'water_ski', children: null },
      {
        label: 'ice_sports',
        children: [
          { label: 'figure_skating', children: null },
          { label: 'ice_dancing', children: null },
          { label: 'speed_skating', children: null },
        ],
      },
      { label: 'squash', children: null },
      { label: 'surfing', children: [{ label: 'longboard', children: null }] },
      { label: 'taekwondo', children: null },
      { label: 'tennis', children: null },
      { label: 'pingpong', children: null },
      {
        label: 'shooting',
        children: [
          { label: 'pistol_shooting', children: null },
          { label: 'clay_shooting', children: null },
        ],
      },
      {
        label: 'archery',
        children: [{ label: 'target_shooting', children: null }],
      },
      { label: 'triathlon', children: null },
      {
        label: 'sailing',
        children: [
          { label: 'windsurfing', children: null },
          { label: 'match_racing', children: null },
          { label: 'ocean_racing', children: null },
          { label: 'kitesurf', children: null },
        ],
      },
      { label: 'free_flight', children: null },
      { label: 'volleyball', children: null },
      { label: 'jet_ski', children: null },
      {
        label: 'rugby',
        children: [
          { label: '15_player_rugby', children: null },
          { label: '13_player_rugby', children: null },
          { label: '7_player_rugby', children: null },
        ],
      },
      {
        label: 'fitness',
        children: [{ label: 'fitness_athlete', children: null }],
      },
      { label: 'fitness_coach', children: null },
      { label: 'matador', children: null },
      {
        label: 'urban_glide',
        children: [
          { label: 'skateboard', children: null },
          { label: 'scooter', children: null },
        ],
      },
      { label: 'e_sport', children: null },
      { label: 'cricket', children: null },
      { label: 'baseball', children: null },
    ],
  },
  {
    label: 'trends_and_fashion',
    children: [
      { label: 'fashion_designer', children: null },
      { label: 'furniture_designer', children: null },
      { label: 'interior_designer', children: null },
      { label: 'creator', children: null },
      { label: 'model', children: null },
      { label: 'art_director', children: null },
    ],
  },
  {
    label: 'influencer',
    children: [
      { label: 'youtuber', children: null },
      { label: 'influencer_global', children: null },
      { label: 'blogger', children: null },
    ],
  },
  {
    label: 'gastronomy',
    children: [
      { label: 'chef', children: null },
      { label: 'oenologist', children: null },
      { label: 'food_critic', children: null },
    ],
  },
  {
    label: 'art_and_media',
    children: [
      {
        label: 'animator',
        children: [
          { label: 'tv', children: null },
          { label: 'radio', children: null },
        ],
      },
      { label: 'artist', children: null },
      { label: 'painter', children: null },
      {
        label: 'illustrator',
        children: [
          { label: 'tatooist', children: null },
          { label: 'comics_illustrator', children: null },
          { label: 'street_artist', children: null },
        ],
      },
      { label: 'author', children: [{ label: 'writer', children: null }] },
      {
        label: 'actor',
        children: [
          { label: 'fim_actor', children: null },
          { label: 'theatre_actor', children: null },
        ],
      },
      { label: 'humorist', children: [{ label: 'imitator', children: null }] },
      { label: 'journalist', children: null },
      { label: 'director', children: null },
      { label: 'magician', children: null },
      { label: 'photographer', children: null },
      { label: 'fiction_character', children: null },
      { label: 'event_or_wedding_planner', children: null },
      { label: 'reality_tv', children: null },
    ],
  },
  {
    label: 'expertise',
    children: [
      { label: 'architect', children: null },
      { label: 'astronaut', children: null },
      { label: 'editor', children: null },
      { label: 'ceo', children: null },
      { label: 'lawyer', children: null },
      { label: 'scientist', children: null },
      { label: 'philosopher', children: null },
      { label: 'geopolitical_scientist', children: null },
      { label: 'fighter_pilot', children: null },
      { label: 'economist', children: null },
      { label: 'sociologist', children: null },
      { label: 'adventurer', children: null },
      { label: 'army_officer', children: null },
      { label: 'futurist', children: null },
      { label: 'professor', children: null },
      { label: 'political_scientist', children: null },
      { label: 'coach', children: null },
      { label: 'speaker', children: null },
      { label: 'gardener', children: null },
      { label: 'doctor', children: null },
      { label: 'hairdresser', children: null },
      { label: 'makeup_artist', children: null },
      { label: 'marketing_and_communication', children: null },
      { label: 'tech_and_ai', children: null },
      { label: 'management', children: null },
    ],
  },
  {
    label: 'music_and_dancing',
    children: [
      {
        label: 'singer',
        children: [
          { label: 'solo', children: null },
          { label: 'band', children: null },
        ],
      },
      { label: 'choreographer', children: null },
      { label: 'musician', children: null },
      { label: 'composer', children: null },
      { label: 'interpretor', children: null },
      { label: 'dj', children: null },
      {
        label: 'dancer',
        children: [
          { label: 'modern_dance', children: null },
          { label: 'classical_dance', children: null },
          { label: 'ball_dance', children: null },
          { label: 'hiphop_dance', children: null },
        ],
      },
      { label: 'conductor', children: null },
      { label: 'producer', children: null },
    ],
  },
  { label: 'unicorn', children: [{ label: 'politics', children: null }] },
  { label: 'other', children: [{ label: 'licence', children: null }] },
];

export default useActivitiesIntl;
