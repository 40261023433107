/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CaseReducer, createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { UAParser } from 'ua-parser-js';

import currencies, { Currency, DEFAULT_CURRENCY } from 'config/currencies';
import * as ENV from 'constants/env';
import * as USER from 'constants/user';

interface BrowserDetectInfo {
  name?: string;
  version?: string;
  mobile?: boolean;
  os?: string;
}

export interface StateEnv {
  locale: string;
  dateFormat: string;
  timezone?: string;
  currency: {
    label: string;
    value: Currency;
    symbol: string;
    symbolAlwaysAtStart?: boolean;
    rates?: {
      target: Currency;
      rate: number | string;
    }[];
  };
  browser: BrowserDetectInfo;
  viewport: {
    height: number;
    width: number;
  };
  userToken: string;
  hasInternet: boolean;
  newVersionAvailable: boolean;
  newVersionRequired: boolean;
  maintenanceModeActivated: boolean;
  forbiddenActionAlert: boolean;
}

const getDefaultLanguage = () => {
  const lang = navigator.language || navigator.userLanguage;
  if (!lang) return 'en-GB';
  if (lang.includes('fr')) return 'fr-FR';
  if (lang.includes('es')) return 'es-ES';
  if (lang.includes('de')) return 'de-DE';

  return 'en-GB';
};

const uaParser = new UAParser();
const browser: StateEnv['browser'] = {
  mobile: uaParser.getDevice()?.type === 'mobile',
  name: uaParser.getBrowser()?.name?.toLocaleLowerCase(),
  version: uaParser.getBrowser()?.version,
  os: uaParser.getOS()?.name,
};

const intialState: StateEnv = {
  locale: getDefaultLanguage(),
  timezone: undefined,
  currency: {
    ...(currencies as {
      label: string;
      value: Currency;
      symbol: string;
      symbolAlwaysAtStart?: undefined;
    }[]).find(currency => currency.value === DEFAULT_CURRENCY)!,
    rates: [] as StateEnv['currency']['rates'],
  },
  dateFormat: 'dd/mm/yyyy',
  browser,
  viewport: {
    height: 0,
    width: 0,
  },
  userToken: '',
  hasInternet: true,
  newVersionAvailable: false,
  newVersionRequired: false,
  maintenanceModeActivated: false,
  forbiddenActionAlert: false,
};

const reducers: { [key: string]: CaseReducer<StateEnv> } = {
  [ENV.CLIENT_TOKEN_SET]: (state, { payload: token }) =>
    update(state, {
      userToken: { $set: token },
    }),
  [ENV.LOCALE_CHANGED]: (state, { payload: locale }) =>
    update(state, {
      locale: { $set: locale },
    }),
  [USER.PARAMS_LOADED]: (
    state,
    { payload: { locale, currency, timezone, dateFormat } },
  ) =>
    update(state, {
      locale: { $set: locale ?? state.locale },
      currency: { $set: currency ?? state.currency },
      timezone: { $set: timezone ?? state.timezone },
      dateFormat: { $set: dateFormat ?? state.dateFormat },
    }),
  [ENV.SET_INTERNET]: (state, { payload: has }) =>
    update(state, {
      hasInternet: { $set: has },
    }),
  [ENV.NEW_VERSION_AVAILABLE]: state =>
    update(state, {
      newVersionAvailable: { $set: true },
    }),
  [ENV.NEW_VERSION_REQUIRED]: state =>
    update(state, {
      newVersionRequired: { $set: true },
    }),
  [ENV.MAINTENANCE_ACTIVATED]: state =>
    update(state, {
      maintenanceModeActivated: { $set: true },
    }),
  [ENV.FORBIDDEN_ACTION]: state =>
    update(state, {
      forbiddenActionAlert: { $set: true },
    }),

  [ENV.SET_VIEWPORT]: (state, { payload: dimensions }) => {
    state.viewport = dimensions;
  },
  [ENV.UPDATE_KEY]: (state, { payload: { key, data } }) => {
    state[key] = data;
  },
};

export default createReducer(intialState, reducers);
