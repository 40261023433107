import React, { useState } from 'react';
import { Text } from 'kolkit';

import FlatDatePicker, { DateType } from 'components/ui/FlatDatePicker';
import UploadMultiPartDropZone from 'components/ui/UploadMultiPartDropZone';
import { CHUNK_UPLOAD_API } from 'slices/contentValidation.slice';

const DebugPage = () => {
  const [singleDate, setSingleDate] = useState<DateType>();
  const [condensedDates, setCondensedDates] = useState<DateType>();
  const [rangeDates, setRangeDates] = useState<DateType>();

  const handleChangeRange = React.useCallback((dates) => {
    setRangeDates(dates);
  }, []);

  return (
    <div
      style={{
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Text tag="h2">Debug Page</Text>
      <div className="flex fdc gap8">
        <Text resetMargin>Upload files using multi part</Text>
        <UploadMultiPartDropZone
          asButton
          processURL={CHUNK_UPLOAD_API(94837)}
          forceToken="eyJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjU0MjEzLCJtaWQiOm51bGwsInQiOiIyMDI0MDcyOTA5NTc0OSJ9.3MRs1RhpvkFirEtoaNgmkWF3c7iqL028jGyZuUl_k4M"
          forceUrl="https://merry-turtle-obviously.ngrok-free.app"
          allowMultiple
          instantUpload
          appendData={{ public_profile_id: '1952675' }}
        />
      </div>
      <FlatDatePicker
        label="Single date picker"
        value={singleDate}
        onChange={(dates) => {
          if (Array.isArray(dates)) setSingleDate(dates[0]);
        }}
      />
      <FlatDatePicker
        type="range-condensed"
        label="Condensed date range picker"
        value={condensedDates}
        onChange={(dates) => {
          if (dates) setCondensedDates(dates);
        }}
      />
      <FlatDatePicker
        type="range"
        label={['Range date picker', 'End date']}
        value={rangeDates}
        onChange={handleChangeRange}
      />
      <FlatDatePicker
        type="range"
        disableInput
        label={['Range date picker', 'With manual input disabled']}
        value={rangeDates}
        onChange={handleChangeRange}
      />
    </div>
  );
};

export default DebugPage;
