const mapCampaigns = (campaigns) => campaigns && Object.entries(campaigns)
  ?.reduce((accumulator, [campaignId, campaignData]) => ({
    ...accumulator,
    [campaignId]: {
      name: campaignData?.name || '',
      createdAt: new Date(campaignData?.created_at || ''),
      affiliates: campaignData?.affiliates || []
    }
  }), {});

const mapAffiliates = (affiliates) => affiliates && Object.entries(affiliates)
  ?.reduce((accumulator, [affiliateId, affiliateData]) => ({
    ...accumulator,
    [affiliateId]: {
      name: affiliateData?.name || '',
    }
  }), {});

export const mapAffilaeCampaignsAndAffiliates = {
  fromApi: (apiResponse) => ({
    campaigns: mapCampaigns(apiResponse?.campaigns),
    affiliates: mapAffiliates(apiResponse?.affiliates)
  })
};

