import React, { memo } from 'react';

type Props = {
  url: string;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
  disableUniqueIds?: boolean;
  className?: string;
  alt?: string;
  id?: string;
};

const SvgFromUrl: React.FC<Props> = ({
  url,
  style,
  width,
  height,
  disableUniqueIds,
  className,
  ...props
}) => {
  return (
    <svg
      data-src={url}
      data-unique-ids={disableUniqueIds ? 'disabled' : ''}
      width={width}
      height={height}
      style={style}
      className={className}
      {...props}
    />
  );
};

export default memo(SvgFromUrl);
