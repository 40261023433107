import { createSelector } from '@reduxjs/toolkit';
import memoize from 'memoizee';
import _orderBy from 'lodash.orderby';
import _flatten from 'lodash.flatten';

import { getStore } from 'config/store';
import { CAMPAIGN_STATES } from 'constants/states';

const getCampaignsAsArray = campaigns => Object.values(campaigns) || [];

export const getCampaignsNotArchivedSorted = createSelector(
  [getCampaignsAsArray],
  campaigns => _orderBy(
    campaigns.filter(s => s.core.state !== CAMPAIGN_STATES.ARCHIVED),
    ['core.isMine',	'core.isWatching', o => new Date(o.core.createdAt)],
    ['desc', 'desc', 'desc']
  )
);

export const getCampaignProfilesAsSortedArray = memoize(campaignProfiles => memoize(profiles => {
  const asArray = Object.values(campaignProfiles);
  const sortedProfiles = _orderBy(asArray, p => p.updatedAt ? new Date(p.updatedAt) : 0, ['desc']);
  return sortedProfiles.map(({id}) => profiles[id]);
}));

export const getCampaignProfiles = memoize(campaignProfiles =>
  memoize(profiles => {
    const profilesActive = Object.values(campaignProfiles);
    return profilesActive.reduce((p, c) => Object.assign(p, {
      [c.id]: profiles[c.id]
    }), {})
  })
);

export const getProfilesIdsSortedByState = (campaignProfiles, stateGroup) => {
	const { profiles: detailedProfiles } = getStore();
	const profilesAsArray = Object.values(campaignProfiles);
	const profilesByState = profilesAsArray.filter(p => p.state === stateGroup);
	const profilesByStatesSorted = _orderBy(
		profilesByState,
		[profile => (
			detailedProfiles[profile.id]?.core?.pseudo ||
			detailedProfiles[profile.id]?.core?.fullName
		)?.trim().toLowerCase()],
		['asc']
	).map(p => p.id);
	return _flatten(profilesByStatesSorted);
}
