import { createAction } from 'redux-actions';
import _throttle from 'lodash.throttle';
import _differenceBy from 'lodash.differenceby';

import { addProfilesToCampaigns } from 'actions/campaigns';
import { setControlledLoader, showModal } from 'actions/ui';
import { updateProfileDetailsId } from 'actions/views';
import { getIntegrations, sendingMethodAction, updateIntegrations } from 'actions/user';

import * as api from 'api/messaging';

import * as mappers from 'mappers/messaging';
import * as MESSAGING from 'constants/messaging';
import * as viewValidator from 'validators/views';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

export const loadMessages = createAction(MESSAGING.LOAD_MESSAGES);
export const loadMessage = createAction(MESSAGING.LOAD_MESSAGE);
export const loadConversationMessages = createAction(MESSAGING.LOAD_CONVERSATION_MESSAGE);
export const updateInboxWebsocketAction = createAction(MESSAGING.UPDATE_INBOX_WEBSOCKET);
export const sortInboxListAction = createAction(MESSAGING.SORT_INBOX_LIST)
export const updateConversationMessageAction = createAction(MESSAGING.CONVERSATION_MESSAGE_UPDATE)

export const loadDrafts = createAction(MESSAGING.LOAD_DRAFTS);
export const loadDraftAction = createAction(MESSAGING.LOAD_DRAFT);
export const postDraft = createAction(MESSAGING.POST_DRAFT);
export const removeDraft = createAction(MESSAGING.DELETE_DRAFT);
export const updateDraftFromWS = createAction(MESSAGING.UPDATE_DRAFT);

export const reloadMessages = createAction(MESSAGING.RELOAD_MESSAGE);
export const createMessage = createAction(MESSAGING.CREATE_MESSAGE);
export const handleMessage = createAction(MESSAGING.HANDLE_MESSAGE);
export const upNewMessageAction = createAction(MESSAGING.UP_NEW_MESSAGE);

export const clearMessage = createAction(MESSAGING.CLEAR_MESSAGE);
export const resetMessageStore = createAction(MESSAGING.RESET_MESSAGE);

export const loadProfiles = createAction(MESSAGING.LOAD_PROFILES);
export const loadProfileDetails = createAction(MESSAGING.DISPLAY_PROFILE);
export const loadCampaign = createAction(MESSAGING.LOAD_CAMPAIGN);

export const searchProfiles = createAction(MESSAGING.SEARCH_PROFILES);

export const handleSelection = createAction(MESSAGING.HANDLE_SELECTION);
export const setRecipients = createAction(MESSAGING.SET_RECIPIENTS);

export const setCampaign = createAction(MESSAGING.SET_CAMPAIGN);
export const resetCampaign = createAction(MESSAGING.RESET_CAMPAIGN);

export const handleView = createAction(MESSAGING.HANDLE_VIEW);

export const toggleKolToCampaign = createAction(MESSAGING.TOGGLE_KOL_TO_CAMPAIGN);

export const templatesLoaded = createAction(MESSAGING.TEMPLATES_LOADED);
export const templateDeleted = createAction(MESSAGING.TEMPLATE_DELETED);
export const getFrom = createAction(MESSAGING.GET_FROM);
export const setReadStatus = createAction(MESSAGING.READ_STATUS);

export const setGmailAddress = createAction(MESSAGING.SET_GMAIL_ADDRESS);

export const setSearchQuery = createAction(MESSAGING.SEARCH_QUERY);
export const setStatusFilter = createAction(MESSAGING.SET_STATUS_FILTER);

export const setAllUnreadMessages = createAction(MESSAGING.SET_ALL_UNREAD_MESSAGES);

export const setCurrentCampaignId = createAction(MESSAGING.SET_CURRENT_CAMPAIGN_ID);

export const setNewSubject = createAction(MESSAGING.SET_NEW_SUBJECT);

export const savePreview = createAction(MESSAGING.SAVE_PREVIEW);
export const clearPreview = createAction(MESSAGING.CLEAR_PREVIEW);

/**
 *
 * GLOBAL MESSAGING ACTION PART
 */

export const resetMessage = () => dispatch => dispatch(resetMessageStore());
export const upNewMessage = () => dispatch => dispatch(upNewMessageAction());
export const createNewMessage = () => dispatch => dispatch(createMessage());
export const setUnreadMessages = data => dispatch => {
  const { unread_messages_count } = data;
  dispatch(setAllUnreadMessages(unread_messages_count))
}

/**
 *
 * INBOX ACTION PART
 */

export const getMessages = ({ name, campaignId, page, perPage, readStatus, search, filterByStatusId, noLoading }) => async (dispatch, getState) => {
  if (readStatus === 'draft') return false;

  const { user, messaging: { inbox, from } } = getState();

  if (user?.profile?.admin && from === 'messaging') return false;

  if (!noLoading) {
    dispatch(setControlledLoader({ id: 'getMessages', show: true }));
  }

  if (campaignId) await dispatch(setCampaign(campaignId));

  try {
    const throttledGetMessages = _throttle(params => {
      return api.getMessages(params)
    }, 1000, { leading: true });

    const response = await throttledGetMessages({
      campaignId, name, page, readStatus, filterByStatusId,
      perPage: (!perPage && perPage !== 0) ? inbox.perPage : perPage,
    });

    const {
      gmailAddress, projectUnreadMessagesCount, conversations, total, sendingMethod
    } = mappers.getMessages.fromApi(response);

    const data = {
      projectUnreadMessagesCount,
      gmailAddress,
      sendingMethod,
      search,
      page,
      perPage,
      total,
      results: Array.from(new Set(conversations))
    };

    dispatch(loadMessages(data));
  } catch (e) {
    console.error(e);
    // Improve UI action for errors
  }

  if (!noLoading) {
    dispatch(setControlledLoader({ id: 'getMessages', show: false }));
  }
}

export const updateConversationsList = data => async (dispatch, getState) => {
  const { messaging: { from, currentCampaignId }} = getState();

  const { action_type } = data;

  if (['new_message', 'batch_messages', 'message_read'].includes(action_type)) {
    const mappedData = mappers.wsConversations.fromApi(data);

    await mappedData.forEach(item => {
      if (
        (from === 'campaign' && String(item.campaignId) === String(currentCampaignId)) ||
        from === 'messaging'
      ) {
        dispatch(updateInboxWebsocketAction({
          message: item,
          action: action_type,
        }));
      }
    })
  }
  if (action_type === 'create_draft_message' || action_type === 'update_draft_message') {
    const mappedDraft = mappers.mapDraft(data?.draft_message);
    dispatch(updateDraftFromWS(mappedDraft));
  }
  if (action_type === 'destroy_draft_message') dispatch(removeDraft(data?.draft_message_id));
  if (action_type !== 'message_read') dispatch(sortInboxListAction());

  return true;
}

export const loadFilteredMessage = filter => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'getMessages', show: true }));
  dispatch(setReadStatus(filter));
  dispatch(setControlledLoader({ id: 'getMessages', show: false }));
}


/**
 *
 * CONVERSATION ACTION PART
 */

export const getConversationMessages = ({ conversationId, page }) => async dispatch => {
  if (!conversationId) return false;
  dispatch(setControlledLoader({ id: 'getMessage', show: true }));

  try {
    const conversationMessages = await api.getConversationMessages({
      conversationId,
      page,
    });
    const { total, rows } = mappers.getConversationMessages.fromApi(conversationMessages);
    dispatch(loadConversationMessages({ page, total, rows }));
  } catch (e) {
    console.error('[getMessage] -> ', e);
  }

  dispatch(setControlledLoader({ id: 'getMessage', show: false }));
}

export const updateConversationMessage = data => dispatch => {
  const mappedData = mappers.mapMessage(data.message);
  dispatch(updateConversationMessageAction(mappedData));
}

export const getMessage = ({ conversationId, noLoading }) => async dispatch => {
  if (!noLoading) {
    dispatch(setControlledLoader({ id: 'getMessage', show: true }));
  }

  try {
    const conversationDetails = await api.getMessage({ conversationId });
    await dispatch(getConversationMessages({ conversationId, page: 0 }));
    dispatch(loadMessage(mappers.getConversation.fromApi(conversationDetails)));
  } catch (e) {
    console.error('[getMessage] -> ', e);
  }

  if (!noLoading) {
    dispatch(setControlledLoader({ id: 'getMessage', show: false }));
  }
};

const checkNestedProperty = (obj, property) => {
  if (!obj || typeof obj !== 'object') return false;
  if (property in obj) return true;
  return Object.keys(obj).some((key) => checkNestedProperty(obj[key], property));
};

export const sendMessages = ({
  message,
  campaignId,
  force,
  onSuccess,
}) => async (dispatch, getState) => {
  const {
    messaging: {
      compose,
      conversation: { id: conversationId, draft, subjectThreads, participants },
    },
    campaigns: { [campaignId]: campaign },
  } = getState();

  dispatch(setControlledLoader({ id: 'chat_sending', show: true }));

  const singleMessage = conversationId && compose.recipient?.length < 2;
  const campaignProfilesIds = Object.keys(campaign?.profiles || {});

  const response = singleMessage
    ? await api.sendMessage(
        conversationId,
        mappers.sendMessage.toApi(message, force),
      )
    : await api.createConversations(
        mappers.createConversation.toApi(
          {
            ...compose,
            message,
            campaignId,
          },
          force,
        ),
      );

  if (response.error) {
    console.error('[messaging] => sendMessages', response);
    // Missing value error
    if (checkNestedProperty(response.payload?.messages, 'missing')) {
      const callback = {
        onSuccess: ({ force: forceSend } = { force: false }) => {
          dispatch(
            sendMessages({ message, campaignId, force: forceSend, onSuccess }),
          );
        },
        onCancel: () => {
          dispatch(setControlledLoader({ id: 'chat_sending', show: false }));
        },
      };
      // Already is an actual conversation
      if (conversationId) {
        dispatch(
          showVariablesMissingValuesModal({
            data: [
              {
                kol: participants.find(
                  participant => participant.type === 'profile',
                ),
                missingFields: response.payload.messages.missing,
              },
            ],
            callback,
            from: 'messaging',
          }),
        );
      } else {
        dispatch(
          showVariablesMissingValuesModal({
            data: Object.entries(response.payload?.messages).reduce(
              (data, [key, value]) =>
                value
                  ? [
                      ...data,
                      {
                        kol: compose.recipient.find(
                          recipient => recipient.id.toString() === key,
                        ),
                        missingFields: value.missing,
                      },
                    ]
                  : data,
              [],
            ),
            callback,
            from: 'messaging',
          }),
        );
      }
    } else {
      const intl = getIntl();
      toast(
        intl.formatMessage({ id: "messaging.attachmentError.global" }),
        { type: 'error' }
      );
    }
    return response;
  }

  if (conversationId) {
    const mappedResponse = mappers.sendMessage.fromApi(response);

    // TODO: Use websocket to update subject threads
    const newThread = {
      id: mappedResponse?.id,
      createdAt: mappedResponse?.createdAt,
      subject: message.subject,
    };

    const isNew =
      subjectThreads.findIndex(thread => thread.subject === newThread.subject) <
      0;

    await dispatch(
      setNewSubject({
        subject: message.subject,
        subjectThreads: isNew ? [...subjectThreads, newThread] : subjectThreads,
      }),
    );
    await dispatch(upNewMessage());
  } else {
    // New conversation or Batch
    if (compose.recipient?.length === 1) {
      await dispatch(upNewMessage());
      await dispatch(
        getMessage({
          conversationId: mappers.createConversation.fromApi(response)?.[0]?.id,
        }),
      );
    }
    if (compose.recipient?.length > 1) dispatch(resetMessage());
  }

  if (draft?.id) {
    await dispatch(deleteDraft(draft?.id));
  }

  if (campaignId) {
    const selectedProfileIds = compose.recipient.map(({ id }) => id);
    const newProfileIds = _differenceBy(
      selectedProfileIds,
      campaignProfilesIds,
      Number,
    );

    const canAutoAddKOL =
      !viewValidator.isCampaignTrackingLinksPage() &&
      !viewValidator.isCampaignDiscountCodesPage();

    if (canAutoAddKOL && newProfileIds.length) {
      await dispatch(
        addProfilesToCampaigns({
          profileIds: newProfileIds,
          campaignIds: [campaignId],
          noToast: true,
        }),
      );

      const intl = getIntl();
      const newRecipent = compose.recipient.find(
        r => r.id === newProfileIds?.[0],
      );
      toast(
        intl.formatMessage(
          { id: "toasts.profilesAddedToCampaign" },
          {
            count: newProfileIds?.length,
            name: newRecipent?.name?.trim()
           }
        ),
        { type: 'success' }
      );
    }
  }

  const finalConversationId = conversationId || response[0]?.id;

  if (onSuccess) {
    onSuccess(finalConversationId);
  }

  dispatch(setControlledLoader({ id: 'chat_sending', show: false }));

  return finalConversationId;
};

export const hasConversation = ({ profileId, campaignId }) => async (dispatch, getState) => {
  const { messaging: { compose: { recipient } } } = getState();

  if (profileId?.length === 1) {
    const response = await api.hasConversation(profileId[0], campaignId);
    if (response?.conversation_id) {
      if (recipient?.length > 1) {
        dispatch(clearMessage())
        return true;
      }
      dispatch(getMessage({ conversationId: response.conversation_id }));
      return true;
    }
    dispatch(clearMessage());
    return true;
  }
}



/**
 *
 * KOLs ACTION PART
 */

export const getKols = ({ name, mySelection, page, perPage, search, campaignId }) => async (dispatch, getState) => {
  const { messaging: { profiles } } = getState();

  dispatch(setControlledLoader({ id: 'getMyProfiles', show: true }));

  try {
    const throttledGetKols = _throttle(params => {
      return api.getKols(params)
    }, 1000, { leading: true });

    const response = await throttledGetKols({
      name,
      mySelection,
      page,
      campaignId,
      perPage: (!perPage && perPage !== 0) ? profiles.perPage : perPage,
    });

    dispatch(loadProfiles({
      search,
      page,
      perPage,
      results: mappers.getKols.fromApi(response),
    }));
  } catch (e) {
    console.error('[getMessage] ->', e);
  }

  dispatch(setControlledLoader({ id: 'getMyProfiles', show: false }));
}

export const getProfileDetails = ({ profileId, displayDetails }) => async dispatch => {
  dispatch(setControlledLoader({ id: 'getProfileDetails', show: true }));
  try {
    dispatch(updateProfileDetailsId(profileId));
    dispatch(loadProfileDetails({ profileId, displayDetails }));
  } catch (e) {
    console.error('[getProfileDetails] ->', e);
  }
  dispatch(setControlledLoader({ id: 'getProfileDetails', show: false }));
}



/**
 *
 * CAMPAIGN ACTION PART
 */

export const getCampaign = ({ name, page, perPage, search }) => async dispatch => {
  dispatch(setControlledLoader({ id: 'getCampaign', show: true }));
  try {
    const { total, rows } = await api.getCampaign({ name, page, perPage });
    const results = mappers.getCampaigns.fromApi(rows);
    dispatch(loadCampaign({
      search,
      total,
      page,
      perPage,
      results: [...new Set(results)]
    }));
  } catch (e) {
    console.error('[getMessage] -> ', e);
  }
  dispatch(setControlledLoader({ id: 'getCampaign', show: false }));
}

export const setConversationCampaign = ({ campaignId, reset }) => dispatch => {
  if (!reset) dispatch(setCampaign(campaignId));
  if (reset) dispatch(resetCampaign());
}


/**
 *
 * GMAIL ACTION PART
 */

export const saveGmailAuthorizationCode = code => async dispatch => {
  const codeToApi = mappers.conversationGmailData.toApi(code)
  const res = await api.saveGmailAuthorizationCode(codeToApi);
  const gmailDataMapped = mappers.conversationGmailData.fromApi(res);
  await dispatch(setGmailAddress(gmailDataMapped?.gmailAddress));
  dispatch(getIntegrations())
}

export const removeGmailAuthorizationCode = gmailAddress => async dispatch => {
  dispatch(setControlledLoader({ id: 'globalLoader', show: true }))
  await api.removeGmailAuthorizationCode(gmailAddress);
  dispatch(updateIntegrations({ gmail_account: null }))
  dispatch(sendingMethodAction('kolsquare'));
  dispatch(setGmailAddress(null));
  dispatch(setControlledLoader({ id: 'globalLoader', show: false }))
}

/**
 *
 * DISPLAY ACTION PART
 */

export const toggleSelection = ({ id, name, pseudo, fullName, avatar, contactableEmail, type }) => dispatch => {
  dispatch(handleSelection({ id, name, pseudo, fullName, avatar, contactableEmail, type }))
}

export const mobileDisplay = displayPanel => dispatch => {
  /**
   *  @param {boolean}.
   */
  dispatch(handleView({ displayPanel }));
}



/**
 *
 * DRAFT ACTION PART
 */

export const getDrafts = ({ name, campaignId, page, perPage }) => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'getMessages', show: true }));

  if (campaignId) dispatch(setCampaign(campaignId));

  try {
    const throttledGetDraft = _throttle( params => {
      const mappedFilters = mappers.getDrafts.toApi(params);
      return api.getDrafts(mappedFilters)
    }, 1000, { leading: true });

    const response = await throttledGetDraft({
      campaignId,
      name,
      page,
      perPage,
    });

    const { total, rows } = mappers.getDrafts.fromApi(response);

    dispatch(loadDrafts({
      total,
      results: rows,
    }));
  } catch (e) {
    console.error('[loadDraft] -> ', e);
  }

  dispatch(setControlledLoader({ id: 'getMessages', show: false }));
}

export const loadDraft = ({ id, conversationId, content, subject, recipient, attachments, campaignId }) => async dispatch => {
  dispatch(setControlledLoader({ id: 'draft', show: true }));

  await dispatch(loadDraftAction({ id, conversationId, content, subject, recipient, attachments, campaignId }));

  dispatch(setControlledLoader({ id: 'draft', show: false }));
}

export const handleDraft = ({ draft, draftId }) => async dispatch => {
  dispatch(setControlledLoader({ id: 'draft', show: true }));
  const mappedDraft = await mappers.draft.toApi(draft);

  const messagePlainText = draft.content?.getCurrentContent()?.getPlainText();
  if (draftId) {
    if (messagePlainText?.trim() !== '') {
      await api.updateDraft({
        draftId,
        draft: mappedDraft
      });
    } else {
      dispatch(showModal({
        id: 'confirmRemoveDraft',
        data: {draftId},
      }));
    }
  } else if (messagePlainText?.trim() !== '') {
      await api.postDraft(mappedDraft);
  }

  dispatch(setControlledLoader({ id: 'draft', show: false }));
};

export const deleteDraft = draftId => async dispatch => {
  dispatch(setControlledLoader({ id: 'getMessages', show: true }));
  await api.deleteDraft(draftId);
  dispatch(setControlledLoader({ id: 'getMessages', show: false }));
}

export const findDraft = ({ profileIds, campaignId }) => async dispatch => {
  const params = mappers.findDraft.toApi({ profileIds, campaignId });
  const response = await api.findDraft(params);
  const draft = mappers.mapDraft(response);
  if (draft) dispatch(loadDraft(draft));
}


/**
 *
 * TEMPLATE ACTION PART
 */

export const getTemplates = () => async dispatch => {
  const response = await api.getTemplates();
  if (!response?.error) {
    const templates = mappers.getTemplates(response);
    dispatch(templatesLoaded(templates));
  }
}

export const saveTemplate = (data) => (_, getState) => {
  const { messaging: { templates } } = getState();
  const isAlreadyExistes = templates.some(template => template?.title?.trim().toLowerCase() === data?.title?.trim().toLowerCase());
  if (isAlreadyExistes) {
    const intl = getIntl();
    toast(
      intl.formatMessage({ id: "messaging.template.alreadyExistes"}),
      { type: 'error' }
    )
    return false;
  }

  if(data?.id) {
    const templatesToApi = mappers.updateTemplates([data]);
    return api.updateTemplates(templatesToApi);
  } else {
    const templateToApi = mappers.createTemplateToApi(data);
    return api.createTemplate(templateToApi);
  }
}

export const saveOrderTempates = templates => () => {
  const templatesToApi = mappers.updateTemplates(templates);
  return api.updateTemplates(templatesToApi);
}

export const removeTemplate = id => async dispatch => {
  await api.removeTemplate(id);
  dispatch(templateDeleted(id))
}

export const showVariablesMissingValuesModal = ({
  data: kolMissingValuesMap,
  callback,
  from,
}) => async dispatch => {
  if (!kolMissingValuesMap || !Array.isArray(kolMissingValuesMap)) return null;
  dispatch(
    showModal({
      id: 'variablesMissingValues',
      data: { kolMissingValuesMap, callback, from },
    }),
  );
};

export const showPreviewModal = ({
  dataMessage, // { content: string, subject: string }
  campaignId,
}) => async (dispatch, getState) => {
  const { messaging } = getState();
  const recipients = [
    ...(messaging.compose.recipient?.length ? messaging.compose.recipient : []),
    ...(!messaging.compose.recipient?.length && messaging.conversation.profile
      ? [messaging.conversation.profile]
      : []),
  ];

  dispatch(
    showModal({
      id: 'previewModal',
      data: { dataMessage, campaignId, recipients },
    }),
  );
};

export const loadPreview = ({
  profileId: profile_id,
  projectId: project_id,
  content,
}) => async dispatch => {
  dispatch(setControlledLoader({ id: 'loadPreview', show: true }));
  const res = await api.loadPreview({
    profile_id,
    project_id,
    message: { content },
  });
  dispatch(savePreview(res));
  dispatch(setControlledLoader({ id: 'loadPreview', show: false }));
};
