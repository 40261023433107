import { useState, useEffect } from 'react';
import moment, { unitOfTime } from 'moment';

interface TimerangeProps {
  startDate: string;
  endDate: string;
}

const TIME_RANGES = ['day', 'week', 'month', 'quarter'] as unitOfTime.Diff[];

export const getTimeRangesByDates = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return [];
  const _startDate = moment(startDate);
  const _endDate = moment(endDate);
  if (!_startDate.isValid() || !_endDate.isValid()) return [];

  const timeRanges: unitOfTime.Diff[] = [];

  TIME_RANGES.forEach((time) => {
    const rangePoints = _endDate.diff(_startDate, time);
    if (
      (rangePoints > 2 && rangePoints < 33) ||
      (time === 'day' && rangePoints <= 2)
    ) {
      timeRanges.push(time);
    }
  });

  return timeRanges;
}

const useTimeRangesByDates = ({ startDate, endDate }: TimerangeProps) => {
  const [timeRanges, setTimeRanges] = useState<string[]>([]);

  useEffect(() => {
    const _timeRanges = getTimeRangesByDates({ startDate, endDate });
    setTimeRanges(_timeRanges)
  }, [startDate, endDate]);

  return timeRanges;
};

export default useTimeRangesByDates;
