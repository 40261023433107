// It's used for the loading - Do not use phrase

export default {
  'en-GB': [
    `If you want the stories of a KOL, don’t forget to add him to your favorites or to one of your campaigns upfront.`,
    `The Earned Media Value of a KOL is estimated based on his publications of the past 3 months.`,
    `You can exclude mentions of your competitors in your searches by using the minus sign (ex. -@competitor).`,
    `The filter “Interests of the audience” is relevant starting from 30%.`,
    `The filter “Location of the audience - Country” is relevant starting from 40%.`,
    `The filter “Location of the audience - City” is relevant starting from 1% for small cities.`,
    `Audience deliverability : having 50% of “< 500 followings” means 50% of the audience follows less than 500 people.`,
    `Top Mentions are useful to spot a collaboration with a KOL, as well as people they talk with (ex. mention of their friends).`,
    `Labels are used to organise your KOLs by category (per type, per customer, per region…).`,
    `Posts are collected every 24h, stories every 8h.`,
    `The “relevance” sorting criteria is based on both frequency and recency of the keywords you use within the KOL’s posts and stories.`,
    `You can export a casting of KOLs within your campaigns from the “KOL” tab - Export KOLs PPTX.`,
  ],
  'fr-FR': [
    `Si vous voulez les stories d'un KOL, pensez à le mettre dans vos favoris ou dans l'une de vos campagnes en amont.`,
    `L’Earned Media Value d'un KOL est estimée sur ses publications des 3 derniers mois.`,
    `Pensez à exclure les mentions de vos concurrents lors de vos recherches en utilisant le signe moins (ex. -@concurrent).`,
    `Le filtre par intérêt de l'audience est utile à partir de 30%.`,
    `Le filtre par Localisation de l'audience - Pays est utile à partir de 40%.`,
    `Le filtre par Localisation de l'audience - Ville est utile à partir de 1 % pour les petites villes.`,
    `Déliverabilité de l’audience : avoir 50% de “< 500 followings” signifie que 50% de l’audience suit moins de 500 personnes.`,
    `Le Top des Mentions sert à repérer les partenariats d'un KOL ainsi qu'à découvrir son entourage proche (ex: mention de ses amis).`,
    `Les Labels vous sont utiles pour organiser vos KOLs par catégorie (par typologie, par clients, par zones géographiques...).`,
    `Les posts sont collectés toutes les 24h quand les stories le sont toutes les 8h.`,
    `Le critère de tri par “pertinence” prend en compte la fréquence et la récence des mots clés recherchés au sein des posts et stories du KOL.`,
    `Vous pouvez exporter un casting de KOLs dans vos campagnes depuis l'onglet "KOL - Export des KOLs PPTX".`,
  ],
  'es-ES': [
    `Si quieres conocer las historias de un KOL, no olvides añadirlo a tus favoritos o a una de tus campañas por adelantado`,
    `El valor mediático de un KOL se calcula en función de sus publicaciones de los últimos 3 meses`,
    `Puedes excluir las menciones de tus competidores en tus búsquedas utilizando el signo menos (ej. -@competidor)`,
    `El filtro "Intereses de la audiencia" es relevante a partir del 30%.`,
    `El filtro "Localización de la audiencia - País" es relevante a partir del 40%`,
    `El filtro "Localización de la audiencia - Ciudad" es relevante a partir del 1% para ciudades pequeñas`,
    `La entregabilidad de la audiencia: tener un 50% de "< 500 seguidores" significa que el 50% de la audiencia sigue a menos de 500 personas`,
    `Las menciones principales son útiles para detectar una colaboración con un KOL, así como las personas con las que habla (por ejemplo, mención de sus amigos)`,
    `Las etiquetas sirven para organizar a sus KOL por categoría (por tipo, por cliente, por región...)`,
    `Los mensajes se recogen cada 24 horas, las historias cada 8 horas`,
    `Los criterios de clasificación de "relevancia" se basan en la frecuencia y la recurrencia de las palabras clave que utiliza en las publicaciones e historias del KOL`,
    `Puedes exportar un casting de KOLs dentro de tus campañas desde la pestaña "KOL" - Exportar KOLs PPTX`,
  ],
  'de-DE': [
    `Wenn Sie die Geschichten eines KOLs hören wollen, vergessen Sie nicht, ihn zu Ihren Favoriten oder zu einer Ihrer Kampagnen hinzuzufügen`,
    `Der Earned Media Value eines KOL wird auf der Grundlage seiner Veröffentlichungen in den letzten 3 Monaten geschätzt`,
    `Sie können die Erwähnung Ihrer Konkurrenten bei der Suche ausschließen, indem Sie das Minuszeichen (z.B. -@Konkurrent) verwenden`,
    `Der Filter "Interessen des Publikums" ist ab 30% relevant`,
    `Der Filter "Standort des Publikums - Land" ist ab 40% relevant`,
    `Der Filter "Standort des Publikums - Stadt" ist ab 1% für kleine Städte relevant`,
    `Erreichbarkeit des Publikums: 50% der "< 500 Follower" bedeutet, dass 50% des Publikums weniger als 500 Personen folgen`,
    `Top-Erwähnungen sind nützlich, um eine Zusammenarbeit mit einem KOL zu erkennen, ebenso wie Personen, mit denen er spricht (z. B. Erwähnung seiner Freunde)`,
    `Labels werden verwendet, um Ihre KOLs nach Kategorien zu organisieren (pro Typ, pro Kunde, pro Region...)`,
    `Beiträge werden alle 24 Stunden gesammelt, Geschichten alle 8 Stunden`,
    `Das Sortierkriterium "Relevanz" basiert sowohl auf der Häufigkeit als auch auf der Aktualität der Schlüsselwörter, die Sie in den Beiträgen und Geschichten der KOLs verwenden`,
    `Sie können ein Casting von KOLs innerhalb Ihrer Kampagnen über die Registerkarte "KOL" exportieren - KOLs PPTX exportieren`,
  ],
  'it-IT': [
    `Se volete le storie di un KOL, non dimenticate di aggiungerlo ai vostri preferiti o a una delle vostre campagne in anticipo.`,
    `L'Earned Media Value di un KOL è stimato in base alle sue pubblicazioni degli ultimi 3 mesi.`,
    `Potete escludere le menzioni dei vostri concorrenti nelle vostre ricerche utilizzando il segno meno (es. -@competitor).`,
    `Il filtro "Interessi del pubblico" è rilevante a partire dal 30%.`,
    `Il filtro "Posizione del pubblico - Paese" è rilevante a partire dal 40%.`,
    `Il filtro "Posizione del pubblico - Città" è rilevante a partire dall'1% per le piccole città.`,
    `Consegnabilità del pubblico: avere il 50% di "< 500 followings" significa che il 50% del pubblico segue meno di 500 persone.`,
    `Le Top Mentions sono utili per individuare una collaborazione con un KOL, così come le persone con cui parla (ad esempio, le menzioni dei suoi amici).`,
    `Le etichette servono a organizzare i KOL per categoria (per tipo, per cliente, per regione...).`,
    `I post vengono raccolti ogni 24 ore, le storie ogni 8 ore.`,
    `Il criterio di ordinamento "rilevanza" si basa sulla frequenza e sulla ricorrenza delle parole chiave utilizzate nei post e nelle storie dei KOL.`,
    `È possibile esportare un casting dei KOL all'interno delle proprie campagne dalla scheda "KOL" - Esporta KOL PPTX.`,
  ],
};
