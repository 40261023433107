import _get from 'lodash.get';
import _has from 'lodash.has';
import _orderBy from 'lodash.orderby';
import moment from 'moment';

import { SHOPIFY_COLUMNS_MONITOR } from 'config/shopify';
import { BACKEND_DATE_FORMAT } from 'config/app';
import { POSTS_PER_PAGE } from 'config/posts';
import { profileStructure } from 'reducers/campaigns';
import { CAMPAIGN_STATES } from 'constants/states';
import { INSTAGRAM, TIK_TOK, TWITTER, YOUTUBE, FACEBOOK, SNAPCHAT } from 'constants/networks';

import { mapPosts } from './posts';

const spokenLanguage = {
  fra: 'french',
  deu: 'german',
  eng: 'english',
  ita: 'italian',
  spa: 'spanish',
}

const mapProfile = {
  fromApi: (profile) => {
    return {
      ...profileStructure,
      id: profile.id,
      state: profile.status_id || '',
      status_name: profile.status_name || '',
      commentsCount: profile.comments_count || 0,
      suggested: profile.suggested || false,
      addedAt: profile.added_at,
      delivery: mapDelivery.fromApi(profile.delivery || {}),
      content_instructions: profile.content_instructions || '',
      content_requests: {
        approved: 0,
        next_publication_date: '',
        published: 0,
        to_be_approved: 0,
        total: 0,
        waiting_proposal: 0,
        need_iteration: 0,
        ...profile.content_requests,
      },
      lang: !profile.lang ? null : spokenLanguage[profile.lang],
      conversationId: profile?.conversation_id,
      discountCode: profile?.discount_code,
      labelIds: profile?.tags,
      stats_sharing_request: profile?.stats_sharing_request,
      share_token: profile?.share_token,
      core: {
        id: profile.id,
        picture: profile.picture,
        first_name: profile.first_name,
        last_name: profile.last_name,
        display_name: profile.display_name,
        username: profile.username,
        full_name: profile.full_name,
        pseudo: profile.pseudo,
        gender: profile.gender,
        is_favorite: profile.is_favorite,
        has_note: profile.has_note,
        contactableEmail: profile.contactable_email,
      },
      details: {
        adress: profile.adress,
        email: profile.contactable_email,
      },
      snas: profile.snas,
      totalCommunityCount: (profile.snas || []).reduce((sum, snas) => {
        return sum + (snas.community_count || 0);
      }, 0),
    };
  }
};

export const mapProfiles = {
  fromApi: (profiles) => (profiles || []).map(profile => mapProfile.fromApi(profile)),
};

export const mapCampaigns = {
  fromApi: campaigns => (campaigns || []).map(campaign => mapCampaign.fromApi(campaign))
};

export const mapCampaign = {
  fromApi: campaign => {
    return {
      id: String(campaign.id),
      core: {
        active: true,
        name: campaign?.name,
        role: campaign?.role,
        enlisted: campaign?.enlisted,
        isWatching: campaign?.is_social_listening_active || false,
        createdAt: campaign?.created_at,
        createdBy: campaign?.author_name,
        updatedAt: campaign?.updated_at,
        teamId: campaign?.team_id ? Number(campaign?.team_id) : null,
        folderId: campaign?.folder_id || null,
        isMine: campaign?.is_mine || false,
        isPrivate: campaign?.is_private || false,
        state: campaign?.state,
        started: campaign?.state === "started",
        startDate: campaign?.start_at || '',
        endDate: campaign?.end_at || '',
        timezone: campaign?.timezone || null,
        globalBudget: campaign?.budget ? Number(campaign.budget) : null,
        globalBudgetCurrency: campaign?.budget_currency,
        goalTurnover: campaign?.turnover_target ? Number(campaign.turnover_target) : null,
        goalTurnoverCurrency: campaign?.turnover_target_currency,
        mentions: campaign?.mentions || [],
        hashtags: campaign?.hashtags || [],
        keywords: campaign?.keywords || [],
        networks: campaign?.networks || [],
        profilesCount: campaign?.profiles_count || campaign?.profiles?.length,
      },
      // profiles: _keyBy(mapProfiles.fromApi(campaign?.profiles), 'id'),
      brief: {
        id: _get(campaign, 'brief.id', null),
        sentAt: _get(campaign, 'brief.sent_at', null),
        title: _get(campaign, 'brief.title', null),
        description: _get(campaign, 'brief.description', null),
        attachments: _get(campaign, 'brief.attachments', []).map(at => ({
          id: at.id,
          filename: at.filename,
          size: at.size,
          url: at.url
        })),
      },
      product: {
        id: _get(campaign, 'product.id', null),
        title: _get(campaign, 'product.title', null),
        description: _get(campaign, 'product.description', null),
        attachments: _get(campaign, 'product.attachments', []).map(at => ({
          id: at.id,
          filename: at.filename,
          size: at.size,
          url: at.url
        })),
        price: String(_get(campaign, 'product.price', '')),
      },
      admin: {
        accountExecutive: String(_get(campaign, 'account_executive.id', null)),
        client: {
          id: _get(campaign, 'client.id', null),
          accountType: _get(campaign, 'client.account_type', null),
          company: _get(campaign, 'client.company_name', null),
          firstName: _get(campaign, 'client.first_name', null),
          lastName: _get(campaign, 'client.last_name', null),
        },
        clientFinal: _get(campaign, 'client_name', ''),
      },
      logo: campaign.logo || null,
      sortedProfiles: {
        byName: _orderBy(
          campaign.profiles,
          profile => {
            const label = profile?.pseudo && profile.pseudo.trim() !== '' ? profile.pseudo : profile.full_name
            return [label?.toLowerCase()?.trim()]
          },
          ['asc']
        ).map(p => p.id)
      },
      affilae: {
        campaignId: campaign.affilae_id
      },
      supports_old_estimations: campaign.supports_old_estimations || false,
      statuses: campaign.statuses,
      kols: campaign.active_profiles,
      current_project_workflow_id: campaign.current_project_workflow_id,
    }
  }
};

const parseDeliveryValue = value => {
  if (value === undefined || value === '' || value === null) return null;
  return Number(value)
}
const mapDelivery = {
  fromApi: (delivery = {}) => ({
    investedBudgetCurrency: delivery.invested_budget_currency,
    total: delivery.total || null,

    investedBudget: parseDeliveryValue(delivery.invested_budget),
  }),
  toApi: (delivery = {}) => ({
    invested_budget: parseDeliveryValue(delivery.investedBudget),
  }),
};

export const createNewCampaign = {
  toApi: query => Object.assign({},
    {
      project: Object.assign(
        {
          name: query.name,
          start_at: query.startDate ? moment(query.startDate).format(BACKEND_DATE_FORMAT) : null,
          end_at: query.endDate ? moment(query.endDate).format(BACKEND_DATE_FORMAT) : null,
          budget: query.globalBudget ? parseFloat(query.globalBudget) : 0,
          turnover_target: query.goalTurnover ? parseFloat(query.goalTurnover) : 0,
          state: query.state || CAMPAIGN_STATES.DRAFT,
          mentions: query.mentions || [],
          hashtags: query.hashtags || [],
          keywords: query.keywords || [],
          networks: query.networks || [],
          affilae_id: query.affilaeCampaignId
        },
        query.accountExecutive ? {
          admin_user_id: query.accountExecutive.id,
          client_name: query.clientFinal
        } : null
      )
    },
    query.client ? { contact_id: query.client.id } : null,
  ),
  fromApi: ({id}) => ({
    id: String(id)
  })
};

export const addProfileToCampaign = {
  toApi: profileId => ({'public_profile_id' : profileId})
};

export const addProfilesToCampaign = {
  toApi: profileIds => ({ 'profile_ids' : profileIds })
};

export const handleProfilesToCampaigns = {
  toApi: ({ campaignIds, profileIds }) => ({
    project_ids: campaignIds.map(id => Number(id)),
    profile_ids: [profileIds].flat().map(id => Number(id)),
  })
};

export const removeProfilesFromCampaign = {
  toApi: profileIds => ({ 'profile_ids': profileIds })
};

export const updateCampaign = {
  toApi: params => {
    const project = Object.assign(
      {},
      'name' in params      ? { name: params.name } : null,
      'accountExecutive' in params  ? { admin_user_id: params.accountExecutive } : null,
      'clientFinal' in params       ? { client_name: params.clientFinal } : null,
      'influencersSize' in params   ? { influencers_size: Number(params.influencersSize) } : null,
      'comment' in params           ? { comment: params.comment } : null,
      'state' in params             ? { state: params.state } : null,
      'hashtags' in params          ? { hashtags: params.hashtags } : null,
      'mentions' in params          ? { mentions: params.mentions } : null,
      'keywords' in params          ? { keywords: params.keywords } : null,
      'networks' in params          ? { networks: params.networks } : null,
      'startDate' in params         ? { start_at: params.startDate } : null,
      'endDate' in params           ? { end_at: params.endDate } : null,
      'globalBudget' in params      ? { budget: parseFloat(params.globalBudget) } : null,
      'goalTurnover' in params      ? { turnover_target: parseFloat(params.goalTurnover) } : null,
      'affilaeCampaignId' in params ? { affilae_id: params.affilaeCampaignId } : null,
      'current_project_workflow_id' in params        ? { current_project_workflow_id: params.current_project_workflow_id } : null,
    );

    return Object.assign({},
      Object.keys(project)?.length > 0 ? { project } : null,
      params.client ? { contact_id: params.client.id } : null
    );
  }
};

export const mapReportingProfileTags = {
  fromApi: (profileTags) => {
    return (profileTags || []).map(tag => ({
      id: tag.id,
      value: tag.value,
      profileIds: [...tag.profile_ids]
    }))
  }
}

const mapReporting = campaign => {
  return {
    search: _get(campaign, 'last_search_params.content', []),
    startDate: _get(campaign, 'last_search_params.start_at', null),
    endDate: _get(campaign, 'last_search_params.end_at', null),
    shareToken: campaign.share_token || null,
    customLogo: campaign.logo || null
  }
}

export const getCampaignDetails = {
  fromApi: ({ exporter, ...datas }) => {
    const campaign = mapCampaign.fromApi(datas);

    const reporting = mapReporting(datas);

    const castingPptxIsLoading = exporter ? {
      firstName : exporter.first_name || '',
      lastName : exporter.last_name || '',
      id : exporter.user_id || '',
    } : null;

    return {
      ...campaign,
      reporting,
      castingPptxIsLoading
    }
  }
};

export const updateProfileState = {
  toApi: ({ state }) => {
    return { status_id : state }
  }
};

export const updateProfileDelivery = {
  fromApi: ({ delivery }) => {
    return mapDelivery.fromApi(delivery)
  },
  toApi: ({ delivery }) => {
    return mapDelivery.toApi(delivery)
  }
};

export const getReporting = {
  fromApi: data => {
    const pptxProcessing = _get(data, 'exporter' , null);
    const exportPptxLoading = pptxProcessing ? {
      firstName : pptxProcessing.first_name || '',
      lastName : pptxProcessing.last_name || '',
      id : pptxProcessing.user_id || '',
    } : null;

    const { profiles, profilesStatsSum } = getReportingProfilesStats.fromApi(data);

    return {
      kols: data.active_profiles,
      profilesStats: profiles,
      globalStats: profilesStatsSum,
      totalPosts: data.total,
      posts: mapPosts(data.posts),
      exportPptxLoading,
    }
  },
  toApi: data => Object.assign({
      content: data.search,
      page: data.page,
      per_page: 12,
      sort_by: data.sortBy,
      start_at: data.startDate ? moment.tz(data.startDate, data.timezone).format(BACKEND_DATE_FORMAT) : null,
      end_at: data.endDate ? moment.tz(data.endDate, data.timezone).format(BACKEND_DATE_FORMAT) : null,
      is_public: !!data.isPublic,
      upgraded_estimations: data.upgraded_estimations,
    },
    data.networks?.length > 0 ? { social_networks: data.networks } : null,
    data.profiles?.length > 0 ? { author_ids: data.profiles } : null,
    data.types?.length !== 0 ? { publication_type: data.types } : null,
    data.types?.length !== 0 ? { publication_type: data.types } : null,
    data?.scopes ? { scopes: data.scopes } : null,
  )
};

export const getReportingProfilesStats = {
  fromApi: ({ accounts_stats: stats, accounts_stats_summary: statsSummary, active_profiles: activeProfiles }) => {
    // TODO: move this to back
    // Temporary handles zeros from the front, may cause division by zero on back
    let aggregatedInvestedBudget = null;

    const profiles = (Array.isArray(stats) && stats?.length)
      ? (stats || []).reduce( (prevStat, stat) => {
        const investedBudget = _get(stat, 'delivery.invested_budget', null);
        if (investedBudget !== null) {
          aggregatedInvestedBudget += Number(investedBudget);
        }

        return {
          ...prevStat,
          [String(stat.profile_id)]: {
            profile: activeProfiles.find(profile => profile.profile_id === stat.profile_id),
            id: String(stat.profile_id),
            campaignAverageCommitment: _get(stat, 'campaign_avg_engagement', 0),
            emv: stat.emv ? Number(stat.emv) : 0,
            roi: stat.roi ? Number(stat.roi) : 0,
            cpm: stat.cpm ? Number(stat.cpm) : 0,
            cpv: stat.cpv ? Number(stat.cpv) : 0,
            investedBudget: investedBudget ? Number(investedBudget) : investedBudget,
            investedBudgetCurrency: _get(stat, 'delivery.invested_budget_currency', null),
            publications: _get(stat, 'posts_count', 0) +
              _get(stat, 'stories_count', 0) +
              _get(stat, 'reels_count', 0) +
              _get(stat, 'snapchat_stories_count', 0) +
              _get(stat, 'spotlights_count', 0) +
              _get(stat, 'saved_stories_count', 0),
            reach: stat.reach_count || 0,
            impressions: stat.impressions_count || 0,
            isImpressionsReal: _get(stat, 'is_impressions_count_real', false),
            likes: stat.likes_count || 0,
            comments: stat.comments_count || 0,
            views: stat.views_count || 0,
            plays: stat.plays_count || 0,
            // swipeUp: stat.swipe_up_count || 0,
            clicks: stat.clicks_count || 0,
            shares: stat.shares_count || 0,
            saved: stat.saved_count || 0,
            replies: stat.replies_count || 0,
            screenshots: stat.screenshots_count || 0,
            variationRate: _get(stat, 'campaign_performance', 0),

            // Affilae
            ...(_has(stat, 'affilae_clicks') ? { affilaeClicks: stat.affilae_clicks || 0 } : null),
            ...(_has(stat, 'affilae_conversions') ? { affilaeConversions: stat.affilae_conversions || 0 } : null),
            ...(_has(stat, 'affilae_conversion_rate') ? { affilaeConversionRate : stat.affilae_conversion_rate || 0 } : null),
            ...(_has(stat, 'affilae_commissions') ? { affilaeCommissions : stat.affilae_commissions || 0 } : null),
            ...(_has(stat, 'affilae_revenue') ? { affilaeRevenue : stat.affilae_revenue || 0 } : null),
            ...(_has(stat, 'affilae_roi') ? { affilaeRoi : stat.affilae_roi || 0 } : null),
            ...(_has(stat, 'affilae_average_basket') ? { affilaeAverageBasket : stat.affilae_average_basket || 0 } : null),
            // Affilae aggregated
            ...(_has(stat, 'net_revenue') ? { netRevenue: stat.net_revenue || 0 } : null),
            ...(_has(stat, 'roi_kol_spend') ? { roiKolSpend: stat.roi_kol_spend || 0 } : null),

            /*
              TODO: GET /reporting > accounts_stats no longer sends the data about SHOPIFY & TRACKING LINKS => clean useless mappers here
            */

            // SHOPIFY
            ...SHOPIFY_COLUMNS_MONITOR.reduce(
              (prev, col) =>
                _has(stat, col)
                  ? { ...prev, [col]: stat[col] || null }
                  : prev,
              {},
            ),

            // TRACKING LINKS
            ...(_has(stat.tracking_link, 'short_link')
            && _has(stat.tracking_link, 'tracking_link')
            && stat.tracking_link.short_link !== null
            && stat.tracking_link.tracking_link !== null
              ? {
                links: {
                  short_link: _get(stat.tracking_link, 'short_link', ''),
                  long_link: _get(stat.tracking_link, 'tracking_link', ''),
                },
              }
              : null),
            ...(_has(stat.tracking_link, 'total_clicks') && stat.tracking_link.total_clicks !== null
              ? { clicks_count: _get(stat.tracking_link, 'total_clicks', 0) }
              : null),
            ...(_has(stat.tracking_link, 'human_clicks') && stat.tracking_link.human_clicks !== null
              ? { human_clicks_count: _get(stat.tracking_link, 'human_clicks', 0) }
              : null),
            ...(_has(stat.tracking_link, 'cities') && stat.tracking_link.cities !== null
              ? { top_cities: stat.tracking_link.cities }
              : null),
            ...(_has(stat.tracking_link, 'countries') && stat.tracking_link.countries !== null
              ? { top_countries: stat.tracking_link.countries }
              : null),
            ...(_has(stat.tracking_link, 'sna_referrers') && stat.tracking_link.sna_referrers !== null
              ? { sna_referrers: stat.tracking_link.sna_referrers }
              : null),

            // TRACKING SALES SHARED LINK
            ...(_has(stat, 'tracking_link')
              ? {
                ts_conversion_rate: _get(stat, 'tracking_link.avg_conversion_rate', 0),
                ts_roas: _get(stat, 'tracking_link.avg_roas', 0),
                ts_roi: _get(stat, 'tracking_link.total_roi', 0),
                ts_invested_budget: _get(stat, 'tracking_link.invested_budget', 0),
                ts_avg_articles_count: _get(stat, 'tracking_link.avg_articles_count', 0),
                ts_avg_basket: _get(stat, 'tracking_link.avg_basket', 0),
                ts_revenue: _get(stat, 'tracking_link.total_revenue', 0),
                ts_sales_count: _get(stat, 'tracking_link.sales_count', 0),
                ts_articles_count: _get(stat, 'tracking_link.articles_count', 0)
              }
              : null),

            snas: (stat.snas || []).reduce( (prevSna, sna) => ({
              ...prevSna,
              [String(sna.id)]: Object.assign(
                {
                  id: String(sna.id),
                  username: sna.username,
                  network: sna.provider,
                  isStatsSharingActive: sna.is_stats_sharing_active,
                  campaignAverageCommitment: _get(sna, 'campaign_avg_engagement', null),
                  variationRate: _get(sna, 'campaign_performance', null),
                  publications: _get(sna, 'posts_count', null),
                  emv: _get(sna, 'emv', null),
                  reach: sna.reach_count || 0,
                  impressions: sna.impressions_count || 0,
                  isImpressionsReal: _get(sna, 'is_impressions_count_real', false),
                  likes: sna.likes_count || 0,
                  comments: sna.comments_count || 0,
                  views: sna.views_count || 0,
                  plays: sna.plays_count || 0,
                  // swipeUp: sna.swipe_up_count || 0,
                  clicks: sna.clicks_count || 0,
                  shares: sna.shares_count || 0,
                  saved: sna.saved_count || 0,
                  replies: stat.replies_count || 0,
                },
                sna.stories && {
                  stories: {
                    publications: _get(sna, 'stories.count', null),
                    emv: _get(sna, 'stories.emv', null),
                    likes: sna.stories.likes_count || 0,
                    comments: sna.stories.comments_count || 0,
                    reach: sna.stories.reach_count || 0,
                    impressions: sna.stories.impressions_count || 0,
                    isImpressionsReal: _get(sna.stories, 'is_impressions_count_real', false),
                    // swipeUp: sna.stories.swipe_up_count || 0,
                    clicks: sna.stories.clicks_count || 0,
                    replies: sna.stories.replies_count || 0,
                  }
                },
                sna.reels && {
                  reels: {
                    publications: _get(sna, 'reels.count', null),
                    emv: _get(sna, 'reels.emv', null),
                    campaignAverageCommitment: sna.reels.campaign_avg_engagement || 0,
                    likes: sna.reels.likes_count || 0,
                    comments: sna.reels.comments_count || 0,
                    reach: sna.reels.reach_count || 0,
                    impressions: sna.reels.impressions_count || 0,
                    saved: sna.reels.saved_count || 0,
                    plays: sna.reels.plays_count || 0,
                    views: sna.reels.views_count || 0,
                  }
                },
                sna.spotlights && {
                  spotlights: {
                    publications: _get(sna, 'spotlights.count', 0),
                    campaignAverageCommitment: sna.spotlights.campaign_avg_engagement || 0,
                    reach: sna.spotlights.reach_count || 0,
                    saved: sna.spotlights.saved_count || 0,
                    views: sna.spotlights.views_count || 0,
                    shares: sna.spotlights.shares_count || 0,
                    screenshots: sna.spotlights.screenshots_count || 0,
                  }
                },
                sna.saved_stories && {
                  savedStories: {
                    publications: _get(sna, 'saved_stories.count', null),
                    campaignAverageCommitment: sna.saved_stories.campaign_avg_engagement || 0,
                    reach: sna.saved_stories.reach_count || 0,
                    views: sna.saved_stories.views_count || 0,
                    shares: sna.saved_stories.shares_count || 0,
                    swipeUp: sna.saved_stories.swipe_up_count || 0,
                  }
                },
                sna.stories && sna.provider === SNAPCHAT && {
                  snapChatStories: {
                    publications: _get(sna, 'stories.count', null),
                    campaignAverageCommitment: sna.stories.campaign_avg_engagement || 0,
                    views: sna.stories.views_count || 0,
                    reach: sna.stories.reach_count || 0,
                    shares: sna.stories.shares_count || 0,
                    swipeUp: sna.stories.swipe_up_count || 0,
                    replies: sna.stories.replies_count || 0,
                  }
                }
              )
            }), {})
          }
        }
      }, {}) : {};

    const profilesStatsSum = {
      engagements: _get(statsSummary, 'engagements', 0),
      campaignAverageCommitment: _get(statsSummary, 'campaign_avg_engagement', 0),
      emv: statsSummary?.emv || 0,
      roi: statsSummary?.roi || 0,
      cpm: statsSummary?.cpm || 0,
      cpv: statsSummary?.cpv || 0,
      investedBudget: statsSummary?.invested_budget ? Number(statsSummary.invested_budget) : aggregatedInvestedBudget,
      investedBudgetCurrency: _get(statsSummary, 'invested_budget_currency', null),
      budget: _get(statsSummary, 'budget', 0),
      budgetCurrency: _get(statsSummary, 'budget_currency', null),
      profilesCount: _get(statsSummary, 'profiles_count', 0),
      accountsCount: _get(statsSummary, 'accounts_count', 0),
      community: _get(statsSummary, 'community_count', 0),
      postsToDeliver: _get(statsSummary, 'posts_to_deliver', 0),
      publications: _get(statsSummary, 'posts_count', 0) +
        _get(statsSummary, 'stories_count', 0) +
        _get(statsSummary, 'reels_count', 0) +
        _get(statsSummary, 'spotlights_count', 0) +
        _get(statsSummary, 'snapchat_stories_count', 0) +
        _get(statsSummary, 'saved_stories_count', 0),
      publicationsDetails: [
        {
          network: INSTAGRAM,
          count: _get(statsSummary, 'posts_instagram_count', 0)
            + _get(statsSummary, 'stories_count', 0)
            + _get(statsSummary, 'reels_count', 0),
          postsCount: _get(statsSummary, 'posts_instagram_count', 0),
          storiesCount: _get(statsSummary, 'stories_count', 0),
          reelsCount: _get(statsSummary, 'reels_count', 0),
        },
        {
          network: SNAPCHAT,
          count: _get(statsSummary, 'snapchat_stories_count', 0)
            + _get(statsSummary, 'saved_stories_count', 0)
            + _get(statsSummary, 'spotlights_count', 0),
          storiesCount: _get(statsSummary, 'snapchat_stories_count', 0),
          savedStoriesCount: _get(statsSummary, 'saved_stories_count', 0),
          spotlightsCount: _get(statsSummary, 'spotlights_count', 0),
        },
        {
          network: YOUTUBE,
          count: _get(statsSummary, 'posts_youtube_count', 0),
        },
        {
          network: TIK_TOK,
          count: _get(statsSummary, 'posts_tiktok_count', 0),
        },
        {
          network: TWITTER,
          count: _get(statsSummary, 'posts_twitter_count', 0),
        },
        {
          network: FACEBOOK,
          count: _get(statsSummary, 'posts_facebook_count', 0),
        }
      ],
      reach: _get(statsSummary, 'reach_count', 0),
      impressions: _get(statsSummary, 'impressions_count', 0),
      isImpressionsReal: _get(statsSummary, 'is_impressions_count_real', false),
      likes: _get(statsSummary, 'likes_count', 0),
      comments: _get(statsSummary, 'comments_count', 0),
      views: _get(statsSummary, 'views_count', 0),
      plays: _get(statsSummary, 'plays_count', 0),
      // swipeUp: _get(statsSummary, 'swipe_up_count', 0),
      clicks: _get(statsSummary, 'clicks_count', 0),
      shares: _get(statsSummary, 'shares_count', 0),
      saved: _get(statsSummary, 'saved_count', 0),
      replies: _get(statsSummary, 'replies_count', 0),
      screenshots: _get(statsSummary, 'screenshots_count', 0),
      audienceCredibility: _get(statsSummary, 'audience_credibility', 0),
      audienceInterest: _get(statsSummary, 'audience_interests', []),
      audienceReachability: _get(statsSummary, 'audience_reachability', []),
      gendersPerAge: _get(statsSummary, 'genders_per_age', {}),
      genders: _get(statsSummary, 'genders', {}),
      audienceLanguages: _get(statsSummary, 'audience_languages', []),
      topCountries: _get(statsSummary, 'top_countries', []),

      // Affilae
      ...(_has(statsSummary, 'affilae_clicks') ? { affilaeClicks: _get(statsSummary, 'affilae_clicks', 0) } : null),
      ...(_has(statsSummary, 'affilae_conversions') ? { affilaeConversions: _get(statsSummary, 'affilae_conversions', 0) } : null),
      ...(_has(statsSummary, 'affilae_conversion_rate') ? { affilaeConversionRate: _get(statsSummary, 'affilae_conversion_rate', 0) } : null),
      ...(_has(statsSummary, 'affilae_commissions') ? { affilaeCommissions: _get(statsSummary, 'affilae_commissions', 0) } : null),
      ...(_has(statsSummary, 'affilae_revenue') ? { affilaeRevenue: _get(statsSummary, 'affilae_revenue', 0) } : null),
      ...(_has(statsSummary, 'affilae_roi') ? { affilaeRoi: _get(statsSummary, 'affilae_roi', 0) } : null),
      ...(_has(statsSummary, 'affilae_average_basket') ? { affilaeAverageBasket: _get(statsSummary, 'affilae_average_basket', 0) } : null),

      // Affilae aggregated
      ...(_has(statsSummary, 'net_revenue') ? { netRevenue: _get(statsSummary, 'net_revenue', 0) } : null),
      ...(_has(statsSummary, 'roi_kol_spend') ? { roiKolSpend: _get(statsSummary, 'roi_kol_spend', 0) } : null),

      // SHOPIFY
      ...SHOPIFY_COLUMNS_MONITOR.reduce(
        (prev, col) =>
          _has(statsSummary, col) && statsSummary[col] !== null
            ? { ...prev, [col]: statsSummary[col] }
            : prev,
        {},
      ),
    };

    return {
      profiles,
      profilesStatsSum
    }
  },
};

export const exportReporting = {
  toApi: ({ search, startDate, endDate, profiles, networks, types, tags, timezone }) => ({
    filters: {
      author_ids: profiles,
      content: search,
      start_at: startDate ? moment(startDate).format(BACKEND_DATE_FORMAT) : null,
      end_at: endDate ? moment(endDate).format(BACKEND_DATE_FORMAT) : null,
      social_networks: networks,
      publication_type: types,
      tags: tags || [],
    },
    timezone
  })
};

export const putTrackingLinksToProfile = {
  fromApi: record => ({
    profile: _get(record, 'profile', null),
    id: _get(record, 'profile.id', null),
    links: {
      id: _get(record, 'id', null),
      long_link: _get(record, 'long_link', ''),
      short_link: _get(record, 'short_link', ''),
    },
    investedBudget: _get(record, 'invested_budget', null),
    clicks_count: _get(record, 'total_clicks', 0),
    human_clicks_count: _get(record, 'human_clicks', 0),
    top_countries: _get(record, 'countries', []),
    top_cities: _get(record, 'cities', []),
    sna_referrers: _get(record, 'sna_referrers', []),
    ts_conversion_rate: _get(record, 'conversion_rate', 0),
    ts_roas: _get(record, 'roas', 0),
    ts_roi: _get(record, 'roi', 0),
    ts_avg_articles_count: _get(record, 'avg_articles_count', 0),
    ts_avg_basket: _get(record, 'avg_basket', 0),
    ts_revenue: _get(record, 'revenue', 0),
    ts_sales_count: _get(record, 'sales_count', 0),
    ts_articles_count: _get(record, 'articles_count', 0),
  })
}

export const getTrackingLinksTotal = {
  fromApi: total => ({
    links: _get(total, 'tracking_links.count', null),
    investedBudget: _get(total, 'invested_budget', null),
    clicks_count: _get(total, 'total_clicks.sum', null),
    human_clicks_count: _get(total, 'human_clicks.sum', null),
    top_countries: _get(total, 'countries', []),
    top_cities: _get(total, 'cities', []),
    sna_referrers: _get(total, 'sna_referrers', null),
    ts_sales_count: _get(total, 'sales_count.sum', null),
    ts_revenue: _get(total, 'revenue.sum', null),
    ts_avg_basket: _get(total, 'avg_basket.avg', null),
    ts_avg_articles_count: _get(total, 'avg_articles_count.avg', null),
    ts_articles_count: _get(total, 'articles_count.sum', null),
    ts_roi: _get(total, 'roi.sum', null),
    ts_roas: _get(total, 'roas.avg', null),
    ts_conversion_rate: _get(total, 'conversion_rate.avg', null),
  })
}

export const getDiscountCodesProfile = {
  fromApi: profile => ({
    profile: _get(profile, 'profile', null),
    investedBudget: _get(profile, 'invested_budget', null),
    sales_promo_code: profile?.sales_promo_code,
    sales_count: profile?.sales_count,
    sales_turnover: profile?.sales_turnover,
    sales_average_basket: profile?.sales_average_basket,
    sales_roas: profile?.sales_roas,
    sales_roi: profile?.sales_roi,
  })
}

export const getDiscountCodesTotal = {
  fromApi: ({total_row, total_count}) => ({
    investedBudget: _get(total_row, 'invested_budget', null),
    sales_promo_code: total_count,
    sales_count: total_row?.sales_count?.sum,
    sales_turnover: total_row?.sales_turnover?.sum,
    sales_average_basket: total_row?.sales_average_basket?.avg,
    sales_roas: total_row?.sales_roas?.avg,
    sales_roi: total_row?.sales_roi?.sum,
  })
}

export const getProfilePosts = {
  toApi: ({ influencerId, startDate, endDate, search, page, types, perPage, networks }) => ({
    author_ids: [influencerId].flat(),
    content: search,
    start_at: startDate ? moment(startDate).format(BACKEND_DATE_FORMAT) : null,
    end_at: endDate ? moment(endDate).format(BACKEND_DATE_FORMAT) : null,
    page,
    publication_type: types,
    sort_by: 'most_recent',
    social_networks: networks,
    per_page: !perPage ? POSTS_PER_PAGE : perPage,
  }),
  fromApi: ({ total, posts }) => ({
    totalPosts: total,
    posts: mapPosts(posts),
  })
};

export const addPostsToReporting = {
  toApi: posts => ({
    include_ids: posts,
  })
};

export const generateShareToken = {
  fromApi: ({share_token}) => share_token || null
};

export const uploadLogo = {
  toApi: logo => ({
    logo: {
      name: logo.name,
      type: logo.type,
      base64: logo.base64,
    },
  })
};

export const removePostFromReporting = {
  toApi: postsIds => ({
    exclude_ids: postsIds,
  }),
};

export const getCampaignUsersRoles = {
  fromApi: users => (users || []).map(user => ({
    id: user.id,
    userId: user.contact_id,
    fullName: `${user.first_name} ${user.last_name}`,
    role: user.role,
  }))
}

export const setCampaignIsPrivate = {
  toApi: isPrivate => ({
    project: {
      is_private: isPrivate
    }
  })
};

export const setCampaignUserRole = {
  toApi: role => ({role}),
};

export const getCampaignsByUser = {
  fromApi: ({ projects, pagination }) => ({
    pagination,
    campaigns: (projects || []).map(s => ({
      id: String(s.id),
      value: String(s.id),
      name: s.name,
      state: s.state,
      profileIds: s.profile_ids,
      label: s.name,
      role: s.role,
      accountExecutive: s.account_executive ? {
        id: String(s.account_executive.id),
        name: `${s.account_executive.first_name} ${s.account_executive.last_name}`,
        firstName: s.account_executive.first_name,
        lastName: s.account_executive.last_name,
      } : null,
      core: {
        name: s?.name,
        role: s?.role,
        createdAt: s?.created_at,
        createdBy: s?.author_name,
        updatedAt: s?.updated_at,
        isMine: s?.is_mine || false,
        state: s?.state,
        started: s?.state === "started",
        profilesCount: s?.profile_ids?.length,
      },
    })),
  })
  };
