import { get } from 'api';

export const getLocations: (props: {
  search: string;
  isCountryOnly?: boolean;
}) => Promise<{ id: string; countryCode: string; city?: string }[]> = async ({
  search,
  isCountryOnly,
}) => {
  try {
    const res = await get({
      endpoint: 'influence/locations',
      params: {
        location_prefix: search,
        countries_only: isCountryOnly,
      },
    });

    if (!res || res.error) {
      throw res.code;
    }

    return Promise.resolve(
      res.map((location) => ({
        id: location.uid,
        city: location.city ? location.city : null,
        countryCode: location.country_code,
      })),
    );
  } catch (e) {
    console.error("Couldn't get locations: ", e);
    return Promise.reject(e);
  }
};
