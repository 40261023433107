import { TYPE_OPTIONS } from 'components/search/SearchEngine/modules/SearchBar/config';
import { NETWORKS } from 'constants/networks';
import { PaginatedResponse } from 'types';
import { ContentFromApi } from 'types/posts';

export type SimpleRangeFilter = {
  type: 'range';
  min?: number;
  max?: number;
};
export type MultipleFilter = {
  type: 'multiple';
  filters: {
    value: string;
  }[];
};
export type MultipleRangeFilter = {
  type: 'multiple_range';
  filters: {
    value: string;
    min: number;
  }[];
};
export type LocationFilter = {
  type: 'location';
  filters: {
    id: string;
    city?: string;
    country: string;
    min?: number;
  }[];
};

export type NetworkTabFilterName =
  | 'profile_filters'
  | 'instagram_filters'
  | 'tiktok_filters'
  | 'youtube_filters'
  | 'twitter_filters'
  | 'snapchat_filters'
  | 'facebook_filters';

export const TemporarilySavedKeys: (keyof SearchParams)[] = [
  'network_filters_operator',
  'basic_filters',
  'required_networks',
  'profile_genders',
  'profile_type',
  'excluded_project_ids',
  'excluded_list_ids',
  'excluded_tag_ids',
  'exclude_favorites',
  'profile_filters',
  'instagram_filters',
  'youtube_filters',
  'tiktok_filters',
  'twitter_filters',
  'snapchat_filters',
  'facebook_filters',
];

export type SearchParams = {
  id?: number | null;
  profile_ids?: number[];
  content_types?: string[];
  terms: string;
  search_scope: (typeof TYPE_OPTIONS)[number]['value'];
  researchable_element: 'profile' | 'content';
  profile_type: 'brand' | 'profile' | 'all';
  network_filters_operator: 'or' | 'and';
  basic_filters: string[];
  profile_genders: ('male' | 'female' | 'other')[];
  excluded_project_ids: number[];
  excluded_list_ids: number[];
  excluded_tag_ids: number[];
  exclude_favorites: boolean;
  sort:
    | 'relevance'
    | 'engagement_rate'
    | 'latest_post'
    | 'community_count'
    | 'followers_growth'
    | 'posts_engagement_rate_growth';
  saved?: boolean;
  page?: number;
  per_page?: number;
  last_page?: boolean;
  required_networks: (typeof NETWORKS)[number][];
  // Period is not sent to API but transformed to 'from' and 'to'
  period?: string; // 'lastMonth', 'last3Months', 'last6Months', 'lastYear', 'all', '07-07-2023to07-08-2023',
  from?: string; // Date
  to?: string; // Date

  // For search content, if we want statistics
  global_statistics?: boolean;

  // To tell that old search params have been migrated to new
  migrated?: boolean;

  profile_filters: Partial<
    Record<
      'languages' | 'activity_labels' | 'influence_themes',
      MultipleFilter
    > &
      Record<'profile_location', LocationFilter>
  >;
  instagram_filters: Partial<
    Record<
      | 'community_size'
      | 'audience_credibility_score'
      | 'reels_estimated_cost'
      | 'reels_engagement_rate'
      | 'reels_likes_count'
      | 'reels_impressions'
      | 'reels_reach'
      | 'stories_estimated_cost'
      | 'stories_impressions'
      | 'stories_reach'
      | 'posts_engagement_rate'
      | 'posts_estimated_cost'
      | 'posts_engagement_3m'
      | 'posts_comments_3m'
      | 'posts_likes_3m'
      | 'posts_impressions'
      | 'posts_reach'
      | 'compliance_score'
      | 'sponsored_post_rate_3m'
      | 'avg_brand_mentions_per_post_3m',
      SimpleRangeFilter
    > &
      Record<
        | 'languages'
        | 'influence_themes'
        | 'community_ages'
        | 'community_genders',
        MultipleRangeFilter
      > &
      Record<'audience_location', LocationFilter>
  >;
  youtube_filters: Partial<
    Record<
      | 'community_size'
      | 'posts_estimated_cost'
      | 'posts_engagement_rate'
      | 'videos_avg_views_3m',
      SimpleRangeFilter
    > &
      Record<
        'languages' | 'community_ages' | 'community_genders',
        MultipleRangeFilter
      > &
      Record<'audience_location', LocationFilter> &
      Record<'account_location', LocationFilter>
  >;
  tiktok_filters: Partial<
    Record<
      | 'community_size'
      | 'posts_estimated_cost'
      | 'posts_engagement_rate'
      | 'posts_impressions',
      SimpleRangeFilter
    > &
      Record<
        'languages' | 'community_ages' | 'community_genders',
        MultipleRangeFilter
      > &
      Record<'audience_location', LocationFilter>
  >;
  twitter_filters: Partial<
    Record<
      | 'community_size'
      | 'posts_estimated_cost'
      | 'posts_engagement_rate'
      | 'posts_impressions',
      SimpleRangeFilter
    >
  >;
  facebook_filters: Partial<
    Record<
      | 'community_size'
      | 'posts_estimated_cost'
      | 'posts_engagement_rate'
      | 'posts_impressions',
      SimpleRangeFilter
    >
  >;
  snapchat_filters: Partial<
    Record<
      | 'community_size'
      | 'spotlights_engagement_rate'
      | 'spotlights_views'
      | 'spotlights_reach'
      | 'saved_stories_engagement_rate'
      | 'saved_stories_views'
      | 'saved_stories_reach',
      SimpleRangeFilter
    > &
      Record<'community_ages' | 'community_genders', MultipleRangeFilter> &
      Record<'audience_location', LocationFilter>
  >;
};

export type SavedSearch = SearchParams & {
  name: string;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  search?: { name: string; query: string } | null;
};

// Initial state
export type SliceState = SearchParams &
  PaginatedResponse<{
    // Hold profile ids from API
    public_profiles: number[];
    contents: ContentFromApi[];
    statistics_by_account: LaunchSearchResponse['statistics_by_account'];
  }> & {
    // Holds loading for actions
    loading: string[];

    // Temporarily hold previous filters, to restore them after closing drawer
    temp_state: Partial<
      Pick<SliceState, (typeof TemporarilySavedKeys)[number]>
    >;
    // Saved search params
    saved_searches: SavedSearch[];
    enabled: boolean;
  };

// API Structure
type ProfileCoreInfos = {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  pseudo: string;
  gender: 'male' | 'female' | 'other';
  picture_url: string;
  contactable_email?: string;
  is_contactable: boolean;
  is_inactive: boolean;
};
export type PublicProfile = {
  core: ProfileCoreInfos & {
    is_favorite: boolean;
    has_note: boolean;
    is_stat_sharing_active: boolean;
    influence_for_good: boolean;
    arpp_certified: boolean;
    werberat_certified: boolean;
    influencerregels_certified: boolean;
    with_an_email: boolean;
    themes: string[];
    audience_credibility_score: number;
    activities: string[];
  };
  details: ProfileCoreInfos;
  snas: Record<
    number,
    {
      id: number;
      username: string;
      label: (typeof NETWORKS)[number];
      concealed: boolean;
      inactive: boolean;
      spammer: boolean;
      community_count: number;
      avg_views_count: number;
      avg_engagement_rate_per_post: number;
      audience_credibility_score: number | null;
    }
  >;
};

export type LaunchSearchResponse = PaginatedResponse<{
  research_id: number;
  saved: boolean;
  public_profiles: PublicProfile[];
  contents: ContentFromApi[];
  page: number;
  // TODO: adapt when back ready
  statistics_by_account?: Array<{
    social_network: (typeof NETWORKS)[number];
    account_id: number;
    count_ratio: number;
    count: number;
    matched_count: number;
    count_by_content_type: {
      posts?: number;
      stories?: number;
      reels?: number;
      videos?: number;
      shorts?: number;
      tweets?: number;
      saved_stories?: number;
      spotlights?: number;
    };
    avg_matched_engagements: number;
    avg_global_engagements: number;
  }>;
}>;
