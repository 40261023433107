import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import moment from 'moment-timezone';

import * as FOLLOWERS_INCREASE from 'constants/followersIncrease';
import { NETWORKS } from 'constants/networks';
import { PUBLICATION_TYPES_LIST, FILTERS_CONTENT_SORT } from 'config/posts';
import { SOURCES } from 'config/followersIncrease';

export interface FollowserIncreasePost {
  id: string;
  campaigns: string[];
  avatar: string;
  snaId: string;
  network: typeof NETWORKS[number];
  username: string;
  displayName: string;
  publishedAt: string;
  contentUrl: string;
  picture: string;
  video: string;
  content: string;
  stickerLinkUrl?: string;
  profileId: string;
  interactionDetails: { [key: string]: string };
  type: 'video' | 'image';
  isStory: boolean;
  isVideoExpired: boolean;
  isIgtv: boolean;
  isReel: boolean;
  isSnapchatStory: boolean;
  isSnapchatSpotlight: boolean;
}

export interface CategoryTableStats {
  emv: number;
  kols: number;
  stories: number;
  posts: number;
  reels: number;
  engagement: number;
  engagementRate: number;
  distribution: {
    nano: number;
    micro: number;
    macro: number;
    all: number;
  }
}

export interface  CategoryBarStats {
  medias: number;
  engagement: number;
  impressions: number;
}
export interface BarStats {
  date: string;
  evolution: number;
  evolutionRate: number;
  followers: number;
  organic: CategoryBarStats | null;
  owned: CategoryBarStats | null;
  campaign: CategoryBarStats | null;
}

export interface SettingsStats {
  startDateLimit?: string | null;
  dateRanges: {
    startDate: string | null;
    endDate: string | null;
  };
  timeRange: string,
  selectedGraph: 'evolution' | 'total';
  enabledTimeRange: string[],
  filters: {
    selectedPeriod: {
      startDate: string | null;
      endDate: string | null;
    } | null;
    sources: string[];
    contentTypes: string[];
    contentSort: typeof FILTERS_CONTENT_SORT[number];
    currentPage: number;
  }
}

export interface FollowersIncreaseEnv {
  brandMonitoring: {
    id?: number;
    mention?: string;
    brandId?: number;
    brandName?: string;
    picture?: string;
    isAccountReady?: boolean;
    rejectionReason?: string;
  },
  stats: {
    owned?: CategoryTableStats | null,
    campaign?: CategoryTableStats | null,
    organic?: CategoryTableStats | null,
    all?: CategoryTableStats | null,
  },
  growthStats: {
    followers?: number;
    evolution?: number;
    evolutionRate?: number;
    data?: BarStats[];
    medias?: number;
    error?: string;
  };
  contents?: {
    rows: never[],
    total: number,
  }
  settings: SettingsStats;
}

export const defaultSettingsState = {
  dateRanges: {
    startDate: moment().startOf('month').format(),
    endDate: new Date().toISOString(),
  },
  timeRange: 'day',
  selectedGraph: 'evolution' as 'evolution' | 'total',
  enabledTimeRange: ['day'],
  filters: {
    selectedPeriod: null,
    sources: SOURCES,
    contentTypes: PUBLICATION_TYPES_LIST,
    contentSort: 'most_recent',
    currentPage: 0,
  }
}

const initialState: FollowersIncreaseEnv = {
  brandMonitoring: {},
  stats: {},
  growthStats: {
    medias: 0,
  },
  contents: {
    total: 0,
    rows: []
  },
  settings: defaultSettingsState
};

const reducers: {
  [key: string]: CaseReducer<FollowersIncreaseEnv, PayloadAction<any>>;
} = {
  [FOLLOWERS_INCREASE.BRAND_INFOS_LOADED]: (state, { payload: brandMonitoring }) => update(state, {
    brandMonitoring: {
      $set: brandMonitoring
    }
  }),
  [FOLLOWERS_INCREASE.SETTINGS_CHANGED]: (state, { payload }) => update(state, {
    settings: {
      $merge: payload
    }
  }),
  [FOLLOWERS_INCREASE.STATS_LOADED]: (state, { payload: stats }) => update(state, {
    stats: {
      $set: stats
    }
  }),
  [FOLLOWERS_INCREASE.GROWTH_STATS_LOADED]: (state, { payload: stats }) => update(state, {
    growthStats: {
      $set: stats
    }
  }),
  [FOLLOWERS_INCREASE.CONTENTS_LOADED]: (state, { payload: { total, rows } }) => update(state, {
    contents: {
      $set: { total, rows }
    }
  }),
};

export default createReducer(initialState, reducers);
