import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';

import { Loader, Input2 } from 'kolkit';
import { Typography } from 'components/ui';

import styles from './ByAppStep.module.scss';

const STEPS = [
  {
    svgUrl: '/svg/2fa/by_app_download.svg',
    text: 'auth.doubleAuthentication.downloadApp',
  },
  {
    svgUrl: '/svg/2fa/by_app_qr_code.svg',
    text: 'auth.doubleAuthentication.scanQrCode',
  },
  {
    svgUrl: '/svg/2fa/by_app_key.svg',
    text: 'auth.doubleAuthentication.oneTimeCode',
  },
];

interface Props {
  step: number;
  loading?: boolean;
  hideStepTag?: boolean;
  base64Image?: string;
  input?: {
    onChange: (string) => void;
    error?: string;
  }
}

const ByAppStep: React.FC<Props> = ({ step, loading, hideStepTag, base64Image, input }) => {
  const intl = useIntl();
  return (
    <div className="flex aic fdc tac gap16">
      <div className={cn(styles.image, {[styles.hasQrCode]: !!base64Image})}>
        {loading && <Loader full className={styles.loading } />}
        <img
          alt={`Step ${step + 1}`}
          srcSet={base64Image
            ? `data:image/png;base64,${base64Image}`
            : `${STEPS[step]?.svgUrl}`
          }
          className={cn({[styles.isImg]: !!base64Image})}
        />
      </div>
      {!hideStepTag && (
        <Typography variant="text/body medium bold" className={styles.step}>
          {`Step ${step + 1}/${STEPS.length}`}
        </Typography>
      )}
      <Typography variant="title/section-title-S">
        <FormattedMessage id={STEPS[step]?.text} />
      </Typography>
      {input && (
        <Input2
          autoFocus
          size="large"
          fullWidth
          placeholder={intl.formatMessage({
            id: 'auth.doubleAuthentication.modal.setup.placeholder',
            defaultMessage: 'xx xx xx xx',
          })}
          onChange={input?.onChange}
          error={!!input?.error}
          errorMessage={
            input?.error &&
            intl.formatMessage({
              id: `auth.doubleAuthentication.modal.error.${input?.error}`,
            })
          }
        />
      )}
    </div>
  );
};

export default ByAppStep;
