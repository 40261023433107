import React, { memo, useMemo } from 'react';
import { Icon } from 'kolkit';

export default memo(({ closeToast }) => {
	const style = useMemo(
		() => ({
			top: '-8px',
			right: '-6px',
			marginLeft: 4
		}),
		[]
	);

	return (
		<Icon
			style={style}
			label="times"
			onClick={closeToast}
			fill="#fff"
			size="small"
			theme="solid"
			isButton
		/>
	);
})
