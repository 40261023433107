import { createAction } from 'redux-actions';

import * as VIEWS from 'constants/views';
import * as api from 'api/profiles';
import * as mapper from 'mappers/profiles';

import { setControlledLoader } from 'actions/ui';
import { batch } from 'actions/profiles';

export const update = createAction(VIEWS.UPDATE);
export const updateKey = createAction(VIEWS.UPDATE_KEY);
export const updateProfileStats = createAction(VIEWS.UPDATE_PROFILE_STATS);
export const handleSelection = createAction(VIEWS.HAS_SELECTION);
export const updateProfileDetailsId = createAction(VIEWS.UPDATE_PROFILE_DETAILS_ID);
export const resetCampaignDetails = createAction(VIEWS.RESET_CAMPAIGN_DETAILS);
export const resetCampaignDetailsProfileParams = createAction(VIEWS.RESET_CAMPAIGN_DETAILS_PROFILES_PARAMS);
export const loadLookalike = createAction(VIEWS.LOAD_LOOKALIKE);
export const updateLookalike = createAction(VIEWS.UPDATE_LOOKALIKE);
export const updateLookalikeConfigAction = createAction(VIEWS.UPDATE_LOOKALIKE_CONFIG);

const handleKolSelection = createAction(VIEWS.HANDLE_SELECTION);

// add an array of ids to the list of selected profiles in a campaign
export const addProfilesToSelection = (ids, key = 'profiles') => async (
  dispatch,
  getState,
) => {
  const { selectedProfiles } = await getState().views.campaignDetails[key];

  dispatch(
    updateKey({
      view: 'campaignDetails',
      key,
      selectedProfiles: [...selectedProfiles, ...ids],
    }),
  );
};

// remove an array of ids to the list of selected profiles in a campaign
export const removeProfilesFromSelection = (ids, key = 'profiles') => async (dispatch, getState) => {
  const { selectedProfiles } = await getState().views.campaignDetails[key];
  dispatch(
    updateKey({
      view: 'campaignDetails',
      key,
      selectedProfiles: selectedProfiles.filter(id => !ids.includes(id)),
    }),
  );
};

export const resetProfilesFromSelection = (key = 'profiles') => dispatch => {
  dispatch(
    updateKey({
      view: 'campaignDetails',
      key,
      selectedProfiles: [],
    }),
  );
};

export const updateLookalikeConfig = ({ kolId, configKey, value, configKeys, datas, update = null }) => async (dispatch) => {
  await dispatch(updateLookalikeConfigAction({ configKey, value, configKeys, datas }));
  dispatch(getSimilarInfluencers({ update, kolId }))
}

export const getSimilarInfluencers = ({ update, kolId }) => async (dispatch, getState) => {
  const { views: { lookalike: { config: {
    similarType, network, page, perPage
  }}}} = getState();

  const profileIds = [kolId]?.flat();
  if (!profileIds?.length) return null;

  dispatch(setControlledLoader({ id: 'globalLoader', show: true }));

  const response = await api.getLookalike({
    page: update ? page : 0,
    profileIds,
    similarType,
    network,
    perPage,
  });

  const mapperResponse = mapper.getLookalike.fromApi(response);

  if (update) dispatch(updateLookalike(mapperResponse));
  if (!update) dispatch(loadLookalike(mapperResponse));

  dispatch(batch(mapper.loadLegacyProfiles.fromApi(response)));

  dispatch(setControlledLoader({ id: 'globalLoader', show: false }));

  return true;
  // Store profiles
}

export const handlekolSelection = ({ id, type }) => dispatch => {
  dispatch(handleKolSelection({ id, type }))
}

