export const ORDER_COLUMNS_MONITOR = [
  'publications',
  'campaignAverageCommitment',
  'emv',
  'roi',
  'investedBudget',
  'likes',
  'comments',
  'reach',
  'impressions',
  'cpm',
  'shares',
  'saved',
  'views',
  'cpv',
  'plays',
  // 'swipeUp',
  'clicks',
  'replies',
  'screenshots',
];

export const MAX_KOLS_TO_PPT = 1500;
export const TIME_BETWEEN_EXPORT = 10; // minutes

export const EXPORT_REPORTING_FORMATS = [
  'csv',
  'pptx',
  'story',
  'reel',
  'post',
];

export const EXPORT_KOL_FORMATS = ['csv', 'pptx'];
