import _isEqual from 'lodash.isequal';

import {
	COUNTRIES_KEYS,
	INTERESTS_KEYS,
	LANGUAGES_KEYS,
	AUDIENCE_LANGUAGES_KEYS,
	AGE_KEYS,
	KOL_THEME_KEYS,
} from 'components/engine/constants';

import { TREE } from 'utils/hooks/useActivitiesIntl';

export const type = ['influencer', 'socialNetworks'];
export const networks = ['instagram', 'youtube', 'tiktok', 'facebook', 'twitter', 'snapchat'];
export const allCommunitySize = ['communityFacebookSize', 'communityInstagramSize', 'communityTiktokSize', 'communityTwitterSize', 'communityYoutubeSize'];

export const advancedSearchFilters = {
	period: {
		type: "select-one",
		datas: ["lastMonth", "last3Months", "last6Months", "lastYear", "all"],
		default: "all",
	},
};

export const adminFilters = {
	state: {
		datas: ["contactableOnly", "communityAccessOnly", "unqualifiedOnly", "showBrands"],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		default: [],
	},
};

export const configFilters = {
	active: {
		default: true,
		info: 'fake filter to return key in case of no filter',
		mapper: 'active',
	},
	activities: {
		tree: TREE,
		type: "selecttags",
		params: {
			sort: false,
			pinned: ['influencer', 'blogger', 'influencer_global', 'youtuber'],
			collapse: true,
		},
		default: [],
		mapper: 'activities'
	},
	ages: {
		datas: AGE_KEYS,
		type: "toggle-buttons",
		params: {
			sort: false,
		},
		default: [],
		mapper: 'ages',
	},
	credibility: {
		type: 'qualityScore',
		mapper: 'credibility',
		default: 0,
	},
	communityAges: {
		datas: AGE_KEYS,
		type: "selectPercent",
		params: {
			max: 6,
		},
		default: [],
		defaultPercent: '30',
		mapper: 'community_ages',
	},
	communityCountries: {
		datas: COUNTRIES_KEYS,
		type: 'selectPercent',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'community_localizations',
		isLocation: true,
		zendeskFaqPath: 'articles/360018816299-Comment-choisir-de-mani%C3%A8re-pertinente-le-pourcentage-de-localisation-de-l-audience-en-fonction-de-chaque-pays-'
	},
	accountCountry: {
		datas: COUNTRIES_KEYS,
		type: 'selectPercent',
		params: {
			max: 1,
		},
		default: [],
		mapper: 'account_location',
		isLocation: false,
	},
	communityEngagementRate: {
		mapper: 'community_engagement_rate',
		default: {
			min: '',
			max: ''
		}
	},
	communityGender: {
		datas: ['male', 'female'],
		type: "selectPercent",
		params: {
			max: 1,
		},
		default: [],
		defaultPercent: '50',
		mapper: 'community_genders',
	},
	communityInterests: {
		datas: INTERESTS_KEYS,
		type: 'selectPercent',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'community_interests',
	},
	communityLanguages: {
		datas: AUDIENCE_LANGUAGES_KEYS,
		type: 'selectPercent',
		params: {
			max: 3,
			pinned: ['de', 'fr', 'en', 'es', 'it', 'nl', 'pt']
		},
		default: [],
		defaultPercent: '30',
		mapper: 'community_languages',
	},
	communitySize: {
		mapper: 'community_size',
		default: {
			min: '',
			max: ''
		}
	},
	enlisted: {
		datas: ['mobilized'],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		default: false,
		mapper: 'enlisted',
	},
	excludeFavorite: {
		type: 'checkboxes',
		default: false,
		mapper: 'exclude_favorites'
	},
	arpp_certified: {
		type: 'checkboxes',
		default: false,
		mapper: 'arpp_certified'
	},
	excludeTag: {
		datas: [],
		type: 'selectMultiple',
		default: [],
		mapper: 'exclude_tag_ids'
	},
	genders: {
		datas: ['male', 'female', 'other'],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: true,
			column: true,
		},
		default: [],
		mapper: 'genders',
	},
	influencerThemes: {
		datas: KOL_THEME_KEYS,
		type: 'selecttags',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'influencer_themes',
	},
	interests: {
		datas: INTERESTS_KEYS,
		type: 'selecttags',
		params: {
			max: 3,
		},
		default: [],
		mapper: 'interests',
	},
	languages: {
		datas: LANGUAGES_KEYS,
		type: "selecttags",
		params: {
			max: 3,
			sort: false,
			collapse: true,
		},
		default: [],
		mapper: 'langs',
	},
	localisations: {
		type: "selectgmaps",
		params: {
			max: 3,
		},
		default: [],
		mapper: 'localizations',
	},
	period: {
		type: "select-one",
		datas: ["lastMonth", "last3Months", "last6Months", "lastYear", "all"],
		default: "last3Months",
	},
	state: {
		datas: ["contactableOnly", "communityAccessOnly", "unqualifiedOnly", "showBrands"],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		default: [],
	},
	seedingKol: {
		type: 'checkboxes',
		default: false,
		mapper: 'seeder',
	},
	status: {
		datas: ['approved'],
		type: "checkboxes",
		params: {
			sort: false,
			collapse: false,
		},
		mapper: 'status',
		default: false,
	},
	views: {
		mapper: 'views',
		default: {
			min: '',
			max: ''
		}
	},
	viewsAverage: {
		mapper: 'views_average',
		default: {
			min: '',
			max: ''
		}
	},
	reelsEngagementRate: {
		mapper: 'reels_engagement_rate',
		default: {
			min: '',
			max: ''
		}
	},
	reelsEstimatedImpressions: {
		mapper: 'reels_estimated_impressions',
		default: {
			min: '',
			max: ''
		}
	},
	reelsEstimatedReach: {
		mapper: 'reels_estimated_reach',
		default: {
			min: '',
			max: ''
		}
	},
	reelsPlays: {
		mapper: 'reels_plays',
		default: {
			min: '',
			max: ''
		}
	},
	reelsLikes: {
		mapper: 'reels_likes',
		default: {
			min: '',
			max: ''
		}
	},
	storiesEngagementRate: {
		mapper: 'stories_engagement_rate',
		default: {
			min: '',
			max: ''
		}
	},
	storiesEstimatedImpressions: {
		mapper: 'stories_estimated_impressions',
		default: {
			min: '',
			max: ''
		}
	},
	storiesEstimatedReach: {
		mapper: 'stories_estimated_reach',
		default: {
			min: '',
			max: ''
		}
	},
	storiesPlays: {
		mapper: 'stories_plays',
		default: {
			min: '',
			max: ''
		}
	},
	storiesLikes: {
		mapper: 'stories_likes',
		default: {
			min: '',
			max: ''
		}
	},
};

export const allFilters = {
	...adminFilters,
	...advancedSearchFilters
};

export const isDefault = (filter, value) => {
	const f = allFilters[filter];
	if (!f) return !value || value === '' || value?.length === 0;
	switch (f.type) {
		case "selecttags":
		case 'checkboxes':
		case 'toggle-buttons':
			return value?.length === 0;
		case 'community-sna-size':
			return _isEqual(value, f.default);
		case "input":
		case "select":
		default:
			return value === '' || value === f.default;
	}
};

export const sortBy = ['communitySize', 'commitment', 'mostRecent', 'relevance'];
export const dateSlices = ['lastMonth', 'last3Months', 'last6Months', 'lastYear', 'all'];

export const defaultFilters = {
	activeFilter: [
		"instagram",
		"youtube",
		"tiktok",
		"facebook",
		"twitter"
	],
	socialNetworks: {
		"instagram": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			},
			"communityInterests": [],
			"communityCountries": [],
			"communityLanguages": [],
			"communityAges": [],
			"communityGender": []
		},
		"youtube": {
			"active": true,
			"accountCountry": [],
			"communitySize": {
				"min": "",
				"max": ""
			},
			"viewsAverage": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			},
			"communityCountries": [],
			"communityAges": [],
			"communityGender": [],
			"communityLanguages": [],
		},
		"tiktok": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			},
			"communityCountries": [],
			"communityLanguages": [],
			"communityAges": [],
			"communityGender": []
		},
		"facebook": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			}
		},
		"twitter": {
			"active": true,
			"communitySize": {
				"min": "",
				"max": ""
			},
			"communityEngagementRate": {
				"min": "",
				"max": ""
			}
		}
	}
}
