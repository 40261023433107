import itemsConfig from 'components/engine/config/itemsConfig';
import { configFilters, networks, adminFilters } from './filters';


const initInfluenceFilter = itemsConfig[0].filters
	.reduce((acc, item) => ({
			...acc,
			[item]: configFilters[item].default,
		}), {});

export const initSocialNetworksFilter = itemsConfig
	.filter((item, index) => index !== 0)
	.reduce((acc, item) => ({
		...acc,
		[item.label]: item.filters.reduce((subAcc, filter) => ({
			...subAcc,
			[filter]: (!configFilters[filter].default && configFilters[filter].default !== 0) ?
				{...configFilters[filter]} :
				configFilters[filter].default,
		}), {})
	}), {});

const initAdminFilter = Object.entries(adminFilters)
	.reduce((acc, [key,]) => ({
		...acc,
		[key]: configFilters[key].default,
	}), {});

export const initialFilter = {
	...initInfluenceFilter,
	socialNetworks: {
		...initSocialNetworksFilter,
	},
};

export const initSearchFilter = {
	activeFilter: [...networks],
	...initInfluenceFilter,
	socialNetworks: {
		...initSocialNetworksFilter,
	},
};

export const filter = {
	...initSearchFilter,
	hasActiveFilter: false,
	search: [],
	period: configFilters.period.default,
};

export const filters = {
	activeFilter: [...networks],
	...initInfluenceFilter,
	socialNetworks: {
		...initSocialNetworksFilter,
	},
	...initAdminFilter,
	hasActiveFilter: false,
	search: [],
	period: configFilters.period.default,
};

export const sortBy = "communitySize";
