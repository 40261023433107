
export const mappedProfile = {
  fromApi: profile => ({
    ...profile,
    snas: profile.social_network_accounts
      ?.reduce((acc, sna) => ({
        ...acc,
        [sna.sna_id]: { ... sna }
      }), {})
  })
}