import { get, post, remove, put } from 'api';
import * as mappers from 'mappers/messaging';
import { initialState } from 'reducers/messaging';

export const getMessages = ({ name, filterByStatusId, profileId, campaignId, readStatus, page, perPage }) => get({
  endpoint: `influence/conversations`,
  params: Object.assign(
    {},
    {
      page: !page ? 0 : page,
      per_page: perPage,
    },
    name && { name },
    campaignId && { project_id: campaignId },
    profileId && { profile_id: profileId },
    readStatus && { read_status: readStatus },
    filterByStatusId && { proposition_status_id: filterByStatusId },
  ),
});

export const getMessage = ({ conversationId }) => get({
  endpoint: `influence/conversations/${conversationId}`,
});

export const getConversationMessages = ({ conversationId, page }) => get({
  endpoint: `influence/conversations/${conversationId}/messages`,
  params: {
    page,
    per_page: initialState.conversation.messages.perPage
  },
});

export const getKols = ({ name, mySelection, page, perPage, campaignId }) => get({
  endpoint: `influence/conversations/search_profiles`,
  params: mappers.searchKolParams.toApi({ name, mySelection, page, perPage, campaignId }),
})

export const createConversations = params => post({
  endpoint: `influence/conversations`,
  params,
  catchErrors: [422],
})

export const sendMessage = (conversationId, params) => post({
  endpoint: `influence/conversations/${conversationId}/messages`,
  params,
  catchErrors: [422],
});

export const saveGmailAuthorizationCode = params => post({
  endpoint: `influence/gmail_accounts`,
  params,
});

export const removeGmailAuthorizationCode = gmailAddress => remove({
  endpoint: `influence/gmail_accounts`,
  params: { gmail_address: gmailAddress },
  debug: true,
});

export const addToCampaign = (conversationId, campaignId) => post({
  endpoint: `influence/conversations/${conversationId}/add_to_project`,
  params: { project_id: campaignId }
})

export const hasConversation = (profileId, campaignId) => get({
  endpoint: `influence/conversations/find`,
  params: Object.assign(
    {},
    campaignId && { project_id: campaignId },
    profileId && { profile_id: profileId },
  ),
  catchErrors: [400, 404],
})

export const getCampaign = ({ name, page, perPage }) => get({
  endpoint: `influence/conversations/search_projects`,
  params: Object.assign(
    {},
    name && { name },
    page && { page },
    perPage && { per_page: perPage },
  ),
});

export const getDrafts = (params) => get({
  endpoint: `influence/draft_messages`,
  params
});

export const postDraft = draft => post({
  endpoint: 'influence/draft_messages',
  params: draft
});

export const updateDraft = ({ draftId, draft }) => put({
  endpoint: `influence/draft_messages/${draftId}`,
  params: draft,
  catchErrors: [404],
});

export const deleteDraft = id => remove({
  endpoint: `influence/draft_messages/${id}`,
  catchErrors: [403, 404],
});

export const findDraft = params => post({
  endpoint: 'influence/draft_messages/find',
  params,
  catchErrors: [404],
});

// TEMPLATING PART
export const createTemplate = (template) => post({
  endpoint: `influence/message_templates`,
  params: template
})

export const getTemplates = () => get({
  endpoint: `influence/message_templates`,
})

export const updateTemplate = ({ templateId, title, content }) => put({
  endpoint: `influence/message_templates/${templateId}`,
  params: Object.assign(
    {},
    title && { title },
    content && { content },
  )
})

export const updateTemplates = (templates) => put({
  endpoint: `influence/message_templates/batch_update`,
  params: templates
})

export const removeTemplate = templateId => remove({
  endpoint: `influence/message_templates/${templateId}`,
})

export const loadPreview = previewParams =>
  get({
    endpoint: `influence/conversations/preview`,
    params: previewParams,
  });
