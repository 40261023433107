import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { planhatTraker } from 'utils/tags';
import useLocalStorage from 'utils/hooks/useLocalStorage';


const useTrackingLogin = () => {
  const [hours, setHours] = useState(null);
  const [value, setValue] = useLocalStorage('session_influence', null);

  const getDateInHours = useCallback(
    (date) => moment().diff(moment(date?.toString()), 'hours'),
    []
  );

  useEffect(() => {
    if (value) {
      setHours(() => getDateInHours(value))
    } else {
      planhatTraker({
        action: 'Daily activity',
      }).then(() => setValue(Date.now()))
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (Number(hours) >= 24) {
      console.log('new daily session');
      planhatTraker({
        action: 'Daily activity',
      }).then(() => setValue(Date.now()))
    }
    // eslint-disable-next-line
  }, [hours])

  return true;
}

export default useTrackingLogin;
