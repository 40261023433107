
const itemsConfig = [
  {
    id: 'influencer',
    label: 'KOL',
    type: 'influencer',
    icon: 'user',
    theme: 'thin',
    checkbox: false,
    filters: [
      'enlisted',
      'credibility',
      'activities',
      'influencerThemes',
      'excludeFavorite',
      'arpp_certified',
      'excludeTag',
      'localisations',
      'seedingKol',
      'status',
      'languages',
      'ages',
      'genders'
    ]
  },
  {
    id: 'communityInstagramSize',
    label: 'instagram',
    type: 'socialNetwork',
    icon: 'instagram-color',
    theme: 'brand',
    active: true,
    checkbox: true,
    filters: [
      'active',
      'communitySize',
      'communityEngagementRate',
      'communityInterests',
      'communityCountries',
      'communityLanguages',
      'communityAges',
      'communityGender',
      'reelsEngagementRate',
      'reelsEstimatedImpressions',
      'reelsEstimatedReach',
      'reelsPlays',
      'reelsLikes',
      'storiesEngagementRate',
      'storiesEstimatedImpressions',
      'storiesEstimatedReach',
      'storiesPlays',
      'storiesLikes',
    ]
  },
  {
    id: 'communityYoutubeSize',
    label: 'youtube',
    type: 'socialNetwork',
    icon: 'youtube-color',
    theme: 'brand',
    active: true,
    checkbox: true,
    filters: [
      'active',
      'accountCountry',
      'communitySize',
      'viewsAverage',
      'communityEngagementRate',
      'communityCountries',
      'communityAges',
      'communityGender',
      'communityLanguages',
    ]
  },
  {
    id: 'communityTiktokSize',
    label: 'tiktok',
    type: 'socialNetwork',
    icon: 'tiktok-color',
    theme: 'brand',
    active: true,
    checkbox: true,
    filters: [
      'active',
      'communitySize',
      'communityEngagementRate',
      'communityCountries',
      'communityLanguages',
      'communityAges',
      'communityGender'
    ]
  },
  {
    id: 'communityFacebookSize',
    label: 'facebook',
    type: 'socialNetwork',
    icon: 'facebook-color',
    theme: 'brand',
    active: true,
    checkbox: true,
    filters: [
      'active',
      'communitySize',
      'communityEngagementRate',
    ]
  },
  {
    id: 'communityTwitterSize',
    label: 'twitter',
    type: 'socialNetwork',
    icon: 'twitter-color',
    theme: 'brand',
    active: true,
    checkbox: true,
    filters: [
      'active',
      'communitySize',
      'communityEngagementRate',
    ]
  }
];

export default itemsConfig;
