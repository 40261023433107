export const pageNames = {
  REQUEST_PASSWORD_RESET: 'request_password_reset',
  RESET_PASSWORD: 'reset_password',
  ACTIVATION: 'activation',
  CONFIRMATION: 'confirmation',
};

export const tokenTypes = {
  RESET_PASSWORD: 'reset_password_token',
  ACTIVATION: 'activation_token',
  CONFIRMATION: 'confirmation_token',
};

export const MINIMUM_PASSWORD_LENGTH = 12;