import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import cn from 'classnames';

import Checkbox from '../SelectionControls/Checkbox';
import Button3 from '../Button3';

import styles from './Modal2.module.scss';

const Footer2 = ({ action, hint, ...rest }) => {
  const { primary, secondary, tertiary, checkbox } = action;

  const getId = nanoid();

  const displayPrimaryButton = useMemo(
    () => {
      if (!primary) return null;

      if (typeof primary === 'object' && 'title' in primary) {
        return (
          <Button3
            onClick={primary.onClick}
            disabled={primary.disabled}
            tooltip={primary.tooltip}
            dataId={primary.dataId}
            fullWidth={primary.fullWidth}
            size={primary.size}
            {...primary}
          >
            {primary.title}
          </Button3>
        )
      }

      return primary;
    },
    [primary]
  );

  const displaySecondaryButton = useMemo(
    () => secondary && 'title' in secondary && (
      <Button3
        className={styles.secondaryButton}
        theme="tertiary"
        onClick={secondary.onClick}
        disabled={secondary.disabled}
        tooltip={secondary.tooltip}
        dataId={secondary.dataId}
        {...secondary}
      >
        {secondary.title}
      </Button3>
    ),
    [secondary]
  );

  const displayTertiaryButton = useMemo(
    () => {
      const cnTertiary = cn(styles.tertiary, {[styles.isAlignRight]: tertiary?.isAlignRight});
      return tertiary && 'title' in tertiary && (
        <div className={cnTertiary}>
          <Button3
            theme={tertiary.theme || "secondary"}
            onClick={tertiary.onClick}
            disabled={tertiary.disabled}
            tooltip={tertiary.tooltip}
            dataId={tertiary.dataId}
            {...tertiary}
          >
            {tertiary.title}
          </Button3>
        </div>
      )
    },
    [tertiary]
  );

  const displayCheckbox = useMemo(
    () => checkbox && (
      <Checkbox
        className={styles.checkbox}
        theme="KolLab"
        label={checkbox.title}
        id={`${getId}-${checkbox.title}`}
        name={`${getId}-${checkbox.title}`}
        checked={checkbox.checked}
        onChange={checkbox.onChange}
        disabled={checkbox.disabled}
        contentTooltip={checkbox.tooltip}
        dataId={checkbox.dataId}
      />
    ),
    [checkbox, getId]
  );

  return (
    <div className={styles.footer} {...rest}>
      {hint}
      <div className={styles.footerActions}>
        {displayCheckbox}
        {displayTertiaryButton}
        {displaySecondaryButton}
        {displayPrimaryButton}
      </div>
    </div>
  );
};

Footer2.propTypes = {
  action: PropTypes.shape({
    primary: PropTypes.oneOfType([
      PropTypes.shape({
        onClick: PropTypes.func,
        title: PropTypes.string,
        tooltip: PropTypes.string,
        dataId: PropTypes.string,
        disabled: PropTypes.bool,
        fullWidth: PropTypes.bool
      }),
      PropTypes.element,
    ]),
    secondary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      dataId: PropTypes.string,
      disabled: PropTypes.bool
    }),
    tertiary: PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      dataId: PropTypes.string,
      disabled: PropTypes.bool,
      isAlignRight: PropTypes.bool,
      theme: PropTypes.string,
    }),
    checkbox: PropTypes.shape({
      title: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      tooltip: PropTypes.string,
      dataId: PropTypes.string,
      disabled: PropTypes.bool,
    })
  }),
  hint: PropTypes.element
};

Footer2.defaultProps = {
  action: {
    primary: null,
    secondary: null,
    tertiary: null
  },
  hint: null
};

export default memo(Footer2);
